import { FC, useMemo, useState } from "react";
import clsx from "clsx";
import Dialog from "../../libs/dialog/Dialog";
import WidgetTabs, { WidgetTabType } from "../../libs/widget-tabs/WidgetTabs";
import DashboardNftsGradesItem, {
    gradeItemType,
} from "./DashboardNftsGradesItem";
import Alert from "../../libs/alert/Alert";
import useUserInfo from "../../hooks/useUserInfo";
import styles from "./DashboardNfts.module.scss";

type Props = {
    className?: string;
    defaultTabKey?: string;
    open: boolean;
    onClose(): void;
};

type UserGradeReqType = {
    mint_count: number;
    invite_points: number;
    network_points: number;
};

const gradeTabs: WidgetTabType[] = [
    {
        key: "standard",
        title: "Standard",
    },
    {
        key: "bronze",
        title: "Bronze",
        selectedClassName: styles.gradeWidgetTabSelectedBronze,
    },
    {
        key: "silver",
        title: "Silver",
        selectedClassName: styles.gradeWidgetTabSelectedSilver,
    },
    {
        key: "gold",
        title: "Gold",
        selectedClassName: styles.gradeWidgetTabSelectedGold,
    },
    {
        key: "platinum",
        title: "Platinum",
        selectedClassName: styles.gradeWidgetTabSelectedPlatinum,
    },
];

const gradeItems: gradeItemType[] = [
    {
        key: "standard",
        requirements: [
            {
                content: "1 mint (Or hold 1 CRC NFT)",
            },
        ],
        perks: [
            {
                content: "Join 60% Net-Profit Sharing",
                hint: "We will airdrop the net-profit from all of CRC platforms (lottery games, VPN services and more) to our holders.",
            },
            {
                content: "Access to CRC network",
                hint: "Earn 15% from your direct invite and 20% more from your network on 5 circles.",
            },
            {
                content: "Earn 5% from invited funding",
                hint: "Invite your friends to add funds in FORIXO's campaigns and earn 5% of their funds.",
            },
            {
                content: "Early Access to our Platforms",
                hint: "You can view and test our platforms before launch and give us your valuable comments.",
            },
            {
                content: "Access to the CRCDAO",
                hint: "Participate in CRC DAO and partake in voting with the community to enhance the ecosystem.",
            },
            {
                content: "Access to the Education System",
                hint: "Visit our discord to join and learn more about the education system and maximize your gains in blockchain universe.",
            },
            // {
            //     content: "Access to the CRC Alpha calls",
            //     hint: "Visit our discord to join alpha calls, where you'll receive alerts about the most promising developments in the crypto and NFT.",
            // },
        ],
    },
    {
        key: "bronze",
        requirements: [
            {
                content: "2 mints",
                hint: "You need to mint 2 CRC NFTs with the same wallet. Your future mints will also automatically upgrde.",
            },
            {
                content: "2 invites (Each NFT)",
                hint: "You can manually upgrade your selected NFT by using 2 invite points.",
            },
        ],
        perks: [
            {
                content: "All Standard perks",
                hint: "All of the perks of Standard Grade is included too.",
            },
            {
                content: "Lifetime premium VPN account",
                hint: "As long as you hold your NFT, you can enjoy our premium VPN service.",
            },
            {
                content: "Access to the trading education",
                hint: "Visit our discord to join Trading Education channel and become a profitable trader.",
            },
            /*{
                content: "Access to the business accelerator (Coming soon)",
                hint: "If you have an online business, We got you covered with 1 month free VPN on each of your sells and more.",
            },*/
            {
                content: "2x your task rewards",
                hint: "Earn 2x from upcoming tasks and spent it on your next mint.",
            },
        ],
    },
    {
        key: "silver",
        requirements: [
            {
                content: "5 mints",
                hint: "You need to mint 5 CRC NFTs with the same wallet. Your future mints will also automatically upgrde.",
            },
            {
                content: "5 total invites (Each NFT)",
                hint: "You can manually upgrade your selected bronze NFT by using 3 invite points.",
            },
        ],
        perks: [
            {
                content: "All Bronze perks",
                hint: "All of the perks of Bronze Grade is included too.",
            },
            {
                content: "Earn 10% from invited funding",
                hint: "Invite your friends to add funds in FORIXO's campaigns and earn 10% of their funds.",
            },
            {
                content: "Access to the Trading signal",
                hint: "Visit our discord to join Trading Signal channel and earn by trading with us.",
            },
            /*{
                content: "2x business accelerator perks",
                hint: "Expand your business by 2 month free VPN on each of your sells and even more boosters for your business.",
            },*/
            {
                content: "3x your task rewards",
                hint: "Earn 3x from upcoming tasks and spent it on your next mint.",
            },
        ],
    },
    {
        key: "gold",
        requirements: [
            {
                content: "10 mints",
                hint: "You need to mint 10 CRC NFTs with the same wallet. Your future mints will also automatically upgrde.",
            },
        ],
        perks: [
            {
                content: "All Silver perks",
                hint: "All of the perks of Silver Grade is included too.",
            },
            {
                content: "Access to VIP Club (Exclusive 10% profit sharing)",
                hint: "Enroll into our private VIP Club and earn more with our exclusive 10% net-profit sharing and more opportunities to come.",
            },
            {
                content: "Special Bronze invite link",
                hint: "You can use the additional invite link, so invited users will be able to jumpt to Bronze Grade by only minting 1 CRC NFT.",
            },
            {
                content: "Keep your network after mint event",
                hint: "We have so much plans for the network after the mint event. Don't miss future gains from your stablished network.",
            },
            {
                content: "4x your task rewards",
                hint: "Earn 4x from upcoming tasks and spent it on your next mint.",
            },
        ],
    },
    {
        key: "platinum",
        requirements: [
            {
                content: "50 mints",
                hint: "You need to mint 50 CRC NFTs with the same wallet. Your future mints will also automatically upgrde.",
            },
            {
                content: "rarity rank of 1-10 (Each NFT)",
                hint: "CRC NFTs that has 1-10 rarity rank will automatically upgrade.",
            },
        ],
        perks: [
            {
                content: "All Gold perks",
                hint: "All of the perks of Gold Grade is included too.",
            },
            {
                content: "Access to V-VIP Club (Exclusive 10% profit sharing)",
                hint: "Enroll into our private V-VIP Club and earn more with our exclusive 10% net-profit sharing and more opportunities to come.",
            },
            {
                content: "Lifetime premium VPN (Family pack)",
                hint: "Make your lifetime VPN account into 4 accounts with our family pack.",
            },
            {
                content: "5x your task rewards",
                hint: "Earn 5x from upcoming tasks and spent it on your next mint.",
            },
        ],
    },
];

const DashboardNftsGrades: FC<Props> = ({
    className,
    defaultTabKey,
    open,
    onClose,
}) => {
    const { fullConnected, loadingUserInfo, userInfo } = useUserInfo();
    const [selectedTabKey, setSelectedTabKey] = useState<string>(
        defaultTabKey || gradeTabs[0].key
    );

    const userGradeReq: UserGradeReqType | null = useMemo(() => {
        if (fullConnected && !loadingUserInfo && userInfo) {
            const mintCount = userInfo.mints_info?.approved_mint_count || 0;
            if (mintCount > 0) {
                return {
                    mint_count: userInfo.mints_info?.approved_mint_count || 0,
                    invite_points: userInfo.grades_info.invite_points,
                    network_points: userInfo.grades_info.network_points,
                };
            }
        }

        return null;
    }, [fullConnected, loadingUserInfo, userInfo]);

    return (
        <Dialog
            className={clsx(styles.dialog, className)}
            onClose={onClose}
            open={open}
            boxProps={{
                title: "CRC NFT Grades",
            }}
        >
            <Alert severity="info" className={styles.gradeAlert}>
                {userGradeReq != null
                    ? `You currently have ${userGradeReq.mint_count} Mints, ${userGradeReq.network_points} Network Points, ${userGradeReq.invite_points} Invite Points.`
                    : `After minting your first NFT, you can upgrade your NFTs by fulfilling the requirements.`}
            </Alert>

            <WidgetTabs
                tabs={gradeTabs}
                selectedTabKey={selectedTabKey}
                onSelect={setSelectedTabKey}
            >
                <DashboardNftsGradesItem
                    gradeItem={
                        gradeItems.find(
                            (gradeItem) => gradeItem.key === selectedTabKey
                        )!
                    }
                />
            </WidgetTabs>
        </Dialog>
    );
};

export default DashboardNftsGrades;
