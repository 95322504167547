import { FC, useState, useEffect } from "react";
import clsx from "clsx";
import Box from "src/libs/box/Box";
import useUserInfo from "src/hooks/useUserInfo";
import { Api } from "src/api/root";
import { HiArrowNarrowRight } from "react-icons/hi";
import Feature from "src/libs/feature/Feature";
import DashboardInvitesNetworksDialog from "./DashboardInvitesNetworksDialog";
import WalletConnectText from "src/components/wallet-connect/WalletConnectText";
import useSettings from "../../hooks/useSettings";
import DashboardInvitesNetworksTree from "./DashboardInvitesNetworksTree";
import styles from "./DashboardInvites.module.scss";

type Props = {
    className?: string;
};

const DashboardInvitesNetworks: FC<Props> = ({ className }) => {
    const { settings } = useSettings();
    const { fullConnected } = useUserInfo();
    const [dialogOpen, setDialogOpen] = useState(false);

    const api = Api.invites().useGetInviteCircles();

    useEffect(() => {
        api.run({});
        return () => api.abort();
    }, [
        fullConnected,
        settings?.launch.id,
        api.state.pageNum,
        api.state.perPage,
    ]);

    const handleViewAll = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <Box
            className={clsx(className)}
            footerRender={
                fullConnected &&
                !!api.state.data?.length && (
                    <div className={styles.boxFooter}>
                        <div className={styles.total} onClick={handleViewAll}>
                            {`Total: ${api.state.totalNum}`}
                        </div>
                        <div className={styles.viewAll} onClick={handleViewAll}>
                            <span>View All</span>
                            <HiArrowNarrowRight className={styles.icon} />
                        </div>
                    </div>
                )
            }
        >
            {fullConnected ? (
                <>
                    <DashboardInvitesNetworksTree />
                    <DashboardInvitesNetworksDialog
                        open={dialogOpen}
                        onClose={handleCloseDialog}
                    />
                </>
            ) : (
                <Feature type="message">
                    <WalletConnectText>Connect Wallet</WalletConnectText> to see
                    your network users
                </Feature>
            )}
        </Box>
    );
};

export default DashboardInvitesNetworks;
