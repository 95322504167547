import { GradeTagType } from "./grade";
import { InvitedMintType } from "src/types/mint";
import { UserInfoType, UserShortType } from "./user";
import { CoinType } from "./coin";

export type InviteInfoType = {
    id: number;
    from_special_invite?: GradeTagType;
    active_invited_user_count: number;
    active_network_user_count: number;
    total_invited_user_count: number;
    total_network_user_count: number;
    total_rewards: number;
    is_activated: boolean;
    last_invite_date: number;
    user?: UserInfoType;
    invited_by?: UserShortType;
};

export type InviteInfoShortType = {
    id: number;
    active_invited_user_count: number;
    active_network_user_count: number;
    total_invited_user_count: number;
    total_network_user_count: number;
    last_invite_date: number;
    user_short?: UserShortType;
    invited_by?: UserShortType;
};

export type InviteCircleType = {
    id: number;
    circle_num: number;
    invited_date: number;
    invite_activated_date?: number;
    invited_user_info: InviteInfoShortType;
};

export type CircleCommission = {
    circle: number;
    mint_commission: number;
    invite_ib_commission_usd: number;
};

export type CommissionRule = {
    key: number;
    mintRange: CommissionRuleRange;
    invitedMintRange: CommissionRuleRange;
    commission: number;
};

export type CommissionRuleRange = {
    fixed?: number;
    from?: number;
    to?: number;
    fromEqual?: number;
    toEqual?: number;
};

export type InviteLeaderboardRule = {
    rank_range: CommissionRuleRange;
    reward_sol: number;
    has_one_by_one: boolean;
};

export type InviteRewardType = {
    id: number;
    circle_num: number;
    reason: InviteRewardReasonType;
    reward_amount: number;
    commission_percent?: number;
    rewarded_date: number;
    invited_user: UserShortType;
    reward_coin: CoinType;
};

export type InviteStatType = {
    id: number;
    invited_mint_count: number;
    invited_invest_count: number;
    invited_ib_count: number;
};

export type InviteRewardReasonType =
    | "invited_mint"
    | "invest_commission"
    | "invest_ib";

export const inviteRewardReasonTitles: Record<InviteRewardReasonType, string> =
    {
        invited_mint: "Mint",
        invest_commission: "Funding",
        invest_ib: "IB",
    };

export const inviteCirclesxample: InviteCircleType[] = [
    {
        id: 1,
        circle_num: 1,
        invited_date: 1663164281,
        invite_activated_date: 1663164281,
        invited_user_info: {
            id: 1,
            active_invited_user_count: 5,
            active_network_user_count: 70,
            total_invited_user_count: 5,
            total_network_user_count: 70,
            last_invite_date: 1675164200,
            user_short: {
                id: 2,
                sol_wallet_short: "s89n...NB10",
                display_name: "Deport",
            },
        },
    },
    {
        id: 2,
        circle_num: 1,
        invited_date: 1663164281,
        invite_activated_date: 1663164281,
        invited_user_info: {
            id: 2,
            active_invited_user_count: 5,
            active_network_user_count: 70,
            total_invited_user_count: 5,
            total_network_user_count: 70,
            last_invite_date: 1675164200,
            user_short: {
                id: 4,
                sol_wallet_short: "nN78...BVc5",
            },
        },
    },
    {
        id: 3,
        circle_num: 2,
        invited_date: 1663164281,
        invite_activated_date: 1663164281,
        invited_user_info: {
            id: 3,
            active_invited_user_count: 5,
            active_network_user_count: 70,
            total_invited_user_count: 5,
            total_network_user_count: 70,
            last_invite_date: 1675164200,
            user_short: {
                id: 5,
                sol_wallet_short: "zb77...7nBB",
            },
        },
    },
    {
        id: 4,
        circle_num: 2,
        invited_date: 1663164281,
        invite_activated_date: 1663164281,
        invited_user_info: {
            id: 3,
            active_invited_user_count: 5,
            active_network_user_count: 70,
            total_invited_user_count: 5,
            total_network_user_count: 70,
            last_invite_date: 1675164200,
            user_short: {
                id: 5,
                sol_wallet_short: "zb77...7nBB",
            },
        },
    },
    {
        id: 5,
        circle_num: 2,
        invited_date: 1663164281,
        invite_activated_date: 1663164281,
        invited_user_info: {
            id: 3,
            active_invited_user_count: 5,
            active_network_user_count: 70,
            total_invited_user_count: 5,
            total_network_user_count: 70,
            last_invite_date: 1675164200,
            user_short: {
                id: 5,
                sol_wallet_short: "zb77...7nBB",
            },
        },
    },
    {
        id: 6,
        circle_num: 2,
        invited_date: 1663164281,
        invite_activated_date: 1663164281,
        invited_user_info: {
            id: 3,
            active_invited_user_count: 5,
            active_network_user_count: 70,
            total_invited_user_count: 5,
            total_network_user_count: 70,
            last_invite_date: 1675164200,
            user_short: {
                id: 5,
                sol_wallet_short: "zb77...7nBB",
            },
        },
    },
    {
        id: 7,
        circle_num: 2,
        invited_date: 1663164281,
        invite_activated_date: 1663164281,
        invited_user_info: {
            id: 3,
            active_invited_user_count: 5,
            active_network_user_count: 70,
            total_invited_user_count: 5,
            total_network_user_count: 70,
            last_invite_date: 1675164200,
            user_short: {
                id: 5,
                sol_wallet_short: "zb77...7nBB",
            },
        },
    },
    {
        id: 8,
        circle_num: 2,
        invited_date: 1663164281,
        invite_activated_date: 1663164281,
        invited_user_info: {
            id: 3,
            active_invited_user_count: 5,
            active_network_user_count: 70,
            total_invited_user_count: 5,
            total_network_user_count: 70,
            last_invite_date: 1675164200,
            user_short: {
                id: 5,
                sol_wallet_short: "zb77...7nBB",
            },
        },
    },
    {
        id: 9,
        circle_num: 2,
        invited_date: 1663164281,
        invite_activated_date: 1663164281,
        invited_user_info: {
            id: 3,
            active_invited_user_count: 5,
            active_network_user_count: 70,
            total_invited_user_count: 5,
            total_network_user_count: 70,
            last_invite_date: 1675164200,
            user_short: {
                id: 5,
                sol_wallet_short: "zb77...7nBB",
            },
        },
    },
    {
        id: 10,
        circle_num: 2,
        invited_date: 1663164281,
        invite_activated_date: 1663164281,
        invited_user_info: {
            id: 3,
            active_invited_user_count: 5,
            active_network_user_count: 70,
            total_invited_user_count: 5,
            total_network_user_count: 70,
            last_invite_date: 1675164200,
            user_short: {
                id: 5,
                sol_wallet_short: "zb77...7nBB",
            },
        },
    },
    {
        id: 11,
        circle_num: 2,
        invited_date: 1663164281,
        invite_activated_date: 1663164281,
        invited_user_info: {
            id: 3,
            active_invited_user_count: 5,
            active_network_user_count: 70,
            total_invited_user_count: 5,
            total_network_user_count: 70,
            last_invite_date: 1675164200,
            user_short: {
                id: 5,
                sol_wallet_short: "zb77...7nBB",
            },
        },
    },
];

export const inviteRewardsExample: InviteRewardType[] = [
    {
        id: 1,
        reason: "invited_mint",
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 1,
        rewarded_date: 1663164281,
        invited_user: {
            id: 1,
            sol_wallet_short: "JHUy...I82N",
        },
        reward_coin: {
            id: 1,
            name: "Solana",
            symbol: "SOL",
            price_usd: 34.21,
            decimals: 9,
        },
    },
    {
        id: 2,
        reason: "invited_mint",
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 2,
        rewarded_date: 1663164281,
        invited_user: {
            id: 1,
            sol_wallet_short: "JHUy...I82N",
        },
        reward_coin: {
            id: 1,
            name: "Solana",
            symbol: "SOL",
            price_usd: 34.21,
            decimals: 9,
        },
    },
    {
        id: 3,
        reason: "invited_mint",
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 1,
        rewarded_date: 1663164281,
        invited_user: {
            id: 1,
            sol_wallet_short: "JHUy...I82N",
        },
        reward_coin: {
            id: 1,
            name: "Solana",
            symbol: "SOL",
            price_usd: 34.21,
            decimals: 9,
        },
    },
    {
        id: 4,
        reason: "invited_mint",
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 1,
        rewarded_date: 1663164281,
        invited_user: {
            id: 2,
            sol_wallet_short: "y65T...oP9v",
        },
        reward_coin: {
            id: 1,
            name: "Solana",
            symbol: "SOL",
            price_usd: 34.21,
            decimals: 9,
        },
    },
    {
        id: 5,
        reason: "invest_commission",
        commission_percent: 10,
        reward_amount: 100,
        circle_num: 1,
        rewarded_date: 1663164281,
        invited_user: {
            id: 1,
            sol_wallet_short: "JHUy...I82N",
        },
        reward_coin: {
            id: 1,
            name: "USDC",
            symbol: "USDC",
            price_usd: 1,
            decimals: 6,
        },
    },
];

export const invitedMintsExample: InvitedMintType[] = [
    {
        id: 1,
        invited_user: {
            id: 1,
            sol_wallet_short: "JHUy...I82N",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663164281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "auditing",
            added_date: 1663164281,
            minted_date: 1663164281,
        },
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 1,
        rewarded_date: 1663164281,
    },
    {
        id: 2,
        invited_user: {
            id: 1,
            sol_wallet_short: "JHUy...I82N",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663164281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "rejected",
            added_date: 1663164281,
            minted_date: 1663164281,
        },
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 2,
        rewarded_date: 1663164281,
    },
    {
        id: 3,
        invited_user: {
            id: 1,
            sol_wallet_short: "JHUy...I82N",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663164281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "approved",
            added_date: 1663164281,
            minted_date: 1663164281,
        },
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 1,
        rewarded_date: 1663164281,
    },
    {
        id: 4,
        invited_user: {
            id: 2,
            sol_wallet_short: "y65T...oP9v",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663164281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "approved",
            added_date: 1663164281,
            minted_date: 1663164281,
        },
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 1,
        rewarded_date: 1663164281,
    },
    {
        id: 5,
        invited_user: {
            id: 1,
            sol_wallet_short: "JHUy...I82N",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663164281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "approved",
            added_date: 1663164281,
            minted_date: 1663164281,
        },
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 3,
        rewarded_date: 1663164281,
    },
    {
        id: 6,
        invited_user: {
            id: 2,
            sol_wallet_short: "y65T...oP9v",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663164281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "approved",
            added_date: 1663164281,
            minted_date: 1663164281,
        },
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 3,
        rewarded_date: 1663164281,
    },
    {
        id: 7,
        invited_user: {
            id: 2,
            sol_wallet_short: "y65T...oP9v",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663164281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "approved",
            added_date: 1663164281,
            minted_date: 1663164281,
        },
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 4,
        rewarded_date: 1663164281,
    },
    {
        id: 8,
        invited_user: {
            id: 1,
            sol_wallet_short: "JHUy...I82N",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663164281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "approved",
            added_date: 1663164281,
            minted_date: 1663164281,
        },
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 1,
        rewarded_date: 1663164281,
    },
    {
        id: 9,
        invited_user: {
            id: 1,
            sol_wallet_short: "JHUy...I82N",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663164281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "approved",
            added_date: 1663164281,
            minted_date: 1663164281,
        },
        commission_percent: 7,
        reward_amount: 0.0315,
        circle_num: 1,
        rewarded_date: 1663164281,
    },
    {
        id: 10,
        invited_user: {
            id: 3,
            sol_wallet_short: "Cv3V...L8t7",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663164281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "approved",
            added_date: 1663154281,
            minted_date: 1663164281,
        },
        commission_percent: 5,
        reward_amount: 0.0225,
        circle_num: 1,
        rewarded_date: 1663164281,
    },
    {
        id: 11,
        invited_user: {
            id: 3,
            sol_wallet_short: "Cv3V...L8t7",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663154281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "approved",
            added_date: 1663154281,
            minted_date: 1663154281,
        },
        commission_percent: 5,
        reward_amount: 0.0225,
        circle_num: 1,
        rewarded_date: 1663164281,
    },
    {
        id: 12,
        invited_user: {
            id: 1,
            sol_wallet_short: "JHUy...I82N",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663154281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "approved",
            added_date: 1663154281,
            minted_date: 1663154281,
        },
        commission_percent: 5,
        reward_amount: 0.0225,
        circle_num: 1,
        rewarded_date: 1663164281,
    },
    {
        id: 13,
        invited_user: {
            id: 3,
            sol_wallet_short: "Cv3V...L8t7",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663154281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "approved",
            added_date: 1663154281,
            minted_date: 1663154281,
        },
        commission_percent: 5,
        reward_amount: 0.0225,
        circle_num: 1,
        rewarded_date: 1663164281,
    },
    {
        id: 14,
        invited_user: {
            id: 2,
            sol_wallet_short: "y65T...oP9v",
        },
        mint_unit: {
            id: 1,
            coin: {
                id: 1,
                name: "Solana",
                symbol: "SOL",
                price_usd: 34.21,
                decimals: 9,
            },
            meta_data: {
                id: 1,
                name: "NFT #1",
                meta_url: "https://arweave.org/something",
                image_url: "https://arweave.org/something",
                rarity_rank: 2132,
                synced_date: 1663154281,
            },
            mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
            mint_price: 0.45,
            status: "approved",
            added_date: 1663154281,
            minted_date: 1663154281,
        },
        commission_percent: 5,
        reward_amount: 0.0225,
        circle_num: 1,
        rewarded_date: 1663164281,
    },
];
