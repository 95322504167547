import { TaskMissionType } from "./task";

export type PointUserType = {
    id: number;
    available_points: number;
    total_points: number;
    unread_points: number;
    unread_count: number;
    last_updated_date: number;
};

export type PointRecordType = {
    id: number;
    associated_with: 'task_mission';
    point_amount: number;
    is_read: boolean;
    created_date: number;
    task_mission?: TaskMissionType;
};

export const pointRecordsExample: PointRecordType[] = [];