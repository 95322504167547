import { FC, ReactNode, useMemo, useState } from "react";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import Box from "../../libs/box/Box";
import Feature from "../../libs/feature/Feature";
import useUserInfo from "../../hooks/useUserInfo";
import Counter from "../../libs/counter/Counter";
import Pair from "../../libs/pair/Pair";
import styles from "./DashboardInvites.module.scss";

type Props = {
    className?: string;
    onOpenGuide(): void;
};

const DashboardInvitesSummary: FC<Props> = ({ className, onOpenGuide }) => {
    const { userInfo, fullConnected, loadingUserInfo } = useUserInfo();

    const mintRewards = useMemo(() => {
        if (!userInfo?.mints_info) return 0;

        return (
            userInfo.mints_info.auditing_network_mint_count +
            userInfo.mints_info.approved_network_mint_count
        );
    }, [userInfo?.mints_info]);

    const investRewards = useMemo(() => {
        if (!userInfo?.invites_stat) return 0;

        return userInfo.invites_stat.invited_invest_count;
    }, [userInfo?.invites_stat]);

    const ibRewards = useMemo(() => {
        if (!userInfo?.invites_stat) return 0;

        return userInfo.invites_stat.invited_ib_count;
    }, [userInfo?.invites_stat]);

    const myInvites = useMemo(() => {
        return userInfo?.invites_info?.active_invited_user_count || 0;
    }, [userInfo?.invites_info]);

    const myNetwork = useMemo(() => {
        return userInfo?.invites_info?.active_network_user_count || 0;
    }, [userInfo?.invites_info]);

    const renderUserInfo = (content: ReactNode) => {
        return fullConnected ? (
            !loadingUserInfo ? (
                content
            ) : (
                <Skeleton width={40} />
            )
        ) : (
            "—"
        );
    };

    return (
        <Box className={clsx(className)} title="Network Overview">
            <div className={styles.invitesSummaryRow}>
                <Feature className={styles.invitesSummaryItem}>
                    <Pair
                        title="Mint Rewards"
                        titlePosition="bottom"
                        value={renderUserInfo(<Counter number={mintRewards} />)}
                    />
                </Feature>

                <Feature className={styles.invitesSummaryItem}>
                    <Pair
                        title="Funding Rewards"
                        titlePosition="bottom"
                        value={renderUserInfo(
                            <Counter number={investRewards} />
                        )}
                    />
                </Feature>

                <Feature className={styles.invitesSummaryItem}>
                    <Pair
                        title="IB Rewards"
                        titlePosition="bottom"
                        value={renderUserInfo(<Counter number={ibRewards} />)}
                    />
                </Feature>
            </div>

            <div className={styles.invitesSummaryRow}>
                <Feature className={styles.invitesSummaryItem}>
                    <Pair
                        title="My Invites"
                        titlePosition="bottom"
                        value={renderUserInfo(<Counter number={myInvites!} />)}
                    />
                </Feature>

                <Feature className={styles.invitesSummaryItem}>
                    <Pair
                        title="My Network"
                        titlePosition="bottom"
                        value={renderUserInfo(<Counter number={myNetwork!} />)}
                    />
                </Feature>

                <div
                    className={clsx(
                        styles.invitesSummaryItem,
                        styles.circlesGuideButton
                    )}
                    onClick={onOpenGuide}
                >
                    Network Guide
                </div>
            </div>
        </Box>
    );
};

export default DashboardInvitesSummary;
