import { FC, PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import ReactLoading from "react-loading";
import { BsArrowRightCircle } from "react-icons/bs";
import styles from "./FormButton.module.scss";

type Props = {
    className?: string;
    [x: string]: any;
    color?:
        | "primary"
        | "secondary"
        | "on"
        | "off"
        | "base"
        | "mute"
        | "colorfull"
        | "gold"; // default: primary
    loading?: boolean;
    loadingtText?: string;
    disabled?: boolean;
    extraInfo?: ReactNode;
    onClick?(e?: any): void;
};

const FormButton: FC<PropsWithChildren<Props>> = ({
    children,
    className,
    color,
    loading,
    loadingtText,
    disabled,
    extraInfo,
    onClick,
    ...attributes
}) => {
    const handleClick = (e: any) => {
        if (onClick && !loading && !disabled) {
            onClick(e);
        }
    };

    return (
        <button
            {...attributes}
            className={clsx(
                styles.button,
                color == "secondary" && styles.buttonSecondary,
                color == "on" && styles.buttonOn,
                color == "off" && styles.buttonOff,
                color == "mute" && styles.buttonMute,
                color == "base" && styles.buttonBase,
                color == "colorfull" && styles.buttonColorfull,
                color == "gold" && styles.buttonColorGold,
                loading && styles.buttonLoading,
                disabled && styles.buttonDisabled,
                className
            )}
            onClick={handleClick}
        >
            <div
                className={clsx(
                    styles.buttonContentWrap,
                    loading && styles.buttonContentWrapHidden
                )}
            >
                {!!extraInfo && (
                    <>
                        <div className={styles.buttonExtraInfo}>
                            {extraInfo}
                        </div>
                        <div className={styles.buttonTriangle}></div>
                    </>
                )}
                <div
                    className={clsx(
                        styles.buttonContent,
                        extraInfo && styles.buttonContentWithExtra
                    )}
                >
                    {children}
                </div>
            </div>

            {loading && (
                <div className={styles.loadingWrap}>
                    <ReactLoading
                        type="spin"
                        width={24}
                        height={24}
                        color="var(--feature-bg)"
                    />
                    {!!loadingtText && (
                        <div className={styles.loadingTextWrap}>
                            <span>{loadingtText}</span>
                            <div className={styles.loadingTextDots}></div>
                        </div>
                    )}
                </div>
            )}
        </button>
    );
};

export default FormButton;
