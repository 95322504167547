import {
    FC,
    createContext,
    useContext,
    useEffect,
    useState,
    PropsWithChildren,
} from "react";
import useSettings from "./useSettings";
import useUserInfo from "./useUserInfo";
import useWalletBalance from "./useWalletBalance";

const LiveContext = createContext<{
    manualUpdate(): Promise<void>;
    updateCount: number;
}>({
    manualUpdate: async () => {},
    updateCount: 0,
});

const useLive = () => useContext(LiveContext);

export const LiveProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
    const [effect, setEffect] = useState<boolean>(false);
    const { updateBalance } = useWalletBalance();
    const { fullConnected, updateUserInfo } = useUserInfo();
    const { updateSettings } = useSettings();
    const [updateCount, setUpdateCount] = useState(0);

    useEffect(() => {
        let timout: NodeJS.Timeout;
        if (fullConnected) {
            timout = setTimeout(() => {
                if (document.visibilityState === "visible") {
                    update();
                    setUpdateCount(updateCount + 1);
                }

                setEffect(!effect);
            }, 30_000);
        }

        return () => {
            if (timout) clearTimeout(timout);
        };
    }, [fullConnected, effect]);

    const update = async () => {
        updateBalance();
        updateUserInfo();
        updateSettings();
    };

    const manualUpdate = async () => {
        setEffect(!effect);
    };

    return (
        <LiveContext.Provider
            value={{
                manualUpdate,
                updateCount,
            }}
        >
            {children}
        </LiveContext.Provider>
    );
};

export default useLive;
