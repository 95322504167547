import { ApiSubItems } from "./general";
import { CoinType, CoinNetworkType } from "./coin";
import { MethodType } from "./method";
import { FiatType } from "./fiat";

export interface ClientType {
    id: number;
    address?: string;
    tag: string;
    type: "main" | "sub" | "agent";
    solana_cluster: "mainnet" | "testnet" | "devnet";
    renew_off_percent?: number;
    direct_visit_off_percent?: number;
    primary_fiat?: FiatType;
    primary_coin?: CoinType;
    advertised_fiat?: FiatType;
    advertised_coin?: CoinType;
    client_methods?: ApiSubItems<ClientMethodType>;
}

export type ClientMethodType = {
    id: number;
    method: MethodType;
    client_currencies: ApiSubItems<ClientCurrencyType>;
};

export type ClientCurrencyType = {
    id: number;
    percent_off?: number;
    is_default?: boolean;
    order_num: number;
    shares: ApiSubItems<ClientCurrencyShareType>;
};

export type ClientCurrencyShareType = {
    id: number;
    share_percent: number;
    fiat?: FiatType;
    coin?: CoinType;
    coin_network?: CoinNetworkType;
};

export interface ClientFullType extends ClientType {
    sub_info?: ClientSubType;
}

export type ClientSubType = {
    id: number;
    brand: string;
    domain?: string;
    sub_domain?: string;
    tier: ClientSubTierType;
    reward_percent: number;
    is_claim_restricted?: boolean;
};

export type ClientSubTierType = "one" | "two" | "three";

export type ClientUserType = {
    id: number;
    approved_payments?: number;
    total_payments?: number;
    live_accounts?: number;
    paid_accounts?: number;
    live_servers?: number;
    paid_servers?: number;
    catch_direct_visit_off?: boolean;
    joined_date: number;
    last_paid_date?: number;
};

export type ClientRewardType = {
    id: number;
    associated_with: "shop_payment";
    original_reward_percent: number;
    reward_percent: number;
    reward_amount: number;
    rewarded_date: number;
    reward_fiat?: FiatType;
    reward_coin?: CoinType;
};

export const clientExample: ClientType = {
    id: 1,
    tag: "main",
    type: "main",
    solana_cluster: "devnet",
    renew_off_percent: 5,
    direct_visit_off_percent: 10,
    primary_fiat: {
        id: 1,
        name: "US Dollar",
        symbol: "USD",
        sign: "$",
        price_usd: 1,
        updated_date: 1667031139,
    },
    advertised_coin: {
        id: 1,
        name: "Solana",
        symbol: "SOL",
        price_usd: 104.21,
        decimals: 9,
    },
    client_methods: {
        count: 1,
        items: [
            {
                id: 1,
                method: {
                    id: 1,
                    network: "solana_chain",
                    solana_cluster: "mainnet",
                    title: "Solana Mainnet",
                },
                client_currencies: {
                    count: 2,
                    items: [
                        {
                            id: 1,
                            order_num: 1,
                            percent_off: 10,
                            shares: {
                                count: 1,
                                items: [
                                    {
                                        id: 1,
                                        share_percent: 100,
                                        coin: {
                                            id: 1,
                                            name: "Solana",
                                            symbol: "SOL",
                                            price_usd: 104.21,
                                            decimals: 9,
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            id: 2,
                            is_default: true,
                            order_num: 2,
                            shares: {
                                count: 1,
                                items: [
                                    {
                                        id: 2,
                                        share_percent: 100,
                                        coin: {
                                            id: 2,
                                            name: "USD Coin",
                                            symbol: "USDC",
                                            price_usd: 1,
                                            decimals: 6,
                                        },
                                        coin_network: {
                                            id: 1,
                                            network: "solana_chain",
                                            solana_cluster: "mainnet",
                                            contract_address:
                                                "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", // devnet: 4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU
                                            platform_coin: {
                                                id: 1,
                                                name: "Solana",
                                                symbol: "SOL",
                                                price_usd: 34.21,
                                                decimals: 9,
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
        ],
    },
};

export const myClientExample: ClientFullType = {
    ...clientExample,
    sub_info: {
        id: 1,
        brand: "Example Co",
        domain: "example.com",
        tier: "one",
        reward_percent: 40,
    },
};
