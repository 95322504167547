import { FC, useMemo, useState } from "react";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import Box from "../../libs/box/Box";
import Feature from "../../libs/feature/Feature";
import Pair from "../../libs/pair/Pair";
import DashboardNftsGrades from "./DashboardNftsGrades";
import useUserInfo from "../../hooks/useUserInfo";
import Counter from "../../libs/counter/Counter";
import { GradeTagType, gradeTitles } from "../../types/grade";
import Alert from "../../libs/alert/Alert";
import styles from "./DashboardNfts.module.scss";

type Props = {
    className?: string;
};

type gradeAvailableItemType = { gradeTag: GradeTagType; available: number };

const DashboardNftsSummary: FC<Props> = ({ className }) => {
    const { userInfo, fullConnected, loadingUserInfo } = useUserInfo();
    const [openGrades, setOpenGrades] = useState<boolean>(false);

    const gradesNftCount: Record<GradeTagType, number> = useMemo(() => {
        const targetGradesNftCount = {
            standard: 0,
            bronze: 0,
            silver: 0,
            gold: 0,
            platinum: 0,
        };

        if (userInfo?.grades_info.grade_stats) {
            userInfo.grades_info.grade_stats.items.forEach((gradeStatItem) => {
                targetGradesNftCount[gradeStatItem.grade.tag] =
                    gradeStatItem.registered_nft_count;
            });
        }

        return targetGradesNftCount;
    }, [userInfo?.grades_info]);

    const gradeAvailableList: gradeAvailableItemType[] = useMemo(() => {
        const _gradeAvailableList: gradeAvailableItemType[] = [];

        if (userInfo?.grades_info.grade_stats) {
            userInfo.grades_info.grade_stats.items.forEach((gradeStatItem) => {
                if (gradeStatItem.available_nft_count > 0) {
                    _gradeAvailableList.push({
                        gradeTag: gradeStatItem.grade.tag,
                        available: gradeStatItem.available_nft_count,
                    });
                }
            });
        }

        return _gradeAvailableList;
    }, [userInfo?.grades_info]);

    return (
        <Box className={clsx(className)} title="NFTs Grades Overview">
            {gradeAvailableList.length > 0 && (
                <Alert severity="info" className={styles.gradeEligibleAlert}>
                    Hooray! Your NFT(s) are now eligible for an upgrade (
                    {gradeAvailableList
                        .map(
                            (gradeAvailableItem) =>
                                `${gradeAvailableItem.available} for ${
                                    gradeTitles[gradeAvailableItem.gradeTag]
                                }`
                        )
                        .join(", ")}
                    )
                </Alert>
            )}

            <div className={styles.nftsSummary}>
                <Feature>
                    <Pair
                        title="Standard NFTs"
                        titlePosition="bottom"
                        value={
                            fullConnected ? (
                                !loadingUserInfo ? (
                                    <Counter number={gradesNftCount.standard} />
                                ) : (
                                    <Skeleton width={40} />
                                )
                            ) : (
                                "—"
                            )
                        }
                    />
                </Feature>
                <Feature>
                    <Pair
                        title="Bronze NFTs"
                        titlePosition="bottom"
                        value={
                            fullConnected ? (
                                !loadingUserInfo ? (
                                    <Counter number={gradesNftCount.bronze} />
                                ) : (
                                    <Skeleton width={40} />
                                )
                            ) : (
                                "—"
                            )
                        }
                    />
                </Feature>
                <Feature>
                    <Pair
                        title="Silver NFTs"
                        titlePosition="bottom"
                        value={
                            fullConnected ? (
                                !loadingUserInfo ? (
                                    <Counter number={gradesNftCount.silver} />
                                ) : (
                                    <Skeleton width={40} />
                                )
                            ) : (
                                "—"
                            )
                        }
                    />
                </Feature>
                <Feature>
                    <Pair
                        title="Gold NFTs"
                        titlePosition="bottom"
                        value={
                            fullConnected ? (
                                !loadingUserInfo ? (
                                    <Counter number={gradesNftCount.gold} />
                                ) : (
                                    <Skeleton width={40} />
                                )
                            ) : (
                                "—"
                            )
                        }
                    />
                </Feature>
                <Feature>
                    <Pair
                        title="Platinum NFTs"
                        titlePosition="bottom"
                        value={
                            fullConnected ? (
                                !loadingUserInfo ? (
                                    <Counter number={gradesNftCount.platinum} />
                                ) : (
                                    <Skeleton width={40} />
                                )
                            ) : (
                                "—"
                            )
                        }
                    />
                </Feature>
                <div
                    className={styles.gradesGuideButton}
                    onClick={() => setOpenGrades(true)}
                >
                    Grades Guide
                </div>
            </div>

            <DashboardNftsGrades
                open={openGrades}
                onClose={() => setOpenGrades(false)}
            />
        </Box>
    );
};

export default DashboardNftsSummary;
