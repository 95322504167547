import { FC, useState, useEffect } from "react";
import clsx from "clsx";
import SwitchTabGroup from "src/libs/switch-tabs/SwitchTabGroup";
import SwitchTab from "src/libs/switch-tabs/SwitchTab";
import DashboardMint from "src/components/dashboard-mint/DashboardMint";
import DashboardNfts from "src/components/dashboard-nfts/DashboardNfts";
import DashboardInvites from "src/components/dashboard-invites/DashboardInvites";
import DashboardInvest from "../dashboard-invest/DashboardInvest";
import useUserInfo from "src/hooks/useUserInfo";
import useResponsive from "src/hooks/useResponsive";
import { DashboardTab } from "src/types/controller";
import useControllerDashboard from "src/hooks/useControllerDashboard";
import Counter from "src/libs/counter/Counter";
import DashboardMessage from "./DashboardMessage";
import useSettings from "../../hooks/useSettings";
import { IoIosPeople } from "react-icons/io";
import useInvite from "../../hooks/useInvite";
import styles from "./Dashboard.module.scss";

type Props = {
    className?: string;
};

const Dashboard: FC<Props> = ({ className }) => {
    const [isMobile] = useResponsive();
    const { storageInviteCode } = useInvite();
    const { userInfo, fullConnected } = useUserInfo();
    const { activeTab, setActiveTab } = useControllerDashboard();
    const [nftCount, setNftCount] = useState<number | null>(null);
    const [networkCount, setNetworkCount] = useState<number | null>(null);
    const { setFixedMessage } = useSettings();

    useEffect(() => {
        if (userInfo) {
            if (userInfo.mints_info) {
                setNftCount(
                    userInfo.mints_info.auditing_mint_count +
                        userInfo.mints_info.approved_mint_count
                );
                setNetworkCount(
                    userInfo.invites_info?.active_network_user_count || 0
                );
            } else {
                setNftCount(0);
                setNetworkCount(0);
            }
        } else {
            setNftCount(null);
            setNetworkCount(null);
        }
    }, [fullConnected, userInfo]);

    useEffect(() => {
        if (
            userInfo?.invites_info?.invited_by /* &&
            (!userInfo.mints_info ||
                userInfo.mints_info?.approved_mint_count == 0)*/
        ) {
            setFixedMessage({
                key: "welcome",
                icon: <IoIosPeople />,
                title: `You're invited to join our community.`,
                subtitle: `By ${
                    userInfo.invites_info.invited_by.sol_wallet_short
                }${
                    userInfo.invites_info.from_special_invite
                        ? " (Special " +
                          userInfo.invites_info.from_special_invite +
                          " Invite)"
                        : ""
                }`,
            });
        } else {
            setFixedMessage(null);
        }
    }, [userInfo, fullConnected, isMobile, storageInviteCode]);

    return (
        <div className={clsx(styles.dashboard, className)}>
            <SwitchTabGroup
                type={!isMobile ? "round" : "line"}
                activeTab={activeTab}
                onChange={(activeTab: DashboardTab) => setActiveTab(activeTab)}
            >
                <SwitchTab tag={DashboardTab.MINT} label="Mint">
                    <DashboardMessage />
                    <DashboardMint />
                </SwitchTab>

                <SwitchTab
                    tag={DashboardTab.NFTS}
                    label={
                        <>
                            My NFTs
                            {/* {fullConnected && nftCount != null && (
                                <>
                                    {" ("}
                                    <Counter number={nftCount} />
                                    {")"}
                                </>
                            )} */}
                        </>
                    }
                >
                    <DashboardNfts />
                </SwitchTab>

                <SwitchTab
                    tag={DashboardTab.INVITES}
                    label={
                        <>
                            Network
                            {/* {fullConnected && networkCount != null && (
                                <>
                                    {" ("}
                                    <Counter number={networkCount} />
                                    {")"}
                                </>
                            )} */}
                        </>
                    }
                >
                    <DashboardInvites />
                </SwitchTab>

                <SwitchTab tag={DashboardTab.INVESTS} label="FORIXO">
                    <DashboardInvest />
                </SwitchTab>
            </SwitchTabGroup>
        </div>
    );
};

export default Dashboard;
