import { FC, ReactNode } from "react";
import clsx from "clsx";
import { IoClose } from "react-icons/io5";
import useSettings from "../../hooks/useSettings";
import useResponsive from "../../hooks/useResponsive";
import Pair from "../../libs/pair/Pair";
import CountdownUI from "../../libs/countdown-ui/CountdownUI";
import { DashboardMessageType } from "../../types/general";
import styles from "./Dashboard.module.scss";

type Props = {
    className?: string;
};

const DashboardMessage: FC<Props> = ({ className }) => {
    const { fixedMessage, setFixedMessage } = useSettings();
    const [isMobile] = useResponsive();

    if (fixedMessage == null) return null;

    const renderDashboardMessage = (discountMessage: DashboardMessageType) => {
        return (
            <div
                className={clsx(
                    styles.messageWrap,
                    isMobile && styles.messageWrapMobile
                )}
            >
                {!isMobile && (
                    <div className={styles.messageIconWrap}>
                        {discountMessage.icon}
                    </div>
                )}

                <Pair
                    className={styles.messageTitleWrap}
                    value={discountMessage.title}
                    title={discountMessage.subtitle}
                    titlePosition="bottom"
                />

                {!!discountMessage.countdownSeconds && (
                    <CountdownUI
                        className={styles.messageCountdown}
                        isWide={isMobile}
                        date={
                            Date.now() + discountMessage.countdownSeconds * 1000
                        }
                        continueWithKey={discountMessage.key}
                        onExpire={() => {
                            setFixedMessage(null);
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <div
            className={clsx(
                styles.wrap,
                isMobile && styles.wrapMobile,
                className
            )}
        >
            <div className={styles.content}>
                {renderDashboardMessage(fixedMessage)}
            </div>
            <div
                className={styles.close}
                onClick={() => {
                    setFixedMessage(null);
                }}
            >
                <IoClose className={styles.icon} />
            </div>
        </div>
    );
};

export default DashboardMessage;
