import { useApi, UseApiOptions } from "src/hooks/useApi";
import { invitedMintsExample } from "src/types/invite";
import { InvitedMintType, MintUnitType, nftsExample } from "src/types/mint";
import { ApiGetGroupParams, apiTestMode } from "./root";

export interface GetMintUnitsParams extends ApiGetGroupParams {
}

export interface GetMintRewardsParams extends ApiGetGroupParams {
}

export interface AddMintParams {
    launch_stage_id: number;
    mint_list: {
        mint_address: string;
        mint_trx: string;
        mint_uri: string;
    }[];
}

export class UseMintsApi {
    useGetMintUnits(config?: UseApiOptions<MintUnitType[]>) {
        return useApi<GetMintUnitsParams, MintUnitType[]>({
            method: "GET",
            path: "/mints/units",
            needAuth: true,
            isList: true,
            test: {
                enable: apiTestMode,
                data: nftsExample,
            },
            ...config,
        });
    }

    useGetMintRewards(config?: UseApiOptions<InvitedMintType[]>) {
        return useApi<GetMintRewardsParams, InvitedMintType[]>({
            method: "GET",
            path: "/mints/rewards",
            needAuth: true,
            isList: true,
            test: {
                enable: apiTestMode,
                data: invitedMintsExample,
            },
            ...config,
        });
    }

    useAddMint(config?: UseApiOptions<{}>) {
        return useApi<AddMintParams, {}>({
            method: "POST",
            path: "/mints",
            needAuth: true,
            test: {
                enable: apiTestMode,
            },
            ...config,
        });
    }
}
