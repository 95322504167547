import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import Dialog from "../../libs/dialog/Dialog";
import AnimateHeight from "react-animate-height";
import { MdKeyboardArrowDown, MdOutlineTaskAlt } from "react-icons/md";
import { FiClock } from "react-icons/fi";
import ProfileConnect from "./ProfileConnect";
import useUserInfo from "../../hooks/useUserInfo";
import Alert from "@material-ui/lab/Alert/Alert";
import useConnectionPortal, {
    platformInfo,
} from "../../hooks/useConnectionPortal";
import { ConnectionType } from "../../types/connection";
import styles from "./Profile.module.scss";

type ProfileActivateType = {
    title: ReactNode;
    content: ReactNode;
};

type Props = {
    className?: string;
    open: boolean;
    onClose(): void;
};

const ProfileActivate: FC<Props> = ({ className, open, onClose }) => {
    const { userInfo } = useUserInfo();
    const { pendingPortalInfo } = useConnectionPortal();
    const [taskOpenIndex, setTaskOpenIndex] = useState<number>(-1);

    // open connect task if it's in the middle of connecting
    useEffect(() => {
        if (
            pendingPortalInfo?.has_redirected &&
            pendingPortalInfo.platform == "discord" &&
            pendingPortalInfo.request_source == "activate_network"
        ) {
            setTaskOpenIndex(0);
        }
    }, [pendingPortalInfo]);

    const mintCount = useMemo(() => {
        return userInfo?.mints_info?.approved_mint_count || 0;
    }, [userInfo]);

    const discordConnection: ConnectionType | undefined = useMemo(() => {
        return userInfo?.connections?.items.find(
            (connection) => connection.platform == "discord"
        );
    }, [userInfo]);

    const twitterConnection: ConnectionType | undefined = useMemo(() => {
        return userInfo?.connections?.items.find(
            (connection) => connection.platform == "twitter"
        );
    }, [userInfo]);

    const renderTaskTitle = (
        number: number,
        title: string,
        isDone: boolean
    ) => {
        return (
            <div className={styles.activateTitleRow}>
                <div className={styles.activateTitle}>
                    <div className={styles.number}>{number}</div>
                    <div className={styles.title}>{title}</div>
                </div>
                <div
                    className={clsx(
                        styles.activateState,
                        isDone && styles.activateStateDone
                    )}
                >
                    {isDone ? (
                        <MdOutlineTaskAlt className={styles.icon} />
                    ) : (
                        <FiClock className={styles.icon} />
                    )}
                    <div className={styles.state}>
                        {isDone ? "Done" : "Pending"}
                    </div>
                </div>
            </div>
        );
    };

    const renderTaskList: ProfileActivateType[] = useMemo(() => {
        return [
            {
                title: renderTaskTitle(
                    1,
                    "Connect to Discord",
                    !!(
                        discordConnection?.is_connected &&
                        discordConnection?.joined_to_channel
                    )
                ),
                content: (
                    <div className={styles.activateContentRow}>
                        {discordConnection?.is_connected &&
                            !discordConnection.joined_to_channel && (
                                <Alert
                                    className={styles.connectAlert}
                                    severity="warning"
                                >
                                    Please{" "}
                                    <a
                                        href={
                                            platformInfo["discord"].channel_url
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        join our server
                                    </a>{" "}
                                    on Discord to complete this step.
                                </Alert>
                            )}

                        <ProfileConnect
                            requestSource="activate_network"
                            platform="discord"
                        />
                    </div>
                ),
            },
            /*{
                title: renderTaskTitle(
                    2,
                    "Connect to Twitter",
                    !!twitterConnection?.is_connected
                ),
                content: (
                    <div className={styles.activateContentRow}>
                        <ProfileConnect
                            requestSource="activate_network"
                            platform="twitter"
                        />
                    </div>
                ),
            },*/
            {
                title: renderTaskTitle(2, "Mint 1 CRC NFT", mintCount > 0),
                content: (
                    <div
                        className={clsx(
                            styles.activateContentRow,
                            styles.activateContentDescription
                        )}
                    >
                        {mintCount > 0
                            ? `Congratulations, You have completed the mint task.`
                            : `Click on 'Mint' button on the home page to
                        mint your first CRC NFT and join our community.`}
                    </div>
                ),
            },
        ];
    }, [userInfo]);

    const handleTaskTitleClick = (taskIndex: number) => {
        if (taskIndex != taskOpenIndex) {
            setTaskOpenIndex(taskIndex);
        } else {
            setTaskOpenIndex(-1);
        }
    };

    return (
        <Dialog
            className={clsx(className)}
            onClose={onClose}
            open={open}
            maxWidth="xs"
            boxProps={{
                title: "Activate your network",
            }}
        >
            <div className={styles.activateDescription}>
                Within two minutes, activate your network by completing the
                tasks below and start earning.
            </div>

            {renderTaskList.map((taskItem, taskIndex) => {
                return (
                    <div
                        key={taskIndex}
                        className={clsx(
                            styles.taskWrap,
                            taskOpenIndex == taskIndex && styles.taskWrapOpen
                        )}
                    >
                        <div
                            className={clsx(
                                styles.taskTitle,
                                taskOpenIndex == taskIndex &&
                                    styles.taskTitleOpen
                            )}
                            onClick={() => handleTaskTitleClick(taskIndex)}
                        >
                            {taskItem.title}
                            <MdKeyboardArrowDown className={styles.arrow} />
                        </div>

                        <AnimateHeight
                            duration={500}
                            height={taskOpenIndex == taskIndex ? "auto" : 0}
                        >
                            <div className={clsx(styles.taskContent)}>
                                {taskItem.content}
                            </div>
                        </AnimateHeight>
                    </div>
                );
            })}
        </Dialog>
    );
};

export default ProfileActivate;
