import { useApi, UseApiOptions } from "src/hooks/useApi";
import { ConnectionPlatformType } from "../types/connection";
import { apiTestMode } from "./root";

export interface InitConnectionParams {
    launch_id: number;
    platform: ConnectionPlatformType;
}

export interface InitConnectionResponse {
    auth_url: string;
}

export interface ConnectToPlatformParams {
    launch_id: number;
    platform: ConnectionPlatformType;
    code?: string;
    state?: string;
}

export interface ConnectToPlatformResponse {
    is_connected: boolean;
}

export interface UpdateConnectionUserDataParams {
    launch_id: number;
    platform: ConnectionPlatformType;
}

export interface DisconnectFromPlatformParams {
    launch_id: number;
    platform: ConnectionPlatformType;
}

export class UseConnectionsApi {
    useInitConnection(config?: UseApiOptions<InitConnectionResponse>) {
        return useApi<InitConnectionParams, InitConnectionResponse>({
            method: "PUT",
            path: "/connections/init",
            needAuth: true,
            test: {
                enable: apiTestMode,
            },
            ...config,
        });
    }

    useConnectToPlatform(config?: UseApiOptions<ConnectToPlatformResponse>) {
        return useApi<ConnectToPlatformParams, ConnectToPlatformResponse>({
            method: "PUT",
            path: "/connections/connect",
            needAuth: true,
            test: {
                enable: apiTestMode,
            },
            ...config,
        });
    }

    useUpdateConnectionUserData(config?: UseApiOptions<{}>) {
        return useApi<UpdateConnectionUserDataParams, {}>({
            method: "PUT",
            path: "/connections/users/info",
            needAuth: true,
            test: {
                enable: apiTestMode,
            },
            ...config,
        });
    }

    useDisconnectFromPlatform(config?: UseApiOptions<{}>) {
        return useApi<DisconnectFromPlatformParams, {}>({
            method: "PUT",
            path: "/connections/disconnect",
            needAuth: true,
            test: {
                enable: apiTestMode,
            },
            ...config,
        });
    }
}
