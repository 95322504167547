import { randomRange, sleep } from "./utils";

export type TestApiOptions = {
    signal?: AbortSignal;
    limit?: number;
    offset?: number;
};

export const testApiWithExample = async (
    responseExample: any,
    options?: TestApiOptions
) => {
    // wait a random period before returning result
    await sleep(randomRange(700, 1200));

    // return result if not aborted
    if (!options?.signal || !options?.signal?.aborted) {
        // prepare response for group or single
        if (Array.isArray(responseExample)) {
            // prepare items (slice if has limit, offset)
            let items;
            if (options?.limit) {
                items = responseExample.slice(
                    options.offset,
                    (options.offset || 0) + options.limit
                );
            } else items = responseExample;

            // return response for group
            return {
                success: true,
                result: {
                    total: responseExample.length,
                    count: items.length,
                    items: items,
                },
            };
        } else {
            // return response for single
            return {
                success: true,
                result: responseExample,
            };
        }
    } else return null;
};

export const fixApiParams = (params: any): any => {
    Object.keys(params).forEach((paramKey) => {
        const paramValue = params[paramKey];

        // set null or undefined as empty
        if (paramValue === null || paramValue === undefined) {
            params[paramKey] = "";
            return;
        }

        // prepare json or array type
        if (paramValue) {
            // detect type
            let valueType: "simple" | "array" | "json" = "simple";
            const stringifyValue = JSON.stringify(paramValue);
            if (stringifyValue.startsWith("[{")) valueType = "json";
            else if (stringifyValue.startsWith("{")) valueType = "json";
            else if (stringifyValue.startsWith("[")) valueType = "array";

            // join if value type is array
            if (valueType == "array") {
                params[paramKey] = paramValue.join(",");
            }

            // stringify if value type is json
            if (valueType == "json") {
                params[paramKey] = stringifyValue;
            }
        }
    });
    return params;
};

export const appendParamsToUrl = (url: string, params: any) => {
    const paramStringList: any[] = [];
    Object.keys(params).forEach((key) => {
        let value = null;
        if (typeof params[key] === "object" || Array.isArray(params[key])) {
            value = JSON.stringify(params[key]);
        } else {
            value = params[key];
        }

        paramStringList.push(key + "=" + value);
    });

    url += "?" + paramStringList.join("&");
    return url;
};
