import moment from "moment";

export const applyMomentConfig = () => {
    moment.locale("en-AU", {
        relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: "seconds",
            ss: "%ss",
            m: "1m",
            mm: "%dm",
            h: "1h",
            hh: "%dh",
            d: "1d",
            dd: "%dd",
            M: "1M",
            MM: "%dM",
            y: "1y",
            yy: "%dY",
        },
    });
    moment.defaultFormat = "lll";
};
