import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/App";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import WalletRoot from "./components/container/WalletRoot";
import Layout from "./components/container/Layout";
import ReactTooltip from "react-tooltip";
import { AlertProvider } from "src/hooks/useAlert";
import { CandyMachineProvider } from "src/hooks/useCandyMachine";
import { ResponsiveProvider } from "src/hooks/useResponsive";
import { UserInfoProvider } from "src/hooks/useUserInfo";
import { SkeletonTheme } from "react-loading-skeleton";
import { SettingsProvider } from "./hooks/useSettings";
import { LiveProvider } from "./hooks/useLive";
import { ConnectionPortalProvider } from "./hooks/useConnectionPortal";

import reportWebVitals from "src/services/reportWebVitals";

import "react-loading-skeleton/dist/skeleton.css";
import "normalize.css";
import "./styles/globals.css";
import { ShopProvider } from "./hooks/useShop";
import { AuthProvider } from "./hooks/useAuth";
import { TasksProvider } from "./hooks/useTasks";

const theme = createTheme({
    palette: {
        type: "dark",
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <WalletRoot>
                <CandyMachineProvider>
                    <ResponsiveProvider>
                        <AlertProvider>
                            <AuthProvider>
                                <UserInfoProvider>
                                    <SettingsProvider>
                                        <TasksProvider>
                                            <ConnectionPortalProvider>
                                                <ShopProvider>
                                                    <LiveProvider>
                                                        <SkeletonTheme
                                                            baseColor="#343440"
                                                            highlightColor="#42414a"
                                                            borderRadius="0.3rem"
                                                            duration={1.2}
                                                        >
                                                            <Layout>
                                                                <App />
                                                                <ReactTooltip
                                                                    effect="solid"
                                                                    backgroundColor="#000"
                                                                    textColor="#ddd"
                                                                    multiline={
                                                                        true
                                                                    }
                                                                    className="tooltip"
                                                                />
                                                            </Layout>
                                                        </SkeletonTheme>
                                                    </LiveProvider>
                                                </ShopProvider>
                                            </ConnectionPortalProvider>
                                        </TasksProvider>
                                    </SettingsProvider>
                                </UserInfoProvider>
                            </AuthProvider>
                        </AlertProvider>
                    </ResponsiveProvider>
                </CandyMachineProvider>
            </WalletRoot>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
