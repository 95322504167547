import { FC, PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import styles from "./WidgetTabs.module.scss";
import useResponsive from "../../hooks/useResponsive";

type Props = {
    className?: string;
    tabs: WidgetTabType[];
    selectedTabKey: string;
    onSelect?(selectedTabKey: string): void;
};

export type WidgetTabType = {
    key: string;
    title: ReactNode;
    className?: string;
    selectedClassName?: string;
};

const WidgetTabs: FC<PropsWithChildren<Props>> = ({
    className,
    children,
    tabs,
    selectedTabKey,
    onSelect,
}) => {
    const [isMobile] = useResponsive();

    const handleSelect = (tab: WidgetTabType) => {
        if (onSelect) {
            onSelect(tab.key);
        }
    };

    return (
        <div className={clsx(styles.widgetWrap, className)}>
            <div className={styles.widgetTabList}>
                {tabs.map((tab) => {
                    return (
                        <div
                            key={tab.key}
                            className={clsx(
                                styles.widgetTabItem,
                                isMobile && styles.widgetTabItemMobile,
                                tab.className,
                                selectedTabKey === tab.key &&
                                    styles.widgetTabSelected,
                                selectedTabKey === tab.key &&
                                    tab.selectedClassName
                            )}
                            onClick={() => handleSelect(tab)}
                        >
                            {tab.title}
                        </div>
                    );
                })}
            </div>

            <div className={styles.widgetContent}>{children}</div>
        </div>
    );
};

export default WidgetTabs;
