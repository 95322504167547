import { FC, useEffect } from "react";
import clsx from "clsx";
import ReactTooltip from "react-tooltip";
import { GoInfo } from "react-icons/go";
import styles from "./Hint.module.scss";

type Props = {
    className?: string;
    tip: string;
};

const Hint: FC<Props> = ({ className, tip }) => {
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [tip]);

    return (
        <GoInfo className={clsx(styles.hintIcon, className)} data-tip={tip} />
    );
};

export default Hint;
