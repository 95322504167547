import { FC, ReactNode } from "react";
import clsx from "clsx";
import styles from "./SectionList.module.scss";

export type SectionItemType = {
    title: ReactNode;
    content: ReactNode;
};

type Props = {
    className?: string;
    list: SectionItemType[];
};

const SectionList: FC<Props> = ({ className, list }) => {
    return (
        <div className={clsx(className)}>
            {list.map((sectionItem, sectionIndex) => {
                return (
                    <div
                        className={clsx(styles.sectionItem)}
                        key={sectionIndex}
                    >
                        <div className={styles.sectionTitle}>
                            <span>{sectionItem.title}</span>
                        </div>

                        <div className={styles.sectionContent}>
                            {sectionItem.content}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default SectionList;
