import { FC, PropsWithChildren } from "react";
import clsx from "clsx";
import { FaInfoCircle } from "react-icons/fa";
import { CgMediaLive } from "react-icons/cg";
import { IoWarning } from "react-icons/io5";
import { MdReportGmailerrorred } from "react-icons/md";
import { FiClock } from "react-icons/fi";
import styles from "./AlertMini.module.scss";

export type BoxProps = {
    className?: string;
    severity: "success" | "info" | "warning" | "error" | "idle";
    withBackground?: boolean;
    hideIcon?: boolean;
};

const AlertMini: FC<PropsWithChildren<BoxProps>> = ({
    children,
    className,
    severity,
    withBackground,
    hideIcon,
}) => {
    return (
        <div
            className={clsx(
                styles.wrap,
                withBackground && styles.wrapWithBackground,
                severity == "success" && styles.wrapSuccess,
                severity == "info" && styles.wrapInfo,
                severity == "warning" && styles.wrapWarning,
                severity == "error" && styles.wrapError,
                className
            )}
        >
            {!hideIcon && (
                <>
                    {severity == "idle" && <FiClock className={styles.icon} />}
                    {severity == "success" && (
                        <CgMediaLive className={styles.icon} />
                    )}
                    {severity == "info" && (
                        <FaInfoCircle className={styles.icon} />
                    )}
                    {severity == "warning" && (
                        <IoWarning className={styles.icon} />
                    )}
                    {severity == "error" && (
                        <MdReportGmailerrorred className={styles.icon} />
                    )}
                </>
            )}

            <div className={styles.message}>{children}</div>
        </div>
    );
};

export default AlertMini;
