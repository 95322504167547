import { CampaignStatType } from "./campaign";
import { clientExample, ClientType } from "./client";
import { CoinType } from "./coin";
import { ApiSubItems } from "./general";
import { TaskType } from "./task";

export type LaunchType = {
    id: number;
    candy_machine_pub: string;
    symbol: string;
    name: string;
    remaining: number;
    total: number;
    cluster: "devnet" | "testnet" | "mainnet";
    current_stage_num: number;
    locked_by?: LaunchLockedByType;
    created_date: number;
    client: ClientType;
    campaign_stats: CampaignStatType;
    stages: ApiSubItems<LaunchStageType>;
    tasks: ApiSubItems<TaskType>;
};

export type LaunchStageType = {
    id: number;
    coin: CoinType;
    start_price: number;
    price: number;
    remaining: number;
    total: number;
    stage_num: number;
    inflation_gap?: number;
    inflation_percent?: number;
    is_whitelist?: boolean;
    start_date?: number;
    end_date?: number;
};

export type LaunchLockedByType = "updating_price";

export const launchExample: LaunchType = {
    id: 1,
    candy_machine_pub: "nTSFEfg2kdkZ6UZeCuVe3Qg3X9Tpwj2N432e2f8a1DX",
    symbol: "DUST",
    name: "DeGods by DUST Labs",
    remaining: 931,
    total: 1000,
    cluster: "devnet",
    current_stage_num: 1,
    created_date: 1663048516,
    stages: {
        count: 1,
        items: [
            {
                id: 1,
                coin: {
                    id: 1,
                    name: "Solana",
                    symbol: "SOL",
                    price_usd: 34.21,
                    decimals: 9,
                },
                start_price: 0.45,
                price: 0.45,
                remaining: 900,
                total: 1000,
                stage_num: 1,
                is_whitelist: false,
            },
        ],
    },
    tasks: {
        count: 2,
        items: [
            {
                id: 1,
                task_tag: 'join_to_discord',
                reward_amount: 0.02,
                point_amount: 0,
                is_demandable: false,
                is_multi_mission: false,
                is_one_time: true,
                active_missions: {
                    count: 1,
                    items: [
                        {
                            id: 1,
                        }
                    ]
                },
            },
            {
                id: 2,
                task_tag: 'join_to_twitter',
                reward_amount: 0.02,
                point_amount: 0,
                is_demandable: false,
                is_multi_mission: false,
                is_one_time: true,
                active_missions: {
                    count: 1,
                    items: [
                        {
                            id: 1,
                        }
                    ]
                },
            }
        ]
    },
    campaign_stats: {
        id: 1,
        active_fund_usd: 105_200,
        apr_percent: 66.34,
        reserve_usd: 12_725,
        total_campaigns: 35,
        funded_campaigns: 34,
        ran_campaigns: 34,
        running_campaigns: 34,
    },
    client: clientExample,
};
