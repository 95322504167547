import { TaskMissionClosureType, TaskUserType } from "./task";
import { InviteInfoShortType, InviteInfoType, InviteStatType } from "./invite";
import { UserMintType } from "./mint";
import { AssetCoinType, AssetRewardType } from "./asset";
import { ApiSubItems } from "./general";
import { GradeUserType } from "./grade";
import { ConnectionType } from "./connection";
import { FileType } from "./file";
import { InvestType } from "./invest";
import { CreateSignatureMessageResponse } from "../api/user";
import { LeaderUserType } from "./leader";
import { PointUserType } from "./point";

export type UserShortType = {
    id: number;
    sol_wallet_short: string;
    display_name?: string;
    avatar?: FileType;
    invites_info?: InviteInfoShortType;
};

export type UserInfoType = {
    id: number;
    sol_wallet_short: string;
    display_name?: string;
    first_name?: string;
    last_name?: string;
    avatar?: FileType;
    invites_info?: InviteInfoType;
    invites_stat?: InviteStatType;
    mints_info?: UserMintType;
    grades_info: GradeUserType;
    invest_info?: InvestType;
    assets?: ApiSubItems<AssetCoinType>;
    connections?: ApiSubItems<ConnectionType>;
    tasks_info?: TaskUserType;
    leaders?: ApiSubItems<LeaderUserType>;
    tasks_missions_closures?: ApiSubItems<TaskMissionClosureType>;
    points?: PointUserType;
    registered_date: number;
};

export const userInfoExample: UserInfoType = {
    id: 1,
    sol_wallet_short: "9bx0...bOOm",
    invites_info: {
        id: 1,
        active_invited_user_count: 25,
        active_network_user_count: 70,
        total_invited_user_count: 25,
        total_network_user_count: 70,
        total_rewards: 13.567,
        is_activated: true,
        last_invite_date: 1675164200,
        invited_by: {
            id: 1,
            sol_wallet_short: "s89n...NB10",
        },
    },
    leaders: {
        count: 1,
        items: [
            {
                id: 1,
                real_value: 251,
                cached_value: 251,
                real_rank_num: 12,
                cached_rank_num: 12,
                real_rank_percent: 5.6,
                leader: {
                    id: 1,
                    leader_tag: 'network',
                    reward_period: 'quarterly',
                    user_count: 250,
                    last_updated_date: 1695164200,
                }
            }
        ]
    },
    invites_stat: {
        id: 1,
        invited_mint_count: 15,
        invited_invest_count: 5,
        invited_ib_count: 27,
    },
    mints_info: {
        id: 1,
        auditing_mint_count: 0,
        approved_mint_count: 8,
        auditing_invited_mint_count: 3,
        approved_invited_mint_count: 11,
        auditing_network_mint_count: 2,
        approved_network_mint_count: 50,
        commission_percent: 7,
    },
    grades_info: {
        id: 1,
        invite_points: 15,
        network_points: 37,
        special_invite_code: "SILVER_b8zby0ZZinJ67Bc88MM",
        base_grade: {
            id: 3,
            tag: "silver",
            level_num: 3,
            req_mint_count: 10,
            reward_multiplier: 3,
            invest_commission_percent: 10,
            nft_count: 10,
            is_default: false,
            last_nft_added_date: 166345007,
        },
        highest_grade: {
            id: 5,
            tag: "platinum",
            level_num: 5,
            req_mint_count: 50,
            req_rarity_min: 10,
            enable_invite_grade_tag: "silver",
            reward_multiplier: 5,
            invest_commission_percent: 10,
            nft_count: 4,
            is_default: false,
            last_nft_added_date: 166347044,
        },
        grade_stats: {
            count: 4,
            items: [
                {
                    id: 1,
                    available_nft_count: 0,
                    registered_nft_count: 4,
                    grade: {
                        id: 1,
                        tag: "standard",
                        level_num: 1,
                        req_mint_count: 1,
                        reward_multiplier: 1,
                        invest_commission_percent: 5,
                        nft_count: 10,
                        is_default: true,
                        last_nft_added_date: 166345007,
                    },
                },
                {
                    id: 1,
                    available_nft_count: 0,
                    registered_nft_count: 3,
                    grade: {
                        id: 2,
                        tag: "bronze",
                        level_num: 2,
                        req_mint_count: 2,
                        req_invite_points: 2,
                        reward_multiplier: 2,
                        invest_commission_percent: 5,
                        nft_count: 10,
                        is_default: false,
                        last_nft_added_date: 166345007,
                    },
                },
                {
                    id: 1,
                    available_nft_count: 2,
                    registered_nft_count: 1,
                    grade: {
                        id: 4,
                        tag: "gold",
                        level_num: 4,
                        req_mint_count: 5,
                        req_network_points: 3,
                        reward_multiplier: 4,
                        invest_commission_percent: 10,
                        nft_count: 10,
                        is_default: false,
                        last_nft_added_date: 166345007,
                    },
                },
                {
                    id: 1,
                    available_nft_count: 0,
                    registered_nft_count: 2,
                    grade: {
                        id: 5,
                        tag: "platinum",
                        level_num: 5,
                        req_mint_count: 50,
                        req_rarity_min: 10,
                        reward_multiplier: 5,
                        invest_commission_percent: 10,
                        nft_count: 10,
                        is_default: false,
                        last_nft_added_date: 166345007,
                    },
                },
            ],
        },
    },
    invest_info: {
        id: 1,
        active_fund_usd: 3000,
        pending_fund_usd: 2000,
        total_income_usd: 235,
        last_funded_date: 1700172600,
        funds: {
            count: 2,
            items: [
                {
                    id: 2,
                    funded_usd: 2000,
                    funding_usd: 0,
                    withdrawing_usd: 0,
                    total_income_usd: 0,
                    is_funded: true,
                    is_funding: false,
                    has_failed_fund: false,
                    created_date: 1700194500,
                    last_entry_date: 1700195500,
                    campaign: {
                        id: 22,
                        campaign_tag: "S22",
                        campaign_hash: 'X8nv9',
                        campaign_num: 22,
                        min_fund_usd: 1000,
                        max_fund_usd: 5000,
                        step_fund_usd: 1000,
                        withdraw_step_fund_usd: 1000,
                        active_fund_usd: 20000,
                        target_fund_usd: 20000,
                        total_gain_usd: 0,
                        total_gain_percent: 14.76,
                        status: "activating",
                        has_funded: true,
                        full_funded: true,
                        has_ran: false,
                        created_date: 1700174600,
                        live_date: 1700174600,
                        view_grant: {
                            id: 2,
                            account_server: "RunwayPerfect",
                            account_num: 51849121,
                            account_pass: "Oni9-nxuwN",
                        },
                    },
                },
                {
                    id: 1,
                    funded_usd: 3000,
                    funding_usd: 0,
                    withdrawing_usd: 0,
                    total_income_usd: 235,
                    is_funded: true,
                    is_funding: false,
                    has_failed_fund: false,
                    created_date: 1700184500,
                    last_entry_date: 1700185500,
                    campaign: {
                        id: 14,
                        campaign_tag: "S14",
                        campaign_hash: 'sssv9',
                        campaign_num: 14,
                        min_fund_usd: 1000,
                        max_fund_usd: 5000,
                        step_fund_usd: 1000,
                        withdraw_step_fund_usd: 1000,
                        active_fund_usd: 20000,
                        target_fund_usd: 20000,
                        total_gain_usd: 2952,
                        total_gain_percent: 14.76,
                        status: "running",
                        has_funded: true,
                        full_funded: true,
                        has_ran: true,
                        created_date: 1700153500,
                        live_date: 1700153500,
                        ran_date: 1700155530,
                        view_grant: {
                            id: 2,
                            account_server: "ShootBoom",
                            account_num: 51849245,
                            account_pass: "Bpn00BB-azorRR",
                        },
                    },
                },
            ],
        },
    },
    assets: {
        count: 1,
        items: [
            {
                id: 1,
                asset: {
                    id: 1,
                    bag_tag: "main",
                    central_wallet: "",
                    can_withdraw: true,
                },
                coin: {
                    id: 1,
                    name: "Solana",
                    symbol: "SOL",
                    price_usd: 34.21,
                    decimals: 9,
                },
                rewarded_amount: 13.567,
                available_amount: 4.791,
                withdrawing_amount: 0,
                mint_locked_amount: 8.776,
            },
        ],
    },
    tasks_info: {
        id: 1,
        completed_tasks: 5,
        total_rewards: 0.65,
        total_points: 3350,
        last_completed_date: 1669435000,
    },
    registered_date: 1663435448,
};

export const signatureMessageExample: CreateSignatureMessageResponse = {
    seed: "9340490343",
    message: "Please sign this message to authenticate. seed: 9340490343",
};
