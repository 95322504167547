import { FC, useEffect, useState } from "react";
import clsx from "clsx";
import styled from "styled-components";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { FiLogOut } from "react-icons/fi";
import { useWallet } from "@solana/wallet-adapter-react";
import useWalletBalance from "src/hooks/useWalletBalance";
import useResponsive from "src/hooks/useResponsive";
import { shortenAddress } from "src/services/candyMachine";
import Counter from "src/libs/counter/Counter";
import ProfileOverview from "../profile/ProfileOverview";
import { FaUser, FaCoins } from "react-icons/fa";
import useUserInfo from "../../hooks/useUserInfo";
import useConnectionPortal from "../../hooks/useConnectionPortal";
import ProfilePoints from "../profile/ProfilePoints";
import styles from "./WalletConnect.module.scss";
import WalletDialog from "../wallet/WalletDialog";

type Props = {
    className?: string;
};

const WalletButton = styled(WalletMultiButton)`
    color: #cbcbcb;
    font-size: 0.95em;
    background: #32323e;
    transition: all 0.2s ease-in-out;
    min-width: 140px;
    padding: 0 15px;
    border-radius: 10px;

    &:hover {
        background: #393946;
    }
`;

const WalletConnect: FC<Props> = ({ className }) => {
    const { userInfo } = useUserInfo();
    const { pendingPortalInfo } = useConnectionPortal();
    const [isMobile] = useResponsive();
    const { connected, disconnect, connecting, disconnecting, publicKey } =
        useWallet();
    const { balances, setBalances } = useWalletBalance();
    const [profileOpen, setProfileOpen] = useState(false);
    const [walletOpen, setWalletOpen] = useState(false);

    useEffect(() => {
        if (disconnecting) {
            setBalances(null);
        }
    }, [disconnecting]);

    // open profile dialog if it's in the middle of connecting
    useEffect(() => {
        if (
            userInfo &&
            pendingPortalInfo?.has_redirected &&
            pendingPortalInfo.request_source == "profile"
        ) {
            setProfileOpen(true);
        }
    }, [userInfo, pendingPortalInfo]);

    return (
        <div className={clsx(styles.tools, className)}>
            <ProfilePoints className={clsx(styles.tool)} />

            {/* {connected && !isMobile && balances !== null && (
                <div>
                    {balances.map((walletBalance) => {
                        return (
                            <div
                                key={walletBalance.symbol}
                                className={clsx(styles.tool, styles.balance)}
                            >
                                {<Counter number={walletBalance.amount} />}{" "}
                                {walletBalance.symbol}
                            </div>
                        );
                    })}
                </div>
            )} */}

            {(!connected || !isMobile) && (
                <WalletButton className={styles.tool}>
                    {connected
                        ? shortenAddress(publicKey?.toString()!)
                        : !connecting
                        ? "Connect Wallet"
                        : "Connecting ..."}
                </WalletButton>
            )}

            {connected && (
                <div
                    className={clsx(styles.tool, styles.toolBox)}
                    data-tip="Profile"
                    onClick={() => setProfileOpen(true)}
                >
                    <FaUser />
                </div>
            )}

            {connected && (
                <div
                    className={clsx(styles.tool, styles.toolBox)}
                    data-tip="Wallet"
                    onClick={() => setWalletOpen(true)}
                >
                    <FaCoins />
                </div>
            )}

            {connected && (
                <div
                    className={clsx(styles.tool, styles.toolBox)}
                    data-tip="Log out"
                    onClick={() => disconnect()}
                >
                    <FiLogOut />
                </div>
            )}

            {connected && profileOpen && (
                <ProfileOverview
                    open={profileOpen}
                    onClose={() => setProfileOpen(false)}
                />
            )}

            {connected && walletOpen && (
                <WalletDialog
                    open={walletOpen}
                    onClose={() => setWalletOpen(false)}
                />
            )}
        </div>
    );
};

export default WalletConnect;
