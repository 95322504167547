import { FC } from "react";
import clsx from "clsx";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { TbWorld } from "react-icons/tb";
import Skeleton from "react-loading-skeleton";
import { useItemsAvailable } from "src/hooks/useCandyMachine";
import useResponsive from "../../hooks/useResponsive";
import styles from "./Preview.module.scss";
import { MdKeyboardArrowDown } from "react-icons/md";

type Props = {
    className?: string;
    learnMoreOpen: boolean;
    learnMoreOpenChange(learnMoreOpen: boolean): void;
};

const PreviewInfo: FC<Props> = ({
    className,
    learnMoreOpen,
    learnMoreOpenChange,
}) => {
    const [itemsAvailable] = useItemsAvailable();
    const [isMobile] = useResponsive();

    return (
        <div
            className={clsx(
                styles.info,
                isMobile && styles.infoMobile,
                className
            )}
        >
            {/* <div className={styles.logoWrap}>
                <img className={styles.logo} src="/assets/crc-logo.png" />
            </div> */}

            <div className={styles.infoTitleWrap}>
                <div
                    className={clsx(
                        styles.title,
                        isMobile && styles.titleMobile
                    )}
                >
                    CRC Entertainment Group
                </div>
                <div className={styles.description}>
                    Next step for web 3.0 entertainment
                </div>
                <div className={styles.socialList}>
                    <a
                        className={styles.socialItem}
                        href="https://crc.club"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <TbWorld size={20} />
                    </a>
                    <a
                        className={styles.socialItem}
                        href="https://discord.gg/eeyTvXgMAq"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <FaDiscord size={20} />
                    </a>
                    <a
                        className={styles.socialItem}
                        href="https://twitter.com/BlackBone_io"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <FaTwitter size={20} />
                    </a>
                    <a
                        className={styles.socialItem}
                        href="https://instagram.com/crystalrabbitclub"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <FiInstagram size={20} />
                    </a>
                </div>
            </div>

            {!isMobile && (
                <div className={styles.total}>
                    <div className={styles.content}>
                        {itemsAvailable ? (
                            itemsAvailable.toLocaleString()
                        ) : (
                            <Skeleton width={70} />
                        )}
                    </div>
                    <div className={styles.label}>Total Items</div>
                </div>
            )}

            {isMobile && (
                <div
                    className={clsx(
                        styles.learnMore,
                        learnMoreOpen && styles.learnMoreOpen
                    )}
                    onClick={() => learnMoreOpenChange(!learnMoreOpen)}
                >
                    <span>Learn More</span>
                    <MdKeyboardArrowDown className={styles.arrow} />
                </div>
            )}
        </div>
    );
};

export default PreviewInfo;
