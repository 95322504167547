import { FC } from "react";
import { CampaignType } from "../../types/campaign";
import AlertMini from "../../libs/alert-mini/AlertMini";
import styles from "./DashboardInvest.module.scss";

type Props = {
    campaign: CampaignType;
    isLive: boolean;
    withBackground?: boolean;
    hideIcon?: boolean;
};

const DashboardInvestCampaignsStatus: FC<Props> = ({
    campaign,
    isLive,
    withBackground,
    hideIcon,
}) => {
    return (
        <>
            {campaign.status == "canceled" ? (
                <AlertMini
                    withBackground={withBackground}
                    hideIcon={hideIcon}
                    severity="error"
                >
                    Canceled
                </AlertMini>
            ) : !isLive ? (
                <AlertMini
                    withBackground={withBackground}
                    hideIcon={hideIcon}
                    severity="idle"
                >
                    Initiating
                </AlertMini>
            ) : campaign.status == "funding" ? (
                <AlertMini
                    withBackground={withBackground}
                    hideIcon={hideIcon}
                    severity="idle"
                >
                    Funding
                </AlertMini>
            ) : campaign.status == "activating" ? (
                <AlertMini
                    withBackground={withBackground}
                    hideIcon={hideIcon}
                    severity="info"
                >
                    Activating
                </AlertMini>
            ) : campaign.status == "paused" ? (
                <AlertMini
                    withBackground={withBackground}
                    hideIcon={hideIcon}
                    severity="warning"
                >
                    Paused
                </AlertMini>
            ) : campaign.status == "running" ? (
                <AlertMini
                    withBackground={withBackground}
                    hideIcon={hideIcon}
                    severity="success"
                >
                    Running
                </AlertMini>
            ) : (
                ""
            )}
        </>
    );
};

export default DashboardInvestCampaignsStatus;
