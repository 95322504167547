import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import {
    FC,
    createContext,
    useContext,
    useEffect,
    useState,
    PropsWithChildren,
} from "react";
import { Api } from "src/api/root";
import { UserInfoType } from "src/types/user";
import useInvite from "./useInvite";

const UserInfoContext = createContext<{
    userInfo: UserInfoType | null;
    setUserInfo(userInfo: UserInfoType | null): void;
    fullConnected: boolean;
    loadingUserInfo: boolean;
    updateUserInfo(): Promise<void>;
}>({
    userInfo: null,
    setUserInfo: () => {},
    fullConnected: false,
    loadingUserInfo: false,
    updateUserInfo: async () => {},
});

const useUserInfo = () => useContext(UserInfoContext);

export const UserInfoProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
    const wallet = useWallet();
    const { saveInviteOnApi } = useInvite();
    const [userInfo, setUserInfo] = useState<UserInfoType | null>(null);
    const [loadingUserInfo, setLoadingUserInfo] = useState<boolean>(false);
    const [fullConnected, setFullConnected] = useState<boolean>(false);
    const api = Api.users().useGetUserInfo();

    // detect full connected
    useEffect(() => {
        const finalFullConnected = isRealConnected();
        setFullConnected(finalFullConnected);

        // set loading too if wallet is connecting
        if (wallet.connecting) {
            setLoadingUserInfo(true);
        }
    }, [wallet]);

    // update user info
    useEffect(() => {
        (async () => {
            if (fullConnected) {
                setLoadingUserInfo(true);
                await updateUserInfo();
                saveInviteOnApi();
                setLoadingUserInfo(false);
            } else {
                setUserInfo(null);
                setLoadingUserInfo(false);
            }
        })();

        return () => api.abort();
    }, [fullConnected]);

    const isRealConnected = () => {
        return wallet.connected && !wallet.connecting && !wallet.disconnecting;
    };

    const updateUserInfo = async () => {
        await api.run({}).then((data) => {
            setUserInfo(data);
        });
    };

    return (
        <UserInfoContext.Provider
            value={{
                userInfo,
                setUserInfo,
                fullConnected,
                loadingUserInfo,
                updateUserInfo,
            }}
        >
            {children}
        </UserInfoContext.Provider>
    );
};

export default useUserInfo;
