import { FC, PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import styles from "./SwitchTabs.module.scss";

export type Props = {
    className?: string;
    tag: string;
    label: string | ReactNode;
};

const SwitchTab: FC<PropsWithChildren<Props>> = ({ children, className }) => {
    return <div className={clsx(styles.boxWrap, className)}>{children}</div>;
};

export default SwitchTab;
