import { FC, useState, useEffect } from "react";
import clsx from "clsx";
import styles from "./Counter.module.scss";
import { useFieldArray } from "react-hook-form";

type Props = {
    className?: string;
    number: number;
};

const Counter: FC<Props> = ({ className, number }) => {
    const [num, setNum] = useState<number | null>(null);
    const [animationClass, setAnimationClass] = useState(styles.initial);

    useEffect(() => {
        if (num != null) {
            // 1. Old number goes up
            const goUpTimout = setTimeout(
                () => setAnimationClass(styles.goUp),
                0
            );
            // 2. Incrementing the counter
            const setNumTimout = setTimeout(() => setNum(number), 100);
            // 3. New number waits down
            const waitDownTimout = setTimeout(
                () => setAnimationClass(styles.waitDown),
                100
            );
            // 4. New number stays in the middle
            const initialTimout = setTimeout(
                () => setAnimationClass(styles.initial),
                200
            );

            return () => {
                clearTimeout(goUpTimout);
                clearTimeout(setNumTimout);
                clearTimeout(waitDownTimout);
                clearTimeout(initialTimout);
            };
        } else {
            setNum(number);
        }
    }, [number]);

    return (
        <span className={clsx(animationClass, className)}>
            {num && num > 1 ? num?.toLocaleString() : num}
        </span>
    );
};

export default Counter;
