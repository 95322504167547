import {
    FC,
    createContext,
    useContext,
    useState,
    PropsWithChildren,
} from "react";

const BalanceContext = createContext(null);

export const useItemsAvailable = () => {
    const [itemsAvailable, setItemsAvailable]: any = useContext(BalanceContext);
    return [itemsAvailable, setItemsAvailable];
};

export const CandyMachineProvider: FC<PropsWithChildren<{}>> = ({
    children,
}) => {
    const [itemsAvailable, setItemsAvailable] = useState<number | null>(null);

    return (
        <BalanceContext.Provider
            value={[itemsAvailable, setItemsAvailable] as any}
        >
            {children}
        </BalanceContext.Provider>
    );
};
