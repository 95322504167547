import { useApi, UseApiOptions } from "src/hooks/useApi";
import { pointRecordsExample, PointRecordType } from "src/types/point";
import { ApiGetGroupParams, apiTestMode } from "./root";

export interface GetPointRecordsParams extends ApiGetGroupParams {
    launch_id: number;
    is_read?: boolean;
}

export interface ReadAllPointRecordsParams {
    launch_id: number;
}

export class UsePointsApi {
    useGetPointRecords(config?: UseApiOptions<PointRecordType[]>) {
        return useApi<GetPointRecordsParams, PointRecordType[]>({
            method: "GET",
            path: "/points/records",
            needAuth: true,
            isList: true,
            test: {
                enable: apiTestMode,
                data: pointRecordsExample,
            },
            ...config,
        });
    }

    useReadAllPointRecords(config?: UseApiOptions<{}>) {
        return useApi<ReadAllPointRecordsParams, {}>({
            method: "PUT",
            path: "/points/records/read",
            needAuth: true,
            test: {
                enable: apiTestMode,
            },
            ...config,
        });
    }
}
