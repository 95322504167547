import { useApi, UseApiOptions } from "src/hooks/useApi";
import { generateTokenExample } from "../types/auth";
import { apiTestMode } from "./root";

export interface GenerateTokenParams {
    grant_type: "password";
    username: string;
    password: string;
    client_id: "user_login";
}

export interface GenerateTokenResponse {
    access_token: string;
    expires_in: number;
    token_type: "Bearer";
    scope: string;
    refresh_token: string;
}

export interface RefreshTokenParams {
    grant_type: "refresh_token";
    refresh_token: string;
    client_id: "user_login";
}

export class UseAuthApi {
    useGenerateToken(config?: UseApiOptions<GenerateTokenResponse>) {
        return useApi<GenerateTokenParams, GenerateTokenResponse>({
            method: "POST",
            path: "/token",
            test: {
                enable: apiTestMode,
                data: generateTokenExample,
            },
            ...config,
        });
    }

    useRefreshToken(config?: UseApiOptions<GenerateTokenResponse>) {
        return useApi<RefreshTokenParams, GenerateTokenResponse>({
            method: "POST",
            path: "/token",
            test: {
                enable: apiTestMode,
                data: generateTokenExample,
            },
            ...config,
        });
    }
}
