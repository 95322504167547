import { FC, PropsWithChildren, useEffect, useState } from "react";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import ReactTooltip from "react-tooltip";
import Box, { BoxProps } from "../box/Box";
import useResponsive from "../../hooks/useResponsive";
import styles from "./Dialog.module.scss";

type Props = {
    className?: string;
    open: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
    boxProps?: BoxProps;
    noBox?: boolean;
    onClose(): void;
};

const DashboardInvitesMintsDialog: FC<PropsWithChildren<Props>> = ({
    className,
    open,
    maxWidth,
    boxProps,
    noBox,
    children,
    onClose,
}) => {
    const [mounted, setMounted] = useState(false);
    const [isMobile] = useResponsive();

    // set mounted to detect client from server
    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        // @ts-ignore
        if (mounted && window.$crisp) {
            if (open) {
                // @ts-ignore
                window.$crisp.push(["do", "chat:hide"]);
            } else {
                // @ts-ignore
                window.$crisp.push(["do", "chat:show"]);
            }
        }
    }, [mounted, open]);

    return (
        <Dialog
            fullWidth={true}
            classes={{
                paper: clsx(
                    !isMobile && styles.dialog,
                    isMobile && styles.dialogMobile,
                    className
                ),
                container: styles.dialogContainer,
            }}
            maxWidth={maxWidth}
            onClose={() => onClose()}
            open={open}
        >
            <div
                className={clsx(
                    styles.dialogContent,
                    isMobile && styles.dialogContentMobile
                )}
            >
                {!noBox ? (
                    <Box {...boxProps} hasCloseButton={true} onClose={onClose}>
                        {children}
                    </Box>
                ) : (
                    children
                )}
            </div>
            <ReactTooltip
                effect="solid"
                backgroundColor="#000"
                textColor="#ddd"
                multiline={true}
                className="tooltip"
            />
        </Dialog>
    );
};

export default DashboardInvitesMintsDialog;
