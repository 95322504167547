import { FC } from "react";
import clsx from "clsx";
import WalletConnect from "../wallet-connect/WalletConnect";
import useResponsive from "src/hooks/useResponsive";
import styles from "./Nav.module.scss";

type Props = {
    className?: string;
    title: string;
    subTitle?: string;
};

const Nav: FC<Props> = ({ className, title, subTitle }) => {
    const [isMobile] = useResponsive();

    return (
        <div
            className={clsx(
                styles.nav,
                isMobile && styles.navMobile,
                className
            )}
        >
            <img className={styles.logo} src="/assets/crc-logo.svg" />

            {!isMobile && (
                <div className={styles.titleGroup}>
                    <div className={styles.titleWrap}>
                        <h1
                            className={clsx(
                                styles.title,
                                isMobile && styles.titleMobile
                            )}
                        >
                            {title}
                        </h1>
                    </div>
                    {subTitle && (
                        <div className={styles.subTitle}>{subTitle}</div>
                    )}
                </div>
            )}

            <div className={styles.toolsWrap}>
                <WalletConnect />
            </div>
        </div>
    );
};

export default Nav;
