import { FC, useState } from "react";
import clsx from "clsx";
import useResponsive from "../../hooks/useResponsive";
import DashboardNftsGrades from "../dashboard-nfts/DashboardNftsGrades";
import { HiOutlineExternalLink } from "react-icons/hi";
import styles from "./Preview.module.scss";
import { MdOutlineArrowRightAlt, MdOutlineChevronRight } from "react-icons/md";
import { HiExternalLink } from "react-icons/hi";
import { FaAngleRight, FaArrowRight } from "react-icons/fa";

type Props = {
    className?: string;
};

const PreviewSummary: FC<Props> = ({ className }) => {
    const [isMobile] = useResponsive();
    const [openGrades, setOpenGrades] = useState<boolean>(false);

    return (
        <div
            className={clsx(
                styles.summaryWrap,
                isMobile && styles.summaryWrapMobile,
                className
            )}
        >
            <div className={styles.summaryContent}>
                {/* <div className={styles.title}>CRC NFT in a nutshell:</div> */}
                <div className={styles.description}>
                    <div className={styles.descriptionItem}>
                        ⦾ 60-80% profit sharing of{" "}
                        <a
                            href="https://lottofactory.io"
                            target="_blank"
                            rel="noreferrer"
                        >
                            LottoFactory
                        </a>
                        ,{" "}
                        <a
                            href="https://solgate.io"
                            target="_blank"
                            rel="noreferrer"
                        >
                            SolGate
                        </a>{" "}
                        and upcoming projects
                    </div>
                    <div className={styles.descriptionItem}>
                        ⦾ Lifetime premium VPN account 😱{" "}
                        <a
                            href="https://solgate.io/accounts"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Claim Here <HiExternalLink />
                        </a>
                    </div>
                    <div className={styles.descriptionItem}>
                        ⦾ Earn up to $10,000 from your network (Even more after
                        mint)
                    </div>
                    <div className={styles.descriptionItem}>
                        ⦾ Unlock 4-10% monthly gain from FORIXO&apos;s funding
                    </div>
                </div>
            </div>

            <div
                className={styles.summaryButton}
                onClick={() => setOpenGrades(true)}
            >
                <span>Complete NFT Benefits</span>
                <HiOutlineExternalLink />
            </div>

            <DashboardNftsGrades
                open={openGrades}
                onClose={() => setOpenGrades(false)}
            />
        </div>
    );
};

export default PreviewSummary;
