import {
    FC,
    createContext,
    useState,
    useContext,
    PropsWithChildren,
} from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { AlertState } from "src/services/utils";

const AlertContext = createContext(null);

const useAlert = () => {
    const [alertState, setAlertState]: any = useContext(AlertContext);
    return [alertState, setAlertState];
};

export const AlertProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: "",
        severity: undefined,
        action: null,
    });

    return (
        <AlertContext.Provider value={[alertState, setAlertState] as any}>
            {children}
            <Snackbar
                open={alertState.open}
                autoHideDuration={
                    alertState.hideDuration === undefined
                        ? 6000
                        : alertState.hideDuration
                }
                onClose={() => setAlertState({ ...alertState, open: false })}
            >
                <Alert
                    onClose={() =>
                        setAlertState({ ...alertState, open: false })
                    }
                    severity={alertState.severity}
                    action={alertState.action}
                >
                    {alertState.message}
                </Alert>
            </Snackbar>
        </AlertContext.Provider>
    );
};

export const AlertAction: FC<
    PropsWithChildren<{
        onClick?(): void;
    }>
> = ({ children, onClick }) => {
    const handleClick = () => {
        if (onClick) onClick();
    };

    return (
        <div className="alertAction" onClick={handleClick}>
            {children}
        </div>
    );
};

export default useAlert;
