import { FC } from "react";
import useAlert from "../../hooks/useAlert";
import Action from "../../libs/action/Action";
import Box from "../../libs/box/Box";
import Dialog from "../../libs/dialog/Dialog";
import Feature from "../../libs/feature/Feature";
import Pair from "../../libs/pair/Pair";
import { CampaignType } from "../../types/campaign";
import styles from "./DashboardInvest.module.scss";

type Props = {
    className?: string;
    open: boolean;
    onClose(): void;
    campaign: CampaignType;
};

const DashboardInvestCampaignsGrant: FC<Props> = ({
    className,
    open,
    onClose,
    campaign,
}) => {
    const [, setAlertState] = useAlert();

    const handleCopy = (title: string) => {
        setAlertState({
            open: true,
            message: `${title} successfully copied!`,
            severity: "success",
        });
    };

    return (
        <Dialog
            className={className}
            onClose={onClose}
            open={open}
            boxProps={{
                title: `Access to Campaign ${campaign.campaign_tag}`,
            }}
            maxWidth="xs"
        >
            <div className={styles.addFundsDescription}>
                In order to see all of the campaign trade positions and their
                outcome, please use the campaign credentials to access the
                account on MetaTrader 4.
            </div>

            <Feature>
                <Pair
                    className={styles.featurePair}
                    title="Campaign Server"
                    titlePosition="start"
                    value={
                        <Action
                            action={{
                                type: "copy",
                                config: {
                                    value: campaign.view_grant?.account_server!,
                                    onCopy: () => handleCopy("Campaign Server"),
                                },
                            }}
                        >
                            {campaign.view_grant?.account_server}
                        </Action>
                    }
                />
                <Pair
                    className={styles.featurePair}
                    title="Campaign Login"
                    titlePosition="start"
                    value={
                        <Action
                            action={{
                                type: "copy",
                                config: {
                                    value: campaign.view_grant?.account_num.toLocaleString()!,
                                    onCopy: () => handleCopy("Campaign ID"),
                                },
                            }}
                        >
                            {campaign.view_grant?.account_num}
                        </Action>
                    }
                />
                <Pair
                    className={styles.featurePair}
                    title="Campaign Password"
                    titlePosition="start"
                    value={
                        <Action
                            action={{
                                type: "copy",
                                config: {
                                    value: campaign.view_grant?.account_pass!,
                                    onCopy: () =>
                                        handleCopy("Campaign Password"),
                                },
                            }}
                        >
                            {campaign.view_grant?.account_pass}
                        </Action>
                    }
                />
            </Feature>
        </Dialog>
    );
};

export default DashboardInvestCampaignsGrant;
