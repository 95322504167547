import { FC, useEffect } from "react";
import clsx from "clsx";
import Pagination from "@material-ui/lab/Pagination";
import DashboardInvitesNetworksList from "./DashboardInvitesNetworksList";
import useUserInfo from "src/hooks/useUserInfo";
import { Api } from "src/api/root";
import { UseApiActionType } from "src/hooks/useApi";
import Dialog from "src/libs/dialog/Dialog";
import styles from "./DashboardInvites.module.scss";
import useSettings from "../../hooks/useSettings";

type Props = {
    className?: string;
    open: boolean;
    onClose(): void;
};

const DashboardInvitesNetworksDialog: FC<Props> = ({
    className,
    open,
    onClose,
}) => {
    const { settings } = useSettings();
    const { fullConnected } = useUserInfo();
    const api = Api.invites().useGetInviteCircles({
        perPage: 6,
    });

    useEffect(() => {
        if (open) {
            api.run({});
        }

        return () => api.abort();
    }, [
        open,
        fullConnected,
        settings?.launch.id,
        api.state.pageNum,
        api.state.perPage,
    ]);

    const handleClose = () => {
        api.dispatch({
            type: UseApiActionType.STATE_RESET,
        });

        onClose();
    };

    const handlePageChange = (page: number) => {
        api.dispatch({
            type: UseApiActionType.STATE_UPDATE,
            newState: { pageNum: page },
        });
    };

    return (
        <Dialog
            className={clsx(styles.dialog, className)}
            onClose={handleClose}
            open={open}
            boxProps={{
                title:
                    "Network Users" +
                    (api.state.totalNum ? ` (${api.state.totalNum})` : ""),
                footerRender: !!api.state.totalNum && (
                    <Pagination
                        className={styles.pagination}
                        page={api.state.pageNum}
                        count={Math.ceil(
                            api.state.totalNum! / api.state.perPage!
                        )}
                        onChange={(event, page) => {
                            handlePageChange(page);
                        }}
                    />
                ),
            }}
        >
            <DashboardInvitesNetworksList
                loading={api.state.loading}
                items={
                    !api.state.loading ? api.state.data : api.state.loadingItems
                }
            />
        </Dialog>
    );
};

export default DashboardInvitesNetworksDialog;
