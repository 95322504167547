import { FC, PropsWithChildren, ReactNode, useState } from "react";
import clsx from "clsx";
import { IoClose } from "react-icons/io5";
import useResponsive from "src/hooks/useResponsive";
import { FaAngleDoubleDown } from "react-icons/fa";
import AnimateHeight from "react-animate-height";
import styles from "./Box.module.scss";

export type BoxProps = {
    className?: string;
    contentClassName?: string;
    title?: ReactNode;
    footerRender?: ReactNode;
    type?: "flat" | "round";
    clickable?: boolean;
    collapseHeightPx?: number;
    hasCloseButton?: boolean;
    onClose?(): void;
};

const Box: FC<PropsWithChildren<BoxProps>> = ({
    children,
    className,
    contentClassName,
    title,
    footerRender,
    type,
    clickable,
    collapseHeightPx,
    hasCloseButton,
    onClose,
}) => {
    const [isMobile] = useResponsive();
    const [isExpanded, setIsExpanded] = useState(false);

    const handleClose = () => {
        if (onClose) onClose();
    };

    const renderContent = () => {
        return (
            <>
                {title && (
                    <div className={styles.titleWrap}>
                        <div
                            className={clsx(
                                styles.title,
                                !hasCloseButton && styles.titleCenter
                            )}
                        >
                            {title}
                        </div>
                        {hasCloseButton && (
                            <div className={styles.closeButton}>
                                <IoClose
                                    className={styles.icon}
                                    onClick={handleClose}
                                />
                            </div>
                        )}
                    </div>
                )}

                <div className={clsx(styles.boxContent, contentClassName)}>
                    {children}
                    {footerRender && (
                        <div className={styles.footer}>{footerRender}</div>
                    )}
                </div>

                {collapseHeightPx && (
                    <div
                        className={clsx(
                            styles.expandWrap,
                            isExpanded && styles.exapndWrapOpen
                        )}
                        onClick={() => setIsExpanded(true)}
                    >
                        <div className={styles.expandContent}>
                            <FaAngleDoubleDown className={styles.logo} />
                            <span>View More</span>
                            <FaAngleDoubleDown className={styles.logo} />
                        </div>
                    </div>
                )}
            </>
        );
    };

    const commonProps = {
        className: clsx(
            styles.box,
            isMobile && styles.boxMobile,
            type == "round" && styles.boxRound,
            clickable && styles.boxClickable,
            className
        ),
    };

    return collapseHeightPx ? (
        <AnimateHeight
            duration={200}
            height={collapseHeightPx && !isExpanded ? collapseHeightPx : "auto"}
            {...commonProps}
        >
            {renderContent()}
        </AnimateHeight>
    ) : (
        <div {...commonProps}>{renderContent()}</div>
    );
};

export default Box;
