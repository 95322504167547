import {
    FC,
    useEffect,
    createContext,
    useContext,
    useState,
    PropsWithChildren,
} from "react";
import useSettings from "./useSettings";
import {
    PublicKey,
    Transaction,
    SystemProgram,
    LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { subtractDiscount } from "../services/price";
import { asyncForEach } from "../services/utils";
import {
    GetShopDiscountsResponse,
    InitializeShopPaymentParams,
    CallbackShopPaymentParams,
} from "../api/shop";
import useUserInfo from "../hooks/useUserInfo";
import { ClientCurrencyShareType } from "../types/client";
import { Api } from "../api/root";
import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    Token,
    TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import useConnection from "./useConnection";

export const getDiscountedPrice = (
    price: number,
    shopDiscounts?: GetShopDiscountsResponse,
    isRenew?: boolean
) => {
    if (isRenew) {
        price = subtractDiscount(
            price,
            shopDiscounts?.summary.renew_amount_off,
            shopDiscounts?.summary.renew_percent_off
        );
    } else {
        price = subtractDiscount(
            price,
            shopDiscounts?.summary.new_buy_amount_off,
            shopDiscounts?.summary.new_buy_percent_off
        );
    }

    return price;
};

export type MakePaymentType = {
    transactionBase64: string;
    reference: string;
};

export class MakePaymentError extends Error {}

type ShopContext = {
    isDirectOffCatched: boolean;
    setIsDirectOffCatched(catchedDirectOff: boolean): void;
    catchedCoupon: string | null;
    setCatchedCoupon(catchedCoupon: string): void;
    shopDiscounts: GetShopDiscountsResponse | null;
    loadingShopDiscounts: boolean;
    updateShopDiscounts(): Promise<void>;
    makePayment(
        initializePayment: InitializeShopPaymentParams
    ): Promise<MakePaymentType>;
    callbackPayment(callbackPayment: CallbackShopPaymentParams): Promise<void>;
};

const ShopContext = createContext<ShopContext>({
    isDirectOffCatched: false,
    setIsDirectOffCatched: () => {},
    catchedCoupon: null,
    setCatchedCoupon: () => {},
    shopDiscounts: null,
    loadingShopDiscounts: false,
    updateShopDiscounts: async () => {},
    makePayment: async () => "" as any,
    callbackPayment: async () => {},
});

const useShop = () => useContext(ShopContext);

export const ShopProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
    const { settings, selectedCurrency } = useSettings();
    const { publicKey } = useWallet();
    const { fullConnected } = useUserInfo();
    const { connection } = useConnection();
    const [isDirectOffCatched, setIsDirectOffCatched] =
        useState<boolean>(false);
    const [catchedCoupon, setCatchedCoupon] = useState<string | null>(null);
    const [shopDiscounts, setShopDiscounts] =
        useState<GetShopDiscountsResponse | null>(null);
    const [loadingShopDiscounts, setLoadingShopDiscounts] =
        useState<boolean>(false);
    const userDiscountsApi = Api.shops().useGetShopDiscounts();
    const anonDiscountsApi = Api.shops().useGetShopDiscountsAnon();
    const initializeApi = Api.shops().useInitializeShopPayment();
    const callbackApi = Api.shops().useCallbackShopPayment();

    // update shop discounts
    /*useEffect(() => {
        (async () => {
            setLoadingShopDiscounts(true);
            await updateShopDiscounts();
            setLoadingShopDiscounts(false);
        })();

        return () => {
            userDiscountsApi.abort();
            anonDiscountsApi.abort();
        };
    }, [fullConnected, settings, isDirectOffCatched, catchedCoupon]);*/

    useEffect(() => {
        if (shopDiscounts) {
            prepareShopDiscounts(shopDiscounts);
        }
    }, [selectedCurrency?.value]);

    const updateShopDiscounts = async () => {
        if (fullConnected) {
            await userDiscountsApi
                .run({
                    catch_direct_visit_off: isDirectOffCatched,
                    ...(catchedCoupon && {
                        coupon_code: catchedCoupon,
                    }),
                    ...(selectedCurrency && {
                        client_currency_id: selectedCurrency.data.id,
                    }),
                })
                .then((data) => {
                    prepareShopDiscounts(data);
                });
        } else {
            await anonDiscountsApi
                .run({
                    ...(catchedCoupon && {
                        coupon_code: catchedCoupon,
                    }),
                    ...(selectedCurrency && {
                        client_currency_id: selectedCurrency.data.id,
                    }),
                })
                .then((data) => {
                    prepareShopDiscounts(data);
                });
        }
    };

    // add or remove currency discount from shop discounts
    // to prevent api loading each time user changes currency
    const prepareShopDiscounts = (target: GetShopDiscountsResponse) => {
        // remove previous currency discount
        if (target.list) {
            const preCurrencyDiscountIndex = target.list.findIndex(
                (item) => item.type == "currency"
            );

            if (
                preCurrencyDiscountIndex != undefined &&
                preCurrencyDiscountIndex != -1
            ) {
                const prePercentOff =
                    target.list[preCurrencyDiscountIndex].percent_off;
                target.list?.splice(preCurrencyDiscountIndex, 1);
                target.summary.new_buy_percent_off! -= prePercentOff!;
                target.summary.renew_percent_off! -= prePercentOff!;
            }
        }

        // add new currency discount
        const newDiscountPercent = selectedCurrency?.data.percent_off || 0;
        if (newDiscountPercent > 0) {
            target.list?.push({
                type: "currency",
                percent_off: newDiscountPercent,
            });

            if (target.summary.new_buy_percent_off) {
                target.summary.new_buy_percent_off += newDiscountPercent;
            } else target.summary.new_buy_percent_off = newDiscountPercent;

            if (target.summary.renew_percent_off) {
                target.summary.renew_percent_off += newDiscountPercent;
            } else target.summary.renew_percent_off = newDiscountPercent;
        }

        setShopDiscounts({ ...target });
    };

    const makePayment = async (
        initializePayment: InitializeShopPaymentParams
    ): Promise<MakePaymentType> => {
        if (!publicKey || !selectedCurrency) throw new Error();

        const buyerAddress = publicKey.toString();
        const buyerPublicKey = new PublicKey(buyerAddress);

        // initialize transaction
        const initializedPayment = await initializeApi.run(initializePayment);

        const shopPublicKey = new PublicKey(initializedPayment.destination_pub);
        const reference = initializedPayment.reference_pub;
        const paymentShareList = initializedPayment.payment_share_list;

        // Get a recent blockhash to include in the transaction
        const { blockhash } = await connection.getLatestBlockhash("finalized");

        const transaction = new Transaction({
            recentBlockhash: blockhash,
            feePayer: buyerPublicKey, // The buyer pays the transaction fee
        });

        // add transaction instructions
        await asyncForEach(
            selectedCurrency.data.shares.items,
            async (currencyShare: ClientCurrencyShareType) => {
                if (!currencyShare.coin) return;

                // prepare amount
                const foundedPaymentItem = paymentShareList.find(
                    (paymentShareItem) =>
                        paymentShareItem.client_currency_share_id ==
                        currencyShare.id
                );
                if (!foundedPaymentItem) throw new Error();

                const amount = foundedPaymentItem.amount;

                // Create the instruction to send from the buyer to the shop
                let transferInstruction;
                if (currencyShare.coin_network?.platform_coin) {
                    // Get details about the token
                    const tokenAddress = new PublicKey(
                        currencyShare.coin_network.contract_address!
                    );

                    // prepare shop token address
                    const shopTokenAddress =
                        await Token.getAssociatedTokenAddress(
                            ASSOCIATED_TOKEN_PROGRAM_ID,
                            TOKEN_PROGRAM_ID,
                            tokenAddress,
                            shopPublicKey
                        );

                    // prepare buyer token address
                    const buyerTokenAddress =
                        await Token.getAssociatedTokenAddress(
                            ASSOCIATED_TOKEN_PROGRAM_ID,
                            TOKEN_PROGRAM_ID,
                            tokenAddress,
                            buyerPublicKey
                        );

                    // throw error if does not have sufficient fund
                    try {
                        const senderAccount =
                            await connection.getTokenAccountBalance(
                                buyerTokenAddress
                            );

                        if ((senderAccount.value.uiAmount || 0) < amount) {
                            throw new MakePaymentError(
                                `Insufficient ${currencyShare.coin.symbol} balance!`
                            );
                        }
                    } catch (error) {
                        throw new MakePaymentError(
                            `Insufficient ${currencyShare.coin.symbol} balance!`
                        );
                    }

                    // create instruction
                    transferInstruction =
                        Token.createTransferCheckedInstruction(
                            TOKEN_PROGRAM_ID,
                            buyerTokenAddress,
                            tokenAddress,
                            shopTokenAddress,
                            buyerPublicKey,
                            [],
                            Math.floor(
                                amount * 10 ** currencyShare.coin.decimals
                            ),
                            currencyShare.coin.decimals
                        );
                } else {
                    // throw error if does not have sufficient fund
                    const balanceLamports = await connection.getBalance(
                        buyerPublicKey
                    );

                    if (balanceLamports < amount * LAMPORTS_PER_SOL) {
                        throw new MakePaymentError(
                            `Insufficient ${currencyShare.coin.symbol} balance!`
                        );
                    }

                    // create instruction
                    transferInstruction = SystemProgram.transfer({
                        fromPubkey: buyerPublicKey,
                        lamports: Math.floor(amount * LAMPORTS_PER_SOL),
                        toPubkey: shopPublicKey,
                    });
                }

                // Add the reference to the instruction as a key
                // This will mean this transaction is returned when we query for the reference
                transferInstruction.keys.push({
                    pubkey: new PublicKey(reference),
                    isSigner: false,
                    isWritable: false,
                });

                // Add the instruction to the transaction
                transaction.add(transferInstruction);
            }
        );

        // Serialize the transaction and convert to base64 to return it
        const serializedTransaction = transaction.serialize({
            // We will need the buyer to sign this transaction after it's returned to them
            requireAllSignatures: false,
        });
        const transactionBase64 = serializedTransaction.toString("base64");

        // Return the serialized transaction
        return {
            transactionBase64,
            reference,
        };
    };

    const callbackPayment = async (
        callbackPayment: CallbackShopPaymentParams
    ) => {
        await callbackApi.run(callbackPayment);
    };

    // TODO
    const addToShopDiscounts = () => {};
    const removeFromShopDiscounts = () => {};

    return (
        <ShopContext.Provider
            value={{
                isDirectOffCatched,
                setIsDirectOffCatched,
                catchedCoupon,
                setCatchedCoupon,
                shopDiscounts,
                loadingShopDiscounts,
                updateShopDiscounts,
                makePayment,
                callbackPayment,
            }}
        >
            {children}
        </ShopContext.Provider>
    );
};

export default useShop;
