import { FC, useState } from "react";
import clsx from "clsx";
import PreviewImage from "./PreviewImage";
import PreviewInfo from "./PreviewInfo";
import PreviewAbout from "./PreviewAbout";
import useResponsive from "../../hooks/useResponsive";
import PreviewProjects from "./PreviewProjects";
import styles from "./Preview.module.scss";
import AnimateHeight from "react-animate-height";

type Props = {
    className?: string;
};

const Preview: FC<Props> = ({ className }) => {
    const [isMobile] = useResponsive();
    const [learnMoreOpen, setLearnMoreOpen] = useState<boolean>(false);

    return (
        <div className={clsx(styles.preview, className)}>
            {!isMobile && <PreviewImage className={styles.previewImage} />}
            <PreviewInfo
                learnMoreOpen={learnMoreOpen}
                learnMoreOpenChange={setLearnMoreOpen}
            />

            <AnimateHeight
                duration={500}
                height={learnMoreOpen || !isMobile ? "auto" : 0}
            >
                <PreviewAbout className={styles.aboutWrap} />
                <PreviewProjects />
            </AnimateHeight>
        </div>
    );
};

export default Preview;
