import clsx from "clsx";
import moment from "moment";
import Column from "rc-table/lib/sugar/Column";
import { FC } from "react";
import { Api } from "../../api/root";
import { AssetClaimNetworkType, AssetClaimType } from "../../types/asset";
import Plat from "../plat/Plat";
import Price from "../price/Price";
import { walletBags } from "./WalletDialog";
import AlertMini from "../../libs/alert-mini/AlertMini";
import useSettings from "../../hooks/useSettings";
import styles from "./Wallet.module.scss";

type Props = {
    className?: string;
};

const WalletClaims: FC<Props> = ({ className }) => {
    const { settings } = useSettings();

    const api = Api.assets().useGetAssetClaims({
        perPage: 2,
    });

    const dialogApi = Api.assets().useGetAssetClaims({
        perPage: 6,
    });

    return (
        <Plat
            className={clsx(className)}
            title="Claims"
            api={api}
            needAuth={true}
            hideHeader={true}
            onApiRequest={(params, options) => {
                api.run(
                    {
                        ...(params as any),
                        launch_id: settings?.launch.id,
                    },
                    options
                );
            }}
            minimal={{
                enable: true,
                dialogApi,
                onDialogApiRequest: (params, options) => {
                    dialogApi.run(
                        {
                            ...(params as any),
                            launch_id: settings?.launch.id,
                        },
                        options
                    );
                },
            }}
            renderTableColumns={() => {
                return (
                    <>
                        <Column
                            title="Wallet"
                            dataIndex={["asset", "bag_tag"]}
                            key="bag_tag"
                            width={30}
                            render={(bag_tag, assetClaim: AssetClaimType) => (
                                <>
                                    <div className={styles.cellTop}>
                                        {
                                            walletBags.find(
                                                (walletBag) =>
                                                    walletBag.asset_bag ==
                                                    bag_tag
                                            )?.title
                                        }
                                    </div>
                                    <AlertMini
                                        severity={
                                            assetClaim.status == "completed"
                                                ? "success"
                                                : assetClaim.status ==
                                                  "rejected"
                                                ? "error"
                                                : "idle"
                                        }
                                    >
                                        {assetClaim.status}
                                    </AlertMini>
                                </>
                            )}
                        />
                        <Column
                            title="Amount"
                            dataIndex={["networks"]}
                            key="amount"
                            width={30}
                            render={(networks) =>
                                networks?.items.map(
                                    (claimNetwork: AssetClaimNetworkType) => {
                                        return claimNetwork.currencies.items.map(
                                            (claimCurrency) => {
                                                return (
                                                    <Price
                                                        key={claimCurrency.id}
                                                        priceSource="none"
                                                        price={
                                                            claimCurrency.amount
                                                        }
                                                        fiat={
                                                            claimCurrency.fiat
                                                        }
                                                        coin={
                                                            claimCurrency.coin
                                                        }
                                                    />
                                                );
                                            }
                                        );
                                    }
                                )
                            }
                        />
                        <Column
                            title="Claim"
                            dataIndex={["created_date"]}
                            key="created_date"
                            width={50}
                            render={(created_date) => (
                                <span
                                    data-tip={
                                        created_date &&
                                        moment.unix(created_date).format()
                                    }
                                >
                                    {created_date
                                        ? moment.unix(created_date).fromNow()
                                        : "_"}
                                </span>
                            )}
                        />
                        <Column
                            title="Payout"
                            dataIndex={["reviewed_date"]}
                            key="reviewed_date"
                            width={50}
                            render={(reviewed_date) => (
                                <span
                                    data-tip={
                                        reviewed_date &&
                                        moment.unix(reviewed_date).format()
                                    }
                                >
                                    {reviewed_date
                                        ? moment.unix(reviewed_date).fromNow()
                                        : "_"}
                                </span>
                            )}
                        />
                    </>
                );
            }}
        />
    );
};

export default WalletClaims;
