import {
    FC,
    createContext,
    useContext,
    useState,
    PropsWithChildren,
    useEffect,
} from "react";
import { TaskMissionType, TaskTagType, TaskType } from "src/types/task";
import useSettings from "./useSettings";
import useUserInfo from "./useUserInfo";

export type MyTaskType = {
    task: TaskType;
    my_missions: MyTaskMission[];
};

export type MyTaskMission = {
    mission: TaskMissionType;
    activate_date?: number;
};

const TasksContext = createContext<{
    availableTasks: MyTaskType[];
    findAvailableTask(taskTag: TaskTagType): MyTaskType | null;
}>({
    availableTasks: [],
    findAvailableTask: () => null,
});

const useTasks = () => useContext(TasksContext);

export const TasksProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
    const { fullConnected, userInfo } = useUserInfo();
    const { settings } = useSettings();
    const [availableTasks, setAvailableTasks] = useState<MyTaskType[]>([]);

    const prepareAvailableTasks = (): MyTaskType[] => {
        if (!fullConnected || !userInfo || !settings) return [];

        // filter tasks that are not yet completed by user
        const myTasks: MyTaskType[] = [];
        settings.launch.tasks.items.forEach((taskItem) => {
            const myTaskMissions: MyTaskMission[] = [];
            taskItem.active_missions.items.forEach((TaskMissionItem) => {
                // find mission closure from the user
                const foundedMissionClosure =
                    userInfo.tasks_missions_closures?.items.find(
                        (taskMissionClosureItem) => {
                            return (
                                taskMissionClosureItem.mission.id ==
                                TaskMissionItem.id
                            );
                        }
                    );

                // do add if not in mission closure or have a reactive date
                if (
                    !foundedMissionClosure ||
                    foundedMissionClosure.activate_date
                ) {
                    myTaskMissions.push({
                        mission: TaskMissionItem,
                        activate_date: foundedMissionClosure?.activate_date,
                    });
                }
            });

            // add task if has available missions
            if (myTaskMissions.length > 0) {
                myTasks.push({
                    task: taskItem,
                    my_missions: myTaskMissions,
                });
            }
        });

        // return my tasks
        return myTasks;
    };

    const findAvailableTask = (taskTag: TaskTagType): MyTaskType | null => {
        return (
            availableTasks.find(
                (myTaskItem) => myTaskItem.task.task_tag == taskTag
            ) || null
        );
    };

    useEffect(() => {
        setAvailableTasks(prepareAvailableTasks());
    }, [userInfo, fullConnected]);

    return (
        <TasksContext.Provider
            value={{
                availableTasks,
                findAvailableTask,
            }}
        >
            {children}
        </TasksContext.Provider>
    );
};

export default useTasks;
