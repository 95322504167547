import { FC } from "react";
import clsx from "clsx";
import DashboardMintGift from "./DashboardMintGift";
import DashboardMintAffiliate from "./DashboardMintAffiliate";
import Mint from "src/components/mint/Mint";
import useConnection from "src/hooks/useConnection";
import { DEFAULT_TIMEOUT } from "src/services/connection";
import DashboardMintRewards from "./DashboardMintRewards";
import styles from "./DashboardMint.module.scss";
import PreviewSummary from "../preview/PreviewSummary";
import DashboardMintPoints from "./DashboardMintPoints";

type Props = {
    className?: string;
};

const DashboardMint: FC<Props> = ({ className }) => {
    const { network, candyMachineId, connection, rpcHost, error } =
        useConnection();

    return (
        <div className={clsx(styles.dashboard, className)}>
            <PreviewSummary />
            <DashboardMintGift className={styles.giftWrap} />
            <Mint
                className={styles.mintWrap}
                candyMachineId={candyMachineId}
                connection={connection}
                txTimeout={DEFAULT_TIMEOUT}
                rpcHost={rpcHost}
                network={network}
                error={error}
            />
            <DashboardMintAffiliate className={styles.affiliateWrap} />
            {/* <DashboardMintPoints className={styles.pointsWrap} /> */}
            <DashboardMintRewards className={styles.rewardsWrap} />
        </div>
    );
};

export default DashboardMint;
