import { FC } from "react";
import clsx from "clsx";
import useResponsive from "../../hooks/useResponsive";
import styles from "./Preview.module.scss";

type Props = {
    className?: string;
};

const PreviewProjects: FC<Props> = ({ className }) => {
    const [isMobile] = useResponsive();

    return (
        <div className={clsx(styles.previewWrap, className)}>
            <div className={styles.previewSection}>
                <div className={styles.previewSectionTitle}>CRC Projects</div>
            </div>

            <div className={styles.projectWrap}>
                <div className={styles.projectNum}>1</div>
                <div className={styles.projectDetails}>
                    <div className={styles.projectTitle}>
                        <div className={styles.title}>LottoFactory.io</div>
                        <div className={styles.subTitle}>
                            Don&apos;t miss our $200,000 lottery jackpot
                            fundraising
                        </div>
                    </div>
                    <a
                        className={styles.projectButton}
                        href="https://lottofactory.io"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {!isMobile ? "See Progress" : "Progress"}
                    </a>
                </div>
            </div>

            <div className={styles.projectWrap}>
                <div className={styles.projectNum}>2</div>
                <div className={styles.projectDetails}>
                    <div className={styles.projectTitle}>
                        <div className={styles.title}>SolGate.io</div>
                        <div className={styles.subTitle}>
                            Fully launched VPN service powered by Solana
                            blockchain
                        </div>
                    </div>
                    <a
                        className={styles.projectButton}
                        href="https://solgate.io"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Visit Here
                    </a>
                </div>
            </div>

            <div className={styles.projectWrap}>
                <div className={styles.projectNum}>3</div>
                <div className={styles.projectDetails}>
                    <div className={styles.projectTitle}>
                        <div className={styles.title}>CRC x FORIXO</div>
                        <div className={styles.subTitle}>
                            Gain 4-10% per month with FORIXO&lsquo;s AI-Powered
                            trading bots
                        </div>
                    </div>
                    <a
                        className={styles.projectButton}
                        href="https://docs.forixo.io"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Learn More
                    </a>
                </div>
            </div>

            <div className={styles.projectWrap}>
                <div className={styles.projectNum}>4</div>
                <div className={styles.projectDetails}>
                    <div className={styles.projectTitle}>
                        <div className={styles.title}>More Coming Soon...</div>
                        <div className={styles.subTitle}>
                            New projects are coming in near future to expand the
                            ecosystem!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreviewProjects;
