import { FC, useState } from "react";
import Alert from "../../libs/alert/Alert";
import Dialog from "../../libs/dialog/Dialog";
import Feature from "../../libs/feature/Feature";
import FormButton from "../../libs/form-button/FormButton";
import NumberCount from "../../libs/number-count/NumberCount";
import Pair from "../../libs/pair/Pair";
import { InvestFundType } from "../../types/invest";
import DashboardInvestFundsWithdrawConfirm from "./DashboardInvestFundsWithdrawConfirm";
import styles from "./DashboardInvest.module.scss";

type Props = {
    className?: string;
    investFund: InvestFundType;
    open: boolean;
    onClose(): void;
};

const penaltyPercent = 10;

const DashboardInvestFundsWithdraw: FC<Props> = ({
    className,
    investFund,
    open,
    onClose,
}) => {
    const [fundsAmount, setFundsAmount] = useState(
        investFund.campaign.withdraw_step_fund_usd
    );
    const [openConfirm, setOpenConfirm] = useState(false);

    return (
        <Dialog
            className={className}
            onClose={onClose}
            open={open}
            maxWidth="xs"
            boxProps={{
                title: `Withdraw Funds (Campaign ${investFund.campaign.campaign_tag})`,
            }}
        >
            <Alert severity="info" className={styles.withdrawFundsAlert}>
                {`Funds can be withdrawn at any time, with a ${penaltyPercent}% penalty in place.`}
            </Alert>

            <div className={styles.withdrawFundsDescription}>
                By withdrawing, you will lose the potential gains from the
                current cycle. Your funds will be directly deposited into your
                wallet.
            </div>

            <Feature className={styles.withdrawFundsSettings}>
                <Pair
                    title="Withdrawing (USD)"
                    titlePosition="start"
                    value={
                        <NumberCount
                            num={fundsAmount}
                            min={investFund.campaign.withdraw_step_fund_usd}
                            max={investFund.funded_usd}
                            step={investFund.campaign.withdraw_step_fund_usd}
                            numChange={setFundsAmount}
                        />
                    }
                />
            </Feature>

            <div className={styles.withdrawFundsButtonGroup}>
                <FormButton color="base" onClick={onClose}>
                    Cancel
                </FormButton>

                <FormButton
                    className={styles.withdrawFundsButton}
                    onClick={() => setOpenConfirm(true)}
                >
                    {`Withdraw Funds ($${fundsAmount.toLocaleString()})`}
                </FormButton>
            </div>

            <DashboardInvestFundsWithdrawConfirm
                investFund={investFund}
                withdrawingUSD={fundsAmount}
                penaltyPercent={penaltyPercent}
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                onConfirm={() => {
                    setOpenConfirm(false);
                    onClose();
                }}
            />
        </Dialog>
    );
};

export default DashboardInvestFundsWithdraw;
