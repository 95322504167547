import { FC, PropsWithChildren, Children, useState, useEffect } from "react";
import clsx from "clsx";
import ScrollBar from "src/libs/scroll-bar/ScrollBar";
import { Props as TabProps } from "./SwitchTab";
import styles from "./SwitchTabs.module.scss";

type Props = {
    className?: string;
    labelWrapClassName?: string;
    activeTab?: string;
    hasScroll?: boolean;
    type?: "round" | "flat" | "line";
    onChange?(tag: string): void;
};

const SwitchTabGroup: FC<PropsWithChildren<Props>> = ({
    children,
    className,
    labelWrapClassName,
    activeTab,
    hasScroll,
    type,
    onChange,
}) => {
    // set default active tab to the first tab (if already not set)
    if (!activeTab && Children.count(children) > 0) {
        activeTab = (Children.toArray(children)[0] as any).props.tag;
    }

    // set state for activeTab
    const [activeTabTag, setActiveTabTag] = useState(activeTab || "");

    // detect activeTab prop change
    useEffect(() => {
        setActiveTabTag(activeTab || "");
    }, [activeTab]);

    // detect change tab
    const onChangeTab = (tag: string) => {
        setActiveTabTag(tag);
        if (onChange) onChange(tag);
    };

    // return dom
    return (
        <div className={clsx(styles.tabWrap, className)}>
            <div
                className={clsx(
                    labelWrapClassName,
                    styles.labelList,
                    type == "flat" && styles.labelListFlat,
                    type == "line" && styles.labelListLine
                )}
            >
                {Children.map(children, (child: any) => {
                    const { label, tag } = child.props as TabProps;
                    return (
                        <div
                            className={clsx(
                                styles.labelItem,
                                tag == activeTabTag && styles.labelActive
                            )}
                            onClick={() => onChangeTab(tag)}
                        >
                            {label}
                        </div>
                    );
                })}
            </div>

            {Children.map(children, (child: any) => {
                const { tag } = child.props as TabProps;
                return (
                    <div
                        className={clsx(hasScroll && styles.tabScrollContent)}
                        style={{
                            display: tag == activeTabTag ? "block" : "none",
                        }}
                    >
                        <ScrollBar ignoreScroll={!hasScroll}>
                            {child.props.children}
                        </ScrollBar>
                    </div>
                );
            })}
        </div>
    );
};

export default SwitchTabGroup;
