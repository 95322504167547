import { UseAssetsApi } from "./asset";
import { UseAuthApi } from "./auth";
import { UseCampaignsApi } from "./campaign";
import { UseConnectionsApi } from "./connection";
import { UseGradesApi } from "./grade";
import { UseInvestsApi } from "./invest";
import { UseInvitesApi } from "./invite";
import { UseLaunchesApi } from "./launch";
import { UseLeadersApi } from "./leader";
import { UseMintsApi } from "./mint";
import { UsePointsApi } from "./point";
import { UseShopApi } from "./shop";
import { UseTasksApi } from "./task";
import { UseUsersApi } from "./user";

export const apiTestMode = process.env.REACT_APP_API_TEST_MODE == "true";

export type ApiGetGroupParams = {
    limit?: number;
    offset?: {
        from: number;
        field?: string;
    };
    order?: {
        field: string;
        direction?: "ASC" | "DESC";
    };
    q?: string;
    fields?: string[];
    exclude_fields?: string[];
    main_fields?: boolean;
    get_total?: boolean;
    get_total_only?: boolean;
};

export type ApiGetSingleParams = {
    fields?: string[];
    exclude_fields?: string[];
    main_fields?: boolean;
};

export type ApiOptions = {
    headers?: Object;
    signal?: AbortSignal;
    runInBackground?: boolean;
    fakeLoading?: boolean;
};

export type ApiResponse = {
    success: boolean;
    result?: Object;
    error?: {
        code: string;
        message: string;
    };
};

export class Api {
    static assets() {
        return new UseAssetsApi();
    }

    static auth() {
        return new UseAuthApi();
    }

    static campaigns() {
        return new UseCampaignsApi();
    }

    static connections() {
        return new UseConnectionsApi();
    }

    static grades() {
        return new UseGradesApi();
    }

    static invests() {
        return new UseInvestsApi();
    }

    static invites() {
        return new UseInvitesApi();
    }

    static leaders() {
        return new UseLeadersApi();
    }

    static mints() {
        return new UseMintsApi();
    }

    static points() {
        return new UsePointsApi();
    }

    static shops() {
        return new UseShopApi();
    }

    static tasks() {
        return new UseTasksApi();
    }

    static users() {
        return new UseUsersApi();
    }

    static launches() {
        return new UseLaunchesApi();
    }
}
