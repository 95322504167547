import clsx from "clsx";
import { FC, ReactNode, useMemo, useState } from "react";
import AnimateHeight from "react-animate-height";
import { HiOutlineExternalLink } from "react-icons/hi";
import { MdKeyboardArrowDown } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import useSettings from "src/hooks/useSettings";
import Feature from "src/libs/feature/Feature";
import Pair from "src/libs/pair/Pair";
import Box from "../../libs/box/Box";
import FaqList, { FaqItemType } from "../../libs/faq-list/FaqList";
import styles from "./DashboardInvest.module.scss";

type Props = {
    className?: string;
};

type InvestGroupType = {
    title: ReactNode;
    content: ReactNode;
};

const investFaqList: FaqItemType[] = [
    {
        title: "What is a campaign?",
        content:
            "Campaigns are where you can participate in funding and share a spot with others to fill the $20,000 target. After fully funding the campaign by the community, you will gain 4-10% monthly passive income from your funding in that campaign.",
    },
    {
        title: "How minimum gain of 4% per month is achieved?",
        content: (
            <>
                It&apos;s possible with our Reserve Treasury to maintain a
                targeted allocation of 25% of the total funds.{" "}
                <a
                    href="https://docs.forixo.io/how-forixo-works/gains-distribution"
                    target="_blank"
                    rel="noreferrer"
                >
                    Click Here
                </a>{" "}
                to learn more.
            </>
        ),
    },
    {
        title: "How to participate in a campaign?",
        content: (
            <>
                You have to connect your wallet and add funds to your desired
                campaign. For step by step guide{" "}
                <a
                    href="https://docs.forixo.io/how-forixo-works/how-to-participate"
                    target="_blank"
                    rel="noreferrer"
                >
                    Click Here
                </a>
                .
            </>
        ),
    },
    {
        title: "Do we have access to our capital and can it be withdrawn?",
        content:
            "Yes for sure, you can withdraw your capital at any time. You will receive it within 3 working days.",
    },
    {
        title: "What happens if Forex Bots lose?",
        content:
            "We are constantly updating our Bots and with the help of Machine Learning, Reserve System, and Anti-liquide Strategy we ensure our contributors receive 4% by the end of the month.",
    },
    {
        title: "Do I have access to the trades?",
        content: (
            <>
                Yes, absolutely. You can monitor the trades of your campaign
                from MT4. For step by step guide{" "}
                <a
                    href="https://docs.forixo.io/how-forixo-works/how-to-monitor"
                    target="_blank"
                    rel="noreferrer"
                >
                    Click Here
                </a>
                .
            </>
        ),
    },
    {
        title: "How can I withdraw my gains?",
        content:
            "After each gain distribution, your wallet will be charged with your gains and you can simply claim your income by clicking on 'Wallet' then 'Claim'.",
    },
    {
        title: "When will the gains be distributed?",
        content:
            "Every 1st and 16th of every month. In case these days are on weekends or public holidays, the withdrawal will take place the next working day.",
    },
    {
        title: "What is a funding commission?",
        content:
            "Funding commission is paid back to those who are active in our network system and they will be rewarded 5-10% (based on highest grade) of each invited funding.",
    },
    {
        title: "What is IB commission?",
        content:
            "IB commission is a passive income, which you receive as a reward when a trade LOT from your network funds is closed. Visit the circles guide to see the commissions for your 5 circles.",
    },
    {
        title: "What currency pairs does Forixo Bot trade on?",
        content: "Eur/USD, and XAU/USD.",
    },
    {
        title: "What is the minimum and maximum amount of participation in the campaign?",
        content:
            "The minimum is $1000 and the maximum is based on each campaign's remaining spots.",
    },
    {
        title: "How much can you earn with community building?",
        content:
            "Well, That depends on your community. You can earn as much as 1 Million Dollars by simply inviting your friends to add funds to the campaigns and also make an extra passive income from IB commission.",
    },
    {
        title: "Why Solana chain?",
        content:
            "Solana's advantages lie in its high throughput, capability of processing thousands of transactions per second, low transaction fees, scalability without compromising security, and its appeal for DeFi and NFT applications due to its architecture's speed and efficiency.",
    },
];

const DashboardInvestInfo: FC<Props> = ({ className }) => {
    const [groupOpenIndex, setGroupOpenIndex] = useState<number>(-1);
    const { settings, loadingSettings } = useSettings();

    const renderSettings = (content: ReactNode) => {
        return !loadingSettings ? content : <Skeleton width={40} />;
    };

    const renderGroupList: InvestGroupType[] = useMemo(() => {
        return [
            {
                title: (
                    <>
                        <mark>T</mark>ransparent Trades
                    </>
                ),
                content: (
                    <div className={styles.infoGroupContent}>
                        FORIXO, the #1 transparent forex trading platform, opens
                        up a window into every trade executed by its bots inside
                        MT4.
                        <br />
                        <br />
                        You can access your funded campaign seamlessly using the
                        campaign credentials, enabling you to monitor each
                        position and observe their outcomes from the initial
                        trade to the present one.
                    </div>
                ),
            },
            {
                title: (
                    <>
                        <mark>A</mark>nti-liquid Strategy
                    </>
                ),
                content: (
                    <div className={styles.infoGroupContent}>
                        FORIXO&apos;s robust bots are designed to mitigate
                        substantial losses. Our intelligent money management
                        system ensures that trades are initiated with only a
                        fraction of the available funds.
                        <br />
                        <br />
                        Additionally, our trading strategy focuses on
                        well-established Forex pairs, such as EUR/USD, which
                        have a history of monetary stability, minimizing the
                        risk of collapse due to their strong financial
                        connections.
                    </div>
                ),
            },
            {
                title: (
                    <>
                        <mark>R</mark>eserve Treasury
                    </>
                ),
                content: (
                    <div className={styles.infoGroupContent}>
                        FORIXO&apos;s reserve strategy is crafted to provide a
                        consistent income to our contributors. The reserve
                        treasury maintains a targeted allocation of 25% of the
                        total funds across all campaigns.
                        <br />
                        <br />
                        The team&apos;s profits from the system primarily
                        contribute to filling the treasury, guaranteeing a
                        consistently well-funded reserve.
                        <br />
                        <br />
                        In months where gains fall below 4%, we tap into the
                        reserve treasury to sustain steady payouts. Conversely,
                        during months with gains exceeding 10%, we replenish the
                        reserve treasury until it reaches the 25% target.
                        <br />
                        <br />
                        This dynamic approach ensures stability and adaptability
                        in managing campaign returns for the benefit of our
                        contributors.
                    </div>
                ),
            },
            {
                title: (
                    <>
                        <mark>T</mark>rusted by Brokers
                    </>
                ),
                content: (
                    <div className={styles.infoGroupContent}>
                        We take pride in our partnership with FORIXO, which
                        boasts over four years of successful engagement in Forex
                        trading. With its extensive experience, FORIXO has
                        earned the highest level of trust from brokers in the
                        industry.
                    </div>
                ),
            },
            {
                title: "FAQ",
                content: <FaqList list={investFaqList} />,
            },
        ];
    }, []);

    const handleGrouopTitleClick = (groupIndex: number) => {
        if (groupIndex != groupOpenIndex) {
            setGroupOpenIndex(groupIndex);
        } else setGroupOpenIndex(-1);
    };

    return (
        <Box
            className={className}
            title="Funding by FORIXO"
            collapseHeightPx={420}
        >
            <div className={styles.infoStats}>
                <Feature className={styles.infoStatsItem}>
                    <Pair
                        title="Total AUM"
                        value={renderSettings(
                            `$${settings?.launch.campaign_stats.active_fund_usd.toLocaleString()}`
                        )}
                        titlePosition="bottom"
                    />
                </Feature>
                <Feature className={styles.infoStatsItem}>
                    <Pair
                        title="Total Reserve"
                        value={renderSettings(
                            `$${settings?.launch.campaign_stats.reserve_usd.toLocaleString()}`
                        )}
                        titlePosition="bottom"
                    />
                </Feature>
                <Feature className={styles.infoStatsItem}>
                    <Pair
                        title="30D APR"
                        value={renderSettings(
                            `${settings?.launch.campaign_stats.apr_percent}%`
                        )}
                        titlePosition="bottom"
                    />
                </Feature>
            </div>

            <div className={styles.infoDescription}>
                Participate in a campaign funding and gain 4-10% monthly income
                via FORIXO&apos;s AI-Powered trading bots, guided by our partner
                to a financial freedom.
                <a
                    href="https://docs.forixo.io"
                    rel="noreferrer"
                    target="_blank"
                >
                    <span>Learn More</span>
                    <HiOutlineExternalLink />
                </a>
                <br />
                <br />
                Relax and indulge in the yummy <mark>TART</mark>, the trustless
                standard where secure funding is simply the way things are done.
            </div>

            {renderGroupList.map((groupItem, groupIndex) => {
                return (
                    <div
                        key={groupIndex}
                        className={clsx(
                            styles.infoWrap,
                            groupOpenIndex == groupIndex && styles.infoWrapOpen
                        )}
                    >
                        <div
                            className={clsx(
                                styles.infoTitle,
                                groupOpenIndex == groupIndex &&
                                    styles.infoTitleOpen
                            )}
                            onClick={() => handleGrouopTitleClick(groupIndex)}
                        >
                            <span>{groupItem.title}</span>
                            <MdKeyboardArrowDown className={styles.arrow} />
                        </div>
                        <AnimateHeight
                            duration={500}
                            height={groupOpenIndex == groupIndex ? "auto" : 0}
                        >
                            <div className={clsx(styles.infoContent)}>
                                {groupItem.content}
                            </div>
                        </AnimateHeight>
                    </div>
                );
            })}
        </Box>
    );
};

export default DashboardInvestInfo;
