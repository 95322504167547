import { useApi, UseApiOptions } from "src/hooks/useApi";
import {
    inviteCirclesxample,
    InviteCircleType,
    InviteRewardReasonType,
    inviteRewardsExample,
    InviteRewardType,
} from "../types/invite";
import { ApiGetGroupParams, apiTestMode } from "./root";

export interface AddInviteParams {
    affiliate_code: string;
}

export interface GetInviteCirclesParams extends ApiGetGroupParams {
    sub_user_id?: number;
    circle_num?: number;
}

export interface GetInviteRewardsParams extends ApiGetGroupParams {
    reason?: InviteRewardReasonType;
    invited_user_id?: number;
    circle_num?: number;
}

export class UseInvitesApi {
    useGetInviteCircles(config?: UseApiOptions<InviteCircleType[]>) {
        return useApi<GetInviteCirclesParams, InviteCircleType[]>({
            method: "GET",
            path: "/invites/circles",
            needAuth: true,
            isList: true,
            test: {
                enable: apiTestMode,
                data: inviteCirclesxample,
            },
            ...config,
        });
    }

    useAddInvite(config?: UseApiOptions<{}>) {
        return useApi<AddInviteParams, {}>({
            method: "POST",
            path: "/invites",
            needAuth: true,
            test: {
                enable: apiTestMode,
            },
            ...config,
        });
    }

    useGetInviteRewards(config?: UseApiOptions<InviteRewardType[]>) {
        return useApi<GetInviteRewardsParams, InviteRewardType[]>({
            method: "GET",
            path: "/invites/rewards",
            needAuth: true,
            isList: true,
            test: {
                enable: apiTestMode,
                data: inviteRewardsExample,
            },
            ...config,
        });
    }
}
