import { FC, PropsWithChildren } from "react";
import clsx from "clsx";
import useResponsive from "src/hooks/useResponsive";
import styles from "./TableUI.module.scss";

type Props = {
    className?: string;
    type?: "simple" | "withBorder";
};

const TableUI: FC<PropsWithChildren<Props>> = ({
    className,
    children,
    type,
}) => {
    const [isMobile] = useResponsive();

    return (
        <div
            className={clsx(
                styles.tableUI,
                isMobile && styles.tableUIMobile,
                (!type || type == "simple") && styles.tableUISimple,
                type == "withBorder" && styles.tableUIWithBorder,
                className
            )}
        >
            {children}
        </div>
    );
};

export default TableUI;
