import { FC, useMemo } from "react";
import clsx from "clsx";
import Countdown, { zeroPad } from "react-countdown";
import styles from "./CountdownUI.module.scss";

type Props = {
    className?: string;
    date: number;
    continueWithKey?: string; // save the date on storage and continue if remained
    isWide?: boolean;
    onExpire?(): void;
};

const CountdownUI: FC<Props> = ({
    className,
    date,
    continueWithKey,
    isWide,
    onExpire,
}) => {
    const finalDate = useMemo(() => {
        if (continueWithKey && continueWithKey.length > 0) {
            const storedKey = `countdown_${continueWithKey}`;
            const storedDate = localStorage.getItem(storedKey);

            if (!!storedDate && parseInt(storedDate) > Date.now()) {
                return parseInt(storedDate);
            } else {
                localStorage.setItem(storedKey, date.toString());
            }
        }

        return date;
    }, [continueWithKey, date]);

    return (
        <Countdown
            className={className}
            date={finalDate}
            renderer={({ hours, minutes, seconds, completed, days }) => {
                if (completed) {
                    return "Finished!";
                } else {
                    return (
                        <div
                            className={clsx(
                                styles.clockWrap,
                                isWide && styles.clockWrapWide
                            )}
                        >
                            {days > 0 && (
                                <>
                                    <div className={styles.clockItem}>
                                        <div className={styles.clockValue}>
                                            {zeroPad(days)}
                                        </div>
                                        <div className={styles.clockTitle}>
                                            DAYS
                                        </div>
                                    </div>
                                    <div className={styles.clockSeprator}>
                                        :
                                    </div>
                                </>
                            )}

                            <div className={styles.clockItem}>
                                <div className={styles.clockValue}>
                                    {zeroPad(hours)}
                                </div>
                                <div className={styles.clockTitle}>HOURS</div>
                            </div>
                            <div className={styles.clockSeprator}>:</div>

                            <div className={styles.clockItem}>
                                <div className={styles.clockValue}>
                                    {zeroPad(minutes)}
                                </div>
                                <div className={styles.clockTitle}>MINS</div>
                            </div>
                            <div className={styles.clockSeprator}>:</div>

                            <div className={styles.clockItem}>
                                <div className={styles.clockValue}>
                                    {zeroPad(seconds)}
                                </div>
                                <div className={styles.clockTitle}>SECONDS</div>
                            </div>
                        </div>
                    );
                }
            }}
            onComplete={onExpire}
        />
    );
};

export default CountdownUI;
