import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FC, useMemo, useRef, useState } from "react";
import { Api } from "../../api/root";
import useAlert from "../../hooks/useAlert";
import useUserInfo from "../../hooks/useUserInfo";
import Alert from "../../libs/alert/Alert";
import Dialog from "../../libs/dialog/Dialog";
import Feature from "../../libs/feature/Feature";
import FormButton from "../../libs/form-button/FormButton";
import Pair from "../../libs/pair/Pair";
import { InvestFundType } from "../../types/invest";
import styles from "./DashboardInvest.module.scss";

type Props = {
    className?: string;
    investFund: InvestFundType;
    withdrawingUSD: number;
    penaltyPercent: number;
    open: boolean;
    onClose(): void;
    onConfirm(): void;
};

const DashboardInvestFundsWithdrawConfirm: FC<Props> = ({
    className,
    investFund,
    withdrawingUSD,
    penaltyPercent,
    open,
    onClose,
    onConfirm,
}) => {
    const [, setAlertState] = useAlert();
    const { updateUserInfo } = useUserInfo();
    const [isFirstChecked, setIsFirstChecked] = useState(false);
    const [isSecondChecked, setIsSecondChecked] = useState(false);
    const [withdrawLoading, setWithdrawLoading] = useState(false);

    const withdrawApi = Api.invests().useWithdrawInvestFund(investFund.id);

    const handleWithdrawConfirmation = () => {
        // show error if all boxes are not checked
        if (!isFirstChecked || !isSecondChecked) {
            setAlertState({
                open: true,
                message: "Please check all of the boxes first.",
                severity: "error",
            });
            return;
        }

        // confirm and withdraw
        if (confirm("Are you sure?")) {
            handleWithdraw();
        }
    };

    const handleWithdraw = async () => {
        // start loading
        setWithdrawLoading(true);

        // withdraw funds
        await withdrawApi.run({
            transaction_usd: withdrawingUSD,
        });

        // update user data
        await updateUserInfo();

        // show success alert
        setAlertState({
            open: true,
            message:
                "Withdrawal confirmed! Your funds will arrive in your wallet within 24 hours.",
            severity: "success",
        });

        // stop loading
        setWithdrawLoading(false);

        // call on confirm
        onConfirm();
    };

    const penaltyUSD = useMemo(() => {
        return withdrawingUSD * (penaltyPercent / 100);
    }, [withdrawingUSD, penaltyPercent]);

    const arrivingUSD = useMemo(() => {
        return withdrawingUSD - penaltyUSD;
    }, [withdrawingUSD, penaltyUSD]);

    return (
        <Dialog
            className={className}
            onClose={onClose}
            open={open}
            maxWidth="xs"
            boxProps={{
                title: `Withdrawal Confirmation`,
            }}
        >
            <Alert severity="warning" className={styles.withdrawFundsAlert}>
                Once funds are withdrawn, the action is irreversible.
            </Alert>

            <Feature className={styles.withdrawFundsSettings}>
                <Pair
                    title="Withdrawing Funds"
                    titlePosition="start"
                    value={`$${withdrawingUSD.toLocaleString()}`}
                />
                <Pair
                    title="Penalty"
                    titlePosition="start"
                    value={`-$${penaltyUSD.toLocaleString()}`}
                />
                <Pair
                    title="Arriving Funds"
                    titlePosition="start"
                    value={`$${arrivingUSD.toLocaleString()}`}
                />
            </Feature>

            <FormControlLabel
                className={styles.withdrawFundsCheck}
                classes={{
                    label: styles.withdrawFundsCheckLabel,
                }}
                control={
                    <Checkbox
                        className={styles.withdrawFundsCheckBox}
                        checked={isFirstChecked}
                        onChange={() => setIsFirstChecked(!isFirstChecked)}
                    />
                }
                label={`I hereby confirm withdrawal of $${withdrawingUSD.toLocaleString()} from campaign ${
                    investFund.campaign.campaign_tag
                }, effective immediately.`}
            />

            <FormControlLabel
                className={styles.withdrawFundsCheck}
                classes={{
                    label: styles.withdrawFundsCheckLabel,
                }}
                control={
                    <Checkbox
                        className={styles.withdrawFundsCheckBox}
                        checked={isSecondChecked}
                        onChange={() => setIsSecondChecked(!isSecondChecked)}
                    />
                }
                label={`I'm aware of losing the potential gains my withdrawing funds would have in the current cycle.`}
            />

            <div className={styles.withdrawFundsButtonGroup}>
                <FormButton color="base" onClick={onClose}>
                    Cancel
                </FormButton>

                <FormButton
                    className={styles.withdrawFundsButton}
                    loading={withdrawLoading}
                    onClick={handleWithdrawConfirmation}
                >
                    Confirm Withdrawal
                </FormButton>
            </div>
        </Dialog>
    );
};

export default DashboardInvestFundsWithdrawConfirm;
