import { FC } from "react";
import clsx from "clsx";
import Dialog from "src/libs/dialog/Dialog";
import styles from "./Profile.module.scss";
import Alert from "src/libs/alert/Alert";
import { FiCheckCircle } from "react-icons/fi";

type Props = {
    className?: string;
    open: boolean;
    onClose(): void;
};

const ProfilePointsInfo: FC<Props> = ({ className, open, onClose }) => {
    return (
        <Dialog
            className={clsx(className)}
            onClose={onClose}
            open={open}
            maxWidth="xs"
            boxProps={{
                title: "Carrots (Coming soon)",
            }}
        >
            <Alert className={styles.pointsInfoAlert} severity="warning">
                Carrots will be available soon!
            </Alert>
            <ul className={styles.pointsInfoBullets}>
                <li>
                    <FiCheckCircle className={styles.icon} />
                    <span>
                        Carrots is our point system and you can collect Carrots
                        by doing simple tasks.
                    </span>
                </li>
                <li>
                    <FiCheckCircle className={styles.icon} />
                    <span>
                        Carrots will be used for token airdrops, upgrading NFTs,
                        purchasing products, joining raffles and more.
                    </span>
                </li>
                <li>
                    <FiCheckCircle className={styles.icon} />
                    <span>
                        Carrots welcomes both minters and visitors, offering
                        minters additional benefits.
                    </span>
                </li>
            </ul>
        </Dialog>
    );
};

export default ProfilePointsInfo;
