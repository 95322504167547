import { FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import ProgressBar from "@ramonak/react-progress-bar";
import Feature from "../../libs/feature/Feature";
import FormButton from "../../libs/form-button/FormButton";
import styled from "styled-components";
import DashboardInvestCampaignsStatus from "./DashboardInvestCampaignsStatus";
import DashboardInvestCampaignsInvest from "./DashboardInvestCampaignsInvest";
import Pair from "../../libs/pair/Pair";
import useUserInfo from "../../hooks/useUserInfo";
import { CampaignType } from "../../types/campaign";
import {
    useWalletModal,
    WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import styles from "./DashboardInvest.module.scss";
import Alert from "../../libs/alert/Alert";
import CountdownUI from "../../libs/countdown-ui/CountdownUI";

type Props = {
    className?: string;
    loading?: boolean;
    campaign?: CampaignType;
};

const WalletButton = styled(WalletMultiButton)`
    color: #201e26;
    font-size: 0.9em;
    font-weight: bold;
    background: #759acf;
    transition: all 0.2s ease-in-out;
    width: 110px;
    padding: 0 15px;
    border-radius: 10px;

    &:hover {
        background: #8bb6ef;
    }
`;

const DashboardInvestCampaignsItem: FC<Props> = ({
    className,
    loading,
    campaign,
}) => {
    if (loading) {
        return (
            <Feature className={className}>
                <Skeleton width={230} />
                <Skeleton width={100} />
            </Feature>
        );
    } else if (!campaign) {
        return <></>;
    }

    const { fullConnected } = useUserInfo();
    const { setVisible } = useWalletModal();
    const [openInvest, setOpenInvest] = useState(false);
    const [liveCountDownDate, setLiveCountDownDate] = useState<number | null>(
        null
    );

    useEffect(() => {
        if (
            campaign.live_date &&
            campaign.live_date > new Date().getTime() / 1000
        ) {
            setLiveCountDownDate(campaign.live_date);
        } else {
            setLiveCountDownDate(null);
        }
    }, [campaign.live_date]);

    const isLive = useMemo(() => {
        return !!campaign.live_date && !liveCountDownDate;
    }, [liveCountDownDate, campaign.live_date]);

    const completedPercent = useMemo(() => {
        let percent =
            (campaign.active_fund_usd / campaign.target_fund_usd) * 100;
        if (percent < 3) percent = 3;

        return percent;
    }, [campaign.active_fund_usd, campaign.target_fund_usd]);

    const handleAddFunds = () => {
        if (fullConnected) {
            setOpenInvest(true);
        } else {
            setVisible(true);
        }
    };

    return (
        <Feature className={clsx(styles.campaignWrap, className)}>
            {!isLive && (
                <div className={styles.campaignAlertWrap}>
                    <Alert severity="info">
                        <div className={styles.campaignAlertContent}>
                            <div>
                                <div className={styles.title}>
                                    Funding will start soon!
                                </div>
                            </div>

                            {liveCountDownDate && (
                                <CountdownUI
                                    date={liveCountDownDate * 1000}
                                    onExpire={() => {
                                        setLiveCountDownDate(null);
                                    }}
                                />
                            )}
                        </div>
                    </Alert>
                </div>
            )}

            <div className={styles.campaignInfoWrap}>
                <Pair
                    className={styles.campaignInfoPair}
                    title={`Start from $${campaign.min_fund_usd.toLocaleString()} | 4-10% monthly gain`}
                    titlePosition="bottom"
                    value={
                        <div className={styles.campaignInfoTitle}>
                            <span>{`Campaign ${campaign.campaign_tag}`}</span>
                            <DashboardInvestCampaignsStatus
                                campaign={campaign}
                                isLive={isLive}
                                withBackground={true}
                            />
                        </div>
                    }
                />

                <FormButton
                    className={styles.campaignInvestButton}
                    disabled={!isLive}
                    onClick={handleAddFunds}
                >
                    Add Funds
                </FormButton>
            </div>

            {campaign.status != "canceled" && !campaign.full_funded && (
                <div className={styles.campaignProgressWrap}>
                    <ProgressBar
                        completed={completedPercent}
                        height="8px"
                        bgColor="#806eb5"
                        baseBgColor="#292932"
                        isLabelVisible={false}
                        animateOnRender
                    />
                    <Pair
                        className={styles.campaignProgressPair}
                        title="progress"
                        titlePosition="start"
                        value={`$${campaign.active_fund_usd.toLocaleString()} / $${campaign.target_fund_usd.toLocaleString()}`}
                        valueClassname={styles.campaignProgressPairValue}
                    />
                </div>
            )}

            <DashboardInvestCampaignsInvest
                open={openInvest}
                onClose={() => setOpenInvest(false)}
                campaign={campaign}
            />
        </Feature>
    );
};

export default DashboardInvestCampaignsItem;
