import { FC, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import Table, { Column } from "rc-table";
import TableUI from "src/libs/table-ui/TableUI";
import Feature from "src/libs/feature/Feature";
import { randomRange } from "src/services/utils";
import State, { StateType } from "src/libs/state/State";
import { InvitedMintType } from "src/types/mint";
import styles from "./DashboardInvites.module.scss";

type Props = {
    className?: string;
    items?: InvitedMintType[];
    loading?: boolean;
};

const DashboardInvitesNetworksList: FC<Props> = ({
    className,
    items,
    loading,
}) => {
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [items]);

    if (loading || !!items?.length) {
        return (
            <TableUI className={clsx(styles.list, className)}>
                <Table
                    data={items}
                    rowKey="id"
                    components={{
                        body: {
                            cell: loading
                                ? (props: any) => (
                                      <td {...props}>
                                          <Skeleton
                                              width={randomRange(30, 50)}
                                          />
                                      </td>
                                  )
                                : undefined,
                        },
                    }}
                >
                    <Column
                        title="User"
                        dataIndex={["invited_user_info", "user_short"]}
                        key="user"
                        width={70}
                        render={(userShort) => (
                            <span data-tip={userShort?.sol_wallet_short}>
                                {userShort?.display_name ||
                                    userShort?.sol_wallet_short}
                            </span>
                        )}
                    />
                    <Column
                        title="Circle"
                        dataIndex={["circle_num"]}
                        key="circle_num"
                        width={30}
                    />
                    <Column
                        title="Invited Date"
                        dataIndex={["invite_activated_date"]}
                        key="invite_activated_date"
                        width={70}
                        render={(invitedDate) => (
                            <span
                                data-tip={
                                    invitedDate &&
                                    moment.unix(invitedDate).format()
                                }
                            >
                                {invitedDate
                                    ? moment.unix(invitedDate).fromNow()
                                    : "_"}
                            </span>
                        )}
                    />
                </Table>
            </TableUI>
        );
    } else {
        return (
            <Feature type="message">
                <State type={StateType.NO_DOCUMENTS}>
                    No network user yet!
                </State>
            </Feature>
        );
    }
};

export default DashboardInvitesNetworksList;
