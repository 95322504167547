import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    Token,
    TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { useWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import {
    FC,
    createContext,
    useContext,
    useEffect,
    useState,
    PropsWithChildren,
} from "react";
import useConnection from "./useConnection";

const WalletBalanceContext = createContext<{
    balances: WalletBalance[] | null;
    setBalances(balances: WalletBalance[] | null): void;
    updateBalance(): Promise<void>;
}>({
    balances: null,
    setBalances: () => {},
    updateBalance: async () => {},
});

export type WalletBalance = {
    contract_address?: string;
    symbol: "SOL" | "USDC";
    amount: number;
};

const defaultWalletBalances: WalletBalance[] = [
    {
        symbol: "SOL",
        amount: 0,
    },
    {
        contract_address: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        symbol: "USDC",
        amount: 0,
    },
];

const useWalletBalance = () => useContext(WalletBalanceContext);

export const WalletBalanceProvider: FC<PropsWithChildren<{}>> = ({
    children,
}) => {
    const { connection } = useConnection();
    const wallet = useWallet();
    const [balances, setBalances] = useState<WalletBalance[] | null>(null);

    useEffect(() => {
        updateBalance();
    }, [wallet, connection]);

    const updateBalance = async () => {
        if (wallet?.publicKey) {
            try {
                const walletBalances = defaultWalletBalances;
                for (const walletBalance of walletBalances) {
                    if (walletBalance.contract_address) {
                        // Get details about the token
                        const tokenAddress = new PublicKey(
                            walletBalance.contract_address
                        );

                        try {
                            const buyerTokenAddress =
                                await Token.getAssociatedTokenAddress(
                                    ASSOCIATED_TOKEN_PROGRAM_ID,
                                    TOKEN_PROGRAM_ID,
                                    tokenAddress,
                                    wallet.publicKey
                                );

                            const senderAccount =
                                await connection.getTokenAccountBalance(
                                    buyerTokenAddress
                                );

                            walletBalance.amount =
                                senderAccount.value.uiAmount || 0;
                        } catch (e: any) {
                            walletBalance.amount = 0;
                        }
                    } else {
                        const balance = await connection.getBalance(
                            wallet.publicKey
                        );
                        walletBalance.amount = balance / LAMPORTS_PER_SOL;
                    }
                }

                setBalances(walletBalances);
                // eslint-disable-next-line no-empty
            } catch (error) {}
        }
    };

    return (
        <WalletBalanceContext.Provider
            value={{ balances, setBalances, updateBalance }}
        >
            {children}
        </WalletBalanceContext.Provider>
    );
};

export default useWalletBalance;
