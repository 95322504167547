import { FC, useState } from "react";
import clsx from "clsx";
import Dialog from "src/libs/dialog/Dialog";
import useUserInfo from "../../hooks/useUserInfo";
import { Api } from "../../api/root";
import { AssetBagType, AssetCoinType } from "../../types/asset";
import WalletOverview, { WalletCoin, WalletType } from "./WalletOverview";
import useAlert from "../../hooks/useAlert";
import useSettings from "../../hooks/useSettings";
import { UserInfoType } from "../../types/user";
import WalletClaims from "./WalletClaims";
import styles from "./Wallet.module.scss";
import Feature from "../../libs/feature/Feature";
import { GiTwoCoins } from "react-icons/gi";
import { MdOutlineRequestPage, MdRequestPage } from "react-icons/md";
import SwitchTabGroup from "src/libs/switch-tabs/SwitchTabGroup";
import SwitchTab from "src/libs/switch-tabs/SwitchTab";
import WalletRewards from "./WalletRewards";

type Props = {
    className?: string;
    open: boolean;
    onClose(): void;
};

type WalletBag = {
    title: string;
    asset_bag: AssetBagType;
};

export enum WalletTab {
    REWARDS = "rewards",
    CLAIMS = "claims",
}

export const walletBags: WalletBag[] = [
    {
        title: "Main",
        asset_bag: "main",
    },
    {
        title: "FORIXO",
        asset_bag: "invest",
    },
];

const WalletDialog: FC<Props> = ({ className, open, onClose }) => {
    const { settings } = useSettings();
    const { userInfo, setUserInfo, updateUserInfo } = useUserInfo();
    const [, setAlertState] = useAlert();
    const [activeTab, setActiveTab] = useState<WalletTab>(WalletTab.REWARDS);
    const claimApi = Api.assets().useAddAssetClaim();

    const getAssetAmount = (assetItem: AssetCoinType, type: WalletType) => {
        let amount = 0;
        if (type == "available") amount = assetItem.available_amount;
        else if (type == "withdrawing") amount = assetItem.withdrawing_amount;
        else if (type == "locked") amount = assetItem.mint_locked_amount;
        else if (type == "rewarded") amount = assetItem.rewarded_amount;

        return amount;
    };

    const getWalletCoins = (
        bagTag: AssetBagType,
        type: WalletType
    ): WalletCoin[] => {
        return (
            userInfo?.assets?.items
                .filter(
                    (assetItem) =>
                        getAssetAmount(assetItem, type) > 0 &&
                        assetItem.asset.bag_tag == bagTag
                )
                .map((assetItem) => {
                    return {
                        coin: assetItem.coin,
                        amount: getAssetAmount(assetItem, type),
                    };
                }) || []
        );
    };

    const handleClaim = (bagTag: AssetBagType) => {
        if (!userInfo?.assets) return;

        // prepare coin ids
        const coin_ids = getWalletCoins(bagTag, "available").map(
            (walletCoin) => walletCoin.coin.id
        );

        // run add claim
        claimApi
            .run({
                launch_id: settings?.launch.id!,
                bag_tag: bagTag,
                currencies: coin_ids.map((coin_id) => {
                    return { coin_id };
                }),
            })
            .then(() => {
                // init new user info
                const newUserInfo = { ...userInfo } as UserInfoType;

                // update user info (TODO: remove me)
                setUserInfo(newUserInfo);

                // update user info
                updateUserInfo();

                // show success message
                setAlertState({
                    open: true,
                    message:
                        "Claim submitted! It will take up to 24 hours for your funds to arrive.",
                    severity: "success",
                });
            });
    };

    return (
        <Dialog
            className={clsx(styles.dialog, className)}
            onClose={onClose}
            open={open}
            boxProps={{
                title: "My CRC Wallet",
            }}
        >
            {walletBags.map((walletBag) => {
                const availableCoins = getWalletCoins(
                    walletBag.asset_bag,
                    "available"
                );
                const withdrawingCoins = getWalletCoins(
                    walletBag.asset_bag,
                    "withdrawing"
                );
                const lockedCoins = getWalletCoins(
                    walletBag.asset_bag,
                    "locked"
                );

                return (
                    <div
                        key={walletBag.asset_bag}
                        className={styles.walletOverview}
                    >
                        <div className={styles.sectionTitle}>
                            <GiTwoCoins className={styles.icon} />
                            <span>{`${walletBag.title} Wallet`}</span>
                        </div>

                        <WalletOverview
                            availableCoins={availableCoins}
                            withdrawingCoins={withdrawingCoins}
                            lockedCoins={lockedCoins}
                            actionButton={{
                                content: "Claim",
                                loading: claimApi.state.loading,
                                disabled: availableCoins.length == 0,
                                onClick: () => handleClaim(walletBag.asset_bag),
                            }}
                        />
                    </div>
                );
            })}

            <SwitchTabGroup
                className={styles.sectionTabs}
                labelWrapClassName={styles.sectionTabsLabelWrap}
                type="round"
                activeTab={activeTab}
                onChange={(activeTab: WalletTab) => setActiveTab(activeTab)}
            >
                <SwitchTab tag={WalletTab.REWARDS} label="My Rewards">
                    <Feature>
                        <WalletRewards />
                    </Feature>
                </SwitchTab>

                <SwitchTab tag={WalletTab.CLAIMS} label="My Claims">
                    <Feature>
                        <WalletClaims />
                    </Feature>
                </SwitchTab>
            </SwitchTabGroup>

            {/* <div className={styles.sectionTitle}>
                <MdRequestPage className={styles.icon} />
                <span>My Claims</span>
            </div> */}
        </Dialog>
    );
};

export default WalletDialog;
