import { FC, ReactNode, useEffect, useMemo } from "react";
import clsx from "clsx";
import useSettings from "../../hooks/useSettings";
import { HiArrowNarrowRight } from "react-icons/hi";
import Pagination from "@material-ui/lab/Pagination";
import PlatContentTable from "./PlatContentTable";
import { ApiGetGroupParams, ApiOptions } from "../../api/root";
import { UseApi, UseApiActionType } from "../../hooks/useApi";
import useUserInfo from "../../hooks/useUserInfo";
import Feature from "../../libs/feature/Feature";
import State, { StateType } from "../../libs/state/State";
import styles from "./Plat.module.scss";
import { createEmptyArray } from "../../services/utils";

type Props = {
    className?: string;
    title: string;
    api: UseApi<any, any>;
    footerType: "view_all" | "pagination";
    isDialog: boolean;
    needAuth?: boolean;
    updateTrigger?: any;
    backgroundUpdateTrigger?: any;
    renderTableColumns?(isDialog: boolean): ReactNode;
    onApiRequest(params: ApiGetGroupParams, options?: ApiOptions): void;
    onViewAll?(): void;
};

const PlatContent: FC<Props> = ({
    className,
    title,
    api,
    footerType,
    isDialog,
    needAuth,
    updateTrigger,
    backgroundUpdateTrigger,
    renderTableColumns,
    onApiRequest,
    onViewAll,
}) => {
    const { fullConnected, loadingUserInfo } = useUserInfo();
    const { loadingSettings } = useSettings();

    // prepare loading
    const loading = useMemo(() => {
        return api.state.loading || loadingUserInfo || loadingSettings;
    }, [api.state.loading, loadingUserInfo, loadingSettings]);

    // prepare is filtered
    const isFiltered = useMemo(() => {
        return api.state.query && api.state.query.length > 0;
    }, [api.state.query]);

    // run api request by listening to deps
    useEffect(() => {
        if (fullConnected || !needAuth) {
            onApiRequest({});
        }
        return () => api.abort();
    }, [
        fullConnected,
        api.state.pageNum,
        api.state.perPage,
        api.state.query,
        updateTrigger,
    ]);

    // run api request in background by listening to deps
    useEffect(() => {
        if (fullConnected || !needAuth) {
            onApiRequest({}, { runInBackground: true });
        }
        return () => api.abort();
    }, [backgroundUpdateTrigger]);

    // handle page change
    const handlePageChange = (page: number) => {
        api.dispatch!({
            type: UseApiActionType.STATE_UPDATE,
            newState: { pageNum: page },
        });
    };

    if (loading || !!api.state.data?.length) {
        return (
            <>
                {renderTableColumns && (
                    <PlatContentTable
                        loading={loading}
                        items={
                            !loading
                                ? api.state.data
                                : createEmptyArray(api.state.perPage || 0)
                        }
                    >
                        {renderTableColumns(isDialog)}
                    </PlatContentTable>
                )}

                {footerType == "view_all" && !loading && (
                    <div className={styles.footer}>
                        <div className={styles.total} onClick={onViewAll}>
                            {`Total: ${api.state.totalNum}`}
                        </div>
                        <div className={styles.viewAll} onClick={onViewAll}>
                            <span>View All</span>
                            <HiArrowNarrowRight className={styles.icon} />
                        </div>
                    </div>
                )}

                {footerType == "pagination" && !loading && (
                    <Pagination
                        className={clsx(styles.footer, styles.footerPagination)}
                        page={api.state.pageNum}
                        count={Math.ceil(
                            api.state.totalNum! / api.state.perPage!
                        )}
                        onChange={(event, page) => {
                            handlePageChange(page);
                        }}
                    />
                )}
            </>
        );
    } else {
        return (
            <Feature type="message">
                <State type={StateType.NO_DOCUMENTS}>
                    {`No ${title.toLowerCase()} ${
                        isFiltered ? "founded" : "yet"
                    }!`}
                </State>
            </Feature>
        );
    }
};

export default PlatContent;
