import { ApiSubItems } from "./general";

export type GradeTagType =
    | "standard"
    | "bronze"
    | "silver"
    | "gold"
    | "platinum";

export type GradeType = {
    id: number;
    tag: GradeTagType;
    level_num: number;
    req_mint_count?: number;
    req_invite_points?: number;
    req_network_points?: number;
    req_rarity_min?: number;
    enable_invite_grade_tag?: GradeTagType;
    reward_multiplier: number;
    invest_commission_percent: number;
    nft_count: number;
    is_default: boolean;
    last_nft_added_date: number;
};

export type GradeUserType = {
    id: number;
    invite_points: number;
    network_points: number;
    special_invite_code?: string;
    base_grade: GradeType;
    highest_grade?: GradeType;
    grade_stats?: ApiSubItems<GradeUserStatType>;
};

export type GradeUserStatType = {
    id: number;
    available_nft_count: number;
    registered_nft_count: number;
    grade: GradeType;
};

export const gradeTitles: Record<GradeTagType, string> = {
    standard: "Standard",
    bronze: "Bronze",
    silver: "Silver",
    gold: "Gold",
    platinum: "Platinum",
};
