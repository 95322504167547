import {
    FC,
    createContext,
    useContext,
    useEffect,
    useState,
    PropsWithChildren,
    ReactNode,
} from "react";
import { ClientCurrencyType, ClientCurrencyShareType } from "../types/client";
import { Api } from "src/api/root";
import { SettingsType } from "src/types/settings";
import { DashboardMessageType } from "../types/general";
import useConnection from "./useConnection";

type CurrencyOptionType = {
    value: string;
    label: string;
    pair: string;
    data: ClientCurrencyType;
};

const SettingsContext = createContext<{
    settings: SettingsType | null;
    setSettings(settings: SettingsType | null): void;
    loadingSettings: boolean;
    updateSettings(): void;
    currencyOptions: CurrencyOptionType[] | null;
    selectedCurrency: CurrencyOptionType | null;
    setSelectedCurrency(selectedCurrency: CurrencyOptionType | null): void;
    fixedMessage: DashboardMessageType | null;
    setFixedMessage(fixedMessage: DashboardMessageType | null): void;
}>({
    settings: null,
    setSettings: () => {},
    loadingSettings: false,
    updateSettings: () => {},
    currencyOptions: [],
    selectedCurrency: null,
    setSelectedCurrency: async () => {},
    fixedMessage: null,
    setFixedMessage: () => {},
});

const useSettings = () => useContext(SettingsContext);

export const SettingsProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
    const [settings, setSettings] = useState<SettingsType | null>(null);
    const [loadingSettings, setLoadingSettings] = useState<boolean>(false);
    const [currencyOptions, setCurrencyOptions] = useState<
        CurrencyOptionType[] | null
    >(null);
    const [selectedCurrency, setSelectedCurrency] =
        useState<CurrencyOptionType | null>(null);
    const { candyMachineId } = useConnection();
    const [fixedMessage, setFixedMessage] =
        useState<DashboardMessageType | null>(null);
    const api = Api.launches().useGetLaunches(candyMachineId?.toString()!);

    useEffect(() => {
        (async () => {
            // start loading
            setLoadingSettings(true);

            // run api
            await updateSettings();

            // stop loading
            setLoadingSettings(false);
        })();

        return () => api.abort();
    }, []);

    const updateSettings = async () => {
        await api.run({}).then((data) => {
            setSettings({
                launch: data,
                client: data.client,
            });
        });
    };

    // prepare currency options
    useEffect(() => {
        if (settings?.client.client_methods) {
            const clientMethod = settings.client.client_methods.items[0];

            const targetCurrencyOptions: CurrencyOptionType[] = [];
            clientMethod.client_currencies.items.forEach(
                (clientCurrency: ClientCurrencyType) => {
                    const currenciesLabelList: string[] = [];
                    clientCurrency.shares.items.forEach(
                        (clientCurrencyShare: ClientCurrencyShareType) => {
                            if (clientCurrencyShare.fiat) {
                                currenciesLabelList.push(
                                    clientCurrencyShare.fiat.symbol
                                );
                            } else if (clientCurrencyShare.coin) {
                                currenciesLabelList.push(
                                    clientCurrencyShare.coin.symbol
                                );
                            }
                        }
                    );

                    const currenciesPair = currenciesLabelList.join(" + ");

                    let currenciesLabel = currenciesPair;
                    if (clientCurrency.percent_off) {
                        currenciesLabel += ` (${clientCurrency.percent_off}% OFF)`;
                    }

                    const currencyOption: CurrencyOptionType = {
                        value: clientCurrency.id.toString(),
                        label: currenciesLabel,
                        pair: currenciesPair,
                        data: clientCurrency,
                    };

                    if (!selectedCurrency && clientCurrency.is_default) {
                        setSelectedCurrency(currencyOption);
                    }

                    targetCurrencyOptions.push(currencyOption);
                }
            );

            setCurrencyOptions(targetCurrencyOptions);
        } else {
            setCurrencyOptions([]);
        }
    }, [settings?.client]);

    return (
        <SettingsContext.Provider
            value={{
                settings,
                setSettings,
                loadingSettings,
                updateSettings,
                currencyOptions,
                selectedCurrency,
                setSelectedCurrency,
                fixedMessage,
                setFixedMessage,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export default useSettings;
