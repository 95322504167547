import { FC, PropsWithChildren } from "react";
import clsx from "clsx";
import { RiFileInfoFill } from "react-icons/ri";
import { BsShieldFillCheck } from "react-icons/bs";
import { IoWarning } from "react-icons/io5";
import { MdReportGmailerrorred } from "react-icons/md";
import styles from "./Alert.module.scss";
import { FiXCircle } from "react-icons/fi";

export type BoxProps = {
    className?: string;
    severity: "success" | "info" | "warning" | "error";
    closable?: boolean;
    onClose?(): void;
};

const Alert: FC<PropsWithChildren<BoxProps>> = ({
    children,
    className,
    severity,
    closable,
    onClose,
}) => {
    return (
        <div
            className={clsx(
                styles.wrap,
                severity == "success" && styles.wrapSuccess,
                severity == "info" && styles.wrapInfo,
                severity == "warning" && styles.wrapWarning,
                severity == "error" && styles.wrapError,
                className
            )}
        >
            {severity == "success" && (
                <BsShieldFillCheck
                    className={clsx(styles.icon, styles.iconSuccess)}
                />
            )}
            {severity == "info" && (
                <RiFileInfoFill
                    className={clsx(styles.icon, styles.iconInfo)}
                />
            )}
            {severity == "warning" && (
                <IoWarning className={clsx(styles.icon, styles.iconWarning)} />
            )}
            {severity == "error" && (
                <MdReportGmailerrorred
                    className={clsx(styles.icon, styles.iconError)}
                />
            )}

            <div className={styles.message}>{children}</div>

            {closable && (
                <FiXCircle className={styles.clear} onClick={onClose} />
            )}
        </div>
    );
};

export default Alert;
