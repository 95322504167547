import { CoinType } from "./coin";
import { GradeType } from "./grade";
import { UserShortType } from "./user";

export type MintUnitType = {
    id: number;
    coin: CoinType;
    meta_data?: MintMetaDataType;
    mint_address: string;
    mint_price?: number;
    grade?: GradeType;
    status: "auditing" | "approved" | "rejected";
    is_meta_data_syncing?: boolean;
    added_date: number;
    minted_date?: number;
    last_upgraded_date?: number;
};

export type UserMintType = {
    id: number;
    auditing_mint_count: number;
    approved_mint_count: number;
    target_mint_count?: number;
    auditing_invited_mint_count: number;
    approved_invited_mint_count: number;
    auditing_network_mint_count: number;
    approved_network_mint_count: number;
    commission_percent: number;
};

export type MintMetaDataType = {
    id: number;
    name: string;
    meta_url: string;
    image_url: string;
    rarity_rank: number;
    synced_date: number;
};

export type InvitedMintType = {
    id: number;
    invited_user: UserShortType;
    mint_unit: MintUnitType;
    commission_percent?: number;
    reward_amount?: number;
    circle_num: number;
    rewarded_date: number;
};

export const nftsExample: MintUnitType[] = [
    {
        id: 1,
        coin: {
            id: 1,
            name: "Solana",
            symbol: "SOL",
            price_usd: 34.21,
            decimals: 9,
        },
        meta_data: {
            id: 1,
            name: "NFT #91",
            meta_url: "https://arweave.org/something",
            image_url: "https://i.postimg.cc/621rkJ1h/1.png",
            rarity_rank: 232,
            synced_date: 1663164281,
        },
        grade: {
            id: 1,
            tag: "standard",
            level_num: 1,
            req_mint_count: 1,
            reward_multiplier: 1,
            invest_commission_percent: 5,
            nft_count: 10,
            is_default: true,
            last_nft_added_date: 166345007,
        },
        mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
        mint_price: 0.45,
        status: "approved",
        added_date: 1663164281,
        minted_date: 1663164281,
    },
    {
        id: 2,
        coin: {
            id: 1,
            name: "Solana",
            symbol: "SOL",
            price_usd: 34.21,
            decimals: 9,
        },
        grade: {
            id: 2,
            tag: "bronze",
            level_num: 2,
            req_mint_count: 2,
            req_invite_points: 2,
            reward_multiplier: 2,
            invest_commission_percent: 5,
            nft_count: 10,
            is_default: false,
            last_nft_added_date: 166345007,
        },
        meta_data: {
            id: 1,
            name: "NFT #425",
            meta_url: "https://arweave.org/something",
            image_url: "https://i.postimg.cc/F7kMnhnD/2.png",
            rarity_rank: 25,
            synced_date: 1663164281,
        },
        mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
        mint_price: 0.45,
        status: "approved",
        is_meta_data_syncing: true,
        added_date: 1663164281,
        minted_date: 1663164281,
        last_upgraded_date: 1663174200,
    },
    {
        id: 3,
        coin: {
            id: 1,
            name: "Solana",
            symbol: "SOL",
            price_usd: 34.21,
            decimals: 9,
        },
        meta_data: {
            id: 1,
            name: "NFT #1246",
            meta_url: "https://arweave.org/something",
            image_url: "https://i.postimg.cc/62QSVKvV/3.png",
            rarity_rank: 143,
            synced_date: 1663164281,
        },
        grade: {
            id: 3,
            tag: "silver",
            level_num: 3,
            req_mint_count: 10,
            reward_multiplier: 3,
            invest_commission_percent: 10,
            nft_count: 10,
            is_default: false,
            last_nft_added_date: 166345007,
        },
        mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
        mint_price: 0.45,
        status: "approved",
        added_date: 1663164281,
        minted_date: 1663164281,
        last_upgraded_date: 1663174200,
    },
    {
        id: 4,
        coin: {
            id: 1,
            name: "Solana",
            symbol: "SOL",
            price_usd: 34.21,
            decimals: 9,
        },
        meta_data: {
            id: 1,
            name: "NFT #45",
            meta_url: "https://arweave.org/something",
            image_url: "https://i.postimg.cc/1VnjTKMf/4.png",
            rarity_rank: 125,
            synced_date: 1663164281,
        },
        grade: {
            id: 4,
            tag: "gold",
            level_num: 4,
            req_mint_count: 5,
            req_network_points: 3,
            reward_multiplier: 4,
            invest_commission_percent: 10,
            nft_count: 10,
            is_default: false,
            last_nft_added_date: 166345007,
        },
        mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
        mint_price: 0.45,
        status: "approved",
        added_date: 1663164281,
        minted_date: 1663164281,
        last_upgraded_date: 1663174200,
    },
    {
        id: 5,
        coin: {
            id: 1,
            name: "Solana",
            symbol: "SOL",
            price_usd: 34.21,
            decimals: 9,
        },
        meta_data: {
            id: 1,
            name: "NFT #9961",
            meta_url: "https://arweave.org/something",
            image_url: "https://i.postimg.cc/62HkFqX1/5.png",
            rarity_rank: 615,
            synced_date: 1663164281,
        },
        grade: {
            id: 4,
            tag: "gold",
            level_num: 4,
            req_mint_count: 5,
            req_network_points: 3,
            reward_multiplier: 4,
            invest_commission_percent: 10,
            nft_count: 10,
            is_default: false,
            last_nft_added_date: 166345007,
        },
        mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
        mint_price: 0.45,
        status: "approved",
        added_date: 1663164281,
        minted_date: 1663164281,
        last_upgraded_date: 1663174200,
    },
    {
        id: 6,
        coin: {
            id: 1,
            name: "Solana",
            symbol: "SOL",
            price_usd: 34.21,
            decimals: 9,
        },
        meta_data: {
            id: 1,
            name: "NFT #1579",
            meta_url: "https://arweave.org/something",
            image_url: "https://i.postimg.cc/JDdFPFNf/6.png",
            rarity_rank: 546,
            synced_date: 1663164281,
        },
        grade: {
            id: 5,
            tag: "platinum",
            level_num: 5,
            req_mint_count: 50,
            req_rarity_min: 10,
            reward_multiplier: 5,
            invest_commission_percent: 10,
            nft_count: 10,
            is_default: false,
            last_nft_added_date: 166345007,
        },
        mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
        mint_price: 0.45,
        status: "approved",
        added_date: 1663164281,
        minted_date: 1663164281,
    },
    {
        id: 7,
        coin: {
            id: 1,
            name: "Solana",
            symbol: "SOL",
            price_usd: 34.21,
            decimals: 9,
        },
        meta_data: {
            id: 1,
            name: "NFT #7857",
            meta_url: "https://arweave.org/something",
            image_url: "https://i.postimg.cc/Sn0wBHBq/7.png",
            rarity_rank: 568,
            synced_date: 1663164281,
        },
        grade: {
            id: 1,
            tag: "standard",
            level_num: 1,
            req_mint_count: 1,
            reward_multiplier: 1,
            invest_commission_percent: 5,
            nft_count: 10,
            is_default: true,
            last_nft_added_date: 166345007,
        },
        mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
        mint_price: 0.45,
        status: "approved",
        added_date: 1663164281,
        minted_date: 1663164281,
    },
    {
        id: 8,
        coin: {
            id: 1,
            name: "Solana",
            symbol: "SOL",
            price_usd: 34.21,
            decimals: 9,
        },
        meta_data: {
            id: 1,
            name: "NFT #4985",
            meta_url: "https://arweave.org/something",
            image_url: "https://i.postimg.cc/HJdFxXWh/8.png",
            rarity_rank: 629,
            synced_date: 1663164281,
        },
        grade: {
            id: 1,
            tag: "standard",
            level_num: 1,
            req_mint_count: 1,
            reward_multiplier: 1,
            invest_commission_percent: 5,
            nft_count: 10,
            is_default: true,
            last_nft_added_date: 166345007,
        },
        mint_address: "EF7Rc3GKv99VXg3M96dQ1AtWh7c51vBVK4UciRgTMRAU",
        mint_price: 0.45,
        status: "approved",
        added_date: 1663164281,
        minted_date: 1663164281,
    },
];
