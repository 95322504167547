import { FC, ReactNode } from "react";
import clsx from "clsx";
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import { FiCheck } from "react-icons/fi";
import styles from "./DashboardNfts.module.scss";
import Hint from "../../libs/hint/Hint";

type Props = {
    className?: string;
    gradeItem: gradeItemType;
};

export type gradeItemType = {
    key: string;
    requirements: {
        content: ReactNode;
        hint?: string;
    }[];
    perks: {
        content: ReactNode;
        hint?: string;
    }[];
};

const DashboardNftsGradesItem: FC<Props> = ({ className, gradeItem }) => {
    return (
        <div className={clsx(styles.gradeItem, className)}>
            <div className={clsx(styles.gradeItemSection, styles.gradeReqs)}>
                <ul>
                    {gradeItem.requirements.length > 1 ? (
                        <li>Requirements (Optional):</li>
                    ) : (
                        <li>Requirement:</li>
                    )}
                    {gradeItem.requirements.map(
                        (requirement, requirementIndex) => {
                            return (
                                <li
                                    key={requirementIndex}
                                    className={styles.sub}
                                >
                                    <MdOutlineRadioButtonChecked
                                        className={styles.icon}
                                    />
                                    <div className={styles.content}>
                                        <span>{requirement.content}</span>
                                        {requirement.hint && (
                                            <Hint tip={requirement.hint} />
                                        )}
                                    </div>
                                </li>
                            );
                        }
                    )}
                </ul>
            </div>

            <div className={clsx(styles.gradeItemSection, styles.gradePerks)}>
                <ul>
                    <li>Perks:</li>
                    {gradeItem.perks.map((perk, perkIndex) => {
                        return (
                            <li key={perkIndex} className={styles.sub}>
                                <FiCheck className={styles.icon} />
                                <div className={styles.content}>
                                    <span>{perk.content}</span>
                                    {perk.hint && <Hint tip={perk.hint} />}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default DashboardNftsGradesItem;
