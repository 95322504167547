import { FC, PropsWithChildren } from "react";
import clsx from "clsx";
import Table from "rc-table";
import Skeleton from "react-loading-skeleton";
import TableUI from "../../libs/table-ui/TableUI";
import styles from "./Plat.module.scss";

type Props = {
    className?: string;
    items?: any[];
    loading?: boolean;
};

const PlatContentTable: FC<PropsWithChildren<Props>> = ({
    children,
    className,
    items,
    loading,
}) => {
    return (
        <TableUI className={clsx(styles.list, className)}>
            <Table
                data={items}
                rowKey="id"
                components={{
                    body: {
                        cell: loading
                            ? (props: any) => (
                                  <td {...props}>
                                      <Skeleton width={50} />
                                  </td>
                              )
                            : undefined,
                    },
                }}
            >
                {children}
            </Table>
        </TableUI>
    );
};

export default PlatContentTable;
