import { useCallback, useEffect, useMemo, useState } from "react";
import { Metadata } from "@metaplex/js";
import clsx from "clsx";
import * as anchor from "@project-serum/anchor";

import styled from "styled-components";
import { Container, Snackbar } from "@material-ui/core";
import Box from "src/libs/box/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
    Commitment,
    Connection,
    LAMPORTS_PER_SOL,
    PublicKey,
    Transaction,
} from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletModalButton } from "@solana/wallet-adapter-react-ui";
import {
    awaitTransactionSignatureConfirmation,
    CANDY_MACHINE_PROGRAM,
    CandyMachineAccount,
    createAccountsForMint,
    getCandyMachineState,
    getCollectionPDA,
    mintOneToken,
    SetupState,
    mintMultipleTokens,
} from "src/services/candyMachine";
import { formatNumber, getAtaForMint, toDate } from "src/services/utils";
import { MintCountdown } from "./MintCountdown";
import { MintButton } from "./MintButton";
import useWalletBalance from "src/hooks/useWalletBalance";
import { GatewayProvider } from "@civic/solana-gateway-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import useAlert, { AlertAction } from "src/hooks/useAlert";
import { useItemsAvailable } from "src/hooks/useCandyMachine";
import ProgressBar from "@ramonak/react-progress-bar";
import Skeleton from "react-loading-skeleton";
import Confetti from "react-dom-confetti";
import { Api } from "src/api/root";
import useUserInfo from "src/hooks/useUserInfo";
import useControllerDashboard from "src/hooks/useControllerDashboard";
import { DashboardTab } from "src/types/controller";
import useSettings from "src/hooks/useSettings";
import Counter from "src/libs/counter/Counter";
import Alert from "../../libs/alert/Alert";
import CountdownUI from "../../libs/countdown-ui/CountdownUI";
import Feature from "../../libs/feature/Feature";
import styles from "./Mint.module.scss";
import Hint from "../../libs/hint/Hint";
import { LaunchStageType } from "../../types/launch";
import NumberCount from "../../libs/number-count/NumberCount";

const ConnectButton = styled(WalletModalButton)`
    width: 100%;
    height: 60px;
    background: linear-gradient(52deg, #8876e7, #7399d1);
    border-radius: 14px;
    box-shadow: 0 0 12px #6568c587;
    color: #201e26;
    font-size: 1.1em;
    font-weight: bold;

    &:hover {
        background: linear-gradient(52deg, #8876e7, #7399d1) !important;
    }
`;

const MintContainer = styled.div``; // add your owns styles here

export interface HomeProps {
    className?: string;
    candyMachineId?: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    txTimeout: number;
    rpcHost: string;
    network: WalletAdapterNetwork;
    error?: string;
}

const Mint = (props: HomeProps) => {
    const [, setAlertState] = useAlert();
    const { setActiveTab } = useControllerDashboard();
    const { updateBalance } = useWalletBalance();
    const { settings, loadingSettings, updateSettings } = useSettings();
    const [itemsAvailable, setItemsAvailable] = useItemsAvailable();
    const [isUserMinting, setIsUserMinting] = useState(false);
    const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
    const [isActive, setIsActive] = useState(false);
    const [endDate, setEndDate] = useState<Date>();
    const [itemsRemaining, setItemsRemaining] = useState<number>();
    const [isWhitelistUser, setIsWhitelistUser] = useState(false);
    const [isPresale, setIsPresale] = useState(false);
    const [isValidBalance, setIsValidBalance] = useState(false);
    const [discountPrice, setDiscountPrice] = useState<anchor.BN>();
    const [price, setPrice] = useState<number>();
    const [needTxnSplit, setNeedTxnSplit] = useState(true);
    const [remainingPercent, setRemainingPercent] = useState<number>();
    const [setupTxn, setSetupTxn] = useState<SetupState>();
    const [confettiActive, setConfettiActive] = useState(false);
    const [startDate, setStartDate] = useState<number | null>(null);
    const [mintCount, setMintCount] = useState<number>(1);
    const { userInfo, updateUserInfo } = useUserInfo();
    const api = Api.mints().useAddMint();

    const rpcUrl = props.rpcHost;
    const wallet = useWallet();
    const cluster = props.network;
    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    const launchStage: LaunchStageType | undefined = useMemo(() => {
        if (settings) {
            return settings?.launch.stages.items.find(
                (launchStageItem) =>
                    launchStageItem.stage_num ==
                    settings?.launch.current_stage_num
            );
        } else {
            return undefined;
        }
    }, [settings?.launch]);

    const pendingFreeMintCount = useMemo(() => {
        if (!userInfo?.mints_info?.target_mint_count) return 0;

        return (
            userInfo.mints_info.target_mint_count -
            userInfo.mints_info.approved_mint_count -
            userInfo.mints_info.auditing_mint_count
        );
    }, [
        userInfo?.mints_info?.approved_mint_count,
        userInfo?.mints_info?.auditing_mint_count,
    ]);

    const refreshCandyMachineState = useCallback(
        async (commitment: Commitment = "confirmed") => {
            if (!anchorWallet) {
                return;
            }
            if (props.error !== undefined) {
                setAlertState({
                    open: true,
                    message: props.error,
                    severity: "error",
                    hideDuration: null,
                });
                return;
            }

            const connection = new Connection(props.rpcHost, commitment);

            if (props.candyMachineId) {
                try {
                    const cndy = await getCandyMachineState(
                        anchorWallet,
                        props.candyMachineId,
                        connection
                    );
                    console.log("Candy machine state: ", cndy);
                    let active = cndy?.state.goLiveDate
                        ? cndy?.state.goLiveDate.toNumber() <
                          new Date().getTime() / 1000
                        : false;
                    let presale = false;

                    // duplication of state to make sure we have the right values!
                    let isWLUser = false;
                    let userPrice = cndy.state.price;

                    // whitelist mint?
                    if (cndy?.state.whitelistMintSettings) {
                        // is it a presale mint?
                        if (
                            cndy.state.whitelistMintSettings.presale &&
                            (!cndy.state.goLiveDate ||
                                cndy.state.goLiveDate.toNumber() >
                                    new Date().getTime() / 1000)
                        ) {
                            presale = true;
                        }
                        // is there a discount?
                        if (cndy.state.whitelistMintSettings.discountPrice) {
                            setDiscountPrice(
                                cndy.state.whitelistMintSettings.discountPrice
                            );
                            userPrice =
                                cndy.state.whitelistMintSettings.discountPrice;
                        } else {
                            setDiscountPrice(undefined);
                            // when presale=false and discountPrice=null, mint is restricted
                            // to whitelist users only
                            if (!cndy.state.whitelistMintSettings.presale) {
                                cndy.state.isWhitelistOnly = true;
                            }
                        }
                        // retrieves the whitelist token
                        const mint = new anchor.web3.PublicKey(
                            cndy.state.whitelistMintSettings.mint
                        );
                        const token = (
                            await getAtaForMint(mint, anchorWallet.publicKey)
                        )[0];

                        try {
                            const balance =
                                await connection.getTokenAccountBalance(token);
                            isWLUser = parseInt(balance.value.amount) > 0;
                            // only whitelist the user if the balance > 0
                            setIsWhitelistUser(isWLUser);

                            if (cndy.state.isWhitelistOnly) {
                                active = isWLUser && (presale || active);
                            }
                        } catch (e) {
                            setIsWhitelistUser(false);
                            // no whitelist user, no mint
                            if (cndy.state.isWhitelistOnly) {
                                active = false;
                            }
                            console.log(
                                "There was a problem fetching whitelist token balance"
                            );
                            console.log(e);
                        }
                    }
                    userPrice = isWLUser ? userPrice : cndy.state.price;
                    const multipleUserPrice: anchor.BN = userPrice.mul(
                        new anchor.BN(mintCount)
                    );

                    if (cndy?.state.tokenMint) {
                        // retrieves the SPL token
                        const mint = new anchor.web3.PublicKey(
                            cndy.state.tokenMint
                        );
                        const token = (
                            await getAtaForMint(mint, anchorWallet.publicKey)
                        )[0];
                        try {
                            const balance =
                                await connection.getTokenAccountBalance(token);

                            const valid = new anchor.BN(
                                balance.value.amount
                            ).gte(multipleUserPrice);

                            // only allow user to mint if token balance >  the user if the balance > 0
                            setIsValidBalance(valid);
                            active = active && valid;
                        } catch (e) {
                            setIsValidBalance(false);
                            active = false;
                            // no whitelist user, no mint
                            console.log(
                                "There was a problem fetching SPL token balance"
                            );
                            console.log(e);
                        }
                    } else {
                        const balance = new anchor.BN(
                            await connection.getBalance(anchorWallet.publicKey)
                        );
                        const valid = balance.gte(multipleUserPrice);
                        setIsValidBalance(valid);
                        active = active && valid;
                    }

                    // datetime to stop the mint?
                    if (cndy?.state.endSettings?.endSettingType.date) {
                        setEndDate(toDate(cndy.state.endSettings.number));
                        if (
                            cndy.state.endSettings.number.toNumber() <
                            new Date().getTime() / 1000
                        ) {
                            active = false;
                        }
                    }
                    // amount to stop the mint?
                    if (cndy?.state.endSettings?.endSettingType.amount) {
                        const limit = Math.min(
                            cndy.state.endSettings.number.toNumber(),
                            cndy.state.itemsAvailable
                        );
                        if (cndy.state.itemsRedeemed < limit) {
                            setItemsRemaining(limit - cndy.state.itemsRedeemed);
                        } else {
                            setItemsRemaining(0);
                            cndy.state.isSoldOut = true;
                        }
                    } else {
                        setItemsRemaining(cndy.state.itemsRemaining);
                    }

                    // set available items
                    setItemsAvailable(cndy.state.itemsAvailable);

                    if (cndy.state.isSoldOut) {
                        active = false;
                    }

                    const [collectionPDA] = await getCollectionPDA(
                        props.candyMachineId
                    );
                    const collectionPDAAccount =
                        await connection.getAccountInfo(collectionPDA);

                    setIsActive((cndy.state.isActive = active));
                    setIsPresale((cndy.state.isPresale = presale));
                    setCandyMachine(cndy);
                    setPrice(formatNumber.asNumber(cndy.state.price));

                    const txnEstimate =
                        892 +
                        (!!collectionPDAAccount && cndy.state.retainAuthority
                            ? 182
                            : 0) +
                        (cndy.state.tokenMint ? 66 : 0) +
                        (cndy.state.whitelistMintSettings ? 34 : 0) +
                        (cndy.state.whitelistMintSettings?.mode?.burnEveryTime
                            ? 34
                            : 0) +
                        (cndy.state.gatekeeper ? 33 : 0) +
                        (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

                    setNeedTxnSplit(txnEstimate > 1230);
                } catch (e) {
                    if (e instanceof Error) {
                        if (
                            e.message ===
                            `Account does not exist ${props.candyMachineId}`
                        ) {
                            // Couldn't fetch candy machine state from candy machine with address: ${props.candyMachineId}, using rpc: ${props.rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!
                            /*setAlertState({
                                open: true,
                                message: "Couldn't fetch mint state!",
                                severity: "error",
                                hideDuration: null,
                            });*/
                        } else if (
                            e.message.startsWith(
                                "failed to get info about account"
                            )
                        ) {
                            // Couldn't fetch candy machine state with rpc: ${props.rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!
                            /*setAlertState({
                                open: true,
                                message: "Couldn't fetch mint state!",
                                severity: "error",
                                hideDuration: null,
                            });*/
                        }
                    } else {
                        setAlertState({
                            open: true,
                            message: `${e}`,
                            severity: "error",
                            hideDuration: null,
                        });
                    }
                    console.log(e);
                }
            } else {
                setAlertState({
                    open: true,
                    message: `Your REACT_APP_CANDY_MACHINE_ID value in the .env file doesn't look right! Make sure you enter it in as plain base-58 address!`,
                    severity: "error",
                    hideDuration: null,
                });
            }
        },
        [
            anchorWallet,
            props.candyMachineId,
            props.error,
            props.rpcHost,
            mintCount,
        ]
    );

    const onMint = async (
        beforeTransactions: Transaction[] = [],
        afterTransactions: Transaction[] = []
    ) => {
        try {
            setIsUserMinting(true);
            if (wallet.connected && candyMachine?.program && wallet.publicKey) {
                let setupMint: SetupState | undefined;
                if (needTxnSplit && setupTxn === undefined) {
                    setAlertState({
                        open: true,
                        message: "Please sign account setup transaction",
                        severity: "info",
                    });
                    setupMint = await createAccountsForMint(
                        candyMachine,
                        wallet.publicKey
                    );
                    let status: any = { err: true };
                    if (setupMint.transaction) {
                        status = await awaitTransactionSignatureConfirmation(
                            setupMint.transaction,
                            props.txTimeout,
                            props.connection,
                            true
                        );
                    }
                    if (status && !status.err) {
                        setSetupTxn(setupMint);
                        setAlertState({
                            open: true,
                            message:
                                "Setup transaction succeeded! Please sign minting transaction",
                            severity: "info",
                        });
                    } else {
                        setAlertState({
                            open: true,
                            message: "Mint failed! Please try again!",
                            severity: "error",
                        });
                        setIsUserMinting(false);
                        return;
                    }
                } else {
                    setAlertState({
                        open: true,
                        message: "Please sign minting transaction",
                        severity: "info",
                    });
                }

                const mintResults = await mintMultipleTokens(
                    candyMachine,
                    wallet.publicKey,
                    beforeTransactions,
                    afterTransactions,
                    setupMint ?? setupTxn,
                    mintCount
                );

                let status: any = { err: true };
                if (mintResults) {
                    const firstMintResult = mintResults[0];
                    status = await awaitTransactionSignatureConfirmation(
                        firstMintResult.mintTxId,
                        props.txTimeout,
                        props.connection,
                        true
                    );
                }

                const mintsInfo = [];
                let successCount = 0;
                if (status && !status.err && mintResults) {
                    for (const mintResult of mintResults) {
                        const metadataStatus =
                            await candyMachine.program.provider.connection.getAccountInfo(
                                mintResult.metadataKey,
                                "processed"
                            );

                        console.log("Metadata status: ", !!metadataStatus);

                        if (metadataStatus) {
                            const metadataParsed = new Metadata(
                                wallet.publicKey,
                                metadataStatus
                            );

                            console.log(
                                "Mint Data: ",
                                new Metadata(wallet.publicKey, metadataStatus)
                            );

                            mintsInfo.push({
                                meta_data: metadataParsed!,
                                mint_trx: mintResult?.mintTxId!,
                            });

                            successCount += 1;
                        }
                    }
                }

                if (successCount > 0) {
                    // manual update since the refresh might not detect
                    // the change immediately
                    const remaining = itemsRemaining! - successCount;
                    setItemsRemaining(remaining);
                    setIsActive((candyMachine.state.isActive = remaining > 0));
                    candyMachine.state.isSoldOut = remaining === 0;
                    setSetupTxn(undefined);
                    await onMintDone(mintsInfo);
                    setAlertState({
                        open: true,
                        message: "Congratulations! Mint succeeded!",
                        severity: "success",
                        hideDuration: 5000,
                        action: (
                            <AlertAction onClick={handleShowNfts}>
                                My NFTs
                            </AlertAction>
                        ),
                    });
                    refreshCandyMachineState("processed");
                } else if (status && !status.err) {
                    setAlertState({
                        open: true,
                        message:
                            "Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.",
                        severity: "error",
                        hideDuration: 8000,
                    });
                    refreshCandyMachineState();
                } else {
                    setAlertState({
                        open: true,
                        message: "Mint failed! Reload & try again!",
                        severity: "error",
                    });
                    refreshCandyMachineState();
                }
            }
        } catch (error: any) {
            let message = error.msg || "Minting failed! Reload &  try again!";
            if (!error.msg) {
                if (!error.message) {
                    message = "Transaction timeout! Please try again.";
                } else if (error.message.indexOf("0x137")) {
                    console.log(error);
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf("0x135")) {
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    console.log(error);
                    message = `SOLD OUT!`;
                    window.location.reload();
                } else if (error.code === 312) {
                    message = `Minting period hasn't started yet.`;
                }
            }

            setAlertState({
                open: true,
                message,
                severity: "error",
            });
            // updates the candy machine state to reflect the latest
            // information on chain
            refreshCandyMachineState();
        } finally {
            setIsUserMinting(false);
        }
    };

    const toggleMintButton = () => {
        let active = !isActive || isPresale;

        if (active) {
            if (candyMachine!.state.isWhitelistOnly && !isWhitelistUser) {
                active = false;
            }
            if (endDate && Date.now() >= endDate.getTime()) {
                active = false;
            }
        }

        if (
            isPresale &&
            candyMachine!.state.goLiveDate &&
            candyMachine!.state.goLiveDate.toNumber() <=
                new Date().getTime() / 1000
        ) {
            setIsPresale((candyMachine!.state.isPresale = false));
        }

        setIsActive((candyMachine!.state.isActive = active));
    };

    const onMintDone = async (
        mintsInfo: { meta_data: Metadata; mint_trx: string }[]
    ) => {
        // call add mint api
        await api.run({
            launch_stage_id: settings?.launch.stages.items[0].id!,
            mint_list: mintsInfo.map((mintInfo) => {
                return {
                    mint_address: mintInfo.meta_data.data.mint,
                    mint_trx: mintInfo.mint_trx,
                    mint_uri: mintInfo.meta_data.data.data.uri,
                };
            }),
        });

        // update balance
        updateBalance();

        // update user info
        await updateUserInfo();

        // update settings
        await updateSettings();

        // show confetti
        setConfettiActive(true);

        // reset confetti activation
        setTimeout(() => {
            setConfettiActive(false);
        }, 10);
    };

    const handleShowNfts = () => {
        setActiveTab(DashboardTab.NFTS);
    };

    useEffect(() => {
        refreshCandyMachineState();
    }, [
        anchorWallet,
        props.candyMachineId,
        props.connection,
        refreshCandyMachineState,
    ]);

    useEffect(() => {
        (function loop() {
            setTimeout(
                () => {
                    if (!document.hidden) {
                        refreshCandyMachineState();
                    }
                    loop();
                },
                !document.hidden ? 120_000 : 1_000
            );
        })();
    }, [refreshCandyMachineState]);

    useEffect(() => {
        // set mint data from settings if candy machine is not loaded yet
        if (settings && !candyMachine) {
            setItemsRemaining(settings.launch.remaining);
            setItemsAvailable(settings.launch.total);
            setPrice(settings.launch.stages.items[0].price);
        }

        if (
            settings?.launch.stages.items[0].start_date &&
            settings.launch.stages.items[0].start_date >
                new Date().getTime() / 1000
        ) {
            setStartDate(settings.launch.stages.items[0].start_date);
        } else {
            setStartDate(null);
        }
    }, [settings]);

    useEffect(() => {
        if (itemsRemaining && itemsAvailable) {
            let percent = Math.floor(
                ((itemsAvailable - itemsRemaining) / itemsAvailable) * 100
            );
            if (percent < 7) percent = 7;

            setRemainingPercent(percent);
        }
    }, [itemsRemaining, itemsAvailable]);

    return (
        <Box className={clsx(props.className)} title="Mint">
            {/* {candyMachine && (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    wrap="nowrap"
                >
                    <Grid item xs={3}>
                        <Typography variant="body2" color="textSecondary">
                            Remaining
                        </Typography>
                        <Typography
                            variant="h6"
                            color="textPrimary"
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            {`${itemsRemaining}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary">
                            {isWhitelistUser && discountPrice
                                ? "Discount Price"
                                : "Price"}
                        </Typography>
                        <Typography
                            variant="h6"
                            color="textPrimary"
                            style={{ fontWeight: "bold" }}
                        >
                            {isWhitelistUser && discountPrice
                                ? `◎ ${formatNumber.asNumber(discountPrice)}`
                                : `◎ ${formatNumber.asNumber(
                                      candyMachine.state.price
                                  )}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        {isActive &&
                        endDate &&
                        Date.now() < endDate.getTime() ? (
                            <>
                                <MintCountdown
                                    key="endSettings"
                                    date={getCountdownDate(candyMachine)}
                                    style={{
                                        justifyContent: "flex-end",
                                    }}
                                    status="COMPLETED"
                                    onComplete={toggleMintButton}
                                />
                                <Typography
                                    variant="caption"
                                    align="center"
                                    display="block"
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    TO END OF MINT
                                </Typography>
                            </>
                        ) : (
                            <>
                                <MintCountdown
                                    key="goLive"
                                    date={getCountdownDate(candyMachine)}
                                    style={{
                                        justifyContent: "flex-end",
                                    }}
                                    status={
                                        candyMachine?.state?.isSoldOut ||
                                        (endDate &&
                                            Date.now() > endDate.getTime())
                                            ? "COMPLETED"
                                            : isPresale
                                            ? "PRESALE"
                                            : "LIVE"
                                    }
                                    onComplete={toggleMintButton}
                                />
                                {isPresale &&
                                    candyMachine.state.goLiveDate &&
                                    candyMachine.state.goLiveDate.toNumber() >
                                        new Date().getTime() / 1000 && (
                                        <Typography
                                            variant="caption"
                                            align="center"
                                            display="block"
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            UNTIL PUBLIC MINT
                                        </Typography>
                                    )}
                            </>
                        )}
                    </Grid>
                </Grid>
            )} */}

            {startDate ? (
                <Alert severity="info" className={styles.mintAlert}>
                    <div className={styles.mintAlertContent}>
                        <div>
                            <div className={styles.title}>
                                Mint event will start soon!
                            </div>
                        </div>
                        <CountdownUI
                            date={startDate * 1000}
                            onExpire={() => {
                                refreshCandyMachineState();
                                setStartDate(null);
                            }}
                        />
                    </div>
                </Alert>
            ) : launchStage?.inflation_percent ? (
                <Alert severity="info" className={styles.mintAlert}>
                    {`Mint price will increase ${launchStage.inflation_percent}% every ${launchStage.inflation_gap} mints.`}
                    <Hint
                        className={styles.mintAlertHint}
                        tip={
                            "Mint Price has started from " +
                            launchStage.start_price +
                            " SOL."
                        }
                    />
                </Alert>
            ) : (
                ""
            )}

            {(candyMachine || (settings && !loadingSettings)) && (
                <div className={styles.progressWrap}>
                    <ProgressBar
                        completed={remainingPercent!}
                        bgColor="#759bcd"
                        baseBgColor="#201e26"
                        labelColor="#201e26"
                        isLabelVisible={remainingPercent! >= 10}
                        labelSize="14px"
                        animateOnRender
                    />
                </div>
            )}
            <div className={styles.statList}>
                <div className={styles.statItem}>
                    <div className={styles.label}>Current Price</div>
                    <div className={styles.content}>
                        {candyMachine ? (
                            isWhitelistUser && discountPrice ? (
                                `${formatNumber.asNumber(discountPrice)} SOL`
                            ) : (
                                `${price} SOL`
                            )
                        ) : settings && !loadingSettings ? (
                            `${price} SOL`
                        ) : (
                            <Skeleton width={50} />
                        )}
                    </div>
                </div>
                <div className={styles.statItem}>
                    <div className={styles.label}>Remaining</div>
                    <div className={styles.content}>
                        {candyMachine || (settings && !loadingSettings) ? (
                            <>
                                <Counter number={itemsRemaining!} />
                                <span> / </span>
                                <span>{itemsAvailable?.toLocaleString()}</span>
                            </>
                        ) : (
                            <Skeleton width={50} />
                        )}
                    </div>
                </div>
            </div>

            <div className={styles.confettiWrap}>
                <Confetti
                    active={confettiActive}
                    config={{
                        angle: 90,
                        spread: 360,
                        startVelocity: 40,
                        elementCount: 70,
                        dragFriction: 0.12,
                        duration: 3000,
                        stagger: 3,
                        width: "10px",
                        height: "10px",
                        colors: [
                            "#a864fd",
                            "#29cdff",
                            "#78ff44",
                            "#ff718d",
                            "#fdff6a",
                        ],
                    }}
                />
            </div>

            <div className={styles.mintButtonWrap}>
                <NumberCount
                    className={styles.mintCount}
                    num={mintCount}
                    numChange={setMintCount}
                    min={1}
                    max={5}
                />

                {!wallet.connected ? (
                    <ConnectButton>MINT</ConnectButton>
                ) : (
                    <>
                        <MintContainer>
                            {candyMachine?.state.isActive &&
                            candyMachine?.state.gatekeeper &&
                            wallet.publicKey &&
                            wallet.signTransaction ? (
                                <GatewayProvider
                                    wallet={{
                                        publicKey:
                                            wallet.publicKey ||
                                            new PublicKey(
                                                CANDY_MACHINE_PROGRAM
                                            ),
                                        //@ts-ignore
                                        signTransaction: wallet.signTransaction,
                                    }}
                                    gatekeeperNetwork={
                                        candyMachine?.state?.gatekeeper
                                            ?.gatekeeperNetwork
                                    }
                                    clusterUrl={rpcUrl}
                                    cluster={cluster}
                                    options={{ autoShowModal: false }}
                                >
                                    <MintButton
                                        candyMachine={candyMachine}
                                        isMinting={isUserMinting}
                                        setIsMinting={(val) =>
                                            setIsUserMinting(val)
                                        }
                                        onMint={onMint}
                                        isValidBalance={isValidBalance}
                                        isInvited={
                                            !!userInfo?.invites_info
                                                ?.invited_by ||
                                            (userInfo?.mints_info
                                                ?.approved_mint_count || 0) > 0
                                        }
                                        isActive={
                                            isActive ||
                                            (isPresale &&
                                                isWhitelistUser &&
                                                isValidBalance)
                                        }
                                        lockedBy={settings?.launch.locked_by}
                                    />
                                </GatewayProvider>
                            ) : (
                                <MintButton
                                    candyMachine={candyMachine}
                                    isMinting={isUserMinting}
                                    setIsMinting={(val) =>
                                        setIsUserMinting(val)
                                    }
                                    onMint={onMint}
                                    isValidBalance={isValidBalance}
                                    isInvited={
                                        !!userInfo?.invites_info?.invited_by ||
                                        (userInfo?.mints_info
                                            ?.approved_mint_count || 0) > 0
                                    }
                                    isActive={
                                        isActive ||
                                        (isPresale &&
                                            isWhitelistUser &&
                                            isValidBalance)
                                    }
                                    lockedBy={settings?.launch.locked_by}
                                />
                            )}
                        </MintContainer>
                    </>
                )}
            </div>
        </Box>
    );
};

const getCountdownDate = (
    candyMachine: CandyMachineAccount
): Date | undefined => {
    if (
        candyMachine.state.isActive &&
        candyMachine.state.endSettings?.endSettingType.date
    ) {
        return toDate(candyMachine.state.endSettings.number);
    }

    return toDate(
        candyMachine.state.goLiveDate
            ? candyMachine.state.goLiveDate
            : candyMachine.state.isPresale
            ? new anchor.BN(new Date().getTime() / 1000)
            : undefined
    );
};

export default Mint;
