import { FC, PropsWithChildren, ReactNode, useState } from 'react';
import clsx from 'clsx';
import Toggle from 'react-toggle';
import styles from './FormField.module.scss';

type Props = {
    className?: string;
    label?: string;
    error?: string;
    labelEndRender?: ReactNode;
    toggle?: {
        active: boolean;
        checkedLabel: string;
        uncheckedLabel: string;
        checked?: boolean;
        onChange?(checked: boolean): void;
        wide?: boolean;
    };
};

const FormField: FC<PropsWithChildren<Props>> = ({
    children,
    className,
    label,
    error,
    labelEndRender,
    toggle,
}) => {
    const [toggleChecked, setToggleChecked] = useState<boolean>(
        toggle?.checked || false
    );

    const onToggleChange = (e: any) => {
        setToggleChecked(e.target.checked);
        if (toggle?.onChange) toggle.onChange(e.target.checked);
    };

    return (
        <div className={clsx(styles.fieldWrap, className)}>
            {label && (
                <label className={styles.label}>
                    <div className={styles.labelStart}>{label}</div>
                    <div className={styles.labelEnd}>
                        {labelEndRender}
                        {toggle?.active && (
                            <Toggle
                                checked={toggleChecked}
                                onChange={onToggleChange}
                                icons={{
                                    checked: toggle.checkedLabel,
                                    unchecked: toggle.uncheckedLabel,
                                }}
                                className={clsx(
                                    styles.toggle,
                                    toggle.wide && 'react-toggle-wide'
                                )}
                            />
                        )}
                    </div>
                </label>
            )}

            {(!toggle?.active || toggleChecked) && (
                <div className={clsx(styles.field, error && styles.fieldError)}>
                    {children}
                </div>
            )}

            {error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

export default FormField;
