import { InviteInfoShortType } from "./invite";
import { UserShortType } from "./user";

export type LeaderType = {
    id: number;
    leader_tag: LeaderTagType;
    reward_period: 'daily'|'weekly'|'monthly'|'quarterly'|'yearly';
    user_count: number;
    last_updated_date: number;
};

export type LeaderUserType = {
    id: number;
    real_value: number;
    cached_value: number;
    real_rank_num: number;
    cached_rank_num: number;
    real_rank_percent: number;
    user?: UserShortType;
    leader?: LeaderType;
};

export type LeaderTagType = 'network';

export const leaderUsersExample: LeaderUserType[] = [
    {
        id: 13,
        real_value: 754.14,
        cached_value: 754.14,
        real_rank_num: 1,
        cached_rank_num: 1,
        real_rank_percent: 1.64,
        user: {
            id: 317,
            sol_wallet_short: "9ae4...3ucX",
            display_name: "SALAH",
            avatar: {
                download_key: "SvKqq7xB",
                address: "",
                type: "image",
                size: 14544,
                format: "png",
                width: 128,
                height: 128,
                read_seconds: 0,
            },
            invites_info: {
                id: 146,
                active_invited_user_count: 4,
                active_network_user_count: 4,
                total_invited_user_count: 4,
                total_network_user_count: 4,
                last_invite_date: 1707247394,
                invited_by: {
                    id: 316,
                    sol_wallet_short: "2uFR...rzHz",
                    display_name: "SALAH",
                }
            }
        },
    },
    {
        id: 18,
        real_value: 609.10912,
        cached_value: 609.10912,
        real_rank_num: 2,
        cached_rank_num: 2,
        real_rank_percent: 3.28,
        user: {
            id: 330,
            sol_wallet_short: "7iea...kwfC",
            display_name: "Monireh.sol",
            avatar: {
                download_key: "yYotMSBm",
                address: "",
                type: "image",
                size: 15774,
                format: "png",
                width: 128,
                height: 128,
                read_seconds: 0,
            },
            invites_info: {
                id: 159,
                active_invited_user_count: 5,
                active_network_user_count: 10,
                total_invited_user_count: 11,
                total_network_user_count: 18,
                last_invite_date: 1707754432,
                invited_by: {
                    id: 329,
                    sol_wallet_short: "J7Lc...TrNY",
                    display_name: "omar",
                }
            }
        }
    },
    {
        id: 12,
        real_value: 560.52,
        cached_value: 560.52,
        real_rank_num: 3,
        cached_rank_num: 3,
        real_rank_percent: 4.92,
        user: {
            id: 316,
            sol_wallet_short: "2uFR...rzHz",
            display_name: "SALAH",
            avatar: {
                download_key: "s7I1oX83",
                address: "",
                type: "image",
                size: 10390,
                format: "png",
                width: 128,
                height: 128,
                read_seconds: 0,
            },
            invites_info: {
                id: 145,
                active_invited_user_count: 1,
                active_network_user_count: 5,
                total_invited_user_count: 1,
                total_network_user_count: 5,
                last_invite_date: 1705486257,
                invited_by: {
                    id: 314,
                    sol_wallet_short: "2HRT...93bf",
                    display_name: "SALAH",
                }
            }
        }
    },
    {
        id: 6,
        real_value: 559.18,
        cached_value: 559.18,
        real_rank_num: 4,
        cached_rank_num: 4,
        real_rank_percent: 6.56,
        user: {
            id: 271,
            sol_wallet_short: "FX2k...wSMH",
            display_name: "Moosa.Sol",
            avatar: {
                download_key: "aWol4Tm3",
                address: "",
                type: "image",
                size: 32312,
                format: "png",
                width: 128,
                height: 128,
                read_seconds: 0,
            },
            invites_info: {
                id: 100,
                active_invited_user_count: 1,
                active_network_user_count: 1,
                total_invited_user_count: 1,
                total_network_user_count: 1,
                last_invite_date: 1705486256,
                invited_by: {
                    id: 289,
                    sol_wallet_short: "DeWm...aqdg",
                    display_name: "raven",
                }
            }
        }
    },
    {
        id: 3,
        real_value: 518.56,
        cached_value: 518.56,
        real_rank_num: 5,
        cached_rank_num: 5,
        real_rank_percent: 8.2,
        user: {
            id: 193,
            sol_wallet_short: "6ygD...gK8s",
            display_name: "Mostafa7Rahimi2",
            avatar: {
                download_key: "CanEf1X7",
                address: "",
                type: "image",
                size: 17533,
                format: "png",
                width: 128,
                height: 128,
                read_seconds: 0,
            },
            invites_info: {
                id: 41,
                active_invited_user_count: 5,
                active_network_user_count: 54,
                total_invited_user_count: 5,
                total_network_user_count: 68,
                last_invite_date: 1705486254,
                invited_by: {
                    id: 158,
                    sol_wallet_short: "DUs8...Dheh",
                    display_name: "Mostafa7Rahimi2",
                }
            }
        }
    },
    {
        id: 17,
        real_value: 482.89956,
        cached_value: 482.89956,
        real_rank_num: 6,
        cached_rank_num: 6,
        real_rank_percent: 9.84,
        user: {
            id: 329,
            sol_wallet_short: "J7Lc...TrNY",
            display_name: "Omar",
            avatar: {
                download_key: "XRgoMYAV",
                address: "",
                type: "image",
                size: 15951,
                format: "png",
                width: 128,
                height: 128,
                read_seconds: 0,
            },
            invites_info: {
                id: 158,
                active_invited_user_count: 2,
                active_network_user_count: 12,
                total_invited_user_count: 4,
                total_network_user_count: 22,
                last_invite_date: 1706968198,
                invited_by: {
                    id: 289,
                    sol_wallet_short: "DeWm...aqdg",
                    display_name: "raven",
                }
            }
        }
    },
    {
        id: 1,
        real_value: 424.987736,
        cached_value: 424.987736,
        real_rank_num: 7,
        cached_rank_num: 7,
        real_rank_percent: 11.48,
        user: {
            id: 289,
            sol_wallet_short: "DeWm...aqdg",
            display_name: "raven",
            avatar: {
                download_key: "3rQ2efRg",
                address: "",
                type: "image",
                size: 13944,
                format: "png",
                width: 128,
                height: 128,
                read_seconds: 0,
            },
            invites_info: {
                id: 121,
                active_invited_user_count: 2,
                active_network_user_count: 15,
                total_invited_user_count: 3,
                total_network_user_count: 26,
                last_invite_date: 1706197384,
                invited_by: {
                    id: 250,
                    sol_wallet_short: "5JDi...MvEZ",
                    display_name: "Mostafa7Rahimi2",
                }
            }
        }
    },
];