import { FC, useState } from "react";
import clsx from "clsx";
import Dialog from "src/libs/dialog/Dialog";
import TableUI from "src/libs/table-ui/TableUI";
import Table, { Column } from "rc-table";
import {
    circleCommissions,
    inviteLeaderboardRules,
} from "../dashboard-mint/DashboradMint.service";
import Alert from "../../libs/alert/Alert";
import SwitchTab from "src/libs/switch-tabs/SwitchTab";
import SwitchTabGroup from "src/libs/switch-tabs/SwitchTabGroup";
import styles from "./DashboardInvites.module.scss";
import { FaCheck } from "react-icons/fa";
import { GoInfo } from "react-icons/go";

type Props = {
    className?: string;
    defaultTab: NetworkGuideTab;
    open: boolean;
    onClose(): void;
};

export enum NetworkGuideTab {
    CIRCLES = "circles",
    LEADERBOARD = "leaderboard",
}

const DashboardInvitesGuide: FC<Props> = ({
    className,
    defaultTab,
    open,
    onClose,
}) => {
    const [activeTab, setActiveTab] = useState<NetworkGuideTab>(defaultTab);

    const renderCirclesGuide = () => {
        return (
            <>
                <Alert severity="info">
                    Your invited friends are your circle 1, and their invited
                    friends on deeper levels will be your next circles.
                </Alert>

                <TableUI
                    className={clsx(styles.list, className)}
                    type="withBorder"
                >
                    <Table data={circleCommissions} rowKey="key">
                        <Column
                            title="Circle"
                            dataIndex={["circle"]}
                            key="circle"
                            width={30}
                            align="center"
                        />
                        <Column
                            title="Mint Share"
                            dataIndex={["mint_commission"]}
                            key="mint_share"
                            width={30}
                            align="center"
                            render={(mint_commission) => `${mint_commission}%`}
                        />
                        <Column
                            title="IB Share (per LOT)"
                            dataIndex={["invite_ib_commission_usd"]}
                            key="invite_ib_share"
                            width={30}
                            align="center"
                            render={(invite_ib_commission_usd) =>
                                `$${invite_ib_commission_usd}`
                            }
                        />
                    </Table>
                </TableUI>
            </>
        );
    };

    const renderLeaderboardGuide = () => {
        return (
            <TableUI className={clsx(styles.list, className)} type="withBorder">
                <Table data={inviteLeaderboardRules} rowKey="key">
                    <Column
                        title="Rank"
                        dataIndex={["rank_range"]}
                        key="rank_range"
                        width={30}
                        align="center"
                        render={(rank_range) => `#${rank_range.fixed}`}
                    />
                    <Column
                        title="Prize"
                        dataIndex={["reward_sol"]}
                        key="reward_sol"
                        width={30}
                        align="center"
                        render={(reward_sol) => `${reward_sol} SOL`}
                    />
                    <Column
                        title="1x1 (Platinum)"
                        dataIndex={["has_one_by_one"]}
                        key="has_one_by_one"
                        width={30}
                        align="center"
                        render={(has_one_by_one) =>
                            has_one_by_one ? <FaCheck /> : "—"
                        }
                    />
                </Table>

                <div className={styles.leaderGuideHint}>
                    <GoInfo className={styles.icon} />
                    <span className={styles.content}>
                        Rewards are scheduled to be distributed at the close of
                        every quarter. (the timeline is subject to change.)
                    </span>
                </div>
                <div className={styles.leaderGuideHint}>
                    <GoInfo className={styles.icon} />
                    <span className={styles.content}>
                        The winner will be granted the opportunity to select one
                        of their NFTs for an upgrade to Platinum. This upgrade
                        will include changing the art to a 1x1 format, thereby
                        making it rare within the collection.
                    </span>
                </div>
            </TableUI>
        );
    };

    return (
        <Dialog
            className={clsx(styles.dialog, className)}
            onClose={onClose}
            open={open}
            boxProps={{
                title: "Network Guide",
            }}
        >
            <SwitchTabGroup
                className={styles.sectionTabs}
                labelWrapClassName={styles.sectionTabsLabelWrap}
                type="round"
                activeTab={activeTab}
                onChange={(activeTab: NetworkGuideTab) =>
                    setActiveTab(activeTab)
                }
            >
                <SwitchTab tag={NetworkGuideTab.CIRCLES} label="Circles">
                    {renderCirclesGuide()}
                </SwitchTab>

                <SwitchTab
                    tag={NetworkGuideTab.LEADERBOARD}
                    label="Leaderboard"
                >
                    {renderLeaderboardGuide()}
                </SwitchTab>
            </SwitchTabGroup>
        </Dialog>
    );
};

export default DashboardInvitesGuide;
