import { FC, MutableRefObject, useRef } from 'react';
import clsx from 'clsx';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import styles from './NumberCount.module.scss';

type Props = {
    className?: string;
    num: number;
    numChange(num: number): void;
    step?: number;
    min?: number;
    max?: number;
};

const NumberCount: FC<Props> = ({
    className,
    num,
    numChange,
    min,
    max,
    step,
}) => {
    const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
    const handleChange = (newNum: number) => {
        if (max && newNum > max) {
            newNum = max;
        } else if (min && newNum < min) {
            newNum = min;
        }

        if (step) {
            newNum = Math.round(newNum / step) * step;
        }

        if (inputRef.current) {
            inputRef.current.value = newNum.toString();
        }

        numChange(newNum);
    };

    const handleMinus = () => {
        handleChange(num - (step || 1));
    };

    const handlePlus = () => {
        handleChange(num + (step || 1));
    };

    const handleInputBlur = (e: any) => {
        handleChange(parseInt(e.target.value));
    };

    const handleInputFocus = (e: any) => {
        e.target.select();
    };

    return (
        <div className={clsx(styles.inputWrap, className)}>
            <div
                className={clsx(
                    styles.wheel,
                    styles.wheelMinus,
                    num == min && styles.wheelDisabled
                )}
                onClick={handleMinus}
            >
                <AiOutlineMinus />
            </div>

            <input
                className={styles.input}
                ref={inputRef}
                type="number"
                defaultValue={num}
                onBlur={handleInputBlur}
                onFocus={handleInputFocus}
            />

            <div
                className={clsx(
                    styles.wheel,
                    styles.wheelPlus,
                    num == max && styles.wheelDisabled
                )}
                onClick={handlePlus}
            >
                <AiOutlinePlus />
            </div>
        </div>
    );
};

export default NumberCount;
