import { useApi, UseApiOptions } from "src/hooks/useApi";
import {
    investFundTransExample,
    InvestFundTransType,
    InvestFundTransTypeType,
    investGainExample,
    InvestGainType,
} from "../types/invest";
import { ApiGetGroupParams, apiTestMode } from "./root";

export interface GetInvestFundTransParams extends ApiGetGroupParams {
    invest_fund_id?: number;
    type?: InvestFundTransTypeType;
    is_completed?: boolean;
}

export interface WithdrawInvestFundParams {
    transaction_usd: number;
}

export interface GetInvestGainsParams extends ApiGetGroupParams {
    campaign_id?: number;
}

export class UseInvestsApi {
    useGetInvestFundTrans(config?: UseApiOptions<InvestFundTransType[]>) {
        return useApi<GetInvestFundTransParams, InvestFundTransType[]>({
            method: "GET",
            path: "/invests/funds/trans",
            needAuth: true,
            isList: true,
            test: {
                enable: apiTestMode,
                data: investFundTransExample,
            },
            ...config,
        });
    }

    useWithdrawInvestFund(invest_fund_id: number, config?: UseApiOptions<{}>) {
        return useApi<WithdrawInvestFundParams, {}>({
            method: "PUT",
            path: `/invests/funds/${invest_fund_id}/withdraw`,
            needAuth: true,
            test: {
                enable: apiTestMode,
            },
            ...config,
        });
    }

    useGetInvestGains(config?: UseApiOptions<InvestGainType[]>) {
        return useApi<GetInvestGainsParams, InvestGainType[]>({
            method: "GET",
            path: "/invests/gains",
            needAuth: true,
            isList: true,
            test: {
                enable: apiTestMode,
                data: investGainExample,
            },
            ...config,
        });
    }
}
