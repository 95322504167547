import { useApi, UseApiOptions } from "src/hooks/useApi";
import {
    CampaignType,
    completedCampaignsExample,
    CampaignStatusType,
} from "../types/campaign";
import { ApiGetGroupParams, apiTestMode } from "./root";

export interface GetCampaignsParams extends ApiGetGroupParams {
    status?: CampaignStatusType;
    exclude_status?: CampaignStatusType;
    campaign_num?: number;
    full_funded?: boolean;
    has_funded?: boolean;
    has_ran?: boolean;
}

export class UseCampaignsApi {
    useGetCampaigns(config?: UseApiOptions<CampaignType[]>) {
        return useApi<GetCampaignsParams, CampaignType[]>({
            method: "GET",
            path: "/campaigns",
            isList: true,
            test: {
                enable: apiTestMode,
                data: completedCampaignsExample,
            },
            ...config,
        });
    }
}
