import { FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { ConnectionPlatformType, ConnectionType } from "../../types/connection";
import useUserInfo from "../../hooks/useUserInfo";
import FormButton from "../../libs/form-button/FormButton";
import Pair from "../../libs/pair/Pair";
import { BiXCircle, BiCheckCircle } from "react-icons/bi";
import useConnectionPortal, {
    platformInfo,
    PortalRequestSourceType,
} from "../../hooks/useConnectionPortal";
import useAlert from "../../hooks/useAlert";
import ReactLoading from "react-loading";
import styles from "./Profile.module.scss";

type Props = {
    className?: string;
    platform: ConnectionPlatformType;
    requestSource: PortalRequestSourceType;
};

const ProfileConnect: FC<Props> = ({ className, platform, requestSource }) => {
    const { userInfo, updateUserInfo } = useUserInfo();
    const [, setAlertState] = useAlert();
    const {
        pendingPortalInfo,
        startConnection,
        connectToPendingPlatform,
        updatePlatformUserInfo,
        disconnectFromPlatform,
    } = useConnectionPortal();
    const [isInitializing, setIsInitilazing] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDisconnecting, setIsDisconnecting] = useState(false);

    const platformData = useMemo(() => {
        return platformInfo[platform];
    }, [platform]);

    const isConnecting = useMemo(() => {
        return (
            !!pendingPortalInfo?.has_redirected &&
            pendingPortalInfo.platform == platform
        );
    }, [pendingPortalInfo, platform]);

    const connection: ConnectionType | undefined = useMemo(() => {
        return userInfo?.connections?.items.find(
            (connection) => connection.platform == platform
        );
    }, [userInfo]);

    // connect or update on init
    useEffect(() => {
        const connectOrUpdate = async () => {
            if (isConnecting) {
                const isConnected = await connectToPendingPlatform();
                if (isConnected) {
                    setAlertState({
                        open: true,
                        message: `You are successfully linked to ${platformData.title}.`,
                        severity: "success",
                        hideDuration: 3000,
                    });
                } else {
                    setAlertState({
                        open: true,
                        message: `Linking to ${platformData.title} failed! Please try again.`,
                        severity: "error",
                        hideDuration: 3000,
                    });
                }
            } else if (connection?.is_connected) {
                updatePlatform();
            }
        };

        connectOrUpdate();
    }, []);

    // update on user come back to page
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (
                platformData.auto_update &&
                document.visibilityState === "visible"
            ) {
                updatePlatform();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange
            );
        };
    }, []);

    const updatePlatform = async () => {
        if (!connection?.is_connected || isUpdating) return;

        setIsUpdating(true);
        await updatePlatformUserInfo(platform);
        setIsUpdating(false);
    };

    const handleConnect = async () => {
        setIsInitilazing(true);
        await startConnection(requestSource, platform);
    };

    const handleDisconnect = async () => {
        setIsDisconnecting(true);
        await disconnectFromPlatform(platform);
        await updateUserInfo();
        setIsDisconnecting(false);

        setAlertState({
            open: true,
            message: `You are successfully unlinked from ${platformData.title}.`,
            severity: "success",
            hideDuration: 3000,
        });
    };

    return (
        <div className={clsx(styles.connectWrap, className)}>
            <div className={styles.connectStatusWrap}>
                <Pair
                    value={
                        <>
                            <platformData.icon className={styles.icon} />
                            <span>{`${platformData.title} Portal`}</span>
                        </>
                    }
                    valueClassname={styles.connectStatusValue}
                    title={
                        isInitializing ? (
                            <div className={styles.connectStatus}>
                                <ReactLoading
                                    type="spin"
                                    width={12}
                                    height={12}
                                />
                                <span>Initializing...</span>
                            </div>
                        ) : isConnecting ? (
                            <div className={styles.connectStatus}>
                                <ReactLoading
                                    type="spin"
                                    width={12}
                                    height={12}
                                />
                                <span>Linking...</span>
                            </div>
                        ) : isUpdating ? (
                            <div className={styles.connectStatus}>
                                <ReactLoading
                                    type="spin"
                                    width={12}
                                    height={12}
                                />
                                <span>Updating...</span>
                            </div>
                        ) : connection?.is_connected ? (
                            <div
                                className={clsx(
                                    styles.connectStatus,
                                    styles.connectStatusConnected
                                )}
                            >
                                <BiCheckCircle className={styles.icon} />
                                <span>Linked</span>
                            </div>
                        ) : (
                            <div
                                className={clsx(
                                    styles.connectStatus,
                                    styles.connectStatusPending
                                )}
                            >
                                <BiXCircle className={styles.icon} />
                                <span>Not Linked</span>
                            </div>
                        )
                    }
                    titleClassname={styles.connectStatusTitle}
                    titlePosition="bottom"
                />

                {connection?.is_connected ? (
                    <FormButton
                        className={styles.connectButton}
                        color="off"
                        loading={isDisconnecting}
                        onClick={handleDisconnect}
                    >
                        Unlink
                    </FormButton>
                ) : (
                    <FormButton
                        className={styles.connectButton}
                        loading={isConnecting || isInitializing}
                        onClick={handleConnect}
                    >
                        Link
                    </FormButton>
                )}
            </div>

            {connection?.is_connected && (
                <div className={styles.connectInfo}>
                    <Pair
                        className={styles.connectInfoPair}
                        title={`${platformData.title} Username`}
                        titlePosition="start"
                        value={connection.username}
                    />
                    <Pair
                        className={styles.connectInfoPair}
                        title={`${platformData.title} ${platformData.channel_key}`}
                        titlePosition="start"
                        value={
                            connection.joined_to_channel ? (
                                "Joined"
                            ) : (
                                <a
                                    href={platformData.channel_url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Join Now
                                </a>
                            )
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default ProfileConnect;
