import { useApi, UseApiOptions } from "src/hooks/useApi";
import { apiTestMode } from "./root";

export interface DemandTaskMissionParams {
    task_mission_id: number;
}

export interface DemandTaskMissionResponse {
    is_paid: boolean;
}

export class UseTasksApi {
    useDemandTaskMission(config?: UseApiOptions<DemandTaskMissionResponse>) {
        return useApi<DemandTaskMissionParams, DemandTaskMissionResponse>({
            method: "PUT",
            path: "/tasks/missions/demand",
            needAuth: true,
            test: {
                enable: apiTestMode,
            },
            ...config,
        });
    }
}
