import { FC, ReactNode, useState } from "react";
import clsx from "clsx";
import DashboardNftsGrades from "../dashboard-nfts/DashboardNftsGrades";
import styles from "./Preview.module.scss";

type AboutGroupType = {
    title: ReactNode;
    content: ReactNode;
};

type Props = {
    className?: string;
};

const PreviewAboutGrades: FC<Props> = ({ className }) => {
    const [openGradeKey, setOpenGradeKey] = useState<string | null>(null);

    return (
        <div
            className={clsx(
                styles.previewSectionSub,
                styles.gradesWrap,
                className
            )}
        >
            <div className={styles.gradeTitle}>
                <span>Upgradable NFTs</span>
                <span className={styles.extra}>No Extra Cost!</span>
            </div>
            <div className={styles.gradeDescription}>
                Discover the steps to upgrade your NFTs&apos; metadata and
                maximize their perks and market value, all for free!
            </div>
            <div className={styles.gradeList}>
                <div
                    className={clsx(
                        styles.logoWrap,
                        styles.gradeItem,
                        styles.gradeItemBronze
                    )}
                    onClick={() => setOpenGradeKey("bronze")}
                >
                    Bronze
                </div>
                <div
                    className={clsx(
                        styles.logoWrap,
                        styles.gradeItem,
                        styles.gradeItemSilver
                    )}
                    onClick={() => setOpenGradeKey("silver")}
                >
                    Silver
                </div>
                <div
                    className={clsx(
                        styles.logoWrap,
                        styles.gradeItem,
                        styles.gradeItemGold
                    )}
                    onClick={() => setOpenGradeKey("gold")}
                >
                    Gold
                </div>
                <div
                    className={clsx(
                        styles.logoWrap,
                        styles.gradeItem,
                        styles.gradeItemPlatinum
                    )}
                    onClick={() => setOpenGradeKey("platinum")}
                >
                    Platinum
                </div>
            </div>

            {openGradeKey && (
                <DashboardNftsGrades
                    open={openGradeKey != null}
                    defaultTabKey={openGradeKey}
                    onClose={() => setOpenGradeKey(null)}
                />
            )}
        </div>
    );
};

export default PreviewAboutGrades;
