import { FC, ReactNode } from "react";
import clsx from "clsx";
import { CoinType } from "../../types/coin";
import Skeleton from "react-loading-skeleton";
import Feature from "../../libs/feature/Feature";
import FormButton from "../../libs/form-button/FormButton";
import Hint from "../../libs/hint/Hint";
import Pair from "../../libs/pair/Pair";
import Price from "../price/Price";
import useSettings from "../../hooks/useSettings";
import useUserInfo from "../../hooks/useUserInfo";
import styles from "./Wallet.module.scss";

type Props = {
    className?: string;
    availableCoins: WalletCoin[];
    withdrawingCoins: WalletCoin[];
    lockedCoins: WalletCoin[];
    actionButton?: {
        content: ReactNode;
        hide?: boolean;
        loading?: boolean;
        disabled?: boolean;
        onClick(): void;
    };
};

export type WalletCoin = {
    coin: CoinType;
    amount: number;
};

export type WalletType = "available" | "withdrawing" | "locked" | "rewarded";

const WalletOverview: FC<Props> = ({
    className,
    availableCoins,
    withdrawingCoins,
    lockedCoins,
    actionButton,
}) => {
    const { settings } = useSettings();
    const { fullConnected, loadingUserInfo } = useUserInfo();

    const renderWalletPrice = (wallet_coins: WalletCoin[]) => {
        if (wallet_coins.length > 0) {
            return wallet_coins.map((wallet_coin) => {
                return (
                    <Price
                        key={wallet_coin.coin.id}
                        priceSource="none"
                        price={wallet_coin.amount}
                        coin={wallet_coin.coin}
                        normalSizeSymbol={true}
                    />
                );
            });
        } else {
            return (
                <Price
                    priceSource="none"
                    price={0}
                    fiat={settings?.client.advertised_fiat}
                    coin={settings?.client.advertised_coin}
                    normalSizeSymbol={true}
                />
            );
        }
    };

    const renderUserInfo = (content: ReactNode) => {
        return fullConnected ? (
            !loadingUserInfo ? (
                content
            ) : (
                <Skeleton width={40} />
            )
        ) : (
            "—"
        );
    };

    return (
        <Feature className={clsx(styles.overviewWrap, className)}>
            <div className={styles.assets}>
                <Pair
                    className={styles.assetItem}
                    title="Available"
                    titlePosition="bottom"
                    value={renderUserInfo(renderWalletPrice(availableCoins))}
                />

                {lockedCoins.length > 0 && (
                    <Pair
                        className={styles.assetItem}
                        title={
                            <>
                                Locked
                                <Hint tip="To unlock your funds, please wait untill the end of mint event or mint 1 NFT." />
                            </>
                        }
                        titlePosition="bottom"
                        value={renderWalletPrice(lockedCoins)}
                    />
                )}

                {withdrawingCoins.length > 0 && (
                    <Pair
                        className={styles.assetItem}
                        title={
                            <>
                                Withdrawing
                                <Hint tip="It will take up to 24 hours to review and transfer the funds to your wallet." />
                            </>
                        }
                        titlePosition="bottom"
                        value={renderWalletPrice(withdrawingCoins)}
                    />
                )}
            </div>

            {actionButton && !actionButton.hide && (
                <FormButton
                    className={styles.actionButton}
                    loading={actionButton.loading}
                    disabled={actionButton.disabled}
                    onClick={actionButton.onClick}
                >
                    {actionButton.content}
                </FormButton>
            )}
        </Feature>
    );
};

export default WalletOverview;
