import { FC, useEffect } from "react";
import clsx from "clsx";
import Box from "src/libs/box/Box";
import useUserInfo from "src/hooks/useUserInfo";
import { Api } from "src/api/root";
import useSettings from "../../hooks/useSettings";
import Plat from "../plat/Plat";
import Column from "rc-table/lib/sugar/Column";
import moment from "moment";
import {
    inviteRewardReasonTitles,
    InviteRewardReasonType,
    InviteRewardType,
} from "../../types/invite";
import styles from "./DashboardInvites.module.scss";

type Props = {
    className?: string;
};

const DashboardInvitesRewards: FC<Props> = ({ className }) => {
    const { settings } = useSettings();
    const { fullConnected, userInfo } = useUserInfo();

    const api = Api.invites().useGetInviteRewards({
        perPage: 5,
    });

    useEffect(() => {
        api.run({});
        return () => api.abort();
    }, [
        fullConnected,
        settings?.launch.id,
        api.state.pageNum,
        api.state.perPage,
    ]);

    useEffect(() => {
        api.run({}, { runInBackground: true });
        return () => api.abort();
    }, [
        userInfo?.mints_info?.auditing_invited_mint_count,
        userInfo?.mints_info?.approved_invited_mint_count,
    ]);

    return (
        <Box className={className}>
            <Plat
                title="Network Rewards"
                api={api}
                needAuth={true}
                minimal={{
                    enable: true,
                    dialogApi: api,
                    onDialogApiRequest: (params, options) => {
                        api.run(params, options);
                    },
                }}
                hideHeader={true}
                onApiRequest={(params, options) => {
                    api.run(params, options);
                }}
                renderTableColumns={() => {
                    return (
                        <>
                            <Column
                                title="User"
                                dataIndex={["invited_user"]}
                                key="user"
                                width={50}
                                render={(
                                    user_short,
                                    invite_reward: InviteRewardType
                                ) => (
                                    <>
                                        <div
                                            data-tip={
                                                user_short?.sol_wallet_short
                                            }
                                        >
                                            {user_short?.display_name ||
                                                user_short?.sol_wallet_short}
                                        </div>
                                        <div className={styles.cellSub}>
                                            {`circle ${invite_reward?.circle_num}`}
                                        </div>
                                    </>
                                )}
                            />
                            <Column
                                title="Action"
                                dataIndex={["reason"]}
                                key="reason"
                                width={30}
                                render={(reason: InviteRewardReasonType) => (
                                    <span
                                        className={clsx(
                                            styles.cellMark,
                                            styles.cellInfo
                                        )}
                                    >
                                        {inviteRewardReasonTitles[reason]}
                                    </span>
                                )}
                            />
                            <Column
                                title="Reward"
                                dataIndex={["reward_amount"]}
                                key="reward_amount"
                                width={30}
                                render={(
                                    reward_amount,
                                    invite_reward: InviteRewardType
                                ) => (
                                    <>
                                        <span>{reward_amount}</span>
                                        <span className={styles.cellExtra}>
                                            {invite_reward?.reward_coin?.symbol}
                                        </span>
                                    </>
                                )}
                            />
                            <Column
                                title="Share"
                                dataIndex={["commission_percent"]}
                                key="commission_percent"
                                width={30}
                                render={(commission_percent) =>
                                    commission_percent
                                        ? `${commission_percent}%`
                                        : "—"
                                }
                            />
                            <Column
                                title="Date"
                                dataIndex={["rewarded_date"]}
                                key="rewarded_date"
                                width={70}
                                render={(rewarded_date) => (
                                    <span
                                        data-tip={
                                            rewarded_date &&
                                            moment.unix(rewarded_date).format()
                                        }
                                    >
                                        {rewarded_date
                                            ? moment
                                                  .unix(rewarded_date)
                                                  .fromNow()
                                            : "_"}
                                    </span>
                                )}
                            />
                        </>
                    );
                }}
            />
        </Box>
    );
};

export default DashboardInvitesRewards;
