import { useApi, UseApiOptions } from "src/hooks/useApi";
import { launchExample, LaunchType } from "src/types/launch";
import { ApiGetSingleParams, apiTestMode } from "./root";

export interface GetMintUnitsParams extends ApiGetSingleParams {}

export class UseLaunchesApi {
    useGetLaunches(candyMachineId: string, config?: UseApiOptions<LaunchType>) {
        return useApi<GetMintUnitsParams, LaunchType>({
            method: "GET",
            path: `/launches/${candyMachineId}`,
            test: {
                enable: apiTestMode,
                data: launchExample,
            },
            ...config,
        });
    }
}
