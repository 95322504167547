export type CampaignType = {
    id: number;
    campaign_tag: string;
    campaign_num: number;
    campaign_hash: string;
    min_fund_usd: number;
    max_fund_usd: number;
    step_fund_usd: number;
    withdraw_step_fund_usd: number;
    active_fund_usd: number;
    target_fund_usd: number;
    total_gain_usd: number;
    total_gain_percent: number;
    status: CampaignStatusType;
    full_funded: boolean;
    has_funded: boolean;
    has_ran: boolean;
    created_date: number;
    live_date?: number;
    ran_date?: number;
    canceled_date?: number;
    next_gain?: CampaignGainType;
    view_grant?: CampaignGrantType;
};

export type CampaignGrantType = {
    id: number;
    account_server: string;
    account_num: number;
    account_pass: string;
};

export type CampaignGainType = {
    id: number;
    gain_num: number;
    active_fund_usd: number;
    active_days?: number;
    pay_delayed_days?: number;
    pnl_usd?: number;
    pnl_percent?: number;
    gain_usd?: number;
    gain_percent?: number;
    paid_usd?: number;
    paid_percent?: number;
    fee_usd?: number;
    fee_percent?: number;
    reserve_usd?: number;
    reserve_percent?: number;
    ib_usd?: number;
    ib_percent?: number;
    status: 'pending' | 'closed' | 'paid';
    open_date: number;
    close_date?: number;
    pay_planned_date?: number;
    pay_executed_date?: number;
    campaign?: CampaignType;
};

export type CampaignStatType = {
    id: number;
    active_fund_usd: number;
    apr_percent: number;
    reserve_usd: number;
    total_campaigns: number;
    funded_campaigns: number;
    ran_campaigns: number;
    running_campaigns: number;
};

export type CampaignStatusType =
    | "funding"
    | "activating"
    | "running"
    | "paused"
    | "canceled";

export const fundingCampaignsExample: CampaignType[] = [
    {
        id: 23,
        campaign_tag: "S23",
        campaign_hash: 'X8nv9',
        campaign_num: 23,
        min_fund_usd: 1000,
        max_fund_usd: 5000,
        step_fund_usd: 1000,
        withdraw_step_fund_usd: 1000,
        active_fund_usd: 4000,
        target_fund_usd: 20000,
        total_gain_usd: 0,
        total_gain_percent: 0,
        status: "funding",
        has_funded: true,
        has_ran: false,
        full_funded: false,
        created_date: 1700183500,
        live_date: 1700183500,
    },
];

export const completedCampaignsExample: CampaignType[] = [
    {
        id: 22,
        campaign_tag: "S22",
        campaign_hash: 'sssv9',
        campaign_num: 22,
        min_fund_usd: 1000,
        max_fund_usd: 5000,
        step_fund_usd: 1000,
        withdraw_step_fund_usd: 1000,
        active_fund_usd: 20000,
        target_fund_usd: 20000,
        total_gain_usd: 0,
        total_gain_percent: 0,
        status: "activating",
        has_funded: true,
        full_funded: true,
        has_ran: false,
        created_date: 1700174600,
        live_date: 1700174600,
        ran_date: 1700175530,
    },
    {
        id: 21,
        campaign_tag: "S21",
        campaign_hash: 'x13v9',
        campaign_num: 21,
        min_fund_usd: 1000,
        max_fund_usd: 5000,
        step_fund_usd: 1000,
        withdraw_step_fund_usd: 1000,
        active_fund_usd: 20000,
        target_fund_usd: 20000,
        total_gain_usd: 170,
        total_gain_percent: 0.85,
        status: "running",
        has_funded: true,
        full_funded: true,
        has_ran: true,
        created_date: 1700163500,
        live_date: 1700163500,
        ran_date: 1700164530,
    },
    {
        id: 20,
        campaign_tag: "S20",
        campaign_hash: 'P0nv9',
        campaign_num: 20,
        min_fund_usd: 1000,
        max_fund_usd: 5000,
        step_fund_usd: 1000,
        withdraw_step_fund_usd: 1000,
        active_fund_usd: 20000,
        target_fund_usd: 20000,
        total_gain_usd: 310,
        total_gain_percent: 1.55,
        status: "running",
        has_funded: true,
        full_funded: true,
        has_ran: true,
        created_date: 1700163500,
        live_date: 1700153500,
        ran_date: 1700154530,
    },
    {
        id: 19,
        campaign_tag: "S19",
        campaign_hash: 'Z8nv0',
        campaign_num: 19,
        min_fund_usd: 1000,
        max_fund_usd: 5000,
        step_fund_usd: 1000,
        withdraw_step_fund_usd: 1000,
        active_fund_usd: 20000,
        target_fund_usd: 20000,
        total_gain_usd: 560,
        total_gain_percent: 2.8,
        status: "running",
        has_funded: true,
        full_funded: true,
        has_ran: true,
        created_date: 1700143500,
        live_date: 1700143500,
        ran_date: 1700144530,
    },
];
