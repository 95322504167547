import { FC } from "react";
import clsx from "clsx";
import Box from "src/libs/box/Box";
import styles from "./DashboardMint.module.scss";

type Props = {
    className?: string;
};

const DashboardMintGift: FC<Props> = ({ className }) => {
    return (
        <Box className={clsx(styles.giftWrap, className)}>
            <img className={styles.giftIcon} src="/assets/gift.png" />
            <div className={styles.giftTitleWrap}>
                <div className={styles.giftTitle}>
                    $88,888 Giveaway to 3 Lucky Minters
                </div>
                <div className={styles.giftSubTitle}>
                    At the end of mint event
                </div>
            </div>
        </Box>
    );
};

export default DashboardMintGift;
