import clsx from "clsx";
import moment from "moment";
import Column from "rc-table/lib/sugar/Column";
import { FC } from "react";
import { Api } from "../../api/root";
import Box from "../../libs/box/Box";
import { InvestGainType } from "../../types/invest";
import Plat from "../plat/Plat";
import styles from "./DashboardInvest.module.scss";

type Props = {
    className?: string;
};

const DashboardInvestGains: FC<Props> = ({ className }) => {
    const api = Api.invests().useGetInvestGains({
        perPage: 6,
    });

    return (
        <Box className={className}>
            <Plat
                className={clsx(className)}
                title="Gains"
                api={api}
                needAuth={true}
                minimal={{
                    enable: true,
                    dialogApi: api,
                    onDialogApiRequest: (params, options) => {
                        api.run(params, options);
                    },
                }}
                hideHeader={true}
                onApiRequest={(params, options) => {
                    api.run(params, options);
                }}
                renderTableColumns={() => {
                    return (
                        <>
                            <Column
                                title="Campaign"
                                dataIndex={[
                                    "campaign_gain",
                                    "campaign",
                                    "campaign_tag",
                                ]}
                                key="campaign_tag"
                            />
                            <Column
                                title="My Funds"
                                dataIndex={["funded_usd"]}
                                key="funded_usd"
                                render={(
                                    funded_usd,
                                    invest_gain_data: InvestGainType
                                ) => (
                                    <>
                                        <div>
                                            {`$${funded_usd?.toLocaleString()}`}
                                        </div>
                                        <div className={styles.cellSub}>
                                            {`from $${invest_gain_data?.campaign_gain?.active_fund_usd?.toLocaleString()}`}
                                        </div>
                                    </>
                                )}
                            />
                            <Column
                                title="Profit"
                                dataIndex={["campaign_gain", "gain_percent"]}
                                key="gain_percent"
                                render={(gain_percent) => (
                                    <span
                                        className={clsx(
                                            styles.cellMark,
                                            gain_percent > 0 &&
                                                styles.cellMarkSuccess,
                                            gain_percent < 0 &&
                                                styles.cellMarkFail
                                        )}
                                    >
                                        {`${gain_percent}%`}
                                    </span>
                                )}
                            />
                            <Column
                                title="My Gain"
                                dataIndex={["gain_usd"]}
                                key="gain_usd"
                                render={(
                                    gain_usd,
                                    invest_gain_data: InvestGainType
                                ) => (
                                    <>
                                        <div>
                                            {`$${gain_usd?.toLocaleString()}`}
                                        </div>
                                        <div className={styles.cellSub}>
                                            {`from $${invest_gain_data?.campaign_gain?.gain_usd?.toLocaleString()}`}
                                        </div>
                                    </>
                                )}
                            />
                            <Column
                                title="Date"
                                dataIndex={["created_date"]}
                                key="created_date"
                                render={(created_date) => (
                                    <span
                                        data-tip={
                                            created_date &&
                                            moment.unix(created_date).format()
                                        }
                                    >
                                        {created_date
                                            ? moment
                                                  .unix(created_date)
                                                  .fromNow()
                                            : "—"}
                                    </span>
                                )}
                            />
                        </>
                    );
                }}
            />
        </Box>
    );
};

export default DashboardInvestGains;
