import { useApi, UseApiOptions } from "src/hooks/useApi";
import { apiTestMode } from "./root";

export interface UpgradeToNextGradeParams {
    mint_unit_id: number;
}

export class UseGradesApi {
    useUpgradeToNextGrade(config?: UseApiOptions<{}>) {
        return useApi<UpgradeToNextGradeParams, {}>({
            method: "POST",
            path: "/grades/upgrade",
            needAuth: true,
            test: {
                enable: apiTestMode,
            },
            ...config,
        });
    }
}
