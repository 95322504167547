import { FC } from "react";
import clsx from "clsx";
import Box from "src/libs/box/Box";
import styles from "./Preview.module.scss";

type Props = {
    className?: string;
};

const PreviewImage: FC<Props> = ({ className }) => {
    return (
        <Box className={clsx(styles.imageWrap, className)}>
            <img src="/assets/crc-preview.png" />
        </Box>
    );
};

export default PreviewImage;
