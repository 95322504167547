import { FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import styles from "./Profile.module.scss";
import { UserShortType, UserInfoType } from "src/types/user";
import { FaUser } from "react-icons/fa";

type Props = {
    className?: string;
    userInfo: UserShortType | UserInfoType;
};

const fileBaseUrl = process.env.REACT_APP_FILE_URL;

const ProfileMini: FC<Props> = ({ className, userInfo }) => {
    return (
        <div className={clsx(styles.miniWrap, className)}>
            <div className={styles.miniAvatar}>
                {userInfo.avatar ? (
                    <img
                        src={`${fileBaseUrl}/${userInfo.avatar.download_key}-48@48.${userInfo.avatar.format}`}
                    />
                ) : (
                    <FaUser className={styles.icon} />
                )}
            </div>

            <div
                className={styles.miniName}
                data-tip={userInfo.sol_wallet_short}
            >
                {userInfo?.display_name || userInfo?.sol_wallet_short}
            </div>
        </div>
    );
};

export default ProfileMini;
