import { FC, useState } from "react";
import clsx from "clsx";
import { FaUserFriends } from "react-icons/fa";
import DashboardInvitesRewards from "./DashboardInvitesRewards";
import DashboardInvitesNetworks from "./DashboardInvitesNetworks";
import { RiGift2Fill } from "react-icons/ri";
import DashboardInvitesSummary from "./DashboardInvitesSummary";
import DashboardInvitesLeaders from "./DashboardInvitesLeaders";
import { MdLeaderboard } from "react-icons/md";
import DashboardInvitesGuide, {
    NetworkGuideTab,
} from "./DashboardInvitesGuide";
import styles from "./DashboardInvites.module.scss";

type Props = {
    className?: string;
};

const DashboardInvites: FC<Props> = ({ className }) => {
    const [openGuideTab, setOpenGuideTab] = useState<NetworkGuideTab | null>(
        null
    );

    return (
        <div className={clsx(styles.dashboard, className)}>
            <DashboardInvitesSummary
                className={styles.summarySection}
                onOpenGuide={() => setOpenGuideTab(NetworkGuideTab.CIRCLES)}
            />

            <div className={styles.sectionLabel}>
                <FaUserFriends className={styles.icon} />
                <div className={styles.titleWrap}>
                    <div className={styles.title}>Network Invites</div>
                    <div className={styles.titleSub}>
                        Users invited by you or your network
                    </div>
                </div>
            </div>
            <DashboardInvitesNetworks className={styles.mintsSection} />

            <div className={styles.sectionLabel}>
                <RiGift2Fill className={styles.icon} />
                <div className={styles.titleWrap}>
                    <div className={styles.title}>Network Rewards</div>
                    <div className={styles.titleSub}>
                        Rewards for the activities of your network circles
                    </div>
                </div>
            </div>
            <DashboardInvitesRewards className={styles.rewardsSection} />

            <div className={styles.sectionLabel}>
                <MdLeaderboard className={styles.icon} />
                <div className={styles.titleWrap}>
                    <div className={styles.title}>Network Leaderboard</div>
                    <div className={styles.titleSub}>
                        Top performers in network earnings cycle
                    </div>
                </div>
            </div>
            <DashboardInvitesLeaders
                className={styles.rewardsSection}
                onOpenGuide={() => setOpenGuideTab(NetworkGuideTab.LEADERBOARD)}
            />

            {openGuideTab && (
                <DashboardInvitesGuide
                    open={!!openGuideTab}
                    defaultTab={openGuideTab}
                    onClose={() => setOpenGuideTab(null)}
                />
            )}
        </div>
    );
};

export default DashboardInvites;
