import { useWallet } from "@solana/wallet-adapter-react";
import {
    FC,
    createContext,
    useContext,
    PropsWithChildren,
    useState,
    useEffect,
    useRef,
} from "react";
import { GenerateTokenResponse } from "../api/auth";
import { Api } from "../api/root";
import useAlert from "./useAlert";

const AuthContext = createContext<{
    getAccessToken(): Promise<string | null>;
}>({
    getAccessToken: async () => null,
});

const useAuth = () => useContext(AuthContext);

export interface AuthInfoType extends GenerateTokenResponse {
    public_key: string;
    created_at: number;
    expires_at: number;
}

interface AuthQueueItem {
    resolve: (value: string | null | PromiseLike<string | null>) => void;
}

export const AuthProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
    const wallet = useWallet();
    const [, setAlertState] = useAlert();
    const [fullConnected, setFullConnected] = useState<boolean>(false);
    const [authQueue, setAuthQueue] = useState<AuthQueueItem[]>([]);
    const abortControllerRef = useRef(new AbortController());

    const messageApi = Api.users().useCreateSignatureMessage();
    const tokenApi = Api.auth().useGenerateToken();
    const refreshApi = Api.auth().useRefreshToken();

    useEffect(() => {
        // set full connected
        setFullConnected(
            wallet.connected && !wallet.connecting && !wallet.disconnecting
        );

        // remove auth info from storage if not connected
        if (wallet.disconnecting) {
            removeAuthInfoFromStroage();
        }
    }, [wallet]);

    useEffect(() => {
        const processQueue = async () => {
            // return if queue list is empty
            if (authQueue.length == 0 || !fullConnected) return;

            // cancel ongoing requests when authQueue changes
            abortControllerRef.current.abort();
            abortControllerRef.current = new AbortController();

            // get auth info from storage
            let authInfo = getAuthInfoFromStorage();

            // generate token if null
            if (authInfo == null) {
                authInfo = await generateToken(abortControllerRef.current);
            }

            // refresh token if expired
            if (authInfo && Date.now() >= authInfo.expires_at * 1000) {
                authInfo = await refreshToken(abortControllerRef.current);
            }

            // disconnect and show error if failed
            if (authInfo == null) {
                wallet.disconnect();
                setAlertState({
                    open: true,
                    message: "Authentication expired! Please connect again.",
                    severity: "error",
                });
            }

            // notify all queue (if signed or failed)
            while (authQueue.length > 0) {
                const nextRequest = authQueue.shift();
                if (nextRequest) {
                    nextRequest.resolve(authInfo?.access_token || null);
                }
            }

            // empty the queue list
            setAuthQueue([]);
        };

        processQueue();

        return () => {
            abortControllerRef.current.abort();
        };
    }, [authQueue, fullConnected]);

    const generateToken = async (
        abortController: AbortController
    ): Promise<AuthInfoType | null> => {
        // return if not connected
        if (!fullConnected) return null;

        // try to generate token
        try {
            // generate sign message
            const result = await messageApi.run(
                {
                    sol_wallet: wallet.publicKey?.toString()!,
                },
                { signal: abortController.signal }
            );

            const seed = result.seed;
            const message = result.message;

            // sign the message
            const encodedMessage = new TextEncoder().encode(message);
            const signature = await wallet.signMessage!(encodedMessage);
            const signatureBase64 = Buffer.from(signature).toString("base64");

            // generate auth info
            const authResponse = await tokenApi.run(
                {
                    grant_type: "password",
                    username: wallet.publicKey?.toString()!,
                    password: `@token_wallet__${signatureBase64}__${seed}`,
                    client_id: "user_login",
                },
                { signal: abortController.signal }
            );

            const authInfo = createAuthInfoFromResponse(authResponse);

            // save in storage
            localStorage.setItem("authInfo", JSON.stringify(authInfo));

            // return auth info
            return authInfo;
        } catch (e: any) {
            return null;
        }
    };

    const refreshToken = async (
        abortController: AbortController
    ): Promise<AuthInfoType | null> => {
        // return if not connected
        if (!fullConnected) return null;

        // try to refresh token
        try {
            // get auth info from storage
            let authInfo = getAuthInfoFromStorage();

            // return if null
            if (!authInfo) return null;

            // refresh token
            const authResponse = await refreshApi.run(
                {
                    grant_type: "refresh_token",
                    refresh_token: authInfo.refresh_token,
                    client_id: "user_login",
                },
                {
                    signal: abortController.signal,
                }
            );

            authInfo = createAuthInfoFromResponse(authResponse);

            // save in storage
            localStorage.setItem("authInfo", JSON.stringify(authInfo));

            // return auth info
            return authInfo;
        } catch (e: any) {
            return null;
        }
    };

    const getAccessToken = async () => {
        return new Promise<string | null>((resolve) => {
            setAuthQueue((prevAuthQueue) => [...prevAuthQueue, { resolve }]);
        });
    };

    const createAuthInfoFromResponse = (
        authResponse: GenerateTokenResponse
    ) => {
        const nowDelayed = Date.now() / 1000 - 60;

        return {
            ...authResponse,
            public_key: wallet.publicKey?.toString()!,
            created_at: nowDelayed,
            expires_at: nowDelayed + authResponse.expires_in,
        };
    };

    const getAuthInfoFromStorage = (): AuthInfoType | null => {
        const authInfoString = localStorage.getItem("authInfo") || null;
        if (authInfoString != null) {
            const authInfo = JSON.parse(authInfoString);

            if (authInfo.public_key == wallet.publicKey?.toString()) {
                return authInfo;
            }
        }

        return null;
    };

    const removeAuthInfoFromStroage = () => {
        localStorage.removeItem("authInfo");
    };

    return (
        <AuthContext.Provider
            value={{
                getAccessToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default useAuth;
