import { CampaignGainType, CampaignType } from "./campaign";
import { ApiSubItems } from "./general";

export type InvestType = {
    id: number;
    active_fund_usd: number;
    pending_fund_usd: number;
    total_income_usd: number;
    last_funded_date?: number;
    last_gained_date?: number;
    funds?: ApiSubItems<InvestFundType>;
};

export type InvestFundType = {
    id: number;
    funded_usd: number;
    funding_usd: number;
    withdrawing_usd: number;
    total_income_usd: number;
    is_funded: boolean;
    is_funding: boolean;
    has_failed_fund: boolean;
    created_date: number;
    last_entry_date?: number;
    last_exit_date?: number;
    last_gained_date?: number;
    campaign: CampaignType;
};

export type InvestFundTransType = {
    id: number;
    type: InvestFundTransTypeType;
    transaction_usd: number;
    penalty_usd: number;
    is_completed: boolean;
    created_date: number;
    completed_date?: number;
};

export type InvestGainType = {
    id: number;
    funded_usd: number;
    gain_usd: number;
    share_percent: number;
    created_date: number;
    campaign_gain: CampaignGainType;
};

export type InvestFundTransTypeType = "deposit" | "withdraw";

export const investFundTransExample: InvestFundTransType[] = [
    {
        id: 3,
        type: "deposit",
        transaction_usd: 3000,
        penalty_usd: 0,
        is_completed: false,
        created_date: 1700183500,
    },
    {
        id: 2,
        type: "withdraw",
        transaction_usd: 1000,
        penalty_usd: 0,
        is_completed: true,
        created_date: 1700172600,
        completed_date: 1700172700,
    },
    {
        id: 1,
        type: "deposit",
        transaction_usd: 2000,
        penalty_usd: 0,
        is_completed: true,
        created_date: 1700161605,
        completed_date: 1700162600,
    },
];

export const investGainExample: InvestGainType[] = [
    {
        id: 4,
        funded_usd: 3000,
        gain_usd: 271,
        share_percent: 15,
        created_date: 1700191605,
        campaign_gain: {
            id: 4,
            active_fund_usd: 20000,
            gain_num: 4,
            gain_usd: 1751,
            gain_percent: 9.26,
            open_date: 1700191605,
            status: 'paid',
            campaign: {
                id: 14,
                campaign_tag: "S14",
                campaign_hash: 'sssv9',
                campaign_num: 14,
                min_fund_usd: 1000,
                max_fund_usd: 5000,
                step_fund_usd: 1000,
                withdraw_step_fund_usd: 1000,
                active_fund_usd: 20000,
                target_fund_usd: 20000,
                total_gain_usd: 2952,
                total_gain_percent: 14.76,
                status: "running",
                has_funded: true,
                full_funded: true,
                has_ran: true,
                created_date: 1700153500,
                live_date: 1700153500,
                ran_date: 1700155530,
                view_grant: {
                    id: 2,
                    account_server: "ShootBoom",
                    account_num: 51849245,
                    account_pass: "Bpn00BB-azorRR",
                },
            },
        },
    },
    {
        id: 3,
        funded_usd: 3000,
        gain_usd: 119,
        share_percent: 15,
        created_date: 1700181605,
        campaign_gain: {
            id: 3,
            active_fund_usd: 20000,
            gain_num: 3,
            gain_usd: 1112,
            gain_percent: 5.99,
            status: 'paid',
            open_date: 1700181605,
            campaign: {
                id: 14,
                campaign_tag: "S14",
                campaign_hash: 'x13v9',
                campaign_num: 14,
                min_fund_usd: 1000,
                max_fund_usd: 5000,
                step_fund_usd: 1000,
                withdraw_step_fund_usd: 1000,
                active_fund_usd: 20000,
                target_fund_usd: 20000,
                total_gain_usd: 2952,
                total_gain_percent: 14.76,
                status: "running",
                has_funded: true,
                full_funded: true,
                has_ran: true,
                created_date: 1700153500,
                live_date: 1700153500,
                ran_date: 1700155530,
                view_grant: {
                    id: 2,
                    account_server: "ShootBoom",
                    account_num: 51849245,
                    account_pass: "Bpn00BB-azorRR",
                },
            },
        },
    },
    {
        id: 2,
        funded_usd: 3000,
        gain_usd: 222,
        share_percent: 15,
        created_date: 1700171605,
        campaign_gain: {
            id: 2,
            active_fund_usd: 20000,
            gain_num: 2,
            gain_usd: 1551,
            gain_percent: 8.12,
            status: 'paid',
            open_date: 1700171605,
            campaign: {
                id: 14,
                campaign_tag: "S14",
                campaign_hash: 'P0nv9',
                campaign_num: 14,
                min_fund_usd: 1000,
                max_fund_usd: 5000,
                step_fund_usd: 1000,
                withdraw_step_fund_usd: 1000,
                active_fund_usd: 20000,
                target_fund_usd: 20000,
                total_gain_usd: 2952,
                total_gain_percent: 14.76,
                status: "running",
                has_funded: true,
                full_funded: true,
                has_ran: true,
                created_date: 1700153500,
                live_date: 1700153500,
                ran_date: 1700155530,
                view_grant: {
                    id: 2,
                    account_server: "ShootBoom",
                    account_num: 51849245,
                    account_pass: "Bpn00BB-azorRR",
                },
            },
        },
    },
    {
        id: 1,
        funded_usd: 3000,
        gain_usd: 143,
        share_percent: 15,
        created_date: 1700161605,
        campaign_gain: {
            id: 1,
            active_fund_usd: 20000,
            gain_num: 1,
            gain_usd: 1215,
            gain_percent: 6.52,
            status: 'paid',
            open_date: 1700161605,
            campaign: {
                id: 14,
                campaign_tag: "S14",
                campaign_hash: 'Z8nv0',
                campaign_num: 14,
                min_fund_usd: 1000,
                max_fund_usd: 5000,
                step_fund_usd: 1000,
                withdraw_step_fund_usd: 1000,
                active_fund_usd: 20000,
                target_fund_usd: 20000,
                total_gain_usd: 2952,
                total_gain_percent: 14.76,
                status: "running",
                has_funded: true,
                full_funded: true,
                has_ran: true,
                created_date: 1700153500,
                live_date: 1700153500,
                ran_date: 1700155530,
                view_grant: {
                    id: 2,
                    account_server: "ShootBoom",
                    account_num: 51849245,
                    account_pass: "Bpn00BB-azorRR",
                },
            },
        },
    },
];
