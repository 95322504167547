export const subtractDiscount = (
    price: number,
    discountAmount?: number,
    discountPercent?: number
) => {
    if (discountPercent && discountPercent != 0) {
        price = price * (1 - discountPercent / 100);
    }
    if (discountAmount && discountAmount != 0) {
        price = price - discountAmount;
    }
    return price;
};

export const calculateDiscountAmount = (
    price: number,
    discountAmount?: number,
    discountPercent?: number
) => {
    let discount = 0;
    if (discountPercent && discountPercent != 0) {
        discount += (price * discountPercent) / 100;
    }
    if (discountAmount && discountAmount != 0) {
        discount += discountAmount;
    }
    return discount;
};

export const convertPrice = (
    sourcePrice: number,
    sourceRate: number,
    targetRate: number
) => {
    return sourcePrice * (sourceRate / targetRate);
};

export const roundPrice = (
    price: number,
    minDecimals: number = 2,
    maxDecimals?: number
): string => {
    let roundedPrice: string = "0.00";

    if (price != 0) {
        let decimals = -1 * Math.log10(price) + 5; // get only 5 non zero digits + decimals

        if (minDecimals != undefined && decimals < minDecimals) {
            decimals = minDecimals;
        }

        if (maxDecimals != undefined && decimals > maxDecimals) {
            decimals = maxDecimals;
        }

        roundedPrice = price.toFixed(decimals); // remove extra decimals
        roundedPrice = parseFloat(roundedPrice).toLocaleString("en-US", {
            maximumFractionDigits: maxDecimals,
            minimumFractionDigits: minDecimals,
        });
    }

    return roundedPrice;
};
