import {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from "react";
import { Api } from "src/api/root";
import { ConnectionPlatformType } from "../types/connection";
import useSettings from "./useSettings";
import useUserInfo from "./useUserInfo";
import { FaDiscord } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";

export type PortalRequestSourceType = "profile" | "activate_network";

type PortalInfoType = {
    request_source: PortalRequestSourceType;
    platform: ConnectionPlatformType;
    code?: string;
    state?: string;
    has_redirected?: boolean;
};

const ConnectionPortalContext = createContext<{
    pendingPortalInfo: PortalInfoType | null;
    startConnection(
        request_source: PortalRequestSourceType,
        platform: ConnectionPlatformType
    ): void;
    connectToPendingPlatform(): Promise<boolean | undefined>;
    disconnectFromPlatform(platform: ConnectionPlatformType): Promise<void>;
    updatePlatformUserInfo(platform: ConnectionPlatformType): Promise<void>;
}>({
    pendingPortalInfo: null,
    startConnection: () => {},
    connectToPendingPlatform: async () => false,
    disconnectFromPlatform: async () => {},
    updatePlatformUserInfo: async () => {},
});

const useConnectionPortal = () => useContext(ConnectionPortalContext);

const devMode = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const platformInfo = {
    discord: {
        title: "Discord",
        icon: FaDiscord,
        channel_key: "Server",
        channel_url: "https://discord.gg/eeyTvXgMAq",
        auto_update: true,
    },
    twitter: {
        title: "X",
        icon: BsTwitter,
        channel_key: "Page",
        channel_url: "https://twitter.com/BlackBone_io",
        auto_update: false,
    },
};

export const ConnectionPortalProvider: FC<PropsWithChildren<{}>> = ({
    children,
}) => {
    const { settings } = useSettings();
    const { updateUserInfo } = useUserInfo();
    const [pendingPortalInfo, setPendingPortalInfo] =
        useState<PortalInfoType | null>(null);

    const initApi = Api.connections().useInitConnection();
    const connectApi = Api.connections().useConnectToPlatform();
    const disconnectApi = Api.connections().useDisconnectFromPlatform();
    const updateInfoApi = Api.connections().useUpdateConnectionUserData();

    useEffect(() => {
        savePendingPortalOnStorage();
        setPendingPortalInfo(getPendingPortalFromStorage());
    }, []);

    const savePendingPortalOnStorage = () => {
        // prepare search params
        const searchParams = new URLSearchParams(window.location.search);

        // add if has query params
        if (searchParams.has("platform")) {
            const portalInfo: PortalInfoType = getPendingPortalFromStorage();

            // add if match with storage
            if (
                portalInfo &&
                portalInfo.platform == searchParams.get("platform")
            ) {
                // update portal info
                portalInfo.code = searchParams.get("code") || undefined;
                portalInfo.state = searchParams.get("state") || undefined;
                portalInfo.has_redirected = true;

                // update storage
                localStorage.setItem("portalInfo", JSON.stringify(portalInfo));

                // remove params from url
                if (window.history.replaceState) {
                    window.history.replaceState({}, "", "/");
                } else {
                    window.history.pushState({}, "", "/");
                }
            }
        }
    };

    const getPendingPortalFromStorage = () => {
        const portalInfoText = localStorage.getItem("portalInfo");
        if (portalInfoText != null && portalInfoText.length > 0) {
            return JSON.parse(portalInfoText);
        } else {
            return null;
        }
    };

    const startConnection = async (
        request_source: PortalRequestSourceType,
        platform: ConnectionPlatformType
    ) => {
        if (!settings) return;

        // prepare portal info
        const portalInfo: PortalInfoType = {
            request_source,
            platform,
        };

        // set pending portal info
        localStorage.setItem("portalInfo", JSON.stringify(portalInfo));
        setPendingPortalInfo(portalInfo);

        // init connection
        const result = await initApi.run({
            launch_id: settings.launch.id,
            platform,
        });

        // open auth url
        if (result) {
            window.open(result.auth_url, "_self");
        }
    };

    const connectToPendingPlatform = async () => {
        if (!settings || !pendingPortalInfo?.code) return;

        const result = await connectApi.run({
            launch_id: settings.launch.id,
            platform: pendingPortalInfo.platform,
            code: pendingPortalInfo.code,
            state: pendingPortalInfo.state,
        });

        const isConnected = result.is_connected;
        if (isConnected) {
            await updateUserInfo();
        }

        localStorage.removeItem("portalInfo");
        setPendingPortalInfo(null);

        return isConnected;
    };

    const disconnectFromPlatform = async (platform: ConnectionPlatformType) => {
        if (!settings) return;

        await disconnectApi.run({
            launch_id: settings.launch.id,
            platform,
        });

        await updateUserInfo();
    };

    const updatePlatformUserInfo = async (platform: ConnectionPlatformType) => {
        if (!settings) return;

        await updateInfoApi.run({
            launch_id: settings.launch.id,
            platform,
        });

        await updateUserInfo();
    };

    return (
        <ConnectionPortalContext.Provider
            value={{
                pendingPortalInfo,
                startConnection,
                connectToPendingPlatform,
                disconnectFromPlatform,
                updatePlatformUserInfo,
            }}
        >
            {children}
        </ConnectionPortalContext.Provider>
    );
};

export default useConnectionPortal;
