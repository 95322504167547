import { FC, ReactNode } from "react";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import { randomRange } from "../../services/utils";
import styles from "./Pair.module.scss";

type Props = {
    className?: string;
    title?: ReactNode;
    titlePosition?: "top" | "bottom" | "start" | "end"; // default: top
    titleClassname?: string;
    value?: ReactNode;
    valueLoading?: boolean;
    valueClassname?: string;
};

const Pair: FC<Props> = ({
    className,
    title,
    titlePosition,
    titleClassname,
    value,
    valueLoading,
    valueClassname,
}) => {
    return (
        <div
            className={clsx(
                (!titlePosition || titlePosition == "top") &&
                    styles.pairTopWrap,
                titlePosition == "bottom" && styles.pairWrapBottom,
                titlePosition == "start" && styles.pairWrapStart,
                titlePosition == "end" && styles.pairWrapEnd,
                className
            )}
        >
            {title != undefined && (
                <div className={clsx(styles.pairTitle, titleClassname)}>
                    {title}
                </div>
            )}

            <div className={clsx(styles.pairValue, valueClassname)}>
                {valueLoading && <Skeleton width={randomRange(45, 55)} />}
                {value}
            </div>
        </div>
    );
};

export default Pair;
