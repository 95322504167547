import { useApi, UseApiOptions } from "src/hooks/useApi";
import { LeaderTagType, leaderUsersExample, LeaderUserType } from "src/types/leader";
import { ApiGetGroupParams, apiTestMode } from "./root";

export interface GetLeaderUsersParams extends ApiGetGroupParams {
    launch_id: number;
    leader_tag: LeaderTagType;
}

export class UseLeadersApi {
    useGetLeaderUsers(config?: UseApiOptions<LeaderUserType[]>) {
        return useApi<GetLeaderUsersParams, LeaderUserType[]>({
            method: "GET",
            path: "/leaders/users",
            isList: true,
            test: {
                enable: apiTestMode,
                data: leaderUsersExample,
            },
            ...config,
        });
    }
}
