import { FC, ReactNode } from "react";
import clsx from "clsx";
import { roundPrice } from "../../services/price";
import { CoinType } from "../../types/coin";
import { FiatType } from "../../types/fiat";
import styles from "./Price.module.scss";

type Props = {
    className?: string;
    targetFiat?: FiatType;
    targetCoin?: CoinType;
    price: number;
    suffix?: ReactNode;
    isCrossedOut?: boolean;
    normalSizeSymbol?: boolean;
};

const PriceUnit: FC<Props> = ({
    className,
    price,
    targetFiat,
    targetCoin,
    suffix,
    isCrossedOut,
    normalSizeSymbol,
}) => {
    const getFixedPrice = () => {
        if (price == 0) return 0;

        const maxDecimal = targetFiat ? 2 : 9; // TODO: change to currency max decimal
        return roundPrice(Math.abs(price), 2, maxDecimal);
    };

    return (
        <div
            className={clsx(
                styles.priceUnit,
                isCrossedOut && styles.priceUnitCrossedOut,
                className
            )}
        >
            <span
                className={clsx(
                    styles.price,
                    price < 0 && styles.priceNegative
                )}
            >
                {price < 0 && "—"}
                {targetFiat?.sign}
                {getFixedPrice()}
            </span>

            {targetCoin && (
                <span
                    className={clsx(
                        styles.priceSymbol,
                        !normalSizeSymbol && styles.priceSymbolSub
                    )}
                >
                    {targetCoin.symbol}
                </span>
            )}
            {suffix && <span className={styles.priceSuffix}>{suffix}</span>}
        </div>
    );
};

export default PriceUnit;
