import { FC, PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./State.module.scss";

export enum StateType {
    DONE = "done",
    EMPTY_INBOX = "empty-inbox",
    ERROR = "error",
    NO_CONNECTION = "no-connection",
    NO_CREDIT_CARD = "no-credit-card",
    NO_DOCUMENTS = "no-documents",
    NO_GPS = "no-gps",
    NO_IMAGES = "no-images",
    NO_ITEMS_CART = "no-items-cart",
    NO_MESSAGES = "no-messages",
    NO_SEARCH_RESULTS = "no-search-results",
    NO_TASKS = "no-tasks",
}

type Props = {
    className?: string;
    type: StateType;
};

const State: FC<PropsWithChildren<Props>> = ({ className, children, type }) => {
    return (
        <div className={clsx(styles.stateWrap, className)}>
            <img className={styles.icon} src={`/assets/states/${type}.png`} />
            <div className={styles.message}>{children}</div>
        </div>
    );
};

export default State;
