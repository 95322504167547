import { FC, useMemo, useState } from "react";
import clsx from "clsx";
import Dialog from "../../libs/dialog/Dialog";
import { MintUnitType } from "../../types/mint";
import Feature from "../../libs/feature/Feature";
import Pair from "../../libs/pair/Pair";
import FormButton from "../../libs/form-button/FormButton";
import { gradeTitles, GradeType } from "../../types/grade";
import { Api } from "../../api/root";
import useAlert from "../../hooks/useAlert";
import Alert from "../../libs/alert/Alert";
import useUserInfo from "../../hooks/useUserInfo";
import styles from "./DashboardNfts.module.scss";

type Props = {
    className?: string;
    nft: MintUnitType;
    nextAvailableGrade: GradeType;
    open: boolean;
    onClose(reload?: boolean): void;
};

type GradePointInfoType = {
    key: "req_invite_points" | "req_network_points";
    title: string;
    decreaseTooTitles?: string[];
    mayDecreaseTooTitles?: string[];
};

type decreaseGradePointType = {
    info: GradePointInfoType;
    decrease: number;
};

const gradePointsInfoInOrder: GradePointInfoType[] = [
    {
        key: "req_invite_points",
        title: "Invite Points",
        decreaseTooTitles: ["Network Points"],
    },
    {
        key: "req_network_points",
        title: "Network Points",
        mayDecreaseTooTitles: ["Invite Points"],
    },
];

const DashboardNftsItemUpgrade: FC<Props> = ({
    className,
    nft,
    nextAvailableGrade,
    open,
    onClose,
}) => {
    const [, setAlertState] = useAlert();
    const [upgradeLoading, setUpgradeLoading] = useState<boolean>(false);
    const { updateUserInfo } = useUserInfo();
    const upgradeApi = Api.grades().useUpgradeToNextGrade();

    const decreaseGradePoints: decreaseGradePointType | null = useMemo(() => {
        const gradePointInfo = gradePointsInfoInOrder.find(
            (gradePointInfo) => nextAvailableGrade[gradePointInfo.key] || 0 > 0
        );

        if (gradePointInfo) {
            return {
                info: gradePointInfo,
                decrease: nextAvailableGrade[gradePointInfo.key] as number,
            };
        } else {
            return null;
        }
    }, []);

    const handleUpgrade = async () => {
        // set loading as true
        setUpgradeLoading(true);

        // do upgrade
        upgradeApi
            .run({ mint_unit_id: nft.id })
            .then(async () => {
                // update user info
                await updateUserInfo();

                // finish loading
                setUpgradeLoading(false);

                // show success message
                setAlertState({
                    open: true,
                    message:
                        "The NFT upgraded! Your NFT's metadata will update soon.",
                    severity: "success",
                });

                // close dialog (after a little bit)
                setTimeout(() => {
                    onClose(true);
                }, 500);
            })
            .catch(() => {
                setUpgradeLoading(false);
            });
    };

    return (
        <Dialog
            className={clsx(styles.dialog, className)}
            onClose={onClose}
            open={open}
            maxWidth="xs"
            boxProps={{
                title: `Upgrade ${nft.meta_data?.name}`,
            }}
        >
            {decreaseGradePoints && (
                <Alert severity="info" className={styles.upgradeAlert}>
                    {`This action will subtract ${
                        decreaseGradePoints.decrease
                    } ${decreaseGradePoints.info.title}!${
                        decreaseGradePoints.info.decreaseTooTitles
                            ? ` It will affect ${decreaseGradePoints.info.decreaseTooTitles} too.`
                            : ""
                    }${
                        decreaseGradePoints.info.mayDecreaseTooTitles
                            ? ` It might affect ${decreaseGradePoints.info.mayDecreaseTooTitles} too.`
                            : ""
                    }`}
                </Alert>
            )}

            <div className={styles.upgradeDescription}>
                You are about to upgrade your NFT&apos;s metadata. The
                NFT&apos;s grade will be passed on when you sell it on secondary
                market.
            </div>

            <Feature className={styles.upgradeTo}>
                <Pair
                    title="Upgrade To"
                    titlePosition="start"
                    value={gradeTitles[nextAvailableGrade.tag]}
                />
            </Feature>

            <div className={styles.upgradeButtons}>
                <FormButton
                    className={styles.cancelUpgradeButton}
                    color="base"
                    onClick={onClose}
                >
                    Cancel
                </FormButton>
                <FormButton
                    className={styles.doUpgradeButton}
                    loading={upgradeLoading}
                    onClick={handleUpgrade}
                >
                    Upgrade
                </FormButton>
            </div>
        </Dialog>
    );
};

export default DashboardNftsItemUpgrade;
