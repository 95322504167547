import { useReducer, useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { ApiOptions } from "src/api/root";
import {
    appendParamsToUrl,
    fixApiParams,
    testApiWithExample,
} from "src/services/api";
import useAlert from "./useAlert";
import { randomRange, sleep } from "../services/utils";
import useAuth from "./useAuth";

export type UseApiOptions<ApiData> = {
    method?: "GET" | "POST" | "PUT" | "DELETE";
    path?: string;
    url?: string;
    needAuth?: boolean;
    isList?: boolean;
    perPage?: number;
    test?: {
        enable: boolean;
        data?: ApiData;
    };
};

export type UseApi<ApiParams, ApiData> = {
    state: UseApiState<ApiData>;
    dispatch(action: UseApiAction<ApiData>): void;
    run(params: ApiParams, options?: ApiOptions): Promise<ApiData>;
    abort(): void;
};

export type UseApiState<ApiData> = {
    pageNum?: number;
    perPage?: number;
    totalNum?: number;
    query?: string;
    data?: ApiData;
    loading?: boolean;
    loadingItems?: any[];
    failed?: boolean;
};

export type UseApiAction<ApiData> = {
    type: UseApiActionType;
    newState?: UseApiState<ApiData>;
};

export enum UseApiActionType {
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_ERROR,
    STATE_RESET,
    STATE_UPDATE,
}

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export function useApi<ApiParams, ApiData>(
    config: UseApiOptions<ApiData>
): UseApi<ApiParams, ApiData> {
    const wallet = useWallet();
    const { getAccessToken } = useAuth();
    const [, setAlertState] = useAlert();

    const initialState: UseApiState<ApiData> = {
        pageNum: 1,
        perPage: config?.perPage || 10,
        totalNum: 0,
        loading: false,
        failed: false,
    };

    const [state, dispatch] = useReducer(
        (state: any, action: UseApiAction<ApiData>) => {
            switch (action.type) {
                case UseApiActionType.FETCH_START:
                    return {
                        ...state,
                        data: null,
                        loading: true,
                        loadingItems: Array.from(
                            Array(state.perPage),
                            (_, i) => {
                                return { id: i + 1 };
                            }
                        ),
                        failed: false,
                    };
                case UseApiActionType.FETCH_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        loadingItems: null,
                        data: action.newState?.data,
                        totalNum: action.newState?.totalNum,
                    };
                case UseApiActionType.FETCH_ERROR:
                    return {
                        ...state,
                        loading: false,
                        loadingItems: null,
                        failed: true,
                    };
                case UseApiActionType.STATE_RESET:
                    return {
                        ...initialState,
                    };
                case UseApiActionType.STATE_UPDATE:
                    return {
                        ...state,
                        ...action.newState,
                    };
                default:
                    return state;
            }
        },
        initialState
    );

    let abortController = new AbortController();
    const run = async (
        params: ApiParams,
        options?: ApiOptions
    ): Promise<ApiData> => {
        return new Promise((resolve, reject) => {
            (async () => {
                const fullConnected =
                    wallet.connected &&
                    !wallet.connecting &&
                    !wallet.disconnecting &&
                    wallet.publicKey != null;

                if (!config.needAuth || fullConnected) {
                    // dispatch fetch start if is not running in background
                    if (!options?.runInBackground) {
                        dispatch({ type: UseApiActionType.FETCH_START });
                    }

                    // get access token if need auth
                    let accessToken = null;
                    if (config.needAuth) {
                        accessToken = await getAccessToken();

                        if (!accessToken) {
                            reject();
                            return;
                        }
                    }

                    // return if aborted
                    if (abortController.signal.aborted) {
                        reject();
                        return;
                    }

                    // prepare options
                    const httpOptions: ApiOptions = {
                        signal: abortController.signal,
                        headers: {
                            "Content-Type": "application/json",
                            ...(config.needAuth
                                ? {
                                      WALLET: wallet.publicKey?.toString(),
                                      Authorization: `Bearer ${accessToken}`,
                                  }
                                : {}),
                        },
                        ...options,
                    };

                    // resolve if fake loading
                    if (httpOptions.fakeLoading) {
                        return;
                    }

                    // prepare params
                    let defaultParams: any = {};
                    if (config.isList) {
                        defaultParams = {
                            limit: state.perPage,
                            offset: {
                                from: (state.pageNum - 1) * state.perPage,
                            },
                            get_total: true,
                        };
                    }

                    // add query to params
                    if (state.query && state.query.trim().length > 0) {
                        defaultParams.q = state.query.trim();
                    }

                    const httpParams = {
                        ...defaultParams,
                        ...fixApiParams(params),
                    };

                    // run url or test
                    let response;
                    if (!config.test?.enable) {
                        const url = config.path
                            ? apiBaseUrl + config.path
                            : config.url;

                        if (url) {
                            response = await runHttp(
                                config.method!,
                                url,
                                httpParams,
                                httpOptions
                            );
                        } else {
                            // TODO: show message for incorrect url
                            reject();
                        }
                    } else {
                        response = await runTest(
                            config.test?.data,
                            httpParams,
                            httpOptions
                        );
                    }

                    // check response
                    if (response) {
                        if (response.success) {
                            if (
                                Object.hasOwn(response.result || {}, "items") &&
                                Object.hasOwn(response.result || {}, "count")
                            ) {
                                dispatch({
                                    type: UseApiActionType.FETCH_SUCCESS,
                                    newState: {
                                        data: response.result.items,
                                        totalNum: response.result.total,
                                    },
                                });
                                resolve(response.result.items);
                            } else {
                                dispatch({
                                    type: UseApiActionType.FETCH_SUCCESS,
                                    newState: {
                                        data: response.result,
                                    },
                                });
                                resolve(response.result);
                            }
                        } else {
                            if (!httpOptions.runInBackground) {
                                dispatch({
                                    type: UseApiActionType.FETCH_ERROR,
                                });
                                setAlertState({
                                    open: true,
                                    message: response.error.message,
                                    severity: "error",
                                });
                            }

                            reject();
                        }
                    }
                } else {
                    dispatch({ type: UseApiActionType.STATE_RESET });
                    reject();
                }
            })();
        });
    };

    const runHttp = async (
        method: string,
        url: string,
        params?: any,
        options?: ApiOptions
    ) => {
        await sleep(randomRange(1, 50)); // to prevent coliding multiple requests at the same time

        let body = undefined;
        if (method == "GET" || method == "DELETE") {
            if (params && Object.keys(params).length > 0) {
                url = appendParamsToUrl(url, params);
            }
        } else {
            body = JSON.stringify(params);
        }

        return fetch(url, {
            method,
            body,
            headers: options?.headers as any,
            signal: options?.signal,
        }).then((response) => response.json());
    };

    const runTest = (
        exampleResponse: any,
        params?: any,
        options?: ApiOptions
    ): Promise<any> => {
        return testApiWithExample(exampleResponse, {
            limit: params?.limit,
            offset: params?.offset?.from,
            signal: options?.signal,
        }) as any;
    };

    const abort = () => {
        abortController.abort();
        abortController = new AbortController();
    };

    return {
        state: state as UseApiState<ApiData>,
        dispatch,
        run,
        abort,
    };
}
