import { FC, ReactNode } from "react";
import Box from "../../libs/box/Box";
import DashboardInvestFundsItem from "./DashboardInvestFundsItem";
import useUserInfo from "../../hooks/useUserInfo";
import Feature from "../../libs/feature/Feature";
import State, { StateType } from "../../libs/state/State";
import WalletConnectText from "../wallet-connect/WalletConnectText";
import Pair from "../../libs/pair/Pair";
import Skeleton from "react-loading-skeleton";
import styles from "./DashboardInvest.module.scss";

type Props = {
    className?: string;
};

const DashboardInvestFunds: FC<Props> = ({ className }) => {
    const { userInfo, loadingUserInfo, fullConnected } = useUserInfo();

    const renderUserInfo = (content: ReactNode) => {
        return fullConnected ? (
            !loadingUserInfo ? (
                content
            ) : (
                <Skeleton width={40} />
            )
        ) : (
            "—"
        );
    };

    return (
        <Box className={className}>
            <div className={styles.fundsOverview}>
                <Feature className={styles.fundsOverviewItem}>
                    <Pair
                        title="Total Funds"
                        titlePosition="bottom"
                        value={renderUserInfo(
                            `$${(
                                (userInfo?.invest_info?.pending_fund_usd || 0) +
                                (userInfo?.invest_info?.active_fund_usd || 0)
                            ).toLocaleString()}`
                        )}
                    />
                </Feature>

                <Feature className={styles.fundsOverviewItem}>
                    <Pair
                        title="Total Gains"
                        titlePosition="bottom"
                        value={renderUserInfo(
                            `$${
                                userInfo?.invest_info?.total_income_usd.toLocaleString() ||
                                0
                            }`
                        )}
                    />
                </Feature>

                <Feature className={styles.fundsOverviewItem}>
                    <Pair
                        title="Invite Share"
                        titlePosition="bottom"
                        value={renderUserInfo(
                            userInfo?.grades_info.highest_grade
                                ? `${userInfo?.grades_info.highest_grade?.invest_commission_percent}%`
                                : "N/A"
                        )}
                    />
                </Feature>
            </div>

            {fullConnected ? (
                loadingUserInfo ? (
                    Array.from(Array(2).keys()).map((_, loadingIndex) => {
                        return (
                            <DashboardInvestFundsItem
                                className={styles.fundItem}
                                key={loadingIndex}
                                loading={true}
                            />
                        );
                    })
                ) : userInfo?.invest_info?.funds ? (
                    userInfo.invest_info.funds.items.map((fundItem) => {
                        return (
                            <DashboardInvestFundsItem
                                className={styles.fundItem}
                                key={fundItem.id}
                                investFund={fundItem}
                            />
                        );
                    })
                ) : (
                    <Feature type="message">
                        <State type={StateType.NO_DOCUMENTS}>
                            No funds yet!
                        </State>
                    </Feature>
                )
            ) : (
                <Feature type="message">
                    <WalletConnectText>Connect Wallet</WalletConnectText> to see
                    your funds
                </Feature>
            )}
        </Box>
    );
};

export default DashboardInvestFunds;
