import { useApi, UseApiOptions } from "../hooks/useApi";
import { ApiGetGroupParams, ApiGetSingleParams, apiTestMode } from "./root";
import {
    ShopPaymentType,
    shopPaymentsExample,
    initializeShopPaymentExample,
    shopDiscountsExample,
    reviewShopPaymentExample,
} from "../types/shop";

export interface GetShopPaymentsParams extends ApiGetGroupParams {
    coin_id?: number;
    shop_order_id?: number;
    status?: "paying" | "pay_timed_out" | "reviewing" | "approved" | "rejected";
}

export type InitializeShopPaymentParams = {
    client_currency_id: number;
    associated_with: string;
    cart_items?: {
        associated_id: number;
        quantity: number;
        vars?: {
            shop_attr_id: number;
            shop_attr_term_id: number;
        }[];
    }[];
    renew_shop_order_id?: number;
    coupon_code?: string;
    catch_first_direct_visit_off?: boolean;
};

export type InitializeShopPaymentResponse = {
    reference_pub: string;
    destination_pub: string;
    payment_share_list: {
        client_currency_share_id: number;
        amount: number;
    }[];
};

export type CallbackShopPaymentParams = {
    reference_pub: string;
};

export type ReviewShopPaymentResponse = {
    is_approved: boolean;
};

export type VerifyShopCouponParams = {
    coin_id: number;
    associated_with: string;
    coupon_name: string;
    is_renew: boolean;
};

export type GetShopDiscountsParams = {
    client_currency_id?: number;
    coupon_code?: string;
    catch_direct_visit_off?: boolean;
};

export type GetShopDiscountsAnonParams = {
    client_currency_id?: number;
    coupon_code?: string;
    invited_affiliate_code?: string;
};

export type GetShopDiscountsResponse = {
    summary: GetShopDiscountsSummaryResponse;
    list?: GetShopDiscountsItemResponse[];
};

export type GetShopDiscountsSummaryResponse = {
    new_buy_amount_off?: number;
    new_buy_percent_off?: number;
    renew_amount_off?: number;
    renew_percent_off?: number;
};

export type GetShopDiscountsItemResponse = {
    type: "direct_visit" | "invite" | "coupon" | "renew" | "currency";
    amount_off?: number;
    percent_off?: number;
    limited_to?: "first_buy" | "renew";
};

export class UseShopApi {
    useGetShopPayments = (config?: UseApiOptions<ShopPaymentType[]>) => {
        return useApi<GetShopPaymentsParams, ShopPaymentType[]>({
            method: "GET",
            path: "/shop/payments",
            needAuth: true,
            isList: true,
            test: {
                enable: apiTestMode,
                data: shopPaymentsExample,
            },
            ...config,
        });
    };

    useGetShopPayment = (
        shopPaymentId: number,
        config?: UseApiOptions<ShopPaymentType>
    ) => {
        return useApi<ApiGetSingleParams, ShopPaymentType>({
            method: "GET",
            path: `/shop/payments/${shopPaymentId}`,
            needAuth: true,
            test: {
                enable: apiTestMode,
                data: shopPaymentsExample[0],
            },
            ...config,
        });
    };

    useInitializeShopPayment = (
        config?: UseApiOptions<InitializeShopPaymentResponse>
    ) => {
        return useApi<
            InitializeShopPaymentParams,
            InitializeShopPaymentResponse
        >({
            method: "POST",
            path: "/shop/payments",
            needAuth: true,
            test: {
                enable: apiTestMode,
                data: initializeShopPaymentExample,
            },
            ...config,
        });
    };

    useCallbackShopPayment = (config?: UseApiOptions<{}>) => {
        return useApi<CallbackShopPaymentParams, {}>({
            method: "PUT",
            path: "/shop/payments/callback",
            needAuth: true,
            test: {
                enable: apiTestMode,
                data: {},
            },
            ...config,
        });
    };

    useReviewShopPayment = (
        shopPaymentId: number,
        config?: UseApiOptions<ReviewShopPaymentResponse>
    ) => {
        return useApi<{}, ReviewShopPaymentResponse>({
            method: "PUT",
            path: `/shop/payments/${shopPaymentId}/review`,
            needAuth: true,
            test: {
                enable: apiTestMode,
                data: reviewShopPaymentExample,
            },
            ...config,
        });
    };

    useVerifyShopCoupon = (config?: UseApiOptions<{}>) => {
        return useApi<VerifyShopCouponParams, {}>({
            method: "PUT",
            path: "/shop/coupons/verify",
            needAuth: true,
            test: {
                enable: apiTestMode,
                data: {},
            },
            ...config,
        });
    };

    useGetShopDiscountsAnon = (
        config?: UseApiOptions<GetShopDiscountsResponse>
    ) => {
        return useApi<GetShopDiscountsAnonParams, GetShopDiscountsResponse>({
            method: "GET",
            path: "/shop/discounts/anon",
            test: {
                enable: apiTestMode,
                data: shopDiscountsExample,
            },
            ...config,
        });
    };

    useGetShopDiscounts = (
        config?: UseApiOptions<GetShopDiscountsResponse>
    ) => {
        return useApi<GetShopDiscountsParams, GetShopDiscountsResponse>({
            method: "GET",
            path: "/shop/discounts",
            needAuth: true,
            test: {
                enable: apiTestMode,
                data: shopDiscountsExample,
            },
            ...config,
        });
    };
}
