import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import styles from "./WalletConnect.module.scss";

type Props = {};

const WalletButton = styled(WalletMultiButton)`
    display: inline-block;
    height: auto;
    line-height: initial;
    background: none;
    color: #87a1c1;
    font-size: 0.85em;
    font-weight: 500;
    border-radius: initial;
    border-bottom: 1px dashed #87a1c1;
    padding: 0;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-image: none !important;
        color: #ccdcef;
    }
`;

const WalletConnectText: FC<PropsWithChildren<Props>> = ({ children }) => {
    const wallet = useWallet();

    return (
        <>
            {!wallet.connected && !wallet.connecting ? (
                <WalletButton startIcon={undefined}>{children}</WalletButton>
            ) : (
                <div className={styles.idleText}>{children}</div>
            )}
        </>
    );
};

export default WalletConnectText;
