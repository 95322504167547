import { useApi, UseApiOptions } from "src/hooks/useApi";
import {
    UserInfoType,
    userInfoExample,
    signatureMessageExample,
} from "src/types/user";
import { ApiGetSingleParams, apiTestMode } from "./root";

export interface GetUserInfoParams extends ApiGetSingleParams {}

export interface CreateSignatureMessageParams {
    sol_wallet: string;
}

export interface CreateSignatureMessageResponse {
    seed: string;
    message: string;
}

export class UseUsersApi {
    useGetUserInfo(config?: UseApiOptions<UserInfoType>) {
        return useApi<GetUserInfoParams, UserInfoType>({
            method: "GET",
            path: "/me/users",
            needAuth: true,
            test: {
                enable: apiTestMode,
                data: userInfoExample,
            },
            ...config,
        });
    }

    useCreateSignatureMessage(
        config?: UseApiOptions<CreateSignatureMessageResponse>
    ) {
        return useApi<
            CreateSignatureMessageParams,
            CreateSignatureMessageResponse
        >({
            method: "PUT",
            path: "/users/signatures/messages",
            test: {
                enable: apiTestMode,
                data: signatureMessageExample,
            },
            ...config,
        });
    }
}
