import { FC, ReactNode, useEffect, useState } from "react";
import clsx from "clsx";
import Feature from "../feature/Feature";
import styles from "./NetworkTree.module.scss";
import { BsArrowLeftShort } from "react-icons/bs";

export type NetworkTreeItemType = {
    content: ReactNode;
    onSelect?(): void;
};

type Props = {
    className?: string;
    treeCenter: ReactNode;
    treeList: NetworkTreeItemType[];
    loading?: boolean;
    renderSubMessage?: ReactNode;
    onSubMessageBack?(): void;
};

const NetworkTree: FC<Props> = ({
    className,
    treeCenter,
    treeList,
    loading,
    renderSubMessage,
    onSubMessageBack,
}) => {
    const [displayCount, setDisplayCount] = useState(0);
    const treeItemWidth = 90;
    const radius = `calc(50% - 40px)`;

    useEffect(() => {
        if (displayCount < treeList.length) {
            const intervalId = setInterval(() => {
                setDisplayCount(displayCount + 1);
            }, 50);
            return () => clearInterval(intervalId);
        }
    }, [displayCount, treeList.length]);

    return (
        <div className={clsx(styles.networkTreeWrap, className)}>
            {renderSubMessage && (
                <Feature className={styles.treeMessage}>
                    <div
                        className={clsx(styles.treeMessageBack)}
                        onClick={onSubMessageBack}
                    >
                        <BsArrowLeftShort className={styles.icon} />
                        <span>Go Back</span>
                    </div>
                    {renderSubMessage}
                </Feature>
            )}

            <div
                className={clsx(
                    styles.networkTree,
                    treeList.length <= 2 && styles.networkTreeMini
                )}
            >
                <div
                    className={styles.middleCircle}
                    style={{
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    {treeCenter}
                </div>

                {!loading &&
                    treeList.map((treeItem, index) => {
                        const angle = index * (360 / treeList.length);
                        const x = `calc(${radius} * ${Math.cos(
                            angle * (Math.PI / 180)
                        )})`;
                        const y = `calc(${radius} * ${Math.sin(
                            angle * (Math.PI / 180)
                        )})`;
                        const isDisplayed = index < displayCount;
                        return (
                            <div
                                key={index}
                                className={clsx(
                                    styles.treeItemWrap,
                                    isDisplayed && styles.treeItemWrapShow
                                )}
                                onClick={treeItem.onSelect}
                            >
                                <div
                                    className={clsx(styles.treeItem)}
                                    style={{
                                        left: `calc(50% + ${x} - ${
                                            treeItemWidth / 2
                                        }px)`,
                                        top: `calc(50% + ${y} - ${
                                            treeItemWidth / 2
                                        }px)`,
                                    }}
                                >
                                    <span>{treeItem.content}</span>
                                </div>

                                <div
                                    className={clsx(
                                        styles.line,
                                        isDisplayed && styles.displayed
                                    )}
                                    style={{
                                        left: `calc(50% + ${x})`,
                                        top: `calc(50% + ${y})`,
                                        transform: `rotate(${angle + 180}deg)`,
                                    }}
                                />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default NetworkTree;
