import { FC, ReactNode, useState } from "react";
import clsx from "clsx";
import WalletConnectText from "../wallet-connect/WalletConnectText";
import PlatContent from "./PlatContent";
import PlatHeader from "./PlatHeader";
import { ApiGetGroupParams, ApiOptions } from "../../api/root";
import { UseApi } from "../../hooks/useApi";
import useUserInfo from "../../hooks/useUserInfo";
import Feature from "../../libs/feature/Feature";
import Dialog from "../../libs/dialog/Dialog";
import Box, { BoxProps } from "../../libs/box/Box";
import styles from "./Plat.module.scss";

type Props = {
    className?: string;
    dialogClassName?: string;
    title: string;
    api: UseApi<any, any>;
    minimal?: {
        enable: boolean; // show view all
        dialogApi: UseApi<any, any>;
        onDialogApiRequest(
            params: ApiGetGroupParams,
            options?: ApiOptions
        ): void;
    };
    needAuth?: boolean;
    hasBox?: boolean;
    boxProps?: BoxProps;
    hideHeader?: boolean;
    hideSearch?: boolean;
    updateTrigger?: any;
    backgroundUpdateTrigger?: any;
    renderTableColumns?(isDialog: boolean): ReactNode;
    onApiRequest(params: ApiGetGroupParams, options?: ApiOptions): void;
};

const Plat: FC<Props> = ({
    className,
    dialogClassName,
    title,
    api,
    minimal,
    needAuth,
    hasBox,
    boxProps,
    hideHeader,
    hideSearch,
    updateTrigger,
    backgroundUpdateTrigger,
    renderTableColumns,
    onApiRequest,
}) => {
    const { fullConnected } = useUserInfo();
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleViewAll = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const renderHeaderHolder = (isDialog: boolean) => {
        if (hideHeader) return null;

        if (fullConnected || !needAuth) {
            return (
                <PlatHeader
                    title={title}
                    api={isDialog ? minimal?.dialogApi! : api}
                    hideSearch={hideSearch}
                />
            );
        } else {
            return null;
        }
    };

    const renderContentHolder = () => {
        return fullConnected || !needAuth ? (
            <>
                <PlatContent
                    title={title}
                    api={api}
                    footerType={minimal?.enable ? "view_all" : "pagination"}
                    isDialog={false}
                    updateTrigger={updateTrigger}
                    backgroundUpdateTrigger={backgroundUpdateTrigger}
                    renderTableColumns={renderTableColumns}
                    onApiRequest={onApiRequest}
                    onViewAll={handleViewAll}
                />

                {minimal?.enable && (
                    <Dialog
                        className={clsx(styles.dialog, dialogClassName)}
                        onClose={handleCloseDialog}
                        open={dialogOpen}
                        boxProps={{
                            title: renderHeaderHolder(true),
                        }}
                    >
                        <PlatContent
                            title={title}
                            api={minimal.dialogApi}
                            footerType="pagination"
                            isDialog={true}
                            needAuth={needAuth}
                            updateTrigger={updateTrigger}
                            backgroundUpdateTrigger={backgroundUpdateTrigger}
                            renderTableColumns={renderTableColumns}
                            onApiRequest={minimal.onDialogApiRequest}
                        />
                    </Dialog>
                )}
            </>
        ) : (
            <Feature type="message">
                <WalletConnectText>Connect Wallet</WalletConnectText> to see the{" "}
                {title.toLowerCase()}
            </Feature>
        );
    };

    if (hasBox) {
        return (
            <Box
                {...boxProps}
                className={clsx(className)}
                title={renderHeaderHolder(false)}
            >
                {renderContentHolder()}
            </Box>
        );
    } else {
        return (
            <div className={clsx(className)}>
                {renderHeaderHolder(false)}
                {renderContentHolder()}
            </div>
        );
    }
};

export default Plat;
