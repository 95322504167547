import { FC } from "react";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import { randomRange } from "src/services/utils";
import Box from "src/libs/box/Box";
import styles from "./DashboardNfts.module.scss";

type Props = {
    className?: string;
};

const DashboardNftsLoading: FC<Props> = ({ className }) => {
    return (
        <Box className={clsx(className)} type="round">
            <Skeleton className={styles.loadingImage} height={150} />
            <Skeleton
                className={styles.loadingTitle}
                width={randomRange(70, 90)}
            />
            <Skeleton width={60} />
        </Box>
    );
};

export default DashboardNftsLoading;
