import { PublicKey } from "@solana/web3.js";
import { useEffect, useState } from "react";
import { Api } from "src/api/root";
import useUserInfo from "./useUserInfo";

const useInvite = () => {
    const { updateUserInfo } = useUserInfo();
    const api = Api.invites().useAddInvite();
    const [storageInviteCode, setStorageInviteCode] = useState<string | null>(
        null
    );

    useEffect(() => {
        setStorageInviteCode(localStorage.getItem("affiliateCode"));
    }, []);

    const saveInviteOnStorage = () => {
        // find the first part of the path (when splitting slash)
        let path = window.location.pathname;
        path = path.substring(1);

        const pathParts = path.split("/");
        const firstPathPart = pathParts[0];

        // add affiliate code if its on the curve
        if (firstPathPart.length > 0) {
            try {
                if (PublicKey.isOnCurve(firstPathPart)) {
                    localStorage.setItem("affiliateCode", firstPathPart);
                    setStorageInviteCode(firstPathPart);
                }
            } catch (e: any) {
                console.log("Invalid affiliate code!");
            }
        }

        // remove affiliate code from url
        if (firstPathPart.length > 0) {
            if (window.history.replaceState) {
                window.history.replaceState({}, "", "/");
            } else {
                window.history.pushState({}, "", "/");
            }
        }
    };

    const saveInviteOnApi = () => {
        const affiliateCode = localStorage.getItem("affiliateCode");
        if (affiliateCode != null && affiliateCode.length > 0) {
            api.run({
                affiliate_code: affiliateCode,
            }).then(() => {
                localStorage.removeItem("affiliateCode");
                setStorageInviteCode(null);
                updateUserInfo();
            });
        }
    };

    return {
        saveInviteOnStorage,
        saveInviteOnApi,
        storageInviteCode,
    };
};

export default useInvite;
