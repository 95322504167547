import { FC, useEffect, useState } from "react";
import Box from "../../libs/box/Box";
import DashboardInvestCampaignsItem from "./DashboardInvestCampaignsItem";
import useSettings from "../../hooks/useSettings";
import { Api, apiTestMode } from "../../api/root";
import Feature from "../../libs/feature/Feature";
import State, { StateType } from "../../libs/state/State";
import { HiOutlineExternalLink } from "react-icons/hi";
import DashboardInvestCampaignsAll from "./DashboardInvestCampaignsAll";
import { fundingCampaignsExample } from "../../types/campaign";
import useUserInfo from "../../hooks/useUserInfo";
import useLive from "../../hooks/useLive";
import styles from "./DashboardInvest.module.scss";

type Props = {
    className?: string;
};

const DashboardInvestCampaigns: FC<Props> = ({ className }) => {
    const { settings } = useSettings();
    const { userInfo } = useUserInfo();
    const { updateCount } = useLive();
    const [openAll, setOpenAll] = useState(false);

    const api = Api.campaigns().useGetCampaigns({
        perPage: 5,
        test: {
            enable: apiTestMode,
            data: fundingCampaignsExample,
        },
    });

    useEffect(() => {
        api.run(
            {
                exclude_status: "canceled",
                full_funded: false,
            },
            {
                runInBackground: !!api.state.data,
            }
        );
        return () => api.abort();
    }, [
        updateCount,
        settings?.launch.id,
        userInfo?.invest_info?.pending_fund_usd,
        userInfo?.invest_info?.active_fund_usd,
    ]);

    return (
        <Box className={className}>
            {api.state.loading ? (
                Array.from(Array(2).keys()).map((_, loadingIndex) => {
                    return (
                        <DashboardInvestCampaignsItem
                            className={styles.campaignItem}
                            key={loadingIndex}
                            loading={true}
                        />
                    );
                })
            ) : api.state.data && api.state.data.length > 0 ? (
                api.state.data.map((campaignItem) => {
                    return (
                        <DashboardInvestCampaignsItem
                            className={styles.campaignItem}
                            key={campaignItem.id}
                            campaign={campaignItem}
                        />
                    );
                })
            ) : (
                <Feature type="message">
                    <State type={StateType.NO_DOCUMENTS}>
                        No funding campaign at the moment!
                    </State>
                </Feature>
            )}

            {(settings?.launch.campaign_stats.funded_campaigns || 0) > 0 && (
                <div
                    className={styles.campaignViewCompleted}
                    onClick={() => setOpenAll(true)}
                >
                    <span>{`Completed Campaigns (${settings?.launch.campaign_stats.funded_campaigns})`}</span>
                    <HiOutlineExternalLink />
                </div>
            )}

            {openAll && (
                <DashboardInvestCampaignsAll
                    open={openAll}
                    onClose={() => setOpenAll(false)}
                />
            )}
        </Box>
    );
};

export default DashboardInvestCampaigns;
