import clsx from "clsx";
import moment from "moment";
import { Column } from "rc-table";
import { FC } from "react";
import { Api } from "../../api/root";
import Dialog from "../../libs/dialog/Dialog";
import { CampaignType } from "../../types/campaign";
import DashboardInvestCampaignsStatus from "./DashboardInvestCampaignsStatus";
import Plat from "../plat/Plat";
import styles from "./DashboardInvest.module.scss";

type Props = {
    className?: string;
    open: boolean;
    onClose(): void;
};

const DashboardInvestCampaignsAll: FC<Props> = ({
    className,
    open,
    onClose,
}) => {
    const api = Api.campaigns().useGetCampaigns({
        perPage: 6,
    });

    return (
        <Dialog
            className={className}
            onClose={onClose}
            open={open}
            boxProps={{
                title: `Completed Campaigns`,
            }}
        >
            <Plat
                className={clsx(className)}
                title="Completed Campaigns"
                api={api}
                hideHeader={true}
                onApiRequest={(params, options) => {
                    api.run(
                        {
                            ...(params as any),
                            //has_funded: true,
                            full_funded: true,
                        },
                        options
                    );
                }}
                renderTableColumns={() => {
                    return (
                        <>
                            <Column
                                title="Campaign"
                                dataIndex={["campaign_tag"]}
                                key="campaign_tag"
                            />
                            <Column
                                title="Status"
                                dataIndex={["status"]}
                                key="status"
                                render={(status, campaign: CampaignType) => (
                                    <DashboardInvestCampaignsStatus
                                        campaign={campaign}
                                        isLive={true}
                                        hideIcon={true}
                                    />
                                )}
                            />
                            <Column
                                title="Funds"
                                dataIndex={["target_fund_usd"]}
                                key="target_fund_usd"
                                render={(target_fund_usd) =>
                                    `$${target_fund_usd?.toLocaleString() || 0}`
                                }
                            />
                            <Column
                                title="Gains"
                                dataIndex={["total_gain_usd"]}
                                key="total_gain_usd"
                                render={(total_gain_usd) =>
                                    `$${total_gain_usd?.toLocaleString() || 0}`
                                }
                            />
                            <Column
                                title="Started"
                                dataIndex={["ran_date"]}
                                key="ran_date"
                                render={(ran_date) => (
                                    <span
                                        data-tip={
                                            ran_date &&
                                            moment.unix(ran_date).format()
                                        }
                                    >
                                        {ran_date
                                            ? moment.unix(ran_date).fromNow()
                                            : "—"}
                                    </span>
                                )}
                            />
                        </>
                    );
                }}
            />
        </Dialog>
    );
};

export default DashboardInvestCampaignsAll;
