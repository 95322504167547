import { FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useWallet } from "@solana/wallet-adapter-react";
import { FiCheckCircle } from "react-icons/fi";
import Box from "src/libs/box/Box";
import Feature from "src/libs/feature/Feature";
import FormButton from "src/libs/form-button/FormButton";
import WalletConnectText from "src/components/wallet-connect/WalletConnectText";
import useUserInfo from "src/hooks/useUserInfo";
import useAlert from "src/hooks/useAlert";
import Skeleton from "react-loading-skeleton";
import { gradeTitles } from "../../types/grade";
import ProfileActivate from "../profile/ProfileActivate";
import useConnectionPortal from "../../hooks/useConnectionPortal";
import styles from "./DashboardMint.module.scss";
import { GoInfo } from "react-icons/go";
import DashboardInvitesGuide, {
    NetworkGuideTab,
} from "../dashboard-invites/DashboardInvitesGuide";

type Props = {
    className?: string;
};

const DashboardMintAffiliate: FC<Props> = ({ className }) => {
    const { publicKey, connected } = useWallet();
    const { userInfo, loadingUserInfo } = useUserInfo();
    const { pendingPortalInfo } = useConnectionPortal();
    const [, setAlertState] = useAlert();
    const publicKeyAddress = publicKey?.toString();
    const [copyText, setCopyText] = useState("Copy");
    const inviteLinkRef = useRef(null);
    const [copySpecialText, setCopySpecialText] = useState("Copy");
    const specialInviteLinkRef = useRef(null);
    const [openActivate, setOpenActivate] = useState<boolean>(false);
    const [openCircles, setOpenCircles] = useState<boolean>(false);

    // open activate dialog if it's in the middle of connecting
    useEffect(() => {
        if (
            userInfo &&
            pendingPortalInfo?.has_redirected &&
            pendingPortalInfo.request_source == "activate_network"
        ) {
            setOpenActivate(true);
        }
    }, [userInfo, pendingPortalInfo]);

    const handleLinkFocus = (e: any) => {
        if (validateInvite()) {
            e.target.select();
        } else {
            e.target.blur();
        }
    };

    const handleCopyInviteLink = (e: any) => {
        if (validateInvite()) {
            navigator.clipboard.writeText(
                (inviteLinkRef?.current as any).value
            );
            setCopyText("Copied!");

            setTimeout(() => {
                setCopyText("Copy");
            }, 2000);
        }
    };

    const handleCopySpecialInviteLink = (e: any) => {
        if (validateInvite()) {
            navigator.clipboard.writeText(
                (specialInviteLinkRef?.current as any).value
            );
            setCopySpecialText("Copied!");

            setTimeout(() => {
                setCopySpecialText("Copy");
            }, 2000);
        }
    };

    const handleActivateNetwork = () => {
        setOpenActivate(true);
    };

    const validateInvite = () => {
        const canInvite =
            (userInfo?.mints_info?.auditing_mint_count || 0) +
                (userInfo?.mints_info?.approved_mint_count || 0) >
            0;

        if (!canInvite) {
            setTimeout(() => {
                setAlertState({
                    open: true,
                    message:
                        "You need to mint at least one NFT to be able to invite.",
                    severity: "error",
                });
            }, 100);
        }

        return canInvite;
    };

    return (
        <Box
            className={clsx(styles.dashboard, className)}
            title="Network System"
        >
            <ul className={styles.inviteBullets}>
                <li>
                    <FiCheckCircle className={styles.icon} />
                    <span>Earn 15% from your invited friends</span>
                </li>
                <li>
                    <FiCheckCircle className={styles.icon} />
                    <span>
                        Earn another 20% from your network (
                        <span
                            className={styles.inviteCirclesLink}
                            onClick={() => setOpenCircles(true)}
                        >
                            up to 5 circles
                        </span>
                        )
                    </span>
                </li>
                <li>
                    <FiCheckCircle className={styles.icon} />
                    <span>Earn 5 SOL by reaching 30 invited mints</span>
                </li>
            </ul>

            {connected ? (
                <>
                    <Feature className={styles.inviteFeature}>
                        <div className={styles.inviteLink}>
                            <div className={styles.inviteLinkLabel}>
                                {`${
                                    !loadingUserInfo &&
                                    userInfo?.grades_info.special_invite_code
                                        ? "Standard"
                                        : "Your"
                                } Invite Link:`}
                            </div>

                            {!loadingUserInfo ? (
                                userInfo?.invites_info?.is_activated ? (
                                    <input
                                        ref={inviteLinkRef}
                                        className={styles.inviteLinkAddress}
                                        value={`${window.location.origin}/${publicKeyAddress}`}
                                        readOnly
                                        onFocus={handleLinkFocus}
                                    />
                                ) : (
                                    "—"
                                )
                            ) : (
                                <Skeleton width="85%" height={20} />
                            )}
                        </div>

                        {!loadingUserInfo &&
                            (userInfo?.invites_info?.is_activated ? (
                                <FormButton
                                    className={styles.inviteCopyButton}
                                    onClick={handleCopyInviteLink}
                                >
                                    {copyText}
                                </FormButton>
                            ) : (
                                <FormButton
                                    className={styles.inviteCopyButton}
                                    onClick={handleActivateNetwork}
                                >
                                    Activate
                                </FormButton>
                            ))}
                    </Feature>

                    {!loadingUserInfo && !userInfo?.invites_info?.is_activated && (
                        <div className={styles.featureHint}>
                            <GoInfo className={styles.icon} />
                            <span>
                                Activating network is optional and it won&apos;t
                                effect revenue sharing program.
                            </span>
                        </div>
                    )}

                    {!loadingUserInfo &&
                        userInfo?.invites_info?.is_activated &&
                        userInfo?.grades_info.special_invite_code && (
                            <Feature className={styles.inviteFeature}>
                                <div className={styles.inviteLink}>
                                    <div className={styles.inviteLinkLabel}>
                                        {`${
                                            gradeTitles[
                                                userInfo.grades_info
                                                    .highest_grade
                                                    ?.enable_invite_grade_tag!
                                            ]
                                        } Invite Link:`}
                                    </div>
                                    <input
                                        ref={specialInviteLinkRef}
                                        className={styles.inviteLinkAddress}
                                        value={`${window.location.origin}/${userInfo.grades_info.special_invite_code}`}
                                        readOnly
                                        onFocus={handleLinkFocus}
                                    />
                                </div>
                                <FormButton
                                    className={styles.inviteCopyButton}
                                    onClick={handleCopySpecialInviteLink}
                                >
                                    {copySpecialText}
                                </FormButton>
                            </Feature>
                        )}
                </>
            ) : (
                <Feature type="message">
                    <WalletConnectText>Connect Wallet</WalletConnectText> to get
                    your invite link
                </Feature>
            )}

            <ProfileActivate
                open={openActivate}
                onClose={() => setOpenActivate(false)}
            />

            {openCircles && (
                <DashboardInvitesGuide
                    open={openCircles}
                    defaultTab={NetworkGuideTab.CIRCLES}
                    onClose={() => setOpenCircles(false)}
                />
            )}
        </Box>
    );
};

export default DashboardMintAffiliate;
