import { CoinType } from "./coin";
import { FiatType } from "./fiat";
import { ApiSubItems } from "./general";
import { TransactionType } from "./transaction";

export type AssetType = {
    id: number;
    bag_tag: AssetBagType;
    central_wallet: string;
    can_withdraw: boolean;
};

export type AssetCoinType = {
    id: number;
    asset: AssetType;
    coin: CoinType;
    rewarded_amount: number;
    available_amount: number;
    withdrawing_amount: number;
    mint_locked_amount: number;
};

export type AssetClaimType = {
    id: number;
    asset: AssetType;
    status: "pending" | "rejected" | "completed";
    created_date: number;
    reviewed_date?: number;
    networks: ApiSubItems<AssetClaimNetworkType>;
};

export type AssetClaimNetworkType = {
    id: number;
    network: "solana_chain";
    is_completed: boolean;
    transaction?: TransactionType;
    currencies: ApiSubItems<AssetClaimCurrencyType>;
};

export type AssetClaimCurrencyType = {
    id: number;
    amount: number;
    fiat?: FiatType;
    coin?: CoinType;
};

export type AssetRewardType = {
    id: number;
    asset: AssetType;
    coin: CoinType;
    amount: number;
    reason: "invited_mint" | "invite_threshold" | "join_to_discord" | "join_to_twitter";
    rewarded_date: number;
};

export type AssetBagType = "main" | "invest";

export type AssetRewardReasonType =
'invited_mint' | 'invite_threshold'| 'join_to_discord'| 'join_to_twitter' | 'invest_commission' | 'invest_ib' | 'invest_gain' | 'revenue_solgate';

export const assetRewardReasonTitles: Record<AssetRewardReasonType, string> =
    {
        invited_mint: "Mint Commission",
        invite_threshold: "30 Invites",
        join_to_discord: "Join Discord",
        join_to_twitter: "Join Twitter",
        invest_commission: "Funding Commission",
        invest_ib: "IB",
        invest_gain: "Funding Gain",
        revenue_solgate: "SolGate Revenue"
    };

export const assetClaimsExample: AssetClaimType[] = [
    {
        id: 1,
        asset: {
            id: 1,
            bag_tag: "main",
            central_wallet: "",
            can_withdraw: true,
        },
        status: "pending",
        created_date: 1663170281,
        reviewed_date: undefined,
        networks: {
            count: 1,
            items: [
                {
                    id: 1,
                    network: "solana_chain",
                    is_completed: false,
                    currencies: {
                        count: 1,
                        items: [
                            {
                                id: 1,
                                amount: 0.151,
                                coin: {
                                    id: 1,
                                    name: "Solana",
                                    symbol: "SOL",
                                    price_usd: 34.21,
                                    decimals: 9,
                                },
                            },
                            {
                                id: 2,
                                amount: 27.05,
                                coin: {
                                    id: 2,
                                    name: "USDC",
                                    symbol: "USDC",
                                    price_usd: 1,
                                    decimals: 6,
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    {
        id: 2,
        asset: {
            id: 1,
            bag_tag: "main",
            central_wallet: "",
            can_withdraw: true,
        },
        status: "completed",
        created_date: 1663169281,
        reviewed_date: 1663164281,
        networks: {
            count: 1,
            items: [
                {
                    id: 1,
                    network: "solana_chain",
                    is_completed: true,
                    currencies: {
                        count: 1,
                        items: [
                            {
                                id: 1,
                                amount: 2.15,
                                coin: {
                                    id: 1,
                                    name: "Solana",
                                    symbol: "SOL",
                                    price_usd: 34.21,
                                    decimals: 9,
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    {
        id: 3,
        asset: {
            id: 1,
            bag_tag: "main",
            central_wallet: "",
            can_withdraw: true,
        },
        status: "completed",
        created_date: 1663168281,
        reviewed_date: 1663164281,
        networks: {
            count: 1,
            items: [
                {
                    id: 1,
                    network: "solana_chain",
                    is_completed: true,
                    currencies: {
                        count: 1,
                        items: [
                            {
                                id: 1,
                                amount: 0.64,
                                coin: {
                                    id: 1,
                                    name: "Solana",
                                    symbol: "SOL",
                                    price_usd: 34.21,
                                    decimals: 9,
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    {
        id: 4,
        asset: {
            id: 1,
            bag_tag: "main",
            central_wallet: "",
            can_withdraw: true,
        },
        status: "rejected",
        created_date: 1663067281,
        reviewed_date: undefined,
        networks: {
            count: 1,
            items: [
                {
                    id: 1,
                    network: "solana_chain",
                    is_completed: false,
                    currencies: {
                        count: 1,
                        items: [
                            {
                                id: 1,
                                amount: 0.952,
                                coin: {
                                    id: 1,
                                    name: "Solana",
                                    symbol: "SOL",
                                    price_usd: 34.21,
                                    decimals: 9,
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    {
        id: 5,
        asset: {
            id: 1,
            bag_tag: "main",
            central_wallet: "",
            can_withdraw: true,
        },
        status: "completed",
        created_date: 1663066281,
        reviewed_date: 1663066281,
        networks: {
            count: 1,
            items: [
                {
                    id: 1,
                    network: "solana_chain",
                    is_completed: true,
                    currencies: {
                        count: 1,
                        items: [
                            {
                                id: 1,
                                amount: 3.1,
                                coin: {
                                    id: 1,
                                    name: "Solana",
                                    symbol: "SOL",
                                    price_usd: 34.21,
                                    decimals: 9,
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    {
        id: 6,
        asset: {
            id: 1,
            bag_tag: "main",
            central_wallet: "",
            can_withdraw: true,
        },
        status: "completed",
        created_date: 1663065281,
        reviewed_date: 1663065281,
        networks: {
            count: 1,
            items: [
                {
                    id: 1,
                    network: "solana_chain",
                    is_completed: true,
                    currencies: {
                        count: 1,
                        items: [
                            {
                                id: 1,
                                amount: 1.055,
                                coin: {
                                    id: 1,
                                    name: "Solana",
                                    symbol: "SOL",
                                    price_usd: 34.21,
                                    decimals: 9,
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    {
        id: 7,
        asset: {
            id: 1,
            bag_tag: "main",
            central_wallet: "",
            can_withdraw: true,
        },
        status: "completed",
        created_date: 1663064281,
        reviewed_date: 1663064281,
        networks: {
            count: 1,
            items: [
                {
                    id: 1,
                    network: "solana_chain",
                    is_completed: true,
                    currencies: {
                        count: 1,
                        items: [
                            {
                                id: 1,
                                amount: 1.93,
                                coin: {
                                    id: 1,
                                    name: "Solana",
                                    symbol: "SOL",
                                    price_usd: 34.21,
                                    decimals: 9,
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    {
        id: 8,
        asset: {
            id: 1,
            bag_tag: "main",
            central_wallet: "",
            can_withdraw: true,
        },
        status: "completed",
        created_date: 1663063281,
        reviewed_date: 1663063281,
        networks: {
            count: 1,
            items: [
                {
                    id: 1,
                    network: "solana_chain",
                    is_completed: true,
                    currencies: {
                        count: 1,
                        items: [
                            {
                                id: 1,
                                amount: 0.115,
                                coin: {
                                    id: 1,
                                    name: "Solana",
                                    symbol: "SOL",
                                    price_usd: 34.21,
                                    decimals: 9,
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    {
        id: 9,
        asset: {
            id: 1,
            bag_tag: "main",
            central_wallet: "",
            can_withdraw: true,
        },
        status: "completed",
        created_date: 1663062281,
        reviewed_date: 1663062281,
        networks: {
            count: 1,
            items: [
                {
                    id: 1,
                    network: "solana_chain",
                    is_completed: true,
                    currencies: {
                        count: 1,
                        items: [
                            {
                                id: 1,
                                amount: 0.74,
                                coin: {
                                    id: 1,
                                    name: "Solana",
                                    symbol: "SOL",
                                    price_usd: 34.21,
                                    decimals: 9,
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
];

export const assetRewardsExample: AssetRewardType[] = [
    
];
