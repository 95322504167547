import clsx from "clsx";
import moment from "moment";
import Column from "rc-table/lib/sugar/Column";
import { FC } from "react";
import { FaCheck, FaMinus } from "react-icons/fa";
import { Api } from "../../api/root";
import AlertMini from "../../libs/alert-mini/AlertMini";
import Dialog from "../../libs/dialog/Dialog";
import { InvestFundTransTypeType, InvestFundType } from "../../types/invest";
import Plat from "../plat/Plat";
import styles from "./DashboardInvest.module.scss";

type Props = {
    className?: string;
    investFund: InvestFundType;
    open: boolean;
    onClose(): void;
};

const DashboardInvestFundsHistory: FC<Props> = ({
    className,
    investFund,
    open,
    onClose,
}) => {
    const api = Api.invests().useGetInvestFundTrans({
        perPage: 6,
    });

    return (
        <Dialog
            className={className}
            onClose={onClose}
            open={open}
            boxProps={{
                title: `My Funds History (Campaign ${investFund.campaign.campaign_tag})`,
            }}
        >
            <Plat
                className={clsx(className)}
                title="Funds History"
                api={api}
                needAuth={true}
                hideHeader={true}
                onApiRequest={(params, options) => {
                    api.run(
                        {
                            ...(params as any),
                            invest_fund_id: investFund.id,
                        },
                        options
                    );
                }}
                renderTableColumns={() => {
                    return (
                        <>
                            <Column
                                title="Type"
                                dataIndex={["type"]}
                                key="type"
                                render={(type: InvestFundTransTypeType) =>
                                    type == "deposit" ? (
                                        <AlertMini
                                            severity="success"
                                            hideIcon={true}
                                        >
                                            Deposit
                                        </AlertMini>
                                    ) : type == "withdraw" ? (
                                        <AlertMini
                                            severity="error"
                                            hideIcon={true}
                                        >
                                            Withdraw
                                        </AlertMini>
                                    ) : (
                                        "—"
                                    )
                                }
                            />
                            <Column
                                title="Transaction"
                                dataIndex={["transaction_usd"]}
                                key="transaction_usd"
                                render={(transaction_usd) =>
                                    `$${transaction_usd?.toLocaleString()}`
                                }
                            />
                            <Column
                                title="Done"
                                dataIndex={["is_completed"]}
                                key="is_completed"
                                render={(is_completed) =>
                                    is_completed ? <FaCheck /> : <FaMinus />
                                }
                            />
                            <Column
                                title="Created"
                                dataIndex={["created_date"]}
                                key="created_date"
                                render={(created_date) => (
                                    <span
                                        data-tip={
                                            created_date &&
                                            moment.unix(created_date).format()
                                        }
                                    >
                                        {created_date
                                            ? moment
                                                  .unix(created_date)
                                                  .fromNow()
                                            : "—"}
                                    </span>
                                )}
                            />
                            <Column
                                title="Completed"
                                dataIndex={["completed_date"]}
                                key="completed_date"
                                render={(completed_date) => (
                                    <span
                                        data-tip={
                                            completed_date &&
                                            moment.unix(completed_date).format()
                                        }
                                    >
                                        {completed_date
                                            ? moment
                                                  .unix(completed_date)
                                                  .fromNow()
                                            : "—"}
                                    </span>
                                )}
                            />
                        </>
                    );
                }}
            />
        </Dialog>
    );
};

export default DashboardInvestFundsHistory;
