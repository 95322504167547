import {
    FC,
    createContext,
    useContext,
    useState,
    PropsWithChildren,
} from "react";
import { DashboardTab } from "src/types/controller";

const ControllerDashboardContext = createContext<{
    activeTab: DashboardTab;
    setActiveTab(activeTab: DashboardTab): void;
}>({
    activeTab: DashboardTab.MINT,
    setActiveTab: () => {},
});

const useControllerDashboard = () => useContext(ControllerDashboardContext);

export const ControllerDashboardProvider: FC<PropsWithChildren<{}>> = ({
    children,
}) => {
    const [activeTab, setActiveTab] = useState<DashboardTab>(DashboardTab.MINT);

    return (
        <ControllerDashboardContext.Provider
            value={{
                activeTab,
                setActiveTab,
            }}
        >
            {children}
        </ControllerDashboardContext.Provider>
    );
};

export default useControllerDashboard;
