import { FC, useEffect } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import Feature from "src/libs/feature/Feature";
import Box from "src/libs/box/Box";
import { MintUnitType } from "src/types/mint";
import { UseApiActionType } from "src/hooks/useApi";
import { Api } from "src/api/root";
import useUserInfo from "src/hooks/useUserInfo";
import DashboardNftsItem from "./DashboardNftsItem";
import DashboardNftsLoading from "./DashboardNftsLoading";
import WalletConnectText from "../wallet-connect/WalletConnectText";
import State, { StateType } from "src/libs/state/State";
import useSettings from "../../hooks/useSettings";
import DashboardNftsSummary from "./DashboardNftsSummary";
import styles from "./DashboardNfts.module.scss";

type Props = {
    className?: string;
};

const DashboardNfts: FC<Props> = ({ className }) => {
    const { settings } = useSettings();
    const { fullConnected, userInfo, loadingUserInfo } = useUserInfo();

    const api = Api.mints().useGetMintUnits({
        perPage: 6,
    });

    useEffect(() => {
        api.run(
            {},
            {
                /*fakeLoading: loadingUserInfo*/
            }
        );

        return () => api.abort();
    }, [
        fullConnected,
        settings?.launch.id,
        api.state.pageNum,
        api.state.perPage,
        userInfo?.mints_info?.auditing_mint_count,
        userInfo?.mints_info?.approved_mint_count,
    ]);

    const handlePageChange = (page: number) => {
        api.dispatch({
            type: UseApiActionType.STATE_UPDATE,
            newState: { pageNum: page },
        });
    };

    const reloadList = () => {
        api.run({});
    };

    return (
        <div className={clsx(className)}>
            <DashboardNftsSummary className={styles.nftsSummaryWrap} />

            {fullConnected ? (
                <>
                    {api.state.loading || api.state.data?.length ? (
                        <>
                            <Grid
                                className={styles.nftList}
                                container
                                spacing={2}
                            >
                                {api.state.loading &&
                                    api.state.loadingItems!.map(
                                        (
                                            loadingItem: any,
                                            loadingIndex: number
                                        ) => (
                                            <Grid
                                                item
                                                xs={6}
                                                key={loadingIndex}
                                            >
                                                <DashboardNftsLoading />
                                            </Grid>
                                        )
                                    )}
                                {!!api.state.data?.length &&
                                    api.state.data.map((nft: MintUnitType) => (
                                        <Grid item xs={6} key={nft.id}>
                                            <DashboardNftsItem
                                                nft={nft}
                                                reloadList={reloadList}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>

                            {(api.state.totalNum || 0) > api.state.perPage! && (
                                <Pagination
                                    className={styles.pagination}
                                    page={api.state.pageNum}
                                    count={Math.ceil(
                                        api.state.totalNum! / api.state.perPage!
                                    )}
                                    onChange={(event, page) => {
                                        handlePageChange(page);
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <Box>
                            <Feature
                                type="message"
                                className={styles.emptyContent}
                            >
                                <State type={StateType.NO_IMAGES}>
                                    No NFTs yet!
                                </State>
                            </Feature>
                        </Box>
                    )}
                </>
            ) : (
                <Box>
                    <Feature type="message">
                        <WalletConnectText>Connect Wallet</WalletConnectText> to
                        see your minted NFTs
                    </Feature>
                </Box>
            )}
        </div>
    );
};

export default DashboardNfts;
