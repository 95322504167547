export const abbreviateNum = (number: number, maxPlaces: number, forcePlaces: number, forceLetter: boolean) => {
    number = Number(number);
    let abbr;
    if (forceLetter) {
        if (number >= 1e12) {
            abbr = 'T';
        } else if (number >= 1e9) {
            abbr = 'B';
        } else if (number >= 1e6) {
            abbr = 'M';
        } else if (number >= 1e3) {
            abbr = 'K';
        } else {
            abbr = '';
        }
    } else {
        abbr = '';
    }
    return annotateNum(number, maxPlaces, forcePlaces, abbr);
}

export const annotateNum = (number: number, maxPlaces: number, forcePlaces: number, abbr?: string) => {
    let floored = 0;
    switch (abbr) {
        case 'T':
            floored = number / 1e12;
            break;
        case 'B':
            floored = number / 1e9;
            break;
        case 'M':
            floored = number / 1e6;
            break;
        case 'K':
            floored = number / 1e3;
            break;
        case '':
            floored = number;
            break;
    }
    if (maxPlaces > 0) {
        const test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$');
        if (test.test(('' + floored))) {
            floored *= Math.pow(10, maxPlaces);
            floored = Math.floor(floored) / Math.pow(10, maxPlaces);
        }
    }
    if (forcePlaces > 0) {
        floored = Math.floor(floored * Math.pow(10, forcePlaces)) / Math.pow(10, forcePlaces);
    } else {
        floored = Math.floor(floored);
    }
    return floored + (abbr || '');
}