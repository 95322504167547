import { FC, ReactNode, useMemo } from "react";
import clsx from "clsx";
import Box from "src/libs/box/Box";
import useUserInfo from "src/hooks/useUserInfo";
import { Api } from "src/api/root";
import useSettings from "../../hooks/useSettings";
import Plat from "../plat/Plat";
import Column from "rc-table/lib/sugar/Column";
import ProfileMini from "../profile/ProfileMini";
import Pair from "src/libs/pair/Pair";
import Price from "../price/Price";
import Feature from "src/libs/feature/Feature";
import Skeleton from "react-loading-skeleton";
import { GoInfo } from "react-icons/go";
import styles from "./DashboardInvites.module.scss";
import AlertMini from "src/libs/alert-mini/AlertMini";

type Props = {
    className?: string;
    onOpenGuide(): void;
};

const DashboardInvitesLeaders: FC<Props> = ({ className, onOpenGuide }) => {
    const { settings } = useSettings();
    const { userInfo, fullConnected, loadingUserInfo } = useUserInfo();

    const api = Api.leaders().useGetLeaderUsers({
        perPage: 5,
    });

    const dialogApi = Api.leaders().useGetLeaderUsers({
        perPage: 5,
    });

    const networkLeader = useMemo(() => {
        return userInfo?.leaders?.items.find(
            (leader) => leader.leader?.leader_tag == "network"
        );
    }, [userInfo?.leaders]);

    const updateTrigger = useMemo(() => {
        return { launch_id: settings?.launch.id };
    }, [settings?.launch.id]);

    const renderUserInfo = (content: ReactNode) => {
        return fullConnected ? (
            !loadingUserInfo ? (
                content
            ) : (
                <Skeleton width={40} />
            )
        ) : (
            "—"
        );
    };

    return (
        <div className={className}>
            <Box className={styles.leaderOverview}>
                <div className={styles.leaderOverviewContent}>
                    <Feature className={styles.leaderOverviewItem}>
                        <Pair
                            title="My Earnings"
                            titlePosition="bottom"
                            value={renderUserInfo(
                                `$${Math.floor(
                                    networkLeader?.real_value || 0
                                ).toLocaleString()}`
                            )}
                        />
                    </Feature>

                    <Feature className={styles.leaderOverviewItem}>
                        <Pair
                            title="My Rank"
                            titlePosition="bottom"
                            value={renderUserInfo(
                                <div className={styles.leaderMyRank}>
                                    <span className={styles.title}>
                                        {networkLeader?.real_rank_num
                                            ? `#${networkLeader?.real_rank_num.toLocaleString()}`
                                            : "N/A"}
                                    </span>
                                    {!!networkLeader?.cached_value &&
                                        networkLeader.real_rank_percent <=
                                            30 && (
                                            <AlertMini
                                                severity="idle"
                                                hideIcon={true}
                                                withBackground={true}
                                            >
                                                {`Top ${Math.ceil(
                                                    networkLeader.real_rank_percent
                                                )}%`}
                                            </AlertMini>
                                        )}
                                </div>
                            )}
                        />
                    </Feature>
                </div>

                <div className={styles.leaderOverviewHint}>
                    <GoInfo className={styles.icon} />
                    <span className={styles.content}>
                        The current stats on the leaderboard reflect Q2 2024
                        performance and will reset upon the cycle&apos;s
                        conclusion. <a onClick={onOpenGuide}>See Prizes</a>
                    </span>
                </div>
            </Box>

            <Box>
                <Plat
                    title="Network Leaderboard"
                    api={api}
                    updateTrigger={updateTrigger}
                    minimal={{
                        enable: true,
                        dialogApi: dialogApi,
                        onDialogApiRequest: (params, options) => {
                            dialogApi.run(
                                {
                                    launch_id: settings?.launch.id || 0,
                                    leader_tag: "network",
                                    ...params,
                                },
                                options
                            );
                        },
                    }}
                    hideHeader={true}
                    onApiRequest={(params, options) => {
                        api.run(
                            {
                                launch_id: settings?.launch.id || 0,
                                leader_tag: "network",
                                ...params,
                            },
                            options
                        );
                    }}
                    renderTableColumns={() => {
                        return (
                            <>
                                <Column
                                    title="Rank"
                                    dataIndex={["cached_rank_num"]}
                                    key="cached_rank_num"
                                    width={50}
                                    render={(cached_rank_num) => (
                                        <div
                                            className={clsx(
                                                styles.leaderRank,
                                                cached_rank_num == 1 &&
                                                    styles.leaderRankOne,
                                                cached_rank_num == 2 &&
                                                    styles.leaderRankTwo,
                                                cached_rank_num == 3 &&
                                                    styles.leaderRankThree
                                            )}
                                        >
                                            {`#${cached_rank_num?.toLocaleString()}`}
                                        </div>
                                    )}
                                />
                                <Column
                                    title="User"
                                    dataIndex={["user"]}
                                    key="user"
                                    width={50}
                                    render={(user_short) => (
                                        <ProfileMini userInfo={user_short} />
                                    )}
                                />
                                <Column
                                    title="Cycle Earnings"
                                    dataIndex={["cached_value"]}
                                    key="cached_value"
                                    width={50}
                                    render={(cached_value) => (
                                        <Pair
                                            title={
                                                <Price
                                                    price={cached_value}
                                                    priceSource="primary"
                                                    coin={
                                                        settings?.client
                                                            .advertised_coin
                                                    }
                                                />
                                            }
                                            value={`$${Math.floor(
                                                cached_value
                                            )?.toLocaleString()}`}
                                            titlePosition="bottom"
                                        />
                                    )}
                                />
                                <Column
                                    title="Total Invites"
                                    dataIndex={[
                                        "user",
                                        "invites_info",
                                        "active_invited_user_count",
                                    ]}
                                    key="active_invited_user_count"
                                    width={50}
                                    render={(total_invites) =>
                                        total_invites?.toLocaleString()
                                    }
                                />
                            </>
                        );
                    }}
                />
            </Box>
        </div>
    );
};

export default DashboardInvitesLeaders;
