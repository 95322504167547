import { useApi, UseApiOptions } from "src/hooks/useApi";
import {
    AssetBagType,
    assetClaimsExample,
    AssetClaimType,
    assetRewardsExample,
    AssetRewardType,
} from "src/types/asset";
import { ApiGetGroupParams, apiTestMode } from "./root";

export interface GetAssetRewardsParams extends ApiGetGroupParams {
    launch_id: number;
    bag_tag?: AssetBagType;
    coin_id?: number;
}

export interface GetAssetClaimsParams extends ApiGetGroupParams {
    launch_id: number;
    bag_tag?: AssetBagType;
    coin_id?: number;
}

export interface AddAssetClaimParams {
    launch_id: number;
    bag_tag: AssetBagType;
    currencies: {
        fiat_id?: number;
        coin_id?: number;
    }[];
}

export class UseAssetsApi {
    useGetAssetRewards(config?: UseApiOptions<AssetRewardType[]>) {
        return useApi<GetAssetRewardsParams, AssetRewardType[]>({
            method: "GET",
            path: "/assets/rewards",
            needAuth: true,
            isList: true,
            test: {
                enable: apiTestMode,
                data: assetRewardsExample,
            },
            ...config,
        });
    }

    useGetAssetClaims(config?: UseApiOptions<AssetClaimType[]>) {
        return useApi<GetAssetClaimsParams, AssetClaimType[]>({
            method: "GET",
            path: "/assets/claims",
            needAuth: true,
            isList: true,
            test: {
                enable: apiTestMode,
                data: assetClaimsExample,
            },
            ...config,
        });
    }

    useAddAssetClaim(config?: UseApiOptions<{}>) {
        return useApi<AddAssetClaimParams, {}>({
            method: "POST",
            path: "/assets/claims",
            needAuth: true,
            test: {
                enable: apiTestMode,
            },
            ...config,
        });
    }
}
