import { CoinType } from "./coin";
import { ApiSubItems } from "./general";
import { FileType } from "./file";
import {
    InitializeShopPaymentResponse,
    GetShopDiscountsResponse,
    ReviewShopPaymentResponse,
} from "../api/shop";
import { FiatType } from "./fiat";
import { MethodType } from "./method";

export type ShopPaymentType = {
    id: number;
    track_id: string;
    original_primary_price: number;
    final_primary_price: number;
    blockchain_trx?: string;
    status: "paying" | "pay_timed_out" | "reviewing" | "approved" | "rejected";
    is_renew: boolean;
    created_date: number;
    paid_date?: number;
    reviewed_date?: number;
    method: MethodType;
    currencies: ApiSubItems<ShopPaymentCurrencyType>;
    discounts?: ApiSubItems<ShopPaymentDiscountType>;
    coupon?: ShopCouponType;
};

export type ShopPaymentCurrencyType = {
    id: number;
    original_amount: number;
    final_amount: number;
    share_percent: number;
    fiat?: FiatType;
    coin?: CoinType;
};

export type ShopPaymentDiscountType = {
    id: number;
    type: "direct_visit" | "invite" | "coupon" | "renew" | "currency";
    amount_off?: number;
    percent_off?: number;
};

export type ShopOrderType = {
    id: number;
    is_subscription: boolean;
    approved_payments: number;
    total_payments: number;
    last_shop_cart: ShopCartType;
};

export type ShopCartType = {
    id: number;
    quantity: number;
    item_primary_price: number;
    vars_primary_price?: number;
    shop_order?: ShopOrderType;
    vars?: ApiSubItems<ShopCartVarType>;
    shop_payment: ShopPaymentType;
};

export type ShopCartVarType = {
    id: number;
    primary_price?: number;
    shop_attr: ShopAttrType;
    shop_attr_term: ShopAttrTermType;
};

export type ShopCouponType = {
    id: number;
    coupon_code: string;
    amount_off?: number;
    percent_off?: number;
};

export type ShopAttrType = {
    id: number;
    name: string;
    type: "checkbox" | "select" | "color" | "switch";
    terms?: ApiSubItems<ShopAttrTermType>;
};

export type ShopAttrTermType = {
    id: number;
    value: string;
    text: string;
    image?: FileType;
};

export const shopPaymentsExample: ShopPaymentType[] = [];

export const initializeShopPaymentExample: InitializeShopPaymentResponse = {
    reference_pub: "86raj87ScwWLKoX6Rvt6KSPU5RGQHf6BtxDAAwzGv4oj",
    destination_pub: "8ys5CkzXuaZWgFJkKjVSHY4kxcS1UPRh3smkojpuT1Ww",
    payment_share_list: [
        {
            client_currency_share_id: 2, // USDC
            amount: 0.1412,
        },
    ],
};

export const reviewShopPaymentExample: ReviewShopPaymentResponse = {
    is_approved: false,
};

export const shopDiscountsExample: GetShopDiscountsResponse = {
    summary: {
        new_buy_percent_off: 20,
        renew_percent_off: 25,
    },
    list: [
        {
            type: "invite",
            percent_off: 20,
        },
        {
            type: "renew",
            percent_off: 5,
            limited_to: "renew",
        },
    ],
    /*summary: {
        new_buy_percent_off: 0,
        renew_percent_off: 5,
    },
    list: [
        {
            type: 'renew',
            percent_off: 5,
            limited_to: 'renew',
        },
    ],*/
};
