import { useEffect } from "react";
import clsx from "clsx";
import { applyMomentConfig } from "src/services/momentConfig";
import Dashboard from "src/components/dashboard/Dashboard";
import Nav from "src/components/nav/Nav";
import Preview from "src/components/preview/Preview";
import useResponsive from "src/hooks/useResponsive";
import PreviewInfo from "src/components/preview/PreviewInfo";
import { ControllerDashboardProvider } from "src/hooks/useControllerDashboard";
import useInvite from "src/hooks/useInvite";
import useConnectionPortal from "../hooks/useConnectionPortal";
import styles from "src/styles/Home.module.css";

const App = () => {
    const [isMobile] = useResponsive();
    const { saveInviteOnStorage } = useInvite();
    applyMomentConfig();

    useEffect(() => {
        saveInviteOnStorage();
    }, []);

    return (
        <div
            className={clsx(
                styles.container,
                isMobile && styles.containerMobile
            )}
        >
            <div className={clsx(styles.main, isMobile && styles.mainMobile)}>
                <Nav
                    className={clsx(styles.nav, isMobile && styles.navMobile)}
                    title="CRC Mint"
                    subTitle="Powered by BlackBone"
                />
                <div
                    className={clsx(
                        styles.contentWrap,
                        isMobile && styles.contentWrapMobile
                    )}
                >
                    <ControllerDashboardProvider>
                        <Preview
                            className={clsx(
                                styles.preview,
                                isMobile && styles.previewMobile
                            )}
                        />
                        <Dashboard className={styles.dashboard} />
                    </ControllerDashboardProvider>
                </div>
            </div>
        </div>
    );
};

export default App;
