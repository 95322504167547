import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { CandyMachineAccount } from "src/services/candyMachine";
import { CircularProgress } from "@material-ui/core";
import { GatewayStatus, useGateway } from "@civic/solana-gateway-react";
import { useEffect, useState, useRef } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
    findGatewayToken,
    getGatewayTokenAddressForOwnerAndGatekeeperNetwork,
    onGatewayTokenChange,
    removeAccountChangeListener,
} from "@identity.com/solana-gateway-ts";
import { CIVIC_GATEKEEPER_NETWORK } from "src/services/utils";
import useAlert from "src/hooks/useAlert";
import styles from "./Mint.module.scss";
import { LaunchLockedByType } from "../../types/launch";

export const CTAButton = styled(Button)`
    width: 100%;
    height: 60px;
    margin-top: 10px;
    margin-bottom: 5px;
    background: linear-gradient(52deg, #8876e7, #7399d1);
    border-radius: 14px !important;
    box-shadow: 0 0 12px #6568c587 !important;
    color: #201e26 !important;
    font-size: 1em !important;
    font-weight: bold !important;
    text-transform: none !important;
`; // add your own styles here

export const MintButton = ({
    onMint,
    candyMachine,
    isMinting,
    setIsMinting,
    isActive,
    isValidBalance,
    isInvited,
    lockedBy,
}: {
    onMint: () => Promise<void>;
    candyMachine?: CandyMachineAccount;
    isMinting: boolean;
    setIsMinting: (val: boolean) => void;
    isActive: boolean;
    isValidBalance: boolean;
    isInvited: boolean;
    lockedBy?: LaunchLockedByType;
}) => {
    const wallet = useWallet();
    const connection = useConnection();
    const [, setAlertState] = useAlert();
    const [verified, setVerified] = useState(false);
    const { requestGatewayToken, gatewayStatus } = useGateway();
    const [webSocketSubscriptionId, setWebSocketSubscriptionId] = useState(-1);
    const [clicked, setClicked] = useState(false);
    const [waitForActiveToken, setWaitForActiveToken] = useState(false);

    const getMintButtonContent = () => {
        if (!candyMachine && (wallet.connecting || wallet.connected)) {
            return "Connecting...";
        } else if (candyMachine?.state.isSoldOut) {
            return "SOLD OUT";
        } else if (isMinting) {
            return <CircularProgress />;
        } else if (
            candyMachine?.state.goLiveDate &&
            candyMachine?.state.goLiveDate.toNumber() >
                new Date().getTime() / 1000
        ) {
            return "Not Started Yet";
        } else if (
            candyMachine?.state.isPresale ||
            candyMachine?.state.isWhitelistOnly
        ) {
            return "WHITELIST MINT";
        }

        return "MINT";
    };

    useEffect(() => {
        const mint = async () => {
            await removeAccountChangeListener(
                connection.connection,
                webSocketSubscriptionId
            );
            await onMint();

            setClicked(false);
            setVerified(false);
        };
        if (verified && clicked) {
            mint();
        }
    }, [
        verified,
        clicked,
        connection.connection,
        onMint,
        webSocketSubscriptionId,
    ]);

    const previousGatewayStatus = usePrevious(gatewayStatus);
    useEffect(() => {
        const fromStates = [
            GatewayStatus.NOT_REQUESTED,
            GatewayStatus.REFRESH_TOKEN_REQUIRED,
        ];
        const invalidToStates = [...fromStates, GatewayStatus.UNKNOWN];
        if (
            fromStates.find((state) => previousGatewayStatus === state) &&
            !invalidToStates.find((state) => gatewayStatus === state)
        ) {
            setIsMinting(true);
        }
        console.log("change: ", GatewayStatus[gatewayStatus]);
    }, [waitForActiveToken, previousGatewayStatus, gatewayStatus]);

    useEffect(() => {
        if (waitForActiveToken && gatewayStatus === GatewayStatus.ACTIVE) {
            console.log("Minting after token active");
            setWaitForActiveToken(false);
            onMint();
        }
    }, [waitForActiveToken, gatewayStatus, onMint]);

    return (
        <CTAButton
            className={styles.mintButton}
            disabled={isMinting || !candyMachine}
            onClick={async () => {
                if (lockedBy == "updating_price") {
                    setAlertState({
                        open: true,
                        message: `Mint price is updating! Please wait a few short minutes.`,
                        severity: "error",
                        hideDuration: 3000,
                    });
                    return;
                }

                if (!isInvited) {
                    setAlertState({
                        open: true,
                        message: `Can't mint without invitation! Use your invite link to get started.`,
                        severity: "error",
                        hideDuration: 3000,
                    });
                    return;
                }

                if (!isValidBalance) {
                    setAlertState({
                        open: true,
                        message: `Not enough balance!`,
                        severity: "error",
                        hideDuration: 3000,
                    });
                    return;
                }

                if (!isActive) {
                    setAlertState({
                        open: true,
                        message: `Mint is not active now!`,
                        severity: "error",
                        hideDuration: 3000,
                    });
                    return;
                }

                if (
                    candyMachine?.state.isActive &&
                    candyMachine?.state.gatekeeper
                ) {
                    const network =
                        candyMachine.state.gatekeeper.gatekeeperNetwork.toBase58();
                    if (network === CIVIC_GATEKEEPER_NETWORK) {
                        if (gatewayStatus === GatewayStatus.ACTIVE) {
                            await onMint();
                        } else {
                            // setIsMinting(true);
                            setWaitForActiveToken(true);
                            await requestGatewayToken();
                            console.log("after: ", gatewayStatus);
                        }
                    } else if (
                        network ===
                            "ttib7tuX8PTWPqFsmUFQTj78MbRhUmqxidJRDv4hRRE" ||
                        network ===
                            "tibePmPaoTgrs929rWpu755EXaxC7M3SthVCf6GzjZt"
                    ) {
                        setClicked(true);
                        const gatewayToken = await findGatewayToken(
                            connection.connection,
                            wallet.publicKey!,
                            candyMachine.state.gatekeeper.gatekeeperNetwork
                        );

                        if (gatewayToken?.isValid()) {
                            await onMint();
                        } else {
                            window.open(
                                `https://verify.encore.fans/?gkNetwork=${network}`,
                                "_blank"
                            );

                            const gatewayTokenAddress =
                                await getGatewayTokenAddressForOwnerAndGatekeeperNetwork(
                                    wallet.publicKey!,
                                    candyMachine.state.gatekeeper
                                        .gatekeeperNetwork
                                );

                            setWebSocketSubscriptionId(
                                onGatewayTokenChange(
                                    connection.connection,
                                    gatewayTokenAddress,
                                    () => setVerified(true),
                                    "confirmed"
                                )
                            );
                        }
                    } else {
                        setClicked(false);
                        throw new Error(
                            `Unknown Gatekeeper Network: ${network}`
                        );
                    }
                } else {
                    await onMint();
                    setClicked(false);
                }
            }}
            variant="contained"
        >
            {getMintButtonContent()}
        </CTAButton>
    );
};

function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
