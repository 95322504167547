import { useWallet } from "@solana/wallet-adapter-react";
import { Transaction } from "@solana/web3.js";
import { FC, useMemo, useState } from "react";
import { InitializeShopPaymentParams } from "../../api/shop";
import useAlert from "../../hooks/useAlert";
import useConnection from "../../hooks/useConnection";
import useSettings from "../../hooks/useSettings";
import useShop, { MakePaymentError } from "../../hooks/useShop";
import useUserInfo from "../../hooks/useUserInfo";
import Alert from "../../libs/alert/Alert";
import Dialog from "../../libs/dialog/Dialog";
import Feature from "../../libs/feature/Feature";
import FormButton from "../../libs/form-button/FormButton";
import NumberCount from "../../libs/number-count/NumberCount";
import Pair from "../../libs/pair/Pair";
import { CampaignType } from "../../types/campaign";
import styles from "./DashboardInvest.module.scss";

type Props = {
    className?: string;
    open: boolean;
    onClose(): void;
    campaign: CampaignType;
};

const DashboardInvestCampaignsInvest: FC<Props> = ({
    className,
    open,
    onClose,
    campaign,
}) => {
    const { selectedCurrency } = useSettings();
    const { makePayment, callbackPayment } = useShop();
    const [, setAlertState] = useAlert();
    const { connection } = useConnection();
    const { sendTransaction } = useWallet();
    const { userInfo, updateUserInfo } = useUserInfo();
    const [fundsAmount, setFundsAmount] = useState(campaign.min_fund_usd);
    const [payLoading, setPayLoading] = useState<boolean>(false);
    const [payLoadingText, setPayLoadingText] = useState<string>();

    const mintCount: number = useMemo(() => {
        return userInfo?.mints_info?.approved_mint_count || 0;
    }, [userInfo?.mints_info]);

    const fundsMax: number = useMemo(() => {
        return Math.min(
            campaign.max_fund_usd,
            campaign.target_fund_usd - campaign.active_fund_usd
        );
    }, []);

    const handleAddFunds = async () => {
        if (!selectedCurrency) return;

        // start loading
        setPayLoading(true);
        setPayLoadingText("Creating Transaction");

        // make payemnt
        try {
            // prepare payment
            const initializePayment: InitializeShopPaymentParams = {
                client_currency_id: selectedCurrency.data.id,
                associated_with: "campaign",
                cart_items: [
                    {
                        associated_id: campaign.id,
                        quantity: fundsAmount / campaign.step_fund_usd,
                    },
                ],
            };

            // make payment
            const { transactionBase64, reference } = await makePayment(
                initializePayment
            ).catch((error) => {
                throw new MakePaymentError(error);
            });

            // Deserialize and send the transaction
            setPayLoadingText("Waiting for Approval");

            const transaction = Transaction.from(
                Buffer.from(transactionBase64, "base64")
            );
            await sendTransaction(transaction, connection);

            // callback payment
            setPayLoadingText("Submitting Order");

            await callbackPayment({ reference_pub: reference });

            // update user info
            await updateUserInfo();

            // success payment
            setPayLoading(false);

            setAlertState({
                open: true,
                message: "Your payment was successful!",
                severity: "success",
            });

            onClose();
        } catch (e) {
            const errorMessage =
                e instanceof MakePaymentError ? e.message : "Payment Failed!";

            console.error(e);

            setAlertState({
                open: true,
                message: errorMessage,
                severity: "error",
            });

            setPayLoading(false);
            return;
        }
    };

    return (
        <Dialog
            className={className}
            onClose={onClose}
            open={open}
            boxProps={{
                title: `Add funds to Campaign ${campaign.campaign_tag}`,
            }}
            maxWidth="xs"
        >
            {mintCount == 0 && (
                <Alert severity="warning" className={styles.addFundsAlert}>
                    You need to mint at least one CRC NFT to be able to add
                    funds.
                </Alert>
            )}

            <div className={styles.addFundsDescription}>
                Please note that by adding funds, you are confirming your
                understanding and acceptance of the{" "}
                <a
                    href="https://docs.forixo.io/terms-and-conditions"
                    rel="noreferrer"
                    target="_blank"
                >
                    Terms and Conditions
                </a>{" "}
                governing our forex AI-trading investment.
            </div>

            <Feature className={styles.addFundsSettings}>
                <Pair
                    title="Funds (USD)"
                    titlePosition="start"
                    value={
                        <NumberCount
                            num={fundsAmount}
                            min={campaign.min_fund_usd}
                            max={fundsMax}
                            step={campaign.step_fund_usd}
                            numChange={setFundsAmount}
                        />
                    }
                />
            </Feature>

            <div className={styles.addFundsButtonGroup}>
                <FormButton color="base" onClick={onClose}>
                    Cancel
                </FormButton>
                <FormButton
                    className={styles.addFundsButton}
                    loading={payLoading}
                    loadingtText={payLoadingText}
                    disabled={mintCount == 0}
                    onClick={handleAddFunds}
                >
                    {`Add Funds (${fundsAmount.toLocaleString()} ${selectedCurrency?.pair
                        })`}

                    {/* {selectedCurrency?.data.shares?.items.map(
                        (currencyShare: ClientCurrencyShareType) => {
                            return (
                                <div
                                    key={currencyShare.id}
                                    className={styles.checkoutTotalShare}
                                >
                                    <Price
                                        key={currencyShare.id}
                                        priceSource="primary"
                                        price={fundsAmount}
                                        coin={currencyShare.coin}
                                        fiat={currencyShare.fiat}
                                    />
                                </div>
                            );
                        }
                    )} */}
                </FormButton>
            </div>
        </Dialog>
    );
};

export default DashboardInvestCampaignsInvest;
