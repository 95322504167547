import { FC, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { BiSearch } from "react-icons/bi";
import { MdOutlineClear } from "react-icons/md";
import { UseApi, UseApiActionType } from "../../hooks/useApi";
import FormField from "../../libs/form-field/FormField";
import styles from "./Plat.module.scss";

type Props = {
    className?: string;
    title: string;
    api: UseApi<any, any>;
    hideSearch?: boolean;
};

const PlatHeader: FC<Props> = ({ className, title, api, hideSearch }) => {
    const searchInput = useRef<HTMLInputElement | null>(null);
    const [searchQuery, setSearchQuery] = useState("");

    const isFiltered = useMemo(() => {
        return searchQuery.length > 0;
    }, [searchQuery]);

    useEffect(() => {
        const delayTimeout = setTimeout(() => {
            api.dispatch!({
                type: UseApiActionType.STATE_UPDATE,
                newState: { query: searchQuery },
            });
        }, 300);

        return () => clearTimeout(delayTimeout);
    }, [searchQuery]);

    const clearSearchInput = () => {
        setSearchQuery("");

        if (searchInput.current) {
            searchInput.current.value = "";
        }
    };

    return (
        <div className={clsx(styles.header, className)}>
            <div className={styles.titleGroup}>
                <div className={styles.title}>{title}</div>
                <div className={styles.total}>
                    {`${isFiltered ? "Filtered Total" : "Total"}: ${
                        !api.state.loading ? api.state.totalNum || 0 : "..."
                    }`}
                </div>
            </div>

            {!hideSearch && (
                <FormField
                    className={clsx(
                        styles.searchField,
                        searchQuery.length > 0 && styles.searchFieldExpand
                    )}
                >
                    <BiSearch className={styles.searchIcon} />
                    <input
                        ref={searchInput}
                        placeholder="Search..."
                        disabled={!isFiltered && api.state.totalNum == 0}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {searchQuery.length > 0 && (
                        <MdOutlineClear
                            className={styles.clearIcon}
                            onClick={clearSearchInput}
                        />
                    )}
                </FormField>
            )}
        </div>
    );
};

export default PlatHeader;
