import { FC, PropsWithChildren } from "react";
import clsx from "clsx";
import { Scrollbars } from "react-custom-scrollbars-2";
import styles from "./ScrollBar.module.scss";

type Props = {
    className?: string;
    ignoreScroll?: boolean;
    style?: any;
};

const ScrollBar: FC<PropsWithChildren<Props>> = ({
    className,
    children,
    ignoreScroll,
}) => {
    return (
        <>
            {!ignoreScroll ? (
                // @ts-ignore
                <Scrollbars
                    className={clsx(styles.scrollWrap, className)}
                    renderThumbVertical={(props) => (
                        <div {...props} className={styles.scrollThumb} />
                    )}
                    universal={true}
                >
                    {children}
                </Scrollbars>
            ) : (
                <div className={clsx(styles.scrollWrap, className)}>
                    {children}
                </div>
            )}
        </>
    );
};

export default ScrollBar;
