import { FC, useEffect, useState } from "react";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import Feature from "src/libs/feature/Feature";
import State, { StateType } from "src/libs/state/State";
import NetworkTree from "../../libs/network-tree/NetworkTree";
import useResponsive from "../../hooks/useResponsive";
import { InviteCircleType, InviteInfoShortType } from "../../types/invite";
import useUserInfo from "../../hooks/useUserInfo";
import { Api } from "../../api/root";
import useSettings from "../../hooks/useSettings";
import styles from "./DashboardInvites.module.scss";
import { UserShortType } from "../../types/user";

type Props = {
    className?: string;
};

const DashboardInvitesNetworksTree: FC<Props> = ({ className }) => {
    const [isMobile] = useResponsive();
    const { userInfo, fullConnected } = useUserInfo();
    const { settings } = useSettings();
    const [nestedInviteUser, setNestedInviteUser] = useState<
        InviteInfoShortType[]
    >([]);
    const [currentInviteUser, setCurrentInviteUser] =
        useState<InviteInfoShortType>();

    const api = Api.invites().useGetInviteCircles({
        perPage: 30,
    });

    useEffect(() => {
        api.run({
            circle_num: 1,
            sub_user_id: currentInviteUser?.user_short?.id || 0,
        });
        return () => api.abort();
    }, [
        fullConnected,
        settings?.launch.id,
        currentInviteUser,
        api.state.pageNum,
        api.state.perPage,
    ]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [api.state.data]);

    const renderTreeCenter = () => {
        let invited_user_count = 0;
        let network_user_count = 0;
        if (!currentInviteUser) {
            invited_user_count =
                userInfo?.invites_info?.active_invited_user_count || 0;
            network_user_count =
                userInfo?.invites_info?.active_network_user_count || 0;
        } else {
            invited_user_count = currentInviteUser.active_invited_user_count;
            network_user_count = currentInviteUser.active_network_user_count;
        }

        return (
            <div className={styles.treeCenterWrap}>
                <div className={styles.title}>
                    {!currentInviteUser ? (
                        "My Circle"
                    ) : (
                        <span
                            data-tip={
                                currentInviteUser.user_short?.sol_wallet_short
                            }
                        >
                            {currentInviteUser.user_short?.display_name ||
                                currentInviteUser.user_short?.sol_wallet_short}
                        </span>
                    )}
                </div>
                {!api.state.loading ? (
                    <>
                        <div className={styles.sub}>
                            Invites: {invited_user_count}
                        </div>
                        <div className={styles.sub}>
                            Network: {network_user_count}
                        </div>
                    </>
                ) : (
                    <Skeleton width={70} />
                )}
            </div>
        );
    };

    const renderTreeItem = (inviteCircle: InviteCircleType) => {
        return (
            <div className={styles.treeItemWrap}>
                <div
                    className={styles.title}
                    data-tip={
                        inviteCircle.invited_user_info?.user_short
                            ?.sol_wallet_short
                    }
                >
                    {inviteCircle.invited_user_info?.user_short?.display_name ||
                        inviteCircle.invited_user_info?.user_short
                            ?.sol_wallet_short}
                </div>
                <div className={styles.sub}>
                    Invites:{" "}
                    {inviteCircle.invited_user_info
                        ?.active_invited_user_count || 0}
                </div>
            </div>
        );
    };

    const onSelectInviteCircle = (inviteCircle: InviteCircleType) => {
        nestedInviteUser.push(inviteCircle.invited_user_info);
        updateNestedInviteUser(nestedInviteUser);
    };

    const onSubMessageBack = () => {
        nestedInviteUser.pop();
        updateNestedInviteUser(nestedInviteUser);
    };

    const updateNestedInviteUser = (
        nestedInviteUser: InviteInfoShortType[]
    ) => {
        setNestedInviteUser([...nestedInviteUser]);
        setCurrentInviteUser(
            nestedInviteUser.length > 0
                ? nestedInviteUser[nestedInviteUser.length - 1]
                : undefined
        );
    };

    if (api.state.loading || !!api.state.data) {
        return (
            <div
                className={clsx(
                    styles.networkTreeWrap,
                    isMobile && styles.networkTreeWrapMobile
                )}
            >
                <NetworkTree
                    treeCenter={renderTreeCenter()}
                    treeList={
                        api.state.data?.map((inviteCircle) => {
                            return {
                                content: renderTreeItem(inviteCircle),
                                onSelect: () =>
                                    onSelectInviteCircle(inviteCircle),
                            };
                        }) || []
                    }
                    loading={api.state.loading}
                    renderSubMessage={
                        currentInviteUser ? (
                            <div className={styles.treeMessageTitle}>
                                <span
                                    className={styles.title}
                                    data-tip={
                                        currentInviteUser.user_short
                                            ?.sol_wallet_short
                                    }
                                >
                                    {currentInviteUser.user_short
                                        ?.display_name ||
                                        currentInviteUser.user_short
                                            ?.sol_wallet_short}
                                </span>
                                <span className={styles.sub}>
                                    (Circle {nestedInviteUser.length + 1})
                                </span>
                            </div>
                        ) : null
                    }
                    onSubMessageBack={onSubMessageBack}
                />
            </div>
        );
    } else {
        return (
            <Feature type="message">
                <State type={StateType.NO_DOCUMENTS}>
                    No invited user yet!
                </State>
            </Feature>
        );
    }
};

export default DashboardInvitesNetworksTree;
