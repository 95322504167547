import { FC, useMemo, useState } from "react";
import clsx from "clsx";
import useUserInfo from "../../hooks/useUserInfo";
import { FaCarrot } from "react-icons/fa";
import { abbreviateNum } from "src/services/number";
import ProfilePointsInfo from "./ProfilePointsInfo";
import styles from "./Profile.module.scss";

type Props = {
    className?: string;
};

const ProfilePoints: FC<Props> = ({ className }) => {
    const { userInfo } = useUserInfo();
    const [infoOpen, setInfoOpen] = useState(false);

    const pointsAbbr = useMemo(() => {
        const points = userInfo?.points?.total_points || 0;
        if (points > 0) {
            return abbreviateNum(points, 2, 1, true);
        } else {
            return "0.00";
        }
    }, [userInfo?.points]);

    return (
        <>
            <div
                className={clsx(styles.pointsWrap, className)}
                onClick={() => setInfoOpen(true)}
            >
                <FaCarrot className={styles.icon} />
                {userInfo && <span className={styles.num}>{pointsAbbr}</span>}
            </div>

            {infoOpen && (
                <ProfilePointsInfo
                    open={infoOpen}
                    onClose={() => setInfoOpen(false)}
                />
            )}
        </>
    );
};

export default ProfilePoints;
