import { FC, PropsWithChildren } from "react";
import clsx from "clsx";
import { FiCopy } from "react-icons/fi";
import styles from "./Action.module.scss";

type ActionCopyType = {
    type: "copy";
    config: {
        value: string;
        onCopy?(): void;
    };
};

type ActionVisibleType = {
    type: "visible";
    config?: {
        preventInvisible?: boolean;
    };
};

type Props = {
    className?: string;
    action: ActionCopyType | ActionVisibleType;
    actionPosition?: "start" | "end"; // default: end
};

const Action: FC<PropsWithChildren<Props>> = ({
    className,
    children,
    action,
    actionPosition,
}) => {
    const handleCopy = () => {
        if (action.type == "copy") {
            navigator.clipboard.writeText(action.config.value);

            if (action.config.onCopy) action.config.onCopy();
        }
    };

    return (
        <div
            className={clsx(
                styles.actionWrap,
                actionPosition == "start" && styles.actionWrapStart,
                className
            )}
        >
            {action.type == "copy" && (
                <FiCopy
                    className={clsx(styles.copyButton)}
                    data-tip="Copy"
                    onClick={handleCopy}
                />
            )}
            {children}
        </div>
    );
};

export default Action;
