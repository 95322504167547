import clsx from "clsx";
import moment from "moment";
import Column from "rc-table/lib/sugar/Column";
import { FC } from "react";
import { Api } from "../../api/root";
import {
    assetRewardReasonTitles,
    AssetRewardReasonType,
    AssetRewardType,
} from "../../types/asset";
import Plat from "../plat/Plat";
import Price from "../price/Price";
import { walletBags } from "./WalletDialog";
import useSettings from "../../hooks/useSettings";
import styles from "./Wallet.module.scss";

type Props = {
    className?: string;
};

const WalletRewards: FC<Props> = ({ className }) => {
    const { settings } = useSettings();

    const api = Api.assets().useGetAssetRewards({
        perPage: 2,
    });

    const dialogApi = Api.assets().useGetAssetRewards({
        perPage: 6,
    });

    return (
        <Plat
            className={clsx(className)}
            title="Rewards"
            api={api}
            needAuth={true}
            hideHeader={true}
            onApiRequest={(params, options) => {
                api.run(
                    {
                        ...(params as any),
                        launch_id: settings?.launch.id,
                    },
                    options
                );
            }}
            minimal={{
                enable: true,
                dialogApi,
                onDialogApiRequest: (params, options) => {
                    dialogApi.run(
                        {
                            ...(params as any),
                            launch_id: settings?.launch.id,
                        },
                        options
                    );
                },
            }}
            renderTableColumns={() => {
                return (
                    <>
                        <Column
                            title="Wallet"
                            dataIndex={["asset", "bag_tag"]}
                            key="bag_tag"
                            width={30}
                            render={(bag_tag) =>
                                walletBags.find(
                                    (walletBag) =>
                                        walletBag.asset_bag == bag_tag
                                )?.title
                            }
                        />
                        <Column
                            title="Source"
                            dataIndex={["reason"]}
                            key="reason"
                            width={30}
                            render={(reason: AssetRewardReasonType) => (
                                <span
                                    className={clsx(
                                        styles.cellMark,
                                        styles.cellInfo
                                    )}
                                >
                                    {assetRewardReasonTitles[reason]}
                                </span>
                            )}
                        />
                        <Column
                            title="Amount"
                            dataIndex={["amount"]}
                            key="amount"
                            width={30}
                            render={(amount, assetReward: AssetRewardType) => (
                                <Price
                                    priceSource="none"
                                    price={amount}
                                    coin={assetReward.coin}
                                />
                            )}
                        />
                        <Column
                            title="Rewarded"
                            dataIndex={["rewarded_date"]}
                            key="rewarded_date"
                            width={50}
                            render={(rewarded_date) => (
                                <span
                                    data-tip={
                                        rewarded_date &&
                                        moment.unix(rewarded_date).format()
                                    }
                                >
                                    {rewarded_date
                                        ? moment.unix(rewarded_date).fromNow()
                                        : "_"}
                                </span>
                            )}
                        />
                    </>
                );
            }}
        />
    );
};

export default WalletRewards;
