import { FC } from "react";
import clsx from "clsx";
import Dialog from "../../libs/dialog/Dialog";
import ProfileConnect from "./ProfileConnect";
import Feature from "../../libs/feature/Feature";
import Pair from "../../libs/pair/Pair";
import { AiOutlineUser } from "react-icons/ai";
import useUserInfo from "../../hooks/useUserInfo";
import styles from "./Profile.module.scss";
import { GoInfo } from "react-icons/go";

type Props = {
    className?: string;
    open: boolean;
    onClose(): void;
};

const fileBaseUrl = process.env.REACT_APP_FILE_URL;

const ProfileOverview: FC<Props> = ({ className, open, onClose }) => {
    const { userInfo } = useUserInfo();

    return (
        <Dialog
            className={clsx(className)}
            onClose={onClose}
            open={open}
            maxWidth="xs"
            boxProps={{
                title: "User Profile",
            }}
        >
            <div className={styles.overviewWrap}>
                <div className={styles.overviewAvatar}>
                    {userInfo?.avatar ? (
                        <img
                            className={styles.image}
                            src={`${fileBaseUrl}/${userInfo.avatar.download_key}.${userInfo.avatar.format}`}
                        />
                    ) : (
                        <AiOutlineUser className={styles.icon} />
                    )}
                </div>
                <Pair
                    className={styles.overviewName}
                    title="Display Name"
                    value={userInfo?.display_name || "—"}
                />
            </div>

            <Feature className={styles.overviewLinkWrap}>
                <ProfileConnect requestSource="profile" platform="discord" />
                <div className={styles.overviewDiscordHint}>
                    <GoInfo className={styles.icon} />
                    <span className={styles.content}>
                        By linking, your CRC profile will automatically sync to
                        your Discord profile.
                    </span>
                </div>
            </Feature>

            <Feature className={styles.overviewLinkWrap}>
                <ProfileConnect requestSource="profile" platform="twitter" />
            </Feature>
        </Dialog>
    );
};

export default ProfileOverview;
