import clsx from "clsx";
import { FC, useMemo, useState } from "react";
import { MdOutlineHistory } from "react-icons/md";
import Feature from "../../libs/feature/Feature";
import DashboardInvestFundsHistory from "./DashboardInvestFundsHistory";
import DashboardInvestCampaignsStatus from "./DashboardInvestCampaignsStatus";
import FormButton from "../../libs/form-button/FormButton";
import Pair from "../../libs/pair/Pair";
import { InvestFundType } from "../../types/invest";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { RiFundsLine } from "react-icons/ri";
import DashboardInvestCampaignsGrant from "./DashboardInvestCampaignsGrant";
import Alert from "../../libs/alert/Alert";
import AlertMini from "../../libs/alert-mini/AlertMini";
import { GoInfo } from "react-icons/go";
import { IoMdExit } from "react-icons/io";
import DashboardInvestFundsWithdraw from "./DashboardInvestFundsWithdraw";
import styles from "./DashboardInvest.module.scss";

type Props = {
    className?: string;
    loading?: boolean;
    investFund?: InvestFundType;
};

const DashboardInvestFundsItem: FC<Props> = ({
    className,
    loading,
    investFund,
}) => {
    const [openHistory, setOpenHistory] = useState(false);
    const [openGrant, setOpenGrant] = useState(false);
    const [openWithdraw, setOpenWithdraw] = useState(false);

    const maxFundUsd = useMemo(() => {
        if (!investFund) return 0;

        let maxFund = investFund.campaign.active_fund_usd;
        if (!investFund.campaign.has_ran) {
            maxFund = investFund.campaign.target_fund_usd;
        }

        return maxFund;
    }, [investFund]);

    const fundedPercent = useMemo(() => {
        if (!investFund) return 0;

        return Math.round((investFund.funded_usd / maxFundUsd) * 100);
    }, [investFund, maxFundUsd]);

    const nextGainDate = useMemo(() => {
        return investFund?.campaign.next_gain?.pay_planned_date || null;
    }, [investFund]);

    if (loading) {
        return (
            <Feature className={className}>
                <Skeleton width={230} />
                <Skeleton width={100} />
            </Feature>
        );
    } else if (!investFund) {
        return <></>;
    }

    return (
        <Feature className={clsx(styles.fundWrap, className)}>
            {investFund.is_funding && (
                <div className={styles.fundAlertWrap}>
                    <Alert severity="info">
                        Please wait for the transaction to be audited...
                    </Alert>
                </div>
            )}
            {investFund.has_failed_fund && (
                <div className={styles.fundAlertWrap}>
                    <Alert severity="error">
                        Auditing transaction failed! Please contact support.
                    </Alert>
                </div>
            )}

            <div className={styles.fundInfoWrap}>
                <Pair
                    className={styles.fundInfoPair}
                    title={
                        <>
                            {investFund.campaign.has_ran ? (
                                <>
                                    <span>Next payout: </span>
                                    {nextGainDate ? (
                                        <span
                                            data-tip={
                                                nextGainDate &&
                                                moment
                                                    .unix(nextGainDate)
                                                    .format()
                                            }
                                        >
                                            {moment
                                                .unix(nextGainDate!)
                                                .fromNow()}
                                        </span>
                                    ) : (
                                        "N/A"
                                    )}
                                </>
                            ) : investFund.campaign.full_funded ? (
                                <>
                                    <GoInfo />
                                    <span>
                                        Campaign will start in less than 24
                                        hours
                                    </span>
                                </>
                            ) : (
                                <>
                                    <GoInfo />
                                    <span>
                                        Campaign will activate after fund
                                        completion
                                    </span>
                                </>
                            )}
                        </>
                    }
                    titlePosition="bottom"
                    titleClassname={styles.fundInfoPairTitle}
                    value={
                        <div className={styles.fundInfoTitle}>
                            <span>{`Campaign ${investFund.campaign.campaign_tag}`}</span>
                            <DashboardInvestCampaignsStatus
                                campaign={investFund.campaign}
                                isLive={true}
                                withBackground={true}
                            />
                        </div>
                    }
                />

                {/* <FormButton
                    className={styles.fundsButton}
                    color="mute"
                    data-tip="My Gains"
                    onClick={() => setOpenHistory(true)}
                >
                    <TbCurrencyDollar className={styles.icon} />
                </FormButton> */}

                <FormButton
                    className={styles.fundsButton}
                    color="mute"
                    data-tip="My Funds History"
                    onClick={() => setOpenHistory(true)}
                >
                    <MdOutlineHistory className={styles.icon} />
                </FormButton>

                {investFund.campaign.view_grant && (
                    <FormButton
                        className={styles.fundsButton}
                        color="mute"
                        data-tip="Access Trades"
                        onClick={() => setOpenGrant(true)}
                    >
                        <RiFundsLine className={styles.icon} />
                    </FormButton>
                )}

                {investFund.funded_usd > 0 && investFund.campaign.has_ran && (
                    <FormButton
                        className={styles.fundsButton}
                        color="mute"
                        data-tip="Withdraw Funds"
                        onClick={() => setOpenWithdraw(true)}
                    >
                        <IoMdExit className={styles.icon} />
                    </FormButton>
                )}
            </div>

            <div className={styles.fundDetailsWrap}>
                <Pair
                    className={styles.fundDetailsPair}
                    title="My Funds"
                    titlePosition="bottom"
                    value={
                        <div className={styles.fundDetailsValue}>
                            <div>
                                {`$${investFund.funded_usd.toLocaleString()}`}
                            </div>

                            {investFund.funding_usd > 0 ? (
                                <AlertMini
                                    withBackground={true}
                                    hideIcon={true}
                                    severity="info"
                                >
                                    {`$${investFund.funding_usd.toLocaleString()} Pending`}
                                </AlertMini>
                            ) : investFund.withdrawing_usd > 0 ? (
                                <AlertMini
                                    withBackground={true}
                                    hideIcon={true}
                                    severity="info"
                                >
                                    {`$${investFund.withdrawing_usd.toLocaleString()} Withdrawing`}
                                </AlertMini>
                            ) : (
                                <div
                                    className={styles.fundDetailsValueExtra}
                                    data-tip={`${fundedPercent}% of $${maxFundUsd.toLocaleString()} campaign`}
                                >
                                    {`${fundedPercent}%`}
                                </div>
                            )}
                        </div>
                    }
                />
                <Pair
                    className={styles.fundDetailsPair}
                    title="My Gains"
                    titlePosition="bottom"
                    value={`$${investFund.total_income_usd.toLocaleString()}`}
                />
                <Pair
                    className={styles.fundDetailsPair}
                    title="Started Date"
                    titlePosition="bottom"
                    value={
                        <span
                            data-tip={
                                investFund.campaign.ran_date &&
                                moment
                                    .unix(investFund.campaign.ran_date)
                                    .format()
                            }
                        >
                            {investFund.campaign.ran_date
                                ? moment
                                      .unix(investFund.campaign.ran_date)
                                      .fromNow()
                                : "—"}
                        </span>
                    }
                />
            </div>

            {openHistory && (
                <DashboardInvestFundsHistory
                    investFund={investFund}
                    open={openHistory}
                    onClose={() => setOpenHistory(false)}
                />
            )}

            {openGrant && (
                <DashboardInvestCampaignsGrant
                    campaign={investFund.campaign}
                    open={openGrant}
                    onClose={() => setOpenGrant(false)}
                />
            )}

            {openWithdraw && (
                <DashboardInvestFundsWithdraw
                    investFund={investFund}
                    open={openWithdraw}
                    onClose={() => setOpenWithdraw(false)}
                />
            )}
        </Feature>
    );
};

export default DashboardInvestFundsItem;
