import { FC, useMemo, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { BiLinkExternal } from "react-icons/bi";
import { MintUnitType } from "src/types/mint";
import Box from "src/libs/box/Box";
import useResponsive from "src/hooks/useResponsive";
import Feature from "../../libs/feature/Feature";
import Pair from "../../libs/pair/Pair";
import FormButton from "../../libs/form-button/FormButton";
import DashboardNftsItemUpgrade from "./DashboardNftsItemUpgrade";
import { GradeTagType, gradeTitles, GradeType } from "../../types/grade";
import Hint from "../../libs/hint/Hint";
import useUserInfo from "../../hooks/useUserInfo";
import styles from "./DashboardNfts.module.scss";

type Props = {
    className?: string;
    nft: MintUnitType;
    reloadList(): void;
};

type GradeStatusType =
    | "auditing"
    | "rejected"
    | "syncing"
    | "synced"
    | "ready_to_upgrade";

const imageWrapGradeStyles: Record<GradeTagType, string> = {
    standard: styles.imageWrapStandard,
    bronze: styles.imageWrapBronze,
    silver: styles.imageWrapSilver,
    gold: styles.imageWrapGold,
    platinum: styles.imageWrapPlatinum,
};

const DashboardNftsItem: FC<Props> = ({ className, nft, reloadList }) => {
    const [isMobile] = useResponsive();
    const { userInfo } = useUserInfo();
    const [isHovering, setIsHovering] = useState(false);
    const [openUpgrade, setOpenUpgrade] = useState<boolean>(false);

    const isUpgraded = useMemo(() => {
        return nft.grade && !nft.grade.is_default;
    }, [nft.grade]);

    const nextAvailableGrade: GradeType | null = useMemo(() => {
        let _nextAvailableGrade: GradeType | null = null;
        for (const gradeStatItem of userInfo?.grades_info.grade_stats?.items ||
            []) {
            const isNextLevel =
                nft.grade &&
                nft.grade.level_num + 1 == gradeStatItem.grade.level_num;

            if (isNextLevel && gradeStatItem.available_nft_count > 0) {
                _nextAvailableGrade = gradeStatItem.grade;
                break;
            }
        }

        return _nextAvailableGrade;
    }, [userInfo?.grades_info]);

    const gradeStatus: GradeStatusType = useMemo(() => {
        if (nft.status == "auditing") {
            return "auditing";
        } else if (nft.status == "rejected") {
            return "rejected";
        } else if (nft.is_meta_data_syncing) {
            return "syncing";
        } else if (nextAvailableGrade) {
            return "ready_to_upgrade";
        } else {
            return "synced";
        }
    }, [nft, nextAvailableGrade]);

    const renderNftStatus = () => {
        if (gradeStatus == "auditing") {
            return (
                <>
                    <span>Auditing</span>
                    <Hint tip="The audit process for your NFT is underway, and verification will follow shortly." />
                </>
            );
        } else if (gradeStatus == "rejected") {
            return (
                <>
                    <span>Rejected</span>
                    <Hint tip="An unexpected issue has occurred. Please contact support for a reassessment of the denial." />
                </>
            );
        } else if (gradeStatus == "synced") {
            return (
                <>
                    <span>Synced</span>
                    <Hint tip="Your NFT's metadata is synced to its grade!" />
                </>
            );
        } else if (gradeStatus == "syncing") {
            return (
                <>
                    <span>Upgrading</span>
                    <Hint tip="Your NFT is currently upgrading to the new grade and it's metadata will sync soon." />
                </>
            );
        } else if (gradeStatus == "ready_to_upgrade") {
            return (
                <>
                    <span>Ready</span>
                    <Hint tip="You have met the requirements to upgrade your NFT to next grade!" />
                </>
            );
        }
    };

    const handleUpgradeClose = (reload?: boolean) => {
        console.log(reload);
        // close dialog
        setOpenUpgrade(false);

        // reload if requested
        if (reload) {
            reloadList();
        }
    };

    return (
        <Box contentClassName={clsx(styles.nftItem, className)} type="round">
            <a
                href={`https://solscan.io/token/${nft.mint_address}`}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <div
                    className={clsx(
                        styles.imageWrap,
                        isUpgraded && imageWrapGradeStyles[nft.grade?.tag!]
                    )}
                >
                    <img
                        className={clsx(
                            styles.image,
                            isMobile && styles.imageMobile
                        )}
                        src={nft.meta_data?.image_url || ""}
                        alt={nft.meta_data?.name}
                    />

                    <div className={styles.overlayShadow}></div>

                    <div
                        className={clsx(
                            styles.overlayLink,
                            isHovering && styles.overlayLinkShow
                        )}
                    >
                        <div className={styles.viewSolscan}>
                            <span>View on SolScan</span>
                            <BiLinkExternal className={styles.icon} />
                        </div>
                    </div>

                    {isUpgraded && (
                        <div className={styles.overlayGradeWrap}>
                            <div className={clsx(styles.overlayGrade)}>
                                <span>{gradeTitles[nft.grade?.tag!]}</span>
                            </div>
                        </div>
                    )}
                </div>
            </a>

            <div className={styles.titleWrap}>
                <div className={styles.topTitle}>
                    <div className={styles.title}>
                        {nft.meta_data?.name || "N/A"}
                    </div>
                    {nft.meta_data && (
                        <div className={styles.rarity} data-tip="Rarity Rank">
                            {`Rank ${nft.meta_data?.rarity_rank.toLocaleString()}`}
                        </div>
                    )}
                </div>
                <div className={styles.subTitle}>
                    {nft.mint_price &&
                        `${nft.mint_price} ${nft.coin.symbol} • `}
                    {moment.unix(nft.added_date).fromNow()}
                </div>
            </div>

            <Feature className={styles.nftGradeWrap}>
                <Pair
                    className={styles.nftUpgradeStatus}
                    title={
                        <div className={styles.nftUpgradeStatusLabel}>
                            Grade Status
                        </div>
                    }
                    titlePosition="top"
                    value={
                        <div className={styles.nftUpgradeStatusValue}>
                            {renderNftStatus()}
                        </div>
                    }
                />
                <FormButton
                    className={styles.nftUpgradeButton}
                    disabled={gradeStatus != "ready_to_upgrade"}
                    onClick={() => setOpenUpgrade(true)}
                >
                    Upgrade
                </FormButton>
            </Feature>

            {nextAvailableGrade && (
                <DashboardNftsItemUpgrade
                    nft={nft}
                    nextAvailableGrade={nextAvailableGrade}
                    open={openUpgrade}
                    onClose={handleUpgradeClose}
                />
            )}
        </Box>
    );
};

export default DashboardNftsItem;
