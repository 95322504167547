import { FC, ReactNode, useState } from "react";
import clsx from "clsx";
import AnimateHeight from "react-animate-height";
import { FiPlus, FiMinus } from "react-icons/fi";
import styles from "./FaqList.module.scss";

export type FaqItemType = {
    title: ReactNode;
    content: ReactNode;
};

type Props = {
    className?: string;
    list: FaqItemType[];
};

const FaqList: FC<Props> = ({ className, list }) => {
    const [openIndex, setOpenIndex] = useState<number>(-1);

    const handleTitleClick = (faqIndex: number) => {
        if (faqIndex != openIndex) {
            setOpenIndex(faqIndex);
        } else setOpenIndex(-1);
    };

    return (
        <div className={clsx(className)}>
            {list.map((faqItem, faqIndex) => {
                return (
                    <div
                        className={clsx(
                            styles.faqItem,
                            openIndex == faqIndex && styles.faqItemActive
                        )}
                        key={faqIndex}
                    >
                        <div
                            className={styles.faqTitle}
                            onClick={() => handleTitleClick(faqIndex)}
                        >
                            <span>{faqItem.title}</span>
                            {openIndex == faqIndex ? (
                                <FiMinus className={styles.icon} />
                            ) : (
                                <FiPlus className={styles.icon} />
                            )}
                        </div>

                        <AnimateHeight
                            duration={200}
                            height={openIndex == faqIndex ? "auto" : 0}
                        >
                            <div className={styles.faqContent}>
                                {faqItem.content}
                            </div>
                        </AnimateHeight>
                    </div>
                );
            })}
        </div>
    );
};

export default FaqList;
