import { CircleCommission, CommissionRule, InviteLeaderboardRule } from "src/types/invite";

export const commissionRules: CommissionRule[] = [
    {
        key: 1,
        mintRange: {
            fromEqual: 1,
        },
        invitedMintRange: {
            to: 15,
        },
        commission: 5,
    },
    {
        key: 2,
        mintRange: {
            fromEqual: 1,
        },
        invitedMintRange: {
            fromEqual: 15,
        },
        commission: 10,
    },
    {
        key: 3,
        mintRange: {
            fromEqual: 2,
        },
        invitedMintRange: {
            to: 15,
        },
        commission: 7,
    },
    {
        key: 4,
        mintRange: {
            fromEqual: 2,
        },
        invitedMintRange: {
            fromEqual: 15,
        },
        commission: 12,
    },
    {
        key: 5,
        mintRange: {
            fromEqual: 3,
        },
        invitedMintRange: {
            to: 15,
        },
        commission: 10,
    },
    {
        key: 6,
        mintRange: {
            fromEqual: 3,
        },
        invitedMintRange: {
            fromEqual: 15,
        },
        commission: 15,
    },
];

export const circleCommissions: CircleCommission[] = [
    {
        circle: 1,
        mint_commission: 15,
        invite_ib_commission_usd: 10,
    },
    {
        circle: 2,
        mint_commission: 10,
        invite_ib_commission_usd: 6,
    },
    {
        circle: 3,
        mint_commission: 5,
        invite_ib_commission_usd: 4,
    },
    {
        circle: 4,
        mint_commission: 3,
        invite_ib_commission_usd: 2,
    },
    {
        circle: 5,
        mint_commission: 2,
        invite_ib_commission_usd: 1,
    },
];

export const inviteLeaderboardRules: InviteLeaderboardRule[] = [
    {
        rank_range: {
            fixed: 1,
        },
        reward_sol: 3,
        has_one_by_one: true,
    },
    {
        rank_range: {
            fixed: 2,
        },
        reward_sol: 2,
        has_one_by_one: false,
    },
    {
        rank_range: {
            fixed: 3,
        },
        reward_sol: 1,
        has_one_by_one: false,
    }
];

