import { FC, ReactNode, useMemo, useState } from "react";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import Box from "src/libs/box/Box";
import Feature from "src/libs/feature/Feature";
import WalletOverview, {
    WalletType,
    WalletCoin,
} from "../wallet/WalletOverview";
import useUserInfo from "src/hooks/useUserInfo";
import { AssetCoinType } from "src/types/asset";
import WalletConnectText from "src/components/wallet-connect/WalletConnectText";
import Counter from "src/libs/counter/Counter";
import useSettings from "src/hooks/useSettings";
import Pair from "../../libs/pair/Pair";
import ProfileOverview from "../profile/ProfileOverview";
import Price from "../price/Price";
import WalletDialog from "../wallet/WalletDialog";
import styles from "./DashboardMint.module.scss";
import useTasks from "src/hooks/useTasks";

type Props = {
    className?: string;
};

const DashboardMintRewards: FC<Props> = ({ className }) => {
    const { userInfo, fullConnected, loadingUserInfo } = useUserInfo();
    const { settings, loadingSettings } = useSettings();
    const { findAvailableTask, availableTasks } = useTasks();
    const [openProfile, setOpenProfile] = useState<boolean>(false);
    const [openWallet, setOpenWallet] = useState<boolean>(false);

    const loading = useMemo(() => {
        return loadingUserInfo || loadingSettings;
    }, [loadingUserInfo, loadingSettings]);

    const joinToDiscordTask = useMemo(() => {
        return findAvailableTask("join_to_discord");
    }, [availableTasks]);

    const joinToTwitterTask = useMemo(() => {
        return findAvailableTask("join_to_twitter");
    }, [availableTasks]);

    const nftCount = useMemo(() => {
        if (!userInfo?.mints_info) return 0;

        return (
            userInfo.mints_info.auditing_mint_count +
            userInfo.mints_info.approved_mint_count
        );
    }, [userInfo?.mints_info]);

    const rewardedTasks = useMemo(() => {
        return userInfo?.tasks_info?.completed_tasks || 0;
    }, [userInfo?.tasks_info]);

    const getAssetAmount = (assetItem: AssetCoinType, type: WalletType) => {
        let amount = 0;
        if (type == "available") amount = assetItem.available_amount;
        else if (type == "withdrawing") amount = assetItem.withdrawing_amount;
        else if (type == "locked") amount = assetItem.mint_locked_amount;
        else if (type == "rewarded") amount = assetItem.rewarded_amount;

        return amount;
    };

    const getWalletMergedCoins = (type: WalletType): WalletCoin[] => {
        const mergedCoinsMap: Map<number, number> = new Map();

        userInfo?.assets?.items.forEach((assetItem) => {
            const amount = getAssetAmount(assetItem, type);

            const existingAmount = mergedCoinsMap.get(assetItem.coin.id) || 0;
            mergedCoinsMap.set(assetItem.coin.id, existingAmount + amount);
        });

        const mergedCoins: WalletCoin[] = Array.from(mergedCoinsMap)
            .filter(([coinId, amount]) => amount > 0)
            .map(([coinId, amount]) => {
                const coin = userInfo?.assets?.items.find(
                    (assetItem) => assetItem.coin.id === coinId
                )?.coin!;

                return { coin, amount };
            });

        return mergedCoins;
    };

    const renderWalletPrice = (wallet_coins: WalletCoin[]) => {
        if (wallet_coins.length > 0) {
            return wallet_coins.map((wallet_coin) => {
                return (
                    <Price
                        key={wallet_coin.coin.id}
                        priceSource="none"
                        price={wallet_coin.amount}
                        coin={wallet_coin.coin}
                        normalSizeSymbol={true}
                    />
                );
            });
        } else {
            return (
                <Price
                    priceSource="none"
                    price={0}
                    fiat={settings?.client.advertised_fiat}
                    coin={settings?.client.advertised_coin}
                    normalSizeSymbol={true}
                />
            );
        }
    };

    const renderUserInfo = (content: ReactNode) => {
        return fullConnected ? (
            !loadingUserInfo ? (
                content
            ) : (
                <Skeleton width={40} />
            )
        ) : (
            "—"
        );
    };

    return (
        <Box className={clsx(styles.dashboard, className)} title="My Rewards">
            {joinToDiscordTask && (
                <div className={styles.rewardItem}>
                    <div className={styles.rewardText}>
                        <Pair
                            title="Link your discord account in your profile."
                            value="Link Discord"
                            titlePosition="bottom"
                        />
                    </div>

                    <div
                        className={styles.rewardButton}
                        onClick={() => setOpenProfile(true)}
                    >
                        <div>Get Reward</div>
                        <div className={styles.rewardButtonSub}>{`(${
                            0.02 *
                            (userInfo?.grades_info.highest_grade
                                ?.reward_multiplier || 1)
                        } SOL)`}</div>
                    </div>

                    <ProfileOverview
                        open={openProfile}
                        onClose={() => setOpenProfile(false)}
                    />
                </div>
            )}

            {joinToTwitterTask && (
                <div
                    className={clsx(styles.rewardItem, styles.rewardItemGreen)}
                >
                    <div className={styles.rewardText}>
                        <Pair
                            title="Link your twitter account in your profile."
                            value="Link Twitter"
                            titlePosition="bottom"
                        />
                    </div>

                    <div
                        className={clsx(
                            styles.rewardButton,
                            styles.rewardButtonGreen
                        )}
                        onClick={() => setOpenProfile(true)}
                    >
                        <div>Get Reward</div>
                        <div className={styles.rewardButtonSub}>{`(${
                            0.02 *
                            (userInfo?.grades_info.highest_grade
                                ?.reward_multiplier || 1)
                        } SOL)`}</div>
                    </div>

                    <ProfileOverview
                        open={openProfile}
                        onClose={() => setOpenProfile(false)}
                    />
                </div>
            )}

            <div className={styles.statList}>
                <Feature className={styles.statItem}>
                    <Pair
                        className={styles.assetItem}
                        title="Total Revenue"
                        titlePosition="bottom"
                        value={renderUserInfo(
                            renderWalletPrice(getWalletMergedCoins("rewarded"))
                        )}
                    />
                </Feature>

                <Feature className={styles.statItem}>
                    <Pair
                        className={styles.assetItem}
                        title="Rewarded Tasks"
                        titlePosition="bottom"
                        value={renderUserInfo(
                            <Counter number={rewardedTasks} />
                        )}
                    />
                </Feature>

                <Feature className={styles.statItem}>
                    <Pair
                        className={styles.assetItem}
                        title="My NFTs"
                        titlePosition="bottom"
                        value={renderUserInfo(<Counter number={nftCount} />)}
                    />
                </Feature>
            </div>

            {fullConnected ? (
                <>
                    <WalletOverview
                        availableCoins={getWalletMergedCoins("available")}
                        withdrawingCoins={getWalletMergedCoins("withdrawing")}
                        lockedCoins={getWalletMergedCoins("locked")}
                        actionButton={{
                            content: "My Wallet",
                            hide: loading,
                            onClick: () => setOpenWallet(true),
                        }}
                    />

                    <WalletDialog
                        open={openWallet}
                        onClose={() => setOpenWallet(false)}
                    />
                </>
            ) : (
                <Feature type="message">
                    <WalletConnectText>Connect Wallet</WalletConnectText> to see
                    your rewards
                </Feature>
            )}

            {/*fullConnected && !loadingUserInfo && (
                <div className={styles.claimHint}>
                    <span>
                        You need to mint at least 2 NFTs in order to claim
                        during mint event.
                    </span>
                    <Hint tip="All unclaimed funds will arrive at your wallet at the end of mint event." />
                </div>
            )*/}
        </Box>
    );
};

export default DashboardMintRewards;
