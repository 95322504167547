import { FC, useState } from "react";
import clsx from "clsx";
import FaqList, { FaqItemType } from "../../libs/faq-list/FaqList";
import DashboardInvitesGuide, {
    NetworkGuideTab,
} from "../dashboard-invites/DashboardInvitesGuide";
import styles from "./Preview.module.scss";

type Props = {
    className?: string;
};

const PreviewAboutFaq: FC<Props> = ({ className }) => {
    const [openCircles, setOpenCircles] = useState<boolean>(false);

    const renderFaqList = (): FaqItemType[] => {
        return [
            {
                title: "Will I earn money by just holding my NFT?",
                content: `By just holding your Nfts you will earn 60-80% net profit of each business venture. You can also join our Marketing system to earn up to 35% from your Network.`,
            },
            {
                title: "What will be happen after mint?",
                content: (
                    <>
                        After mint, from our side, we will launch our first
                        Project named{" "}
                        <a
                            href="https://lottofactory.io"
                            target="_blank"
                            rel="noreferrer"
                        >
                            LottoFactory.io
                        </a>
                        , and we will continue to develop more businesses. From
                        your side, The network will continue with a new
                        collection which will not have access to the CRC DAO,
                        but they will have access to the network benefits.
                    </>
                ),
            },
            {
                title: "What is LottoFactory?",
                content: (
                    <>
                        <a
                            href="https://lottofactory.io"
                            target="_blank"
                            rel="noreferrer"
                        >
                            LottoFactory.io
                        </a>{" "}
                        is a cutting-edge lottery system that utilizes
                        blockchain technology to provide secure and transparent
                        gameplay. The platform was developed by a highly skilled
                        team of engineers and business builders and is built on
                        the Solana blockchain, known for its high transaction
                        speed and scalability.{" "}
                        <a
                            href="https://docs.lottofactory.io"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Learn more
                        </a>
                    </>
                ),
            },
            {
                title: "How can I participate in business growth?",
                content: `By building a proactive and reliable Network, you can lead them to achieve the specific goals.`,
            },
            {
                title: "Can I really make 15% from each invited mint?",
                content: `For each direct invite you do, you will be eligible to earn exact 15% of their contribution. After you mint your first CRC NFT you will have access to create your affiliate link.`,
            },
            {
                title: "What are the network circles?",
                content: `Your invited friends are your circle 1, and their invited friends on deeper levels will be your next circles.`,
            },
            {
                title: "What is the 20% network commission?",
                content: (
                    <>
                        You will be eligible to earn up to 20% from your invites
                        from your network (circles 2 to 5) based on{" "}
                        <a onClick={() => setOpenCircles(true)}>
                            Circles Commissions
                        </a>
                        .
                    </>
                ),
            },
            {
                title: "How can I see my network's new invites and mints?",
                content: `By navigating to "My Network" menu you will access your network. by clicking on each invited member you can see their network and activity.`,
            },
            {
                title: "Can I instantly claim my revenue from afiiliate program?",
                content: `Yes, for sure. After each mint in your network, you have access to claim your share.`,
            },
            {
                title: "When is the $88,888 giveaway?",
                content: `The $88,888 giveaway will run right after selling out the CRC collection. It's for celebrating and appreciating your trust and what you did through time as our early supporter. It's just the start of the Beginning.`,
            },
            {
                title: "Where can I find more info about the project?",
                content: (
                    <>
                        Join our community in{" "}
                        <a
                            href="https://discord.gg/eeyTvXgMAq"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Discord
                        </a>
                        , and follow our social media for more updates.
                    </>
                ),
            },
        ];
    };

    return (
        <>
            <FaqList
                list={renderFaqList()}
                className={clsx(styles.faqList, className)}
            />
            <DashboardInvitesGuide
                open={openCircles}
                defaultTab={NetworkGuideTab.CIRCLES}
                onClose={() => setOpenCircles(false)}
            />
        </>
    );
};

export default PreviewAboutFaq;
