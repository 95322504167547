import { FC, ReactNode, useMemo, useState } from "react";
import clsx from "clsx";
import AnimateHeight from "react-animate-height";
import { MdKeyboardArrowDown } from "react-icons/md";
import PreviewAboutFaq from "./PreviewAboutFaq";
import SectionList from "../../libs/section-list/SectionList";
import PreviewAboutGrades from "./PreviewAboutGrades";
import styles from "./Preview.module.scss";

type AboutGroupType = {
    title: ReactNode;
    content: ReactNode;
};

type Props = {
    className?: string;
};

const PreviewAbout: FC<Props> = ({ className }) => {
    const [groupOpenIndex, setGroupOpenIndex] = useState<number>(-1);

    const renderGroupList: AboutGroupType[] = useMemo(() => {
        return [
            {
                title: "CRC DAO",
                content: (
                    <SectionList
                        list={[
                            {
                                title: `HODLer Rewards & More..`,
                                content: `60-80% of all Crystal Rabbit Club business venture profits (60% to all NFTs, 10% to VIP Club and 10% to V-VIP Club) are distributed to all CRC holders each day. The more CRC you hold the more PROFITS you earn.`,
                            },
                            {
                                title: `DAO Proposal Submissions`,
                                content: `Have an idea that can improve the club? Maybe a new business plan? Submit the proposal to the DAO… it may just get voted in and funded if needed.`,
                            },
                            {
                                title: `DAO Votes`,
                                content: `The core of a strong community DAO is the ability of the members to have a say in the direction the community takes. Each wallet holding a CRC will have 1 vote on proposals submitted.`,
                            },
                        ]}
                    />
                ),
            },
            {
                title: "Roadmap",
                content: (
                    <SectionList
                        list={[
                            {
                                title: `Q2 2023 - Step 1: Initial Launch`,
                                content: (
                                    <>
                                        We&apos;ll launch our collection of
                                        10,000 CRC NFTs, and implement a holder
                                        verification system. Following a
                                        successful sellout, we&apos;ll implement
                                        our marketing plan and create a DAO for
                                        future business plans. Initial funds
                                        (mint fund) will be split into 2
                                        categories. Both ensure the long-term
                                        success of the project.
                                        <br />
                                        <br />
                                        - 50% of funds will go to the treasury
                                        wallet to secure the future investments.
                                        <br />
                                        - 30% of funds will go to the marketing
                                        wallet to implement our massive
                                        marketing plan.
                                        <br />- 20% of funds will go to the
                                        team, for developing and creating this
                                        amazing platform.
                                    </>
                                ),
                            },
                            {
                                title: `Q3 2023 - Step 2: Platforms Demo`,
                                content: (
                                    <>
                                        In order to maintain customer
                                        satisfaction as well as satisfy
                                        investors that we are working on the
                                        development of the platforms, the team
                                        will release teasers and demos.
                                        <br />
                                        <br />
                                        - A variety of media will be present,
                                        from promotional videos to early
                                        business demonstrations.
                                        <br />- It will take place before the
                                        full platform is launched.
                                    </>
                                ),
                            },
                            {
                                title: `Q4 2023 - Step 3: Marketing`,
                                content: (
                                    <>
                                        Once we have proof of a working
                                        platform/demo, more influencers will
                                        approach the team and we will be
                                        regarded higher amongst the NFT
                                        community. Our marketing department will
                                        benefit from this status. As part of our
                                        marketing efforts, we will engage large
                                        influencers who see the potential of CRC
                                        products.
                                    </>
                                ),
                            },
                            {
                                title: `Q1 2024 - Step 4: Launch Platforms & Partnership`,
                                content: (
                                    <>
                                        Everyone will be able to join us and
                                        observe the full site. Managing
                                        strategic partnerships for several top
                                        online business ventures as a senior
                                        community manager.
                                    </>
                                ),
                            },
                            {
                                title: `From Q2 2024 - Step 5: Future development & More Businesses`,
                                content: (
                                    <>
                                        The future of our project will be
                                        community driven. Everyone can
                                        participate to choose or design new
                                        business plan.
                                        <br />
                                        <br />
                                        - Our goal is to make a new business
                                        every 3 months. in hindsight, more
                                        businesses equals more revenue stream
                                        for community and more profit for
                                        investors.
                                        <br />- We&apos;ll launch our exclusive
                                        partnership platform so we can reach our
                                        global goals.
                                    </>
                                ),
                            },
                        ]}
                    />
                ),
            },
            {
                title: "Risks and Mitigations",
                content: (
                    <SectionList
                        list={[
                            {
                                title: `Risk 1: Solana NFT Market Trends and Adaptability`,
                                content: (
                                    <>
                                        The Solana NFT market is volatile and
                                        subject to frequent shifts in trends.
                                        Adapting quickly to meet market needs
                                        can be challenging. To mitigate this
                                        risk, we focus on building a strong
                                        community, diversifying our offerings,
                                        and emphasizing long-term value. Our
                                        sustainable income-generating ecosystem
                                        supports the longevity of our project.
                                    </>
                                ),
                            },
                            {
                                title: `Risk 2: Project Adoption`,
                                content: (
                                    <>
                                        To address the risk of limited adoption
                                        of the CRC NFT collection, we have
                                        developed a comprehensive marketing and
                                        outreach strategy. This includes
                                        engaging with influencers, partnering
                                        with industry leaders, and leveraging
                                        social media platforms to drive
                                        awareness and attract a wide user base.
                                        We will also seek strategic
                                        collaborations and utilize data-driven
                                        insights to optimize our efforts and
                                        ensure the widespread adoption of our
                                        NFT collection.
                                    </>
                                ),
                            },
                            {
                                title: `Risk 3: Crypto Market Volatility`,
                                content: (
                                    <>
                                        The Crypto market is known for its high
                                        level of volatility, as evidenced by
                                        notable failures and disruptions in
                                        blockchain development and support in
                                        2022. To mitigate this risk, we employ a
                                        proactive approach by closely monitoring
                                        the market and promptly adjusting our
                                        strategies to anticipate and navigate
                                        potential downturns or sudden shifts in
                                        trends. By staying vigilant and
                                        adaptable, we aim to minimize the impact
                                        of market volatility on our
                                        project&apos;s progress and success.
                                    </>
                                ),
                            },
                        ]}
                    />
                ),
            },
            {
                title: "FAQ",
                content: <PreviewAboutFaq />,
            },
        ];
    }, []);

    const handleGrouopTitleClick = (groupIndex: number) => {
        if (groupIndex != groupOpenIndex) {
            setGroupOpenIndex(groupIndex);
        } else setGroupOpenIndex(-1);
    };

    return (
        <div className={clsx(styles.previewWrap, className)}>
            <div className={styles.previewSection}>
                <div className={styles.previewSectionTitle}>What is CRC?</div>
            </div>
            <div className={styles.about}>
                <a href="https://crc.club" target="_blank" rel="noreferrer">
                    CRC
                </a>{" "}
                is a profit-sharing NFT collection where 60-80% of every
                business venture&apos;s net profits will be airdropped to
                holders! (60% to all NFTs, 10% to VIP Club and 10% to V-VIP
                Club)
                <br />
                <br />
                Holders will have access to the DAO to make the most critical
                decisions for the project&apos;s good
            </div>

            <PreviewAboutGrades />

            {renderGroupList.map((groupItem, groupIndex) => {
                return (
                    <div
                        key={groupIndex}
                        className={clsx(
                            styles.groupWrap,
                            groupOpenIndex == groupIndex && styles.groupWrapOpen
                        )}
                    >
                        <div
                            className={clsx(
                                styles.groupTitle,
                                groupOpenIndex == groupIndex &&
                                    styles.groupTitleOpen
                            )}
                            onClick={() => handleGrouopTitleClick(groupIndex)}
                        >
                            <span>{groupItem.title}</span>
                            <MdKeyboardArrowDown className={styles.arrow} />
                        </div>
                        <AnimateHeight
                            duration={500}
                            height={groupOpenIndex == groupIndex ? "auto" : 0}
                        >
                            <div className={clsx(styles.groupContent)}>
                                {groupItem.content}
                            </div>
                        </AnimateHeight>
                    </div>
                );
            })}
        </div>
    );
};

export default PreviewAbout;
