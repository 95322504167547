import { FC, PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "./Feature.module.scss";

type Props = {
    className?: string;
    type?: "simple" | "message";
};

const Feature: FC<PropsWithChildren<Props>> = ({
    children,
    type,
    className,
}) => {
    return (
        <div
            className={clsx(
                styles.wrap,
                type == "message" && styles.message,
                className
            )}
        >
            {children}
        </div>
    );
};

export default Feature;
