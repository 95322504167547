import { FC, useEffect, useState, ReactNode } from "react";
import clsx from "clsx";
import PriceUnit from "./PriceUnit";
import { convertPrice } from "../../services/price";
import { CoinType } from "../../types/coin";
import { FiatType } from "../../types/fiat";
import useSettings from "../../hooks/useSettings";
import styles from "./Price.module.scss";

type Props = {
    className?: string;
    fiat?: FiatType;
    coin?: CoinType;
    priceSource: "primary" | "none";
    price: number;
    originalPrice?: number;
    originalPosition?: "start" | "end" | "top" | "bottom"; // default: bottom
    suffix?: ReactNode;
    normalSizeSymbol?: boolean;
};

const Price: FC<Props> = ({
    className,
    fiat,
    coin,
    priceSource,
    price,
    originalPrice,
    originalPosition,
    suffix,
    normalSizeSymbol,
}) => {
    const { settings } = useSettings();
    const [targetPrice, setTargetPrice] = useState<number>(0);
    const [targetOriginalPrice, setTargetOriginalPrice] = useState<number>(0);
    const [targetFiat, setTargetFiat] = useState<FiatType>();
    const [targetCoin, setTargetCoin] = useState<CoinType>();

    useEffect(() => {
        if (settings) {
            // set target price and original
            if (priceSource != "none" && (fiat || coin)) {
                let primaryRateInUsd = 0;
                if (settings?.client.primary_fiat) {
                    primaryRateInUsd = settings?.client.primary_fiat.price_usd;
                } else if (settings?.client.primary_coin) {
                    primaryRateInUsd = settings?.client.primary_coin.price_usd;
                }

                if (fiat) {
                    setTargetPrice(
                        convertPrice(price, primaryRateInUsd, fiat.price_usd)
                    );
                    setTargetOriginalPrice(
                        convertPrice(
                            originalPrice || 0,
                            primaryRateInUsd,
                            fiat.price_usd
                        )
                    );
                } else if (coin) {
                    setTargetPrice(
                        convertPrice(price, primaryRateInUsd, coin.price_usd)
                    );
                    setTargetOriginalPrice(
                        convertPrice(
                            originalPrice || 0,
                            primaryRateInUsd,
                            coin.price_usd
                        )
                    );
                }
            } else {
                setTargetPrice(price);
                setTargetOriginalPrice(originalPrice || 0);
            }

            // set target fiat or coin
            if (fiat || coin) {
                setTargetFiat(fiat);
                setTargetCoin(coin);
            } else {
                setTargetFiat(settings.client.primary_fiat);
                setTargetCoin(settings.client.primary_coin);
            }
        }
    }, [settings?.client, price, originalPrice, priceSource, fiat, coin]);

    return (
        <div
            className={clsx(
                styles.priceWrap,
                originalPosition == "top" && styles.priceWrapTop,
                (!originalPosition || originalPosition == "bottom") &&
                    styles.priceWrapBottom,
                originalPosition == "start" && styles.priceWrapStart,
                originalPosition == "end" && styles.priceWrapEnd,
                className
            )}
        >
            <PriceUnit
                className={styles.priceItem}
                price={targetPrice}
                targetFiat={targetFiat}
                targetCoin={targetCoin}
                suffix={suffix}
                normalSizeSymbol={normalSizeSymbol}
            />
            {!!targetOriginalPrice && targetOriginalPrice != targetPrice && (
                <PriceUnit
                    className={styles.priceItem}
                    price={targetOriginalPrice}
                    targetFiat={targetFiat}
                    targetCoin={targetCoin}
                    suffix={suffix}
                    isCrossedOut={true}
                    normalSizeSymbol={normalSizeSymbol}
                />
            )}
        </div>
    );
};

export default Price;
