import { FC } from "react";
import clsx from "clsx";
import DashboardInvestInfo from "./DashboardInvestInfo";
import DashboardInvestCampaigns from "./DashboardInvestCampaigns";
import DashboardInvestFunds from "./DashboardInvestFunds";
import DashboardInvestGains from "./DashboardInvestGains";
import { RiFundsFill, RiMoneyDollarCircleFill } from "react-icons/ri";
import { BsPiggyBankFill } from "react-icons/bs";
import styles from "./DashboardInvest.module.scss";

type Props = {
    className?: string;
};

const DashboardInvest: FC<Props> = ({ className }) => {
    return (
        <div className={clsx(styles.dashboard, className)}>
            <DashboardInvestInfo />

            <div className={styles.sectionLabel}>
                <RiFundsFill className={styles.icon} />
                <div className={styles.titleWrap}>
                    <div className={styles.title}>Funding Campaigns</div>
                    <div className={styles.titleSub}>
                        Campaigns that are currently funding by CRC community
                    </div>
                </div>
            </div>
            <DashboardInvestCampaigns />

            <div className={styles.sectionLabel}>
                <BsPiggyBankFill className={styles.icon} />
                <div className={styles.titleWrap}>
                    <div className={styles.title}>My Campaigns</div>
                    <div className={styles.titleSub}>
                        Campaigns funded by you that will gain accordingly
                    </div>
                </div>
            </div>
            <DashboardInvestFunds />

            <div className={styles.sectionLabel}>
                <RiMoneyDollarCircleFill className={styles.icon} />
                <div className={styles.titleWrap}>
                    <div className={styles.title}>My Gains</div>
                    <div className={styles.titleSub}>
                        Your income from your funded campaigns
                    </div>
                </div>
            </div>
            <DashboardInvestGains />
        </div>
    );
};

export default DashboardInvest;
