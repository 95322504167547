import {
    FC,
    createContext,
    useContext,
    useEffect,
    useState,
    PropsWithChildren,
} from "react";

const ResponsiveContext = createContext(null);

const useResponsive = () => {
    const [isMobile, setIsMobile]: any = useContext(ResponsiveContext);
    return [isMobile, setIsMobile];
};

export const ResponsiveProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // init dimensions
        updateDimensions();

        // detect dimensions
        window.addEventListener("resize", updateDimensions);

        // clean dimensions detection
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setIsMobile(width < 1024);
    };

    return (
        <ResponsiveContext.Provider value={[isMobile, setIsMobile] as any}>
            {children}
        </ResponsiveContext.Provider>
    );
};

export default useResponsive;
